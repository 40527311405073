import React from "react";
import { Form, Input, Radio, Typography, Checkbox, Tag } from "antd";
import { Col, Row } from "antd";
import { DatePicker, Space } from "antd";
import moment from "moment";
import createUserLogo from "../../../assets/images/icons/fullname_generalinfo_grey@3x.png";
import userLogoWhite from "../../../assets/images/icons/User@3x.png";
import Message from "../../../assets/images/icons/mail_generalinfo_grey@3x.png";
import Password from "../../../assets/images/icons/password_generalinfo_grey@3x.png";
import PreviewMessage from "../../../assets/images/icons/message-white-3x.png";
import PreviewLock from "../../../assets/images/icons/Pass@3x.png";
import Calendar from "../../../assets/images/icons/Calendar@3x.png";
import Gender from "../../../assets/images/icons/Gender@3x.png";
import Phone from "../../../components/PhoneInput";
import Country from "../../../components/CountryDropdown";

const FormInputs = ({
  disable,
  title,
  firstName,
  lastName,
  arabicFirstName,
  arabicLastName,
  memberEmail,
  memberPassword,
  phoneCode,
  memberPhone,
  memberCountry,
  fileList,
  gender,
  DOB,
  selectedServices,
  setFirstName,
  setLastName,
  setArabicFirstName,
  setArabicLastName,
  setMemberEmail,
  setMemberPassword,
  setMemberPhone,
  handleSelect,
  handleFlagCountry,
  selected,
  onGenderChange,
  onDateChange,
  radioStyle,
}) => {
  const dateFormat = "YYYY-MM-DD";

  return (
    <div className={title === "Preview" && "preview-only"}>
      {title === "Preview" ? (
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please input name!",
            },
          ]}
        >
          <Input
            disabled={true}
            addonBefore={
              <img alt="" src={userLogoWhite} className="preview-icons input-icon-field-twenty" />
            }
            placeholder="Enter your Name"
            autoComplete="off"
            defaultValue={`${firstName}${" "}${lastName}`}
          />
        </Form.Item>
      ) : (
        <>
          <Row justify="space-between" style={{ marginBottom: "-18px" }}>
            <Col span={11}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input first name!",
                  },
                ]}
              >
                <Input
                  className={"inputfield mtt-20"}
                  defaultValue={firstName}
                  maxLength={15}
                  prefix={
                    <img
                      alt=""
                      src={createUserLogo}
                      className="site-form-item-icon input-icon-field-twenty"
                    />
                  }
                  placeholder="Enter First Name"
                  disabled={disable}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please input last name!",
                  },
                ]}
              >
                <Input
                  className={"inputfield mtt-20"}
                  defaultValue={lastName}
                  maxLength={15}
                  prefix={
                    <img
                      alt=""
                      src={createUserLogo}
                      className="site-form-item-icon input-icon-field-twenty"
                    />
                  }
                  placeholder="Enter Last Name"
                  disabled={disable}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      {title === "Preview" ? (
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please input arabic name!",
            },
          ]}
        >
          <div className="arabic-fields">
            <Input
              disabled={true}
              addonAfter={
                <img alt="" src={userLogoWhite} className="preview-icons input-icon-field-twenty" />
              }
              placeholder="Enter your Name"
              autoComplete="off"
              defaultValue={`${arabicFirstName}${" "}${arabicLastName}`}
            />
          </div>
        </Form.Item>
      ) : (
        <>
          <div className="arabic-fields">
            <Row justify="space-between">
              <Col span={11}>
                <Form.Item
                  name="arabicLastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input last arabic name!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-20"}
                    defaultValue={arabicLastName}
                    maxLength={15}
                    suffix={
                      <img
                        alt=""
                        src={createUserLogo}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    placeholder="إدخال اسم آخر"
                    onChange={(e) => {
                      setArabicLastName(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="arabicFirstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input first arabic name!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-20"}
                    defaultValue={arabicFirstName}
                    maxLength={15}
                    suffix={
                      <img
                        alt=""
                        src={createUserLogo}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    placeholder="أدخل الاسم الأول"
                    onChange={(e) => {
                      setArabicFirstName(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </>
      )}

      {title === "Preview" ? (
        <Form.Item
          name="memberEmail"
          rules={[
            {
              required: true,
              message: "Please Input Arabic Name!",
            },
          ]}
        >
          <Input
            disabled={true}
            addonBefore={
              <img
                alt=""
                src={PreviewMessage}
                className="preview-icons input-icon-field"
              />
            }
            placeholder="Enter your Email"
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item
            name="memberEmail"
            rules={[
              {
                required: true,
                message: "Please input email!",
              },
            ]}
          >
            <Input
              className={"inputfield"}
              defaultValue={memberEmail}
              prefix={
                <img
                  alt=""
                  src={Message}
                  className="site-form-item-icon input-icon-field"
                />
              }
              placeholder="Enter your Email"
              disabled={disable}
              onChange={(e) => {
                setMemberEmail(e.target.value);
              }}
            />
          </Form.Item>
        </>
      )}

      {title === "Preview" ? (
        <Form.Item
          name="memberPassword"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input
            disabled={true}
            addonBefore={
              <img
                alt=""
                src={PreviewLock}
                className="preview-icons input-icon-field-twenty"
              />
            }
            type="password"
            placeholder="Enter Password"
            autoComplete="off"
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item
            name="memberPassword"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              className="inputfield"
              prefix={
                <img
                  alt=""
                  src={Password}
                  className="site-form-item-icon input-icon-field-twenty"
                />
              }
              type="password"
              placeholder="Enter Your Password"
              defaultValue={memberPassword}
              disabled={disable}
              onChange={(e) => {
                setMemberPassword(e.target.value);
              }}
            />
          </Form.Item>
        </>
      )}

      <Form.Item
        name="memberPhone"
        rules={[
          {
            required: true,
            message: "Please input your phone number!",
          },
        ]}
      >
        <Phone
          disabled={disable}
          value={memberPhone}
          onChange={(phone) => setMemberPhone(phone)}
        />
      </Form.Item>
      <Form.Item
        name="country"
        rules={[
          {
            required: true,
            message: "Please input country!",
          },
        ]}
      >
        <Country
          selected={selected}
          onSelect={handleFlagCountry}
          disabled={disable}
        />
      </Form.Item>

      {title === "Preview" ? (
        <Form.Item
          name="DOB"
          rules={[
            {
              required: false,
              message: "Please input date of birth!",
            },
          ]}
        >
          <Input
            defaultValue={DOB}
            disabled={true}
            addonBefore={
              <img alt="" src={Calendar} className="preview-icons input-icon-field-twenty" />
            }
            placeholder="Enter your DOB"
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item name="DOB">
            <Typography className="date-of-birth">Date of Birth</Typography>
            <Space direction="vertical" style={{ width: "100%" }}>
              <DatePicker
                className="inputfield"
                onChange={onDateChange}
                defaultValue={moment(DOB, dateFormat)}
                format={dateFormat}
              />
            </Space>
          </Form.Item>
        </>
      )}

      {title === "Preview" ? (
        <Form.Item
          name="tenantGender"
          rules={[
            {
              required: false,
              message: "Please input gender!",
            },
          ]}
        >
          <Input
            defaultValue={gender}
            disabled={true}
            addonBefore={<img alt="" src={Gender} className="preview-icons input-icon-field-twenty" />}
            placeholder="Enter your Gender"
          />
        </Form.Item>
      ) : (
        <>
          <div className="gender-radio-div">
            <Form.Item
              name="tenantGender"
              rules={[
                {
                  required: true,
                  message: "Please select gender!",
                },
              ]}
            >
              <Typography className="date-of-birth">Gender</Typography>
              <Radio.Group
                defaultValue={gender}
                onChange={onGenderChange}
                buttonStyle="solid"
              >
                <Radio.Button
                  value="Male"
                  className={gender === "Male" ? "" : "grey-border"}
                >
                  <div className="inner-container-radio-gender">
                    <p>Male</p>
                    {/* <img
                      alt=""
                      src={gender === "Male" ? FilledCircle : Circle}
                      className="gender-circle"
                    /> */}
                  </div>
                </Radio.Button>
                <Radio.Button
                  value="Female"
                  className={gender === "Female" ? "" : "grey-border"}
                >
                  <div className="inner-container-radio-gender">
                    <p>Female</p>
                    {/* <img
                      alt=""
                      src={gender === "Female" ? FilledCircle : Circle}
                      className="gender-circle"
                    /> */}
                  </div>
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
        </>
      )}

      {title === "Preview" && (
        <>
          <h6 className="sm-title-card mtt-10"> Assigned Services </h6>
          <div className="selected-services mtt-20">
            <Form.Item
              name="services"
              rules={[
                {
                  required: true,
                  message: "Please select services!",
                },
              ]}
            >
              {selectedServices?.map((item, index) => (
                <>
                  <div className="slotAvailability d-flex my-3">
                    <Checkbox
                      className="slotChecks"
                      style={{ width: "50%", alignItems: "center" }}
                      options={selectedServices}
                      defaultValue={selectedServices}
                      key={index}
                      checked={true}
                    >
                      <div>{item.label}</div>
                    </Checkbox>
                    <div
                      style={{
                        width: "50%",
                        textAlignLast: "end",
                      }}
                    >
                      <Tag
                        type="primary"
                        className="privilege-Btn"
                        style={{ margin: "0 8px", textAlignLast: "center" }}
                      >
                        <p style={{ margin: "0.35rem 0 0.25rem 0" }}>
                          {item.privilege === "read"
                            ? "Read-Only"
                            : "Read-Write"}
                        </p>
                      </Tag>
                    </div>
                  </div>
                </>
              ))}
            </Form.Item>
          </div>
        </>
      )}
    </div>
  );
};
export default FormInputs;
