export const MAINTENANCE_DASHBOARD = "MAINTENANCE_DASHBOARD";
export const MAINTENANCE_DASHBOARD_ERROR = "MAINTENANCE_DASHBOARD_ERROR";
export const ADD_MAINTENANCE = "ADD_MAINTENANCE";
export const ADD_MAINTENANCE_ERROR = "ADD_MAINTENANCE_ERROR";
export const MAINTENANCE_REQUESTS = "MAINTENANCE_REQUESTS";
export const MAINTENANCE_REQUESTS_ERROR = "MAINTENANCE_REQUESTS_ERROR";
export const UPDATE_MAINTENANCE_MEMBER = "UPDATE_MAINTENANCE_MEMBER";
export const STATUS_MAINTENANCE_MEMBER = "STATUS_MAINTENANCE_MEMBER";
export const STATUS_MAINTENANCE_MEMBER_ERROR = "STATUS_MAINTENANCE_MEMBER_ERROR";
export const MAINTENANCE_LISTING = "MAINTENANCE_LISTING";
export const MAINTENANCE_LISTING_ERROR = "MAINTENANCE_LISTING_ERROR";
