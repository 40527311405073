import React, { useState } from "react";
import "antd/dist/antd.min.css";
import { Button, Card, Col, Divider, Row, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import galleryPlaceholder from "../../assets/images/icons/galleryPlaceholder.jpg";
import { organizationMaintenanceList } from "../../store/actions/maintenance.action";
import Pagination from "../../components/Pagination";

const { Meta } = Card;

const MaintenanceListing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [maintenanceList, setMaintenanceList] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [loading, setLoading] = useState(true);
  const reset = location?.state?.reset;
  const id = useSelector((state) => state?.user?.currentUser?.organization_id);
  const getMaintenance = useSelector(
    (state) => state?.maintenance?.maintenanceListing
  );
  const per_page = getMaintenance?.pagination?.per_page;
  const page_count = getMaintenance?.pagination?.page;
  const services = useSelector((state) => state?.user?.services);

  let currentService = "";
  if (services) {
    currentService = services?.find(
      (item) => item?.service === "Maintenance"
    )?.privilege;
  }

  const listOfMaintenance = async () => {
    if (reset === true) {
      setMaintenanceList([]);
      const maintenance = await dispatch(
        organizationMaintenanceList(1, 10, id)
      );
      setMaintenanceList(maintenance.data?.maintenaces);
      setLoading(false);
      navigate(location.pathname, { replace: true });
    } else {
      setMaintenanceList([]);
      const maintenance = await dispatch(
        organizationMaintenanceList(
          page_count ? page_count : pageCount,
          per_page ? per_page : perPage,
          id
        )
      );
      setPageCount(page_count);
      setPerPage(per_page);
      setMaintenanceList(maintenance.data?.maintenaces);
      setLoading(false);
    }
  };

  const handlePageChange = async (page, pageSize) => {
    setPageCount(page);
    setPerPage(pageSize);
    const res = await dispatch(organizationMaintenanceList(page, pageSize, id));
    setMaintenanceList(res.data?.maintenaces);
  };

  useEffect(() => {
    setTotalPageItems(getMaintenance?.pagination?.count);
  }, [getMaintenance]);

  useEffect(() => {
    listOfMaintenance();
  }, []);
  return loading ? (
    <div className="no-data-found">
      <Spin />
    </div>
  ) : (
    <div className="site-card-wrapper">
      <div className="maintenanceListing">
        <Row gutter={16}>
          {maintenanceList?.length > 0 ? (
            maintenanceList?.map((maintenance) => {
              return (
                <Col key={maintenance.id} flex="0 0 25%">
                  <Card
                    hoverable
                    className="listing-card"
                    cover={
                      <img
                        alt=""
                        src={
                          maintenance?.services_images.slice(-1)[0]?.file_name
                            ? `${process.env.REACT_APP_S3_BUCKET_URL}/${
                                maintenance?.services_images.slice(-1)[0]
                                  ?.file_name
                              }`
                            : galleryPlaceholder
                        }
                        className="listing-image"
                      />
                    }
                  >
                    <div className="listing-body">
                      <Meta
                        title={
                          maintenance?.name.slice(0, 17) +
                          (maintenance?.name.length > 17 ? "..." : "")
                        }
                      />
                      <p href="#" className="listing-price">
                        <b className="ps-2">
                          {maintenance.amount !== null
                            ? "AED " + maintenance.amount
                            : "AED 0"}
                        </b>
                      </p>
                    </div>
                    <div>
                      <Button
                        onClick={() =>
                          navigate(`/havenly/editMaintenance`, {
                            state: maintenance,
                          })
                        }
                        type="primary"
                        className="LgBtn build-btn mtt-10"
                        style={{
                          width: "220px",
                          justifyContent: "space-evenly",
                        }}
                        disabled={
                          currentService === "read-write" ? false : true
                        }
                      >
                        <p className="add-new-building-btn">Manage</p>
                      </Button>
                    </div>
                  </Card>
                </Col>
              );
            })
          ) : (
            <div className="no-data-found">
              <p className="grey-defTxt">No Maintenance Found!</p>
            </div>
          )}
        </Row>
        <div style={{ width: "100%", position: "relative" }}>
          <div className="listing-table-pagination">
            <Pagination
              total={totalPageItems}
              defaultPageSize={perPage}
              defaultCurrent={pageCount}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceListing;
