import {
  EARLIER_NOTIFICATIONS,
  EARLIER_NOTIFICATIONS_ERROR,
  NOTIFICATIONS,
  NOTIFICATIONS_ERROR,
  TODAY_NOTIFICATIONS,
  TODAY_NOTIFICATIONS_ERROR,
  UN_READ_NOTIFICATIONS_COUNT_AMENITIES,
  UN_READ_NOTIFICATIONS_COUNT_MAINTENANCE,
  UN_READ_NOTIFICATIONS_COUNT_PARCEL,
  NOTIFICATION_RECEIVED_ARRAY,
} from "../types/notifications.types";
import axios from "axios";
import _ from "lodash";
export const allNotificationsAction =
  (current, perItem, id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/announcement/api/notification/admin/list/?page=${current}&per_page=${perItem}&sort_by=created_at&sort_order=DESC`,
        { organization_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: NOTIFICATIONS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: NOTIFICATIONS_ERROR,
        payload: error,
      });
      return error;
    }
  };
export const todayNotificationsAction =
  (current, perItem, id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/announcement/api/notification/admin/today?page=${current}&per_page=${perItem}&sort_by=created_at&sort_order=DESC`,
        { organization_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: TODAY_NOTIFICATIONS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: TODAY_NOTIFICATIONS_ERROR,
        payload: error,
      });
      return error;
    }
  };
export const earlierNotificationsAction =
  (current, perItem, id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/announcement/api/notification/admin/earlier?page=${current}&per_page=${perItem}&sort_by=created_at&sort_order=DESC`,
        { organization_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: EARLIER_NOTIFICATIONS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: EARLIER_NOTIFICATIONS_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const getAllNotificationsCountAction = (id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/announcement/api/notification/admin/count`,
      { organization_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};
export const unreadNotificationCountOfParcel = (count) => (dispatch) => {
  dispatch({
    type: UN_READ_NOTIFICATIONS_COUNT_PARCEL,
    payload: count,
  });
};

export const unreadNotificationCountOfAmenities = (count) => (dispatch) => {
  dispatch({
    type: UN_READ_NOTIFICATIONS_COUNT_AMENITIES,
    payload: count,
  });
};

export const unreadNotificationCountOfMaintenance = (count) => (dispatch) => {
  dispatch({
    type: UN_READ_NOTIFICATIONS_COUNT_MAINTENANCE,
    payload: count,
  });
};


export const todayNotificationsLabelAction =
  (current, perItem, id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/announcement/api/notification/admin/today-list-label?page=${current}&per_page=${perItem}&sort_by=created_at&sort_order=DESC`,
        { organization_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      return res.data;
    } catch (error) {
      return error;
    }
  };

export const notificationReceivedArray =
  (value) => async (dispatch, getState) => {
    const state = await getState();
    if (!_.isEqual(value, state?.notifications?.notificationsReceivedType))
      dispatch({
        type: NOTIFICATION_RECEIVED_ARRAY,
        payload: value,
      });
  };
