import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Dropdown,
  Menu,
  Select,
  Table,
  Popover,
  Spin,
  Badge,
} from "antd";
import Pagination from "../../components/Pagination";
import { useNavigate, useLocation } from "react-router-dom";
import rescheduleIcon from "../../assets/images/icons/reshedule@3x.png";
import cancelIcon from "../../assets/images/icons/cancel@3x.png";
import ebutler from "../../assets/images/icons/butler.png";
import actionBtn from "../../assets/images/icons/action.png";
import { useDispatch, useSelector } from "react-redux";
import {
  AmenityStatusAction,
  organizationAmenitiesRequestsAction,
  searchAmenitiesRequestsAction,
} from "../../store/actions/amenities.action";
import toastr from "toastr";
import FilterByDate from "../../components/DateFilter";
import { TitleSearch } from "../../components/SearchFilter";
import moment from "moment";
import { AmPmFormat } from "../../services/DateAndTime";

const { Option } = Select;

const AmenitiesRequests = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [company, setCompany] = useState();
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [searchDate, setSearchDate] = useState("");
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const reset = location?.state?.reset;
  const id = useSelector((state) => state?.user?.currentUser?.organization_id);
  const amenitiesRequests = useSelector((state) => state?.amenity?.amenities);
  const notificationArr = useSelector(
    (state) => state?.notifications?.notificationsReceivedType
  );
  const services = useSelector((state) => state?.user?.services);
  let currentService = "";
  if (services) {
    currentService = services?.find(
      (item) => item?.service === "Amenities"
    )?.privilege;
  }

  const per_page = amenitiesRequests?.pagination?.per_page;
  const page_count = amenitiesRequests?.pagination?.page;

  const getAmenities = async () => {
    if (reset === true) {
      const res = await dispatch(
        organizationAmenitiesRequestsAction(0, 10, id)
      );
      if (res?.statusCode === 200) {
        setLoading(false);
        setRequests(res?.data?.amenities);
        navigate(location.pathname, { replace: true });
      } else {
        toastr.error(res?.response?.data?.message);
      }
    } else {
      const res = await dispatch(
        organizationAmenitiesRequestsAction(
          page_count ? page_count : pageCount,
          per_page ? per_page : perPage,
          id
        )
      );
      if (res?.statusCode === 200) {
        setLoading(false);
        setRequests(res?.data?.amenities);
        setPageCount(page_count);
        setPerPage(per_page);
      } else {
        toastr.error(res?.response?.data?.message);
      }
    }
  };
  useEffect(() => {
    getAmenities();
  }, []);

  useEffect(() => {
    if (notificationArr?.filter((item) => item?.type === "amenity_booking")) {
      getAmenities();
    }
  }, [notificationArr]);

  useEffect(() => {
    setTotalPageItems(amenitiesRequests?.pagination?.count);
  }, [amenitiesRequests]);

  const handleSearch = async (searchText) => {
    if (searchText || searchDate) {
      const dateFormat = "YYYY-MM-DD";
      let formatDate = moment(searchDate).format(dateFormat);
      const res = await dispatch(
        searchAmenitiesRequestsAction(
          0,
          10,
          id,
          searchText,
          formatDate !== "Invalid date" ? formatDate : searchDate
        )
      );
      if (res?.statusCode === 200) {
        setRequests(res?.data?.amenities);
      } else {
        toastr.error(res?.response?.data?.message);
      }
    } else {
      getAmenities();
    }
  };

  const handleChange = (status, row) => {
    if (row.services_bookings_slots.length < 1 && status === "1" && row.date) {
      toastr.error("Can't approve tenant with empty timeSlots & date");
      return;
    } else {
      dispatch(
        AmenityStatusAction(
          status,
          row?.id,
          "service not available for you",
          amenitiesRequests?.requests
        )
      );
    }
  };

  const handlePageChange = async (page, pageSize) => {
    setPageCount(page);
    setPerPage(pageSize);
    const res = await dispatch(
      organizationAmenitiesRequestsAction(page, pageSize, id)
    );
    if (res?.statusCode === 200) {
      setRequests(res?.data?.amenities);
    } else {
      toastr.error(res?.response?.data?.message);
    }
  };

  const handleReschedule = (e, amenityDetails) => {
    e.stopPropagation();
    navigate(`/havenly/rescheduleServices`, {
      state: { serviceDetails: amenityDetails, type: "amenity" },
    });
  };
  const menu = (
    <Menu
      className="editDropdown rescheduleDropdown"
      items={[
        {
          label: (
            <div
              onClick={(e) => handleReschedule(e, company)}
              className="listing-edit-dropdown d-flex"
            >
              <img
                alt=""
                src={rescheduleIcon}
                className="mt-1 me-2 input-icon-field"
              />
              <span>Reschedule</span>
            </div>
          ),
          className: "rescheduleBtn",
          key: "0",
        }
      ]}
    />
  );
  const columns = [
    {
      title: "",
      align: "right",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <>
          {row?.is_read === "0" && <Badge status="processing" />}
          <Avatar
            alt="tenant image"
            src={
              row?.tenant_image
                ? `${process.env.REACT_APP_S3_BUCKET_URL}/${row?.tenant_image}`
                : ebutler
            }
            className={
              row?.is_read === "1" ? "butler-img margin-left-15" : "butler-img"
            }
          />
        </>
      ),
    },
    {
      title: "Tenants",
      dataIndex: "tenant_name",
      align: "left",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <span className="form-text-font-design">
          {props
            ? props?.slice(0, 20) + (props?.length > 20 ? "..." : "")
            : "N/A"}
        </span>
      ),
    },
    {
      title: "Selected Time",
      dataIndex: "services_bookings_slots",
      responsive: ["xs", "sm", "md"],
      render: (services_bookings_slots) => (
        <Popover
          placement="bottom"
          content={services_bookings_slots.map((item, i) => {
            return (
              <p key={i}>
                {`${AmPmFormat(item.start_time)} to ${AmPmFormat(
                  item.end_time
                )}`}
              </p>
            );
          })}
          title="Selected Time"
        >
          <span className="form-text-font-design">
            {
              services_bookings_slots?.map((service, i) =>
                service?.start_time
                  ? `${AmPmFormat(service.start_time)}`
                  : "N/A"
              )[0]
            }
          </span>
        </Popover>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Amenity Name",
      dataIndex: "amenity_name",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">
          {props
            ? props?.slice(0, 15) + (props?.length > 15 ? "..." : "")
            : "N/A"}
        </div>
      ),
    },
    {
      title: "Building No.",
      dataIndex: "building_no",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Apt. No.",
      dataIndex: "apartment_no",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div>
          <div
            className={
              props === "1"
                ? "activeSelector"
                : props === "2"
                ? "InactiveSelector"
                : "pendingSelector"
            }
          >
            <Select
              defaultValue={
                row.services_bookings_slots.length >= 1 && row.date
                  ? props
                  : "0"
              }
              value={
                row.services_bookings_slots.length < 1 &&
                row.date &&
                props === "1"
                  ? "0"
                  : props
              }
              onChange={(val) => handleChange(val, row)}
              style={{ width: 122 }}
              disabled={currentService === "read-write" ? false : true}
            >
              <Option value="0">Pending</Option>
              <Option value="1">Approved</Option>
              <Option value="2">Disapproved</Option>
            </Select>{" "}
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      responsive: ["xs", "sm", "md"],
      render: (record) => (
        <div>
          <Dropdown
            onClick={() => setCompany(record)}
            overlay={menu}
            trigger={["click"]}
            disabled={currentService === "read-write" ? false : true}
          >
            <a onClick={(e) => e.stopPropagation()} className="action-menu">
              <img alt="" src={actionBtn} className="actions-col-btn" />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="requestsScreen">
        <Card className="card">
          {loading ? (
            <div className="no-data-found">
              <Spin />
            </div>
          ) : (
            <>
              <div className="row">
                <div className="requests-card-heading mb-3">
                  <h5 className="table-header">Requests List</h5>
                  <div className="filterButtons">
                    <FilterByDate
                      onSearch={handleSearch}
                      setSearchDate={setSearchDate}
                      searchDate={searchDate}
                      getAllData={getAmenities}
                    />
                    <TitleSearch onSearch={handleSearch} className="mx-2" />
                  </div>
                </div>
                <div className="col-lg-12 tabled-data">
                  <Table
                    pagination={false}
                    bordered={false}
                    scroll={{
                      x: 700,
                    }}
                    dataSource={requests}
                    columns={columns}
                  />
                </div>
              </div>
              <Pagination
                total={totalPageItems}
                defaultPageSize={amenitiesRequests?.pagination?.per_page}
                defaultCurrent={amenitiesRequests?.pagination?.page}
                onChange={handlePageChange}
              />
            </>
          )}
        </Card>
      </div>
    </>
  );
};

export default AmenitiesRequests;
