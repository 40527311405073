
export const genders = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];
export const userState = [
    { label: "Read-Only", value: "read" },
    { label: "Read-Write", value: "read-write" },
  ];

