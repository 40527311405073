import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";
import createUserLogo from "../../assets/images/icons/fullname_generalinfo_grey@3x.png";
import addImgRectangle from "../../assets/images/icons/add_img_rectangle.png.png";
import Circle from "../../assets/images/icons/Circle.png";
import FilledCircle from "../../assets/images/icons/filled-circle.png";
import ReactFlagsSelect from "react-flags-select";
import Message from "../../assets/images/icons/mail_generalinfo_grey@3x.png";
import PhoneInput from "react-phone-number-input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import countryList from "react-select-country-list";
import moment from "moment";
import toastr from "toastr";
import AddImageModal from "../../components/AddImageModal";
import Congratulations from "../../components/congratulations";
import { updateOrganizationUser } from "../../store/actions/organization.action";
import Country from "../../components/CountryDropdown";
import Phone from "../../components/PhoneInput";
const { Option } = Select;

const EditOrganizationMembers = () => {
  const { id } = useParams();
  const dateFormat = "YYYY-MM-DD";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const phone = "";
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState("");
  const [state, setState] = useState({});
  const [memberGender, setMemberGender] = useState();
  const [selected, setSelected] = useState();
  const [DOB, setDOB] = useState();
  const [visible, setVisible] = useState(false);
  const [imgVisible, setImgVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [memberCountry, setmemberCountry] = useState();
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const { memberData } = location?.state;

  const [form] = Form.useForm();

  form.setFieldsValue({
    organizationImage: fileList,
  });

  useEffect(() => {
    if (memberData?.image !== null) {
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: `${process.env.REACT_APP_S3_BUCKET_URL}/${memberData?.image}`,
        },
      ]);
    }
    setMemberGender(memberData?.gender);
    setActive(memberData?.active);
    if (memberData?.country !== null) {
      const country = countryList().getValue(memberData?.country);
      setSelected(country);
    }
    if (memberData?.date_of_birth === null) {
      setDOB(moment("1888/08/08", dateFormat));
    } else {
      setDOB(memberData?.date_of_birth);
    }

    form.setFieldsValue({
      organizationImage: fileList,
      firstName: memberData?.first_name,
      lastName: memberData?.last_name,
      arabicFirstName: memberData?.first_name_ar,
      arabicLastName: memberData?.last_name_ar,
      email: memberData?.email,
      phone: `+${memberData?.phone}`,
    });
  }, [location]);

  const handleStatusChange = (val) => {
    setActive(val);
  };

  const onGenderChange = ({ target: { value } }) => {
    setMemberGender(value);
  };

  const handleFlagCountry = (code) => {
    setSelected(code);
    const countryLabel = countryList().getLabel(code);
    setmemberCountry(countryLabel);
  };

  const onDateChange = (date, dateString) => {
    setDOB(dateString);
  };

  const settingValuesInForm = (key, value) => {
    form.setFieldsValue({
      [key]: value,
    });
  };

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const handleDeleteImage = () => {
    setFileList([]);
  };

  const handlePopupModal = () => {
    setImgVisible(true);
  };

  const hideImgModal = () => {
    setImgVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    navigate("/havenly/organizationMembersListing", {
      state: { id: user?.organization_id },
    });
  };

  const onSubmit = async (values) => {
    let fd = new FormData();
    setLoading(true);
    fd.append("first_name", values?.firstName); //append the values with key, value pair
    fd.append("last_name", values?.lastName);
    fd.append("first_name_ar", values?.arabicFirstName);
    fd.append("last_name_ar", values?.arabicLastName);
    fd.append("email", values?.email);
    fd.append("phone", values?.phone);
    fd.append("country", memberCountry ? memberCountry : memberData?.country);
    fd.append("gender", memberGender);
    fd.append("date_of_birth", DOB);
    fd.append("image", fileList?.slice(-1)[0]?.originFileObj);
    fd.append("member_id", id);
    fd.append("active", active);
    fd.append("organization_id", memberData?.organization_id);
    const res = await dispatch(updateOrganizationUser(fd));
    if (res.statusCode === 200) {
      setLoading(false);
      showModal();
    } else if (Object.keys(res)?.length === 0) {
      setLoading(false);
      showModal();
    } else {
      setLoading(false);
      toastr.error(res?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="main-edit-tenant-wallet">
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onSubmit}
        >
          <Card className="card">
            <h6 className="sm-title-card mtt-10"> Personal Information </h6>

            <div className="row mtt-20">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input first name!",
                        },
                      ]}
                    >
                      <Input
                        className={"inputfield"}
                        prefix={
                          <img
                            alt=""
                            src={createUserLogo}
                            className="site-form-item-icon input-icon-field-twenty"
                          />
                        }
                        placeholder="Enter First Name"
                        maxLength={15}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-6">
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input last name!",
                        },
                      ]}
                    >
                      <Input
                        className={"inputfield"}
                        prefix={
                          <img
                            alt=""
                            src={createUserLogo}
                            className="site-form-item-icon input-icon-field-twenty"
                          />
                        }
                        placeholder="Enter Last Name"
                        maxLength={15}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="arabic-fields row">
                  <div className="col-lg-6">
                    <Form.Item
                      name="arabicLastName"
                      rules={[
                        {
                          required: false,
                          message: "Please input last name!",
                        },
                      ]}
                    >
                      <Input
                        className={"inputfield"}
                        suffix={
                          <img
                            alt=""
                            src={createUserLogo}
                            className="site-form-item-icon input-icon-field-twenty"
                          />
                        }
                        placeholder="إدخال اسم آخر"
                        maxLength={15}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-6">
                    <Form.Item
                      name="arabicFirstName"
                      rules={[
                        {
                          required: false,
                          message: "Please input first name!",
                        },
                      ]}
                    >
                      <Input
                        className={"inputfield "}
                        suffix={
                          <img
                            alt=""
                            src={createUserLogo}
                            className="site-form-item-icon input-icon-field-twenty"
                          />
                        }
                        placeholder="أدخل الاسم الأول"
                        maxLength={15}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="gender-radio-div">
                  <Form.Item
                    name="gender"
                    rules={[
                      {
                        required: false,
                        message: "Please select gender!",
                      },
                    ]}
                  >
                    <Typography className="date-of-birth">Gender</Typography>
                    <Radio.Group
                      value={memberGender}
                      onChange={onGenderChange}
                      buttonStyle="solid"
                    >
                      <Radio.Button
                        value="1"
                        className={memberGender === "1" ? "" : "grey-border"}
                      >
                        <div className="inner-container-radio-gender">
                          <p>Male</p>
                        </div>
                      </Radio.Button>
                      <Radio.Button
                        value="0"
                        className={memberGender === "0" ? "" : "grey-border"}
                      >
                        <div className="inner-container-radio-gender">
                          <p>Female</p>
                        </div>
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <Form.Item
                  name="memberCountry"
                  rules={[
                    {
                      required: false,
                      message: "Please input country!",
                    },
                  ]}
                >
                  <Country
                    selected={selected}
                    onSelect={(code) => handleFlagCountry(code)}
                    disabled={false}
                  />
                </Form.Item>
                <Form.Item name="date">
                  <Typography className="date-of-birth">
                    Date of Birth
                  </Typography>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <div className="edit-date-picker">
                      <DatePicker
                        value={moment(DOB, dateFormat)}
                        className="inputfield"
                        onChange={onDateChange}
                        format={dateFormat}
                      />
                    </div>
                  </Space>
                </Form.Item>
                <h3 className="common-heading">Profile Settings</h3>
                <div className="disable-email">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input email!",
                      },
                    ]}
                  >
                    <Input
                      className={"inputfield mtt-10"}
                      disabled={true}
                      prefix={
                        <img
                          alt=""
                          src={Message}
                          className="site-form-item-icon input-icon-field"
                        />
                      }
                      placeholder="Enter your Email"
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Phone
                    disabled={false}
                    value={phone}
                    onChange={(phone) => settingValuesInForm("phone", phone)}
                  />
                </Form.Item>
                <Form.Item
                  name="active"
                  rules={[
                    {
                      required: false,
                      message: "Please enter status!",
                    },
                  ]}
                >
                  <div
                    className={
                      active === "1"
                        ? "edit-tenant-select activeSelector"
                        : "edit-tenant-select InactiveSelector"
                    }
                  >
                    <Select
                      value={active}
                      onChange={(active) => handleStatusChange(active)}
                    >
                      <Option value="1">Active</Option>
                      <Option value="0">Inactive</Option>
                    </Select>
                  </div>
                </Form.Item>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-4 preview-only">
                <Form.Item
                  name="organizationImage"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the image!",
                    },
                  ]}
                >
                  {fileList.length === 0 ? (
                    <>
                      <div className="rectangle-frame-img">
                        <img
                          alt=""
                          src={addImgRectangle}
                          className="upload-img-box"
                          onClick={handlePopupModal}
                        />
                        <p className="img-warn mtt-10">
                          Files Supported: PNG, JPEG
                        </p>
                        <p className="img-warn mtt-10">Image Size (200x200)</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <Upload
                        disabled={true}
                        listType="picture-card"
                        fileList={fileList.slice(-1)}
                        beforeUpload={() => false}
                        onPreview={handlePopupModal}
                      >
                        {fileList.length < 1 && "+"}
                      </Upload>
                      <p className="img-warn mt-1">
                        Files Supported: PNG, JPEG
                      </p>
                      <p className="img-warn mtt-10">Image Size (200x200)</p>
                    </>
                  )}
                </Form.Item>
              </div>
            </div>
            <Form.Item>
              <div className="mtt-20 align-right">
                <Button
                  className="servicesBackBtn mx-2"
                  onClick={() => hideModal()}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="servicesMainBtn"
                  htmlType="submit"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Update"}
                </Button>
              </div>
            </Form.Item>
          </Card>
        </Form>
      </div>
      <AddImageModal
        handleDeleteImage={handleDeleteImage}
        file={fileList}
        state={state}
        onChange={onChange}
        visible={imgVisible}
        hideModal={hideImgModal}
      />
      <Congratulations
        visible={visible}
        hideModal={hideModal}
        message={"Your Member has been successfully updated!"}
      />
    </>
  );
};

export default EditOrganizationMembers;
