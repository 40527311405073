import React, { useEffect, useState } from "react";
import { Avatar, Card, Select, Table, Badge, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import ebutler from "../../assets/images/icons/butler.png";

import { useDispatch, useSelector } from "react-redux";
import {
  RequestsByAdminAction,
  searchRequestsByAdminAction,
} from "../../store/actions/visitors.action";
import Pagination from "../../components/Pagination";
import { TitleSearch } from "../../components/SearchFilter";
import FilterByDate from "../../components/DateFilter";
import moment from "moment";
import { AmPmFormat } from "../../services/DateAndTime";

const { Option } = Select;

const RequestsByAdmin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [searchDate, setSearchDate] = useState("");
  const [visitor, setVisitor] = useState([]);
  const [loading, setLoading] = useState(true);
  const reset = location?.state?.reset;
  const id = useSelector((state) => state?.user?.currentUser?.organization_id);
  const getVisitorRequestByAdmin = useSelector( (state) => state?.visitors?.requestsByAdmin);
  const notificationArr = useSelector((state) => state?.notifications?.notificationsReceivedType);
  const per_page = getVisitorRequestByAdmin?.pagination?.per_page;
  const page_count = getVisitorRequestByAdmin?.pagination?.page;

  const getData = async () => {
    if (reset === true) {
      const res = await dispatch(RequestsByAdminAction(0, 10, id));
      setVisitor(res?.data?.guests);
      setLoading(false);
      navigate(location.pathname, { replace: true });
    } else {
      const res = await dispatch(
        RequestsByAdminAction(
          page_count ? page_count : pageCount,
          per_page ? per_page : perPage,
          id
        )
      );
      setPageCount(page_count);
      setPerPage(per_page);
      setVisitor(res?.data?.guests);
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleSearch = async (searchText) => {
    if (searchText || searchDate) {
      const dateFormat = "YYYY-MM-DD";
      let formatDate = moment(searchDate).format(dateFormat);
      const res = await dispatch(
        searchRequestsByAdminAction(
          id,
          searchText,
          formatDate !== "Invalid date" ? formatDate : searchDate
        )
      );
      setVisitor(res?.data?.visitors);
    } else {
      getData();
    }
  };

  useEffect(() => {
    setTotalPageItems(getVisitorRequestByAdmin?.pagination?.count);
  }, [getVisitorRequestByAdmin]);

  const handlePageChange = async (page, pageSize) => {
    setLoading(true);
    setVisitor([]);
    setPageCount(page);
    setPerPage(pageSize);
    const res = await dispatch(RequestsByAdminAction(page, pageSize, id));
    setVisitor(res?.data?.guests);
    setLoading(false);
  };

  useEffect(() => {
    if (notificationArr?.filter((item) => item?.type === "unexpected_guest_request_accept" || "unexpected_guest_request_reject")) {
      getData();
    }
  }, [notificationArr]);

  const columns = [
    {
      title: "",
      align: "right",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <>
          {row?.is_read === "0" && <Badge status="processing" />}
          <Avatar
            alt="tenant image"
            src={
              row?.tenant_image
                ? `${process.env.REACT_APP_S3_BUCKET_URL}/${row?.tenant_image}`
                : ebutler
            }
            className={
              row?.is_read === "1" ? "butler-img margin-left-15" : "butler-img"
            }
          />
        </>
      ),
    },
    {
      title: "Tenants",
      dataIndex: "tenant_name",
      align: "left",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <span className="form-text-font-design" style={{ marginLeft: "5px" }}>
          {props
            ? props?.slice(0, 20) + (props?.length > 20 ? "..." : "")
            : "N/A"}
        </span>
      ),
    },
    {
      title: "Visit Time",
      dataIndex: "visit_time",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">
          {props ? AmPmFormat(props) : "N/A"}
        </div>
      ),
    },
    {
      title: "Visit Date",
      dataIndex: "visit_date",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },

    {
      title: "Building No.",
      dataIndex: "building_no",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Apt. No.",
      dataIndex: "apartment_no",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Total Visitors",
      dataIndex: "number_of_visitors",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div className="staticStatus">
          <div
            className={
              props === "1"
                ? "activeSelector"
                : props === "2"
                ? "InactiveSelector"
                : "pendingSelector"
            }
          >
            <Select
              suffixIcon={null}
              defaultValue={props ? props : "0"}
              value={row.date && props === "1" ? "0" : props}
              style={{ width: 120 }}
              disabled="true"
            >
              <Option value="0">Pending</Option>
              <Option value="1">Approved</Option>
              <Option value="2">Disapproved</Option>
            </Select>{" "}
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="requestsScreen">
        <Card className="card">
          {loading ? (
            <div className="no-data-found">
              <Spin />
            </div>
          ) : (
            <>
              <div className="requests-card-heading mb-3">
                <h5 className="table-header">Requests List</h5>
                <div className="filterButtons">
                  <FilterByDate
                    onSearch={handleSearch}
                    setSearchDate={setSearchDate}
                    searchDate={searchDate}
                    getAllData={getData}
                  />
                  <TitleSearch onSearch={handleSearch} className="mx-2" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 tabled-data">
                  <Table
                    pagination={false}
                    bordered={false}
                    dataSource={getVisitorRequestByAdmin?.requests}
                    columns={columns}
                    scroll={{
                      x: 700,
                    }}
                  />
                </div>
              </div>

              <Pagination
                total={totalPageItems}
                defaultPageSize={perPage}
                defaultCurrent={pageCount}
                onChange={handlePageChange}
              />
            </>
          )}
        </Card>
      </div>
    </>
  );
};

export default RequestsByAdmin;
