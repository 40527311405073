export const GET_USERS = 'GET_USERS';
export const USERS_ERROR = 'USERS_ERROR';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const FIND_EMAIL = 'FIND_EMAIL';
export const FIND_EMAIL_ERROR = 'FIND_EMAIL_ERROR';
export const CHECK_EMAIL = 'CHECK_EMAIL';
export const CHECK_EMAIL_ERROR = 'CHECK_EMAIL_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD ';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_TENANT_PASSWORD = 'RESET_TENANT_PASSWORD ';
export const RESET_TENANT_PASSWORD_ERROR = 'RESET_TENANT_PASSWORD_ERROR';
export const CURRENT_USER = 'CURRENT_USER';
export const CURRENT_USER_ERROR = 'CURRENT_USERS_ERROR';