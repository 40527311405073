import React, { useEffect, useState } from "react";
import { Button, Modal, Upload } from "antd";
import DragPhoto from "../assets/images/icons/drag_photo.png";
import UploadPhoto from "../assets/images/icons/upload_photo.png";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import crossIcon from '../assets/images/icons/super admin - 1x PNG/cross-3x.png'
import deleteWhite  from '../assets/images/icons/delete_w@3x.png'
import toastr from "toastr";
const { Dragger } = Upload;
const AddImageModal = ({
  handleDeleteImage,
  visible,
  hideModal,
  file,
  onChange,
  state,
}) => {
  const { imageUrl } = state;

  return (
    <div className="add-img-modal-container">
      <Modal visible={visible} footer={null} onCancel={hideModal} okText="Ok">
        {file.length === 0 ? (
          <>
          <div className=" mb-2 d-flex justify-content-between align-items-center">
            <span className="modal-header-text">Add Image</span>
        <img src={crossIcon} className='crossIcon' alt="close-modal" onClick={() => hideModal()}/>
        </div>
            <Dragger
              accept=".png,.jpg"
              fileList={file}
              onChange={onChange}
              beforeUpload={ (file) => {
                const acceptedFormats = ["PNG", "png", "jpg", "JPG", 'bmp', 'BMP', 'ico', 'ICO', 'gif', 'GIF', 'jpeg', 'JPEG'];
                const myArr = file?.name.split(".");
                const myVal = myArr[myArr?.length - 1];
                const formats = acceptedFormats.includes(myVal)
                if(formats === false){
                  toastr.error('Format not Supported')
                }
                return formats
                  ? false
                  : Upload.LIST_IGNORE;
              }}
            >
              <div className="drag-upload-img-div">
                <div style={{ marginBottom: "-135px" }}>
                  <img src={DragPhoto} />
                  <p className="drag-caption" style={{ margin: "20px" }}>
                    Drag photo here
                  </p>
                </div>
                <div style={{ marginTop: "-70px" }}>
                  <p
                    className="text-bw-drag-and-upload"
                    style={{ margin: "20px" }}
                  >
                    or
                  </p>
                  <Button type="primary" className="modal-upload-btn">
                    <img src={UploadPhoto} />
                    <p className="add-new-building-btn">Upload From Computer</p>
                  </Button>
                </div>
              </div>
            </Dragger>
          </>
        ) : (
          <>
          <div className="d-flex justify-content-between">
            <p className="modal-header-text">Image</p>
        <img src={crossIcon} className='crossIcon' alt="close-modal" onClick={() => hideModal()}/>
        </div>
            <img
              alt="example"
              style={{ width: "300px", height:'300px',  borderRadius: "50%", marginLeft:'90px' }}
              src={imageUrl ? imageUrl : file[0]?.url}
            />
          </>
        )}
        {file.length !== 0 && (
          <div className="custom-modal-footer">
            <Upload
              accept=".png,.jpg"
              fileList={file}
              onChange={onChange}
              beforeUpload={ (file) => {
                const acceptedFormats = ["PNG", "png", "jpg", "JPG", 'bmp', 'BMP', 'ico', 'ICO', 'gif', 'GIF', 'jpeg', 'JPEG'];
                const myArr = file?.name.split(".");
                const myVal = myArr[myArr?.length - 1];
                const formats = acceptedFormats.includes(myVal)
                if(formats === false){
                  toastr.error('Format not Supported')
                }
                return formats
                  ? false
                  : Upload.LIST_IGNORE
              }
            }
            >
              <Button type="primary" className="modal-btn servicesMainBtn">
            <EditOutlined/> <span className="modal-btn-span">Edit</span>
              </Button>
            </Upload>
            <Button type="primary" className="modal-btn modal-btn-wth-upload servicesMainBtn" onClick={handleDeleteImage}>
            <img src={deleteWhite} style={{height:'30px', marginBottom:'5px'}}/> <span className="modal-btn-span mt-2" >Remove</span>
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AddImageModal;
