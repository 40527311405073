import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";
import VisitorGeneralForm from "./VisitorGeneralForm";
import { useDispatch } from "react-redux/es/exports";
import {
  AddNewVisitorByAdmin,
  RequestsByAdminAction,
} from "../../../store/actions/visitors.action";
import toastr from "toastr";
import crossIcon from "../../../assets/images/icons/cross-3x.png";
import { getTenantUsingApartmentAndBuilding } from "../../../store/actions/parcel.action";

const AddNewVisitor = ({ showVisitorModel, setShowVisitorModel, orgId }) => {
  const [visitorName, setVisitorName] = useState("");
  const [arabicVisitorName, setArabicVisitorName] = useState("");
  const [visitorNum, setVisitorNum] = useState("");
  const [buildingNum, setBuildingNum] = useState("");
  const [apartmentNum, setApartmentNum] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [arabicTenantName, setArabicTenantName] = useState("");
  const [numOfVisitors, setNumOfVisitors] = useState([]);
  const [arabicNumOfVisitors, setArabicNumOfVisitors] = useState("");
  const [tenantId, setTenantId] = useState();
  const [btnDisable, setBtnDisable] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  var today = new Date();
  var currentDate =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var currentTime = today.getHours() + ":" + today.getMinutes();
  const handleCancel = () => {
    setShowVisitorModel(false);
  };
  const handleSave = async (values) => {
    if(values){
      const obj={
        ...values,
        visit_date: currentDate,
        visit_time: currentTime,
        organization_id: orgId,
        tenant_id: tenantId
      }
    setBtnDisable(true);
    const res = await dispatch(AddNewVisitorByAdmin(obj));
    if (res.statusCode === 200) {
      toastr.success("Visitor Request Created Successfully");
      dispatch(RequestsByAdminAction(0, 10, orgId));
      setShowVisitorModel(false);
    } else if (Object.keys(res)?.length === 0) {
      toastr.success("Visitor Request Created Successfully");
      dispatch(RequestsByAdminAction(0, 10, orgId));
      setShowVisitorModel(false);
    } else {
      toastr.error(res?.response?.data?.message);
      setBtnDisable(false);
    }
  }
  };
  const handleGetTenantByApartmentAndBuilding = async (
    building_no,
    apartment_no
  ) => {
    const res = await dispatch(
      getTenantUsingApartmentAndBuilding(building_no, apartment_no, orgId)
    );
    if (res.statusCode === 200) {
      if (res?.data?.tenant?.length !== 0) {
        setBtnDisable(false);
        form.setFieldsValue({
          tenant_name: res?.data?.tenant[0]?.full_name,
          tenant_name_ar: res?.data?.tenant[0]?.full_name_ar,
        });
        setTenantId(res?.data?.tenant[0]?.id);
      } else {
        setBtnDisable(true);
        toastr.error("Building or apartment number is wrong");
        form.setFieldsValue({
          tenant_name: "",
          tenant_name_ar: "",
        });
      }
    } else {
      toastr.error(res?.response?.data?.message);
    }
  };

 
  return (
    <div className="visitorModel p-2">
      <Modal
        title={
          <>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className="visitorModel-header p-2">
                  Add Visitors Request
                </span>
              </div>
              <img
                src={crossIcon}
                className="crossIcon"
                alt="close-modal"
                onClick={() => handleCancel()}
              />
            </div>
          </>
        }
        centered
        footer={null}
        header={null}
        visible={showVisitorModel}
        onOk={() => handleSave()}
        onCancel={() => handleCancel()}
        width={850}
      >
        <div className="addVisitor p-2">
          <Form
            name="add-visitor"
            className="add-visitor-form"
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={handleSave}
          >
            <div className="row">
              <VisitorGeneralForm
                setVisitorName={setVisitorName}
                setArabicVisitorName={setArabicVisitorName}
                setVisitorNum={setVisitorNum}
                visitorNum={visitorNum}
                buildingNum={buildingNum}
                setBuildingNum={setBuildingNum}
                setApartmentNum={setApartmentNum}
                apartmentNum={apartmentNum}
                setTenantName={setTenantName}
                setArabicTenantName={setArabicTenantName}
                setNumOfVisitors={setNumOfVisitors}
                setArabicNumOfVisitors={setArabicNumOfVisitors}
                handleGetTenantByApartmentAndBuilding={
                  handleGetTenantByApartmentAndBuilding
                }
              />
            </div>

            <Form.Item className="mt-5">
              <div className="steps-action-one">
                <Button
                  className="servicesMainBtn"
                  htmlType="submit"
                  onClick={() => handleSave()}
                  disabled={btnDisable}
                  style={{ color: "#FFFFFF" }}
                >
                  {btnDisable ? "Loading..." : "Notify Tenant"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default AddNewVisitor;
