import React, { useEffect, useState } from "react";
import { Avatar, Menu, Dropdown, Button, Divider } from "antd";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import Dummy_Profile from "../../assets/images/core-images/dummy-img.png";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/images/icons/super admin - 1x PNG/search_grey@3x.png";
import SearchIconBlue from "../../assets/images/icons/super admin - 1x PNG/search_blue@3x.png";
import Mail from "../../assets/images/icons/super admin - 1x PNG/message_grey@3x.png";
import BlueMail from "../../assets/images/icons/super admin - 1x PNG/message_blue@3x.png";

import Setting from "../../assets/images/icons/settings@3x.png";
import LogOut from "../../assets/images/icons/logout@3x.png";
import { useDispatch, useSelector } from "react-redux";
import AddNewVisitor from "../Visitors/components/AddNewVisitor";
import {
  todayNotificationsAction,
  earlierNotificationsAction,
  notificationReceivedArray,
  todayNotificationsLabelAction,
} from "../../store/actions/notifications.action";
import NotificationsDropdown from "../../components/NotificationsDropdown";
import { runOneSignalOnLogout } from "../../components/OneSignal";
import { logoutAction } from "../../store/actions/user.action";
import toastr from "toastr";
import BellboyGuidelines from "../Bellboy/components/BellboyGuidelines";
import VisitorGuidelines from "../Visitors/components/VisitorGuidelines";
import { getAllMemberOfOrganizationAction } from "../../store/actions/organization.action";
import { getAllTenant } from "../../store/actions/tenant.action";
import { HeaderHeading } from "../../helpers/helper";
import HeaderButtons from "./components/HeaderButtons";
const HeaderTop = ({ organization_id, drawerhandler }) => {
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState("");
  const [members, setMembers] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [currentIconSelected, setCurrentIconSelected] = useState(false);
  const [showVisitorModel, setShowVisitorModel] = useState(false);
  const [showBellboyModal, setShowBellboyModal] = useState(false);
  const [showVisitorGuidelinesModal, setShowVisitorGuidelinesModal] =
    useState(false);
  const [todayNotifications, setTodayNotifications] = useState([]);
  const [earlierNotifications, setEarlierNotifications] = useState([]);
  const services = useSelector((state) => state?.user?.services);
  let userService = "";
  let bellboyService = "";
  let amenitiesService = "";
  let visitorsService = "";
  let parcelsService = "";
  let maintenanceService = "";
  if (services) {
    userService = services?.find((item) => item?.service === "User")?.privilege;
    bellboyService = services?.find(
      (item) => item?.service === "Bellboy"
    )?.privilege;
    amenitiesService = services?.find(
      (item) => item?.service === "Amenities"
    )?.privilege;
    visitorsService = services?.find(
      (item) => item?.service === "Visitors"
    )?.privilege;
    parcelsService = services?.find(
      (item) => item?.service === "Parcels"
    )?.privilege;
    maintenanceService = services?.find(
      (item) => item?.service === "Maintenance"
    )?.privilege;
  }

  const final_service = services?.map((x) => x.service)
  const add_service = [...final_service, 'organization_id']


  const getUsers = async () => {
    const res = await dispatch(
      getAllMemberOfOrganizationAction(organization_id)
    );
    await setMembers(res?.data?.data?.members);
  };

  const getTenants = async () => {
    const res = await dispatch(getAllTenant(organization_id));
    await setTenants(res?.data?.tenants);
  };

  const getNotification = async () => {
    if (currentUser?.organization_id) {
      const today = await dispatch(
        todayNotificationsAction(0, 2, currentUser?.organization_id)
      );
      if (today?.statusCode === 200) {
        setTodayNotifications(today?.data?.notifications);
      }
      const earlier = await dispatch(
        earlierNotificationsAction(0, 2, currentUser?.organization_id)
      );
      if (earlier?.statusCode === 200) {
        setEarlierNotifications(earlier?.data?.notifications);
      }
    }
  };

  const getTodayNotification = async () => {
    const today = await dispatch(
      todayNotificationsAction(0, 2, currentUser?.organization_id)
    );
    if (today?.statusCode === 200) {
      setTodayNotifications([]);
      setTodayNotifications(today?.data?.notifications);
    }
  };

  const getNotiLabel = async () => {
    const today = await dispatch(
      todayNotificationsLabelAction(0, 2, currentUser?.organization_id)
    );
    if (today?.statusCode === 200) {
      if (today?.data?.notifications?.length === 0) {
        return;
      } else {
        const val = today?.data?.notifications?.map((item) => {
          return {
            type: item?.type,
            created_at: item?.created_at,
          };
        });
        dispatch(notificationReceivedArray(val));
      }
    }
  };
  useEffect(() => {
    setUserRole(currentUser?.role);
    getNotification();
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.role !== "eb-super-admin") {
      getNotiLabel();
    }
  }, []);

  const handleLogout = async () => {
    const res = await dispatch(logoutAction());
    if (res.statusCode === 200) {
      runOneSignalOnLogout(add_service);
      localStorage.clear();
      navigate("/");
      toastr.success("logged out successfully");
    } else {
      toastr.error("Error occurred while logging out");
    }
  };
  const handleAddVisitor = () => {
    setShowVisitorModel(true);
  };

  const handleAddBellboyGuidelines = () => {
    setShowBellboyModal(true);
  };

  const handleAddVisitorGuideline = () => {
    setShowVisitorGuidelinesModal(true);
  };

  const toggleHover = (value) => {
    setCurrentIconSelected(value);
    setIsHovered(!isHovered);
  };

  const orgOptions = [
    {
      icon: (
        <Avatar
          src={`${process.env.REACT_APP_S3_BUCKET_URL}/${currentUser?.image}`}
        />
      ),
      key: "1",
      label: (
        <>
          <span className="profile-name mb-2">{`${currentUser?.first_name} ${currentUser?.last_name}`}</span>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <Divider className="profile-menu-drawer" />
        </>
      ),
    },
    {
      label: (
        <div
          className="d-flex align-items-center profile-menu"
          onClick={() => {
            navigate("/havenly/organizationSetting", {
              state: { id: organization_id },
            });
          }}
        >
          <img
            alt=""
            src={Setting}
            className="me-2"
            style={{ height: "20px" }}
          />
          <span className="mx-2">Organization Settings</span>
        </div>
      ),
      key: "3",
    },
    {
      label: (
        <div
          className="d-flex  align-items-center profile-menu"
          onClick={() => handleLogout()}
        >
          <img
            alt=""
            src={LogOut}
            className="me-2"
            style={{ height: "20px" }}
          />
          <span className="mx-2">Logout</span>
        </div>
      ),
      key: "4",
    },
  ];
  const Options = [
    {
      icon: (
        <Avatar
          src={`${process.env.REACT_APP_S3_BUCKET_URL}/${currentUser?.image}`}
        />
      ),
      key: "1",
      label: (
        <>
          <span className="profile-name mb-2">{`${currentUser?.first_name} ${currentUser?.last_name}`}</span>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <Divider className="profile-menu-drawer" />
        </>
      ),
    },

    {
      label: (
        <div
          className="d-flex  align-items-center profile-menu"
          onClick={() => handleLogout()}
        >
          <img
            alt=""
            src={LogOut}
            className="me-2"
            style={{ height: "20px" }}
          />
          <span className="mx-2">Logout</span>
        </div>
      ),
      key: "4",
    },
  ];

  const menu = (
    <Menu
      className="profile-dropdown-menu-item"
      items={userRole === "org-super-admin" ? orgOptions : Options}
    />
  );

  return (
    <>
      <nav>
        <div className="header-heading">
          <h5 className="logo">{HeaderHeading(location.pathname)}</h5>
          <>
            <HeaderButtons
              userService={userService}
              parcelsService={parcelsService}
              bellboyService={bellboyService}
              amenitiesService={amenitiesService}
              visitorsService={visitorsService}
              maintenanceService={maintenanceService}
              location={location}
              tenants={tenants}
              organization_id={organization_id}
              members={members}
              getUsers={() => getUsers()}
              getTenants={() => getTenants()}
              handleAddBellboyGuidelines={() => handleAddBellboyGuidelines()}
              handleAddVisitor={() => handleAddVisitor()}
              handleAddVisitorGuideline={() => handleAddVisitorGuideline()}
            />
          </>
        </div>
        <div className="d-flex flex-end">
          <Button
            className="menu-drawer-btn mtt-10 LgBtn"
            type="primary"
            icon={<MenuOutlined />}
            onClick={() => drawerhandler()}
          />
          <ul className="nav-links nav-links-header mt-2">
            <li>
              <img
                alt=""
                src={Mail}
                className="nav-links-icons nav-links-icons-right mx-2 headerIcons"
              />
            </li>
            {userRole !== "eb-super-admin" && (
              <NotificationsDropdown
                organization_id={organization_id}
                todayNotifications={todayNotifications}
                earlierNotifications={earlierNotifications}
                toggleHover={toggleHover}
                getTodayNotification={getTodayNotification}
                isHovered={isHovered}
                currentIconSelected={currentIconSelected}
              />
            )}

            <Dropdown
              overlay={menu}
              trigger={"click"}
              placement="bottomRight"
              arrow
            >
              <li>
                <Avatar
                  src={`${process.env.REACT_APP_S3_BUCKET_URL}/${currentUser?.image}`}
                  className="mx-2"
                />
              </li>
            </Dropdown>
          </ul>
        </div>
      </nav>
      {showVisitorModel && (
        <AddNewVisitor
          showVisitorModel={showVisitorModel}
          setShowVisitorModel={setShowVisitorModel}
          orgId={organization_id}
        />
      )}
      {showBellboyModal && (
        <BellboyGuidelines
          showBellboyModal={showBellboyModal}
          setShowBellboyModal={setShowBellboyModal}
          orgId={organization_id}
        />
      )}
      {showVisitorGuidelinesModal && (
        <VisitorGuidelines
          showVisitorGuidelinesModal={showVisitorGuidelinesModal}
          setShowVisitorGuidelinesModal={setShowVisitorGuidelinesModal}
          orgId={organization_id}
        />
      )}
    </>
  );
};

export default HeaderTop;
