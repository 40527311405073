import {
  ADD_MAINTENANCE,
  ADD_MAINTENANCE_ERROR,
  MAINTENANCE_DASHBOARD,
  MAINTENANCE_DASHBOARD_ERROR,
  MAINTENANCE_LISTING,
  MAINTENANCE_LISTING_ERROR,
  MAINTENANCE_REQUESTS,
  MAINTENANCE_REQUESTS_ERROR,
  STATUS_MAINTENANCE_MEMBER,
  STATUS_MAINTENANCE_MEMBER_ERROR,
  UPDATE_MAINTENANCE_MEMBER,
} from "../types/maintenance.types";

const initialState = {
  maintenance: [],
  loading: true,
  isData: false,
};

function amenitiesReducer(state = initialState, action) {
  switch (action.type) {
    case MAINTENANCE_DASHBOARD:
      return {
        ...state,
        maintenance: action.payload,
        isData: true,
        loading: false,
      };
    case MAINTENANCE_DASHBOARD_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    case ADD_MAINTENANCE:
      return {
        ...state,
        maintenance: action.payload,
        isData: true,
        loading: false,
      };
    case ADD_MAINTENANCE_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    case MAINTENANCE_REQUESTS:
      return {
        ...state,
        maintenance: action.payload,
        isData: true,
        loading: false,
      };
    case MAINTENANCE_REQUESTS_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
      case MAINTENANCE_LISTING:
        return {
          ...state,
          maintenanceListing: action.payload,
          isData: true,
          loading: false,
        };
      case MAINTENANCE_LISTING_ERROR:
        return {
          error: action.payload,
          loading: false,
          isData: false,
        };
    case UPDATE_MAINTENANCE_MEMBER:
      return {
        ...state,
        maintenance: action.payload,
        isData: true,
        loading: false,
      };
    case STATUS_MAINTENANCE_MEMBER: {
      return {
        ...state,
        isData: true,
        loading: false,
      };
    }
    case STATUS_MAINTENANCE_MEMBER_ERROR: {
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    }
    default:
      return state;
  }
}
export default amenitiesReducer;
