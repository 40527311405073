import {
  CREATE_TENANT,
  CREATE_TENANT_ERROR,
  GET_All_TENANTS,
  GET_ALL_TENANTS_ERROR,
  GET_APPROVED_TENANT,
  GET_APPROVED_TENANT_ERROR,
  GET_AWAITING_TENANT,
  GET_AWAITING_TENANT_ERROR,
  STATUS_ORGANIZATION_TENANT,
  STATUS_ORGANIZATION_TENANT_ERROR,
  UPDATE_ORGANIZATION_TENANT,
} from "../types/tenant.types";

const initialState = {
  tenant: [],
  loading: true,
  isData: false,
};

function tenantReducer(state = initialState, action) {
  switch (action.type) {
    case GET_APPROVED_TENANT:
      return {
        ...state,
        tenant: action.payload,
        isData: true,
        loading: false,
      };
    case GET_APPROVED_TENANT_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_AWAITING_TENANT:
      return {
        ...state,
        tenant: action.payload,
        isData: true,
        loading: false,
      };
    case GET_AWAITING_TENANT_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case UPDATE_ORGANIZATION_TENANT:
      return {
        ...state,
        tenant: action.payload,
        isData: true,
        loading: false,
      };
    case STATUS_ORGANIZATION_TENANT:
      return {
        ...state,
        loading: false,
        isData: true,
      };
    case STATUS_ORGANIZATION_TENANT_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case CREATE_TENANT:
      return {
        ...state,
        loading: false,
        isData: true,
      };
    case CREATE_TENANT_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_All_TENANTS:
      return {
        ...state,
        allTenants: action.payload.tenants,
        isData: true,
        loading: false,
      };
    case GET_ALL_TENANTS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
export default tenantReducer;
