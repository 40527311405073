import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAmenityServiceAvailability,
  getAllTimeSlots,
} from "../../../store/actions/amenities.action";
import { deleteMaintenanceServiceAvailability } from "../../../store/actions/maintenance.action";
import { dayName, fullDayName } from "../../../services/DateAndTime";
import RescheduleMain from "../../../components/RescheduleMain";
const TimeAvailability = ({
  selectedDays,
  setSelectedDays,
  selectedTags,
  setSelectedTags,
  setSlots,
  edit,
  slots,
  setSelectedShifts,
  selectedShifts,
  service_id,
  maintenanceView,
  onSubmit,
  prev,
  addingService,
}) => {
  const timeSlots = useSelector((state) => state?.amenity?.timeSlots?.slots);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTimeSlots());
  }, []);

  const [form] = Form.useForm();

  form.setFieldsValue({
    open_days: selectedDays,
    open_shifts: selectedTags,
  });

  let curr = new Date();
  let days = [];
  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    days.push({ label: dayName(day), value: fullDayName(day) });
  }
  const onDayChange = (checkedDays, e) => {
    if (e.target.checked) {
      if (!selectedDays.some((x) => (x.id ? x.id : x === checkedDays))) {
        setSelectedDays([...selectedDays, checkedDays]);
      }
    } else {
      if (selectedDays.some((x) => (x.id ? x.id : x === checkedDays))) {
        const filtered = selectedDays.filter(
          (value) => value !== checkedDays && value !== checkedDays + 1
        );
        setSelectedDays(filtered);
      }
    }
  };

  const handleShiftChange = (tag, checked) => {
    const nextSelectedShifts = checked
      ? [...selectedShifts, tag]
      : selectedShifts?.filter((t) => t !== tag);
    setSelectedShifts(nextSelectedShifts);
    const nextSelectedTags = selectedTags?.filter((t) => t.shift !== tag);
    setSelectedTags(nextSelectedTags);
    let ids = [];
    selectedTags?.filter((t) => t.shift === tag && ids.push(t.id)); //getting checked id's from that unchecked shift
  };
  const handleSlotChange = (tag, checked) => {
    if (selectedShifts?.find((item) => item === tag.shift)) {
      const nextSelectedTags =
        checked && tag.id
          ? [...selectedTags, tag]
          : selectedTags.filter((t) => t.id !== tag.id);
      setSelectedTags(nextSelectedTags);
    }
  };

  return (
    <>
      <h6 className="sm-title-card mtt-10 mbb-10"> Time Availability </h6>
      <span className="sub-heading mtt-10">
        Select days available for booking
      </span>
      <div style={{ justifyContent: "space-between" }}>
        <div className="timeAvailability">
          <Form.Item
            name="open_days"
            rules={[
              {
                required: true,
                message: "Please Select Your Day!",
              },
            ]}
          >
            <div>
              <div className="daysCheckboxParent">
                {days.map((item, index) => {
                  return (
                    <Checkbox
                      className="daysCheckbox"
                      defaultChecked={selectedDays.find(
                        (x) =>
                          (x.id ? x.id : x) === (item.id ? item.id : item.value)
                      )}
                      key={item.label}
                      label={item.label}
                      options={days}
                      onChange={(e) => onDayChange(item.value, e)}
                    >
                      {item.label}
                    </Checkbox>
                  );
                })}
              </div>
            </div>
          </Form.Item>
        </div>
        <div className="slotAvailability">
          <h6 className="slot-available">Slots Available</h6>
          <Form.Item
            name="open_shifts"
            rules={[
              {
                required: true,
                message: "Please Select Your Shift!",
              },
            ]}
          >
            <>
              <RescheduleMain
                timeSlots={timeSlots}
                handleSlotChange={handleSlotChange}
                selectedShifts={selectedShifts}
                selectedTags={selectedTags}
                handleShiftChange={handleShiftChange}
              />
            </>
          </Form.Item>
          {!maintenanceView && (
            <Form.Item name="open_slots_per_user">
              <>
                {" "}
                <h6 className="slot-available">Open Slots per User</h6>
                <Select
                  placeholder="Select No of Slots"
                  onClick={(e) => e.stopPropagation()}
                  defaultValue={slots ? slots : 1}
                  style={{ width: "50%", marginTop: "15px" }}
                  onChange={(e) => {
                    setSlots(e);
                  }}
                >
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                </Select>{" "}
              </>
            </Form.Item>
          )}
        </div>
      </div>
      {!edit && (
        <Form.Item>
          <div className="steps-action-one">
            <Button className="servicesBackBtn" onClick={() => prev()}>
              Back
            </Button>
            <Button
              type="primary"
              className="servicesMainBtn"
              htmlType="submit"
              disabled={addingService}
              style={{ margin: "0 8px" }}
            >
              {addingService ? "Loading..." : "Finish"}
            </Button>
          </div>
        </Form.Item>
      )}
    </>
  );
};

export default TimeAvailability;
