import React, { useEffect, useState } from "react";
import { Card } from "antd";
import totalTaskIcon from "../../assets/images/icons/amenities - 1x PNG/total_task.png";
import inProgressTaskIcon from "../../assets/images/icons/amenities - 1x PNG/in_progress.png";
import completedTaskIcon from "../../assets/images/icons/amenities - 1x PNG/completed.png";
import { visitorsDashboardAction } from "../../store/actions/visitors.action";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApexCharts from "../../components/ApexChart";

const VisitorsDashboard = () => {
  const dispatch = useDispatch();
  const visitors = useSelector((state) => state?.visitors?.visitor?.data);
  const location = useLocation();
  const id = location?.state?.id;
  const [res, setRes] = useState();
  // const apiCall = async (id) => {
  //   const apiRes = await dispatch(visitorsDashboardAction(id));
  //   if (apiRes?.statusCode === 200) {
  //     setRes(apiRes?.data);
  //   } else {
  //     toastr.error(apiRes?.response?.data.message)
  //   }
  // };
  // useEffect(() => {
  //   apiCall(id);
  // }, []);
  return (
    <div className="amenities">
      <div className="row mb-5">
        <h2 className="amenities-heading"> Overview </h2>
        <div className="col-lg-3">
          <Card
            style={{
              marginTop: 16,
            }}
          >
            <div className="row">
              <div className="col-10">
                <div>
                  <img alt="" src={totalTaskIcon} />
                </div>
              </div>
              {/* <div className="col-2">
                  <img alt="" src={menuIcon} />
                </div> */}
            </div>

            <h6 className="sm-title-card my-3">
              {res?.bookings_count?.total > 0 ? res?.bookings_count?.total : 0}
            </h6>
            <span className="desc-grey"> Total Task </span>
          </Card>
        </div>
        <div className="col-lg-3">
          <Card
            style={{
              marginTop: 16,
            }}
          >
            <div className="row">
              <div className="col-10">
                <div>
                  <img alt="" src={inProgressTaskIcon} />
                </div>
              </div>
              {/* <div className="col-2">
                  <img alt="" src={menuIcon} />
                </div> */}
            </div>

            <h6 className="sm-title-card my-3">
              {res?.bookings_count?.not_completed > 0
                ? res?.bookings_count?.not_completed
                : 0}
            </h6>
            <span className="desc-grey"> In Progress </span>
          </Card>
        </div>
        <div className="col-lg-3">
          <Card
            style={{
              marginTop: 16,
            }}
          >
            <div className="row">
              <div className="col-10">
                <div>
                  <img alt="" src={completedTaskIcon} />
                </div>
              </div>
              {/* <div className="col-2">
                  <img alt="" src={menuIcon} />
                </div> */}
            </div>

            <h6 className="sm-title-card my-3">
              {res?.bookings_count?.completed > 0
                ? res?.bookings_count?.completed
                : 0}
            </h6>
            <span className="desc-grey"> Completed Task </span>
          </Card>
        </div>
      </div>
      <div className="row mtt-20">
        <div className="col-lg-12">
          <h2 className="amenities-heading"> Monthly Overview </h2>
          <Card className="card">
            <div>
              <ApexCharts
                chartData={visitors?.bookings_count_per_day}
                labelOne="Completed"
                labelTwo="Total"
                heading="Completed Vs Total"
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default VisitorsDashboard;
