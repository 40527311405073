import React from 'react'
import ReactFlagsSelect from "react-flags-select";

const Country  = ({selected, onSelect, disabled}) => {
    return(
        <>
        <ReactFlagsSelect
        className='react-flag-select'
              searchable={true}
                selected={selected}
                onSelect={onSelect}
                disabled={disabled}
              />
        </>
    )
}

export default Country;