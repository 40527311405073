export const VISITORS_DASHBOARD = "VISITORS_DASHBOARD";
export const VISITORS_DASHBOARD_ERROR = "VISITORS_DASHBOARD_ERROR";
export const ADD_VISITORS = "ADD_VISITORS";
export const ADD_VISITORS_ERROR = "ADD_VISITORS_ERROR";
export const REQUESTS_By_TENANT = "REQUESTS_By_TENANT";
export const REQUESTS_By_TENANT_ERROR = "REQUESTS_By_TENANT_ERROR";
export const REQUESTS_By_ADMIN = "REQUESTS_By_ADMIN";
export const REQUESTS_By_ADMIN_ERROR = "REQUESTS_By_ADMIN_ERROR";
export const UPDATE_GUEST_MEMBER = "UPDATE_GUEST_MEMBER";
export const STATUS_GUEST_MEMBER = "STATUS_GUEST_MEMBER";
export const STATUS_GUEST_MEMBER_ERROR = "STATUS_GUEST_MEMBER_ERROR";