import React from "react";
import { Button, Col, Form, Row, Select } from "antd";
import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import maintenanceServiceIcon from "../../../assets/images/icons/maintenance_grey@3x-1.png";
import EngAmountIcon from "../../../assets/images/icons/amount_eng_grey@3x.png";
import capacityIcon from "../../../assets/images/icons/capasity_grey@3x.png";
import infoIcon from "../../../assets/images/icons/info@3x.png";

function MaintenanceGeneralInfo({
  setMaintenanceServiceName,
  setArabicMaintenanceServiceName,
  setAmount,
  status,
  capacity,
  setCapacity,
  setStatus,
  setDescription,
  setArabicDescription,
  maintenanceServiceName,
  arabicMaintenanceServiceName,
  amount,
  description,
  arabicDescription,
  onSubmitNext,
  editForm,
}) {
  return (
    <>
      <Form
        name="add_maintenance"
        className="maintenance_form"
        initialValues={{
          remember: true,
          name: maintenanceServiceName,
          name_ar: arabicMaintenanceServiceName,
          amount: amount,
          status: status,
          description: description,
          description_ar: arabicDescription,
          capacity: capacity,
        }}
        onFinish={onSubmitNext}
      >
        <h6 className="sm-title-card"> General Information </h6>
        <div className="row mtt-20">
          <div className="addServiceForm">
            <Row justify="space-between">
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: "5px" }}
              >
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input maintenance service name!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield"}
                    prefix={
                      <img
                        alt=""
                        src={maintenanceServiceIcon}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    placeholder="Maintenance Service"
                    onChange={(e) => {
                      setMaintenanceServiceName(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: "5px" }}
              >
                <Form.Item
                  name="name_ar"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please input maintenance service name in arabic!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield"}
                    suffix={
                      <img
                        alt=""
                        src={maintenanceServiceIcon}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    lang={"ar"}
                    dir={"rtl"}
                    placeholder={`خدمة الصيانة`}
                    onChange={(e) => {
                      setArabicMaintenanceServiceName(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: "5px" }}
              >
                <Form.Item
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Please input valid amount!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-20"}
                    type="number"
                    min={0}
                    prefix={
                      <img
                        alt=""
                        src={EngAmountIcon}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    placeholder="Amount"
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: "5px" }}
              >
                <Form.Item
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "Please input status!",
                    },
                  ]}
                >
                  <div className={"mtt-20"}>
                    {" "}
                    <Select
                      onClick={(e) => e.stopPropagation()}
                      fullwidth
                      defaultValue={status}
                      onChange={(e) => {
                        setStatus(e);
                      }}
                    >
                      <Option value="1">Active</Option>
                      <Option value="0">Inactive</Option>
                    </Select>{" "}
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ padding: "5px" }}
              >
                <Form.Item
                  name="capacity"
                  rules={[
                    {
                      required: true,
                      message: "Please input capacity!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-20 mb-0"}
                    min="0"
                    prefix={
                      <img
                        alt=""
                        src={capacityIcon}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    type="number"
                    defaultValue={capacity}
                    placeholder="Capacity"
                    onChange={(e) => {
                      setCapacity(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </Form.Item>
                <p className="m-1 mx-0">
                  <img alt="" src={infoIcon} style={{ height: "17px" }} />
                  <span className="helpingText mx-2">
                    Number of people that can be accommodated in one slot.
                  </span>
                </p>
              </Col>
            </Row>

            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input description!",
                },
              ]}
            >
              <TextArea
                autoSize={(false, { minRows: 4, maxRows: 6 })}
                showCount
                className={"mtt-20"}
                placeholder="Description"
                maxLength={100}
                style={{
                  padding: "5px",
                  height: 120,
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              name="description_ar"
              rules={[
                {
                  required: true,
                  message: "Please input description in arabic!",
                },
              ]}
            >
              <TextArea
                showCount
                autoSize={(false, { minRows: 4, maxRows: 6 })}
                className={"mtt-20"}
                lang={"ar"}
                dir={"rtl"}
                placeholder={`وصف`}
                maxLength={100}
                style={{
                  padding: "5px",
                  height: 120,
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  setArabicDescription(e.target.value);
                }}
              />
            </Form.Item>
          </div>
        </div>
        {!editForm && (
          <>
            <div className="stepNumber">
              <span className="sub-heading"> Step 1 of 3 </span>
            </div>
            <Form.Item>
              <div className="steps-action-one">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="servicesMainBtn"
                >
                  Next
                </Button>
              </div>
            </Form.Item>
          </>
        )}
      </Form>
    </>
  );
}

export default MaintenanceGeneralInfo;
