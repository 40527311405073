import {
  ADD_MAINTENANCE,
  ADD_MAINTENANCE_ERROR,
  MAINTENANCE_DASHBOARD,
  MAINTENANCE_DASHBOARD_ERROR,
  MAINTENANCE_LISTING,
  MAINTENANCE_LISTING_ERROR,
  MAINTENANCE_REQUESTS,
  MAINTENANCE_REQUESTS_ERROR,
  STATUS_MAINTENANCE_MEMBER,
  STATUS_MAINTENANCE_MEMBER_ERROR,
  UPDATE_MAINTENANCE_MEMBER,
} from "../types/maintenance.types";
import axios from "axios";
import { responseApi } from "../../helpers/helper";
export const maintenanceDashboardAction = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/maintenance/api/cms/maintenance/dashboard`,
      { organization_id: payload },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    dispatch({
      type: MAINTENANCE_DASHBOARD,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: MAINTENANCE_DASHBOARD_ERROR,
      payload: error,
    });
    return error;
  }
};
export const organizationMaintenanceList =
  (current, perItem, organization_id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/maintenance/api/common/fetch-maintenance-services/?page=${current}&per_page=${perItem}&sort_by=created_at&sort_order=DESC`,
        { organization_id: organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const maintenanceListing = {
        requests: res?.data?.data?.maintenaces,
        pagination: res?.data?.data?.pagination,
      };
      dispatch({
        type: MAINTENANCE_LISTING,
        payload: maintenanceListing,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: MAINTENANCE_LISTING_ERROR,
        payload: error,
      });
      return error;
    }
  };
export const createMaintenanceService = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/maintenance/api/cms/maintenance/create`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    if (res?.data?.data?.req_id) {
      const data = await responseApi(res?.data?.data?.req_id);
      dispatch({
        type: ADD_MAINTENANCE,
        payload: data,
      });
      return data;
    }
  } catch (error) {
    dispatch({
      type: ADD_MAINTENANCE_ERROR,
      payload: error,
    });
    return error;
  }
};
export const editMaintenanceService = (payload, maintenance_id) => async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/maintenance/api/cms/maintenance/edit_service/${maintenance_id}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    if (res?.data?.data?.req_id) {
      const data = await responseApi(res?.data?.data?.req_id);
      return data;
    }
  } catch (error) {
    return error;
  }
};
export const deleteMaintenanceServiceImage = (payload) => async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/maintenance/api/cms/maintenance/delete-maintenance-image`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    if (res?.data?.data?.req_id) {
      const data = await responseApi(res?.data?.data?.req_id);
      return data;
    }
  } catch (error) {
    return error;
  }
};
export const deleteMaintenanceServiceAvailability = (payload) => async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/maintenance/api/cms/maintenance/delete-availability`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    if (res?.data?.data?.req_id) {
      const data = await responseApi(res?.data?.data?.req_id);
      return data;
    }
  } catch (error) {
    return error;
  }
};
export const organizationMaintenanceRequests =
  (current, perItem, organization_id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/maintenance/api/cms/maintenance/listRequest/?page=${current}&per_page=${perItem}&sort_by=created_at&sort_order=DESC`,
        { organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const maintenanceRequests = {
        requests: res?.data?.data?.maintenance_data,
        pagination: res?.data?.data?.pagination,
        unread_notifications_count: res?.data?.data?.unread_notifications_count
      };
      dispatch({
        type: MAINTENANCE_REQUESTS,
        payload: maintenanceRequests,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: MAINTENANCE_REQUESTS_ERROR,
        payload: error,
      });
      return error;
    }
  };
export const searchMaintenanceRequestsAction =
  (current, perItem, id, searchedVal, searchedDate) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/maintenance/api/cms/maintenance/search_booked_maintenance?page=${current}&per_page=${perItem}&sort_by=created_at&sort_order=ASC`,
        { organization_id: id, search: searchedVal, date: searchedDate },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const maintenanceRequests = {
        requests: res?.data?.data?.maintenances,
        pagination: res?.data?.data?.pagination,
      };
      dispatch({
        type: MAINTENANCE_REQUESTS,
        payload: maintenanceRequests,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: MAINTENANCE_REQUESTS_ERROR,
        payload: error,
      });
      return error;
    }
  };
export const maintenanceReschedule = (payload) => async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/maintenance/api/cms/maintenance/reschedule`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
      return res?.data;
  } catch (error) {
    return error;
  }
};
export const checkOpenDaysAndSlots =
  ({ maintenance_service_id, tenant_id, date }) =>
  async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/maintenance/api/common/check-availability`,
        { maintenance_service_id, tenant_id, date },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      return res.data;
    } catch (error) {
      return error;
    }
  };
export const MaintenanceStatusAction =
  (status, service_booked_id, disapprove_reason, maintenance) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/maintenance/api/cms/maintenance/change-status/${service_booked_id}`,
        { status, disapprove_reason },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const state = await getState();
      const duplicateMaintenanceData = [...maintenance];
      const index = await duplicateMaintenanceData.findIndex(
        ({ id }) => id === service_booked_id
      );
      duplicateMaintenanceData[index].status = status;
      const updateMaintenanceData = {
        requests: duplicateMaintenanceData,
        pagination: state?.maintenance?.maintenance?.pagination,
      };

      if (res?.data?.data?.req_id) {
        const data = await responseApi(res?.data?.data?.req_id);
        dispatch({
          type: UPDATE_MAINTENANCE_MEMBER,
          payload: updateMaintenanceData,
        });
        dispatch({
          type: STATUS_MAINTENANCE_MEMBER,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: STATUS_MAINTENANCE_MEMBER_ERROR,
        payload: error,
      });
    }
  };
export const getMaintenanceBanner = (payload) => async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/maintenance/api/common/list-banner`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const addMaintenanceBanner = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/maintenance/api/cms/maintenance/add-banner`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    if (res?.data?.data?.req_id) {
      const data = await responseApi(res?.data?.data?.req_id);
      return data;
    }
  } catch (error) {
    return error;
  }
};
export const deleteMaintenanceBanner = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/maintenance/api/cms/maintenance/delete-banner/${payload}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    if (res?.data?.data?.req_id) {
      const data = await responseApi(res?.data?.data?.req_id);
      return data;
    }
  } catch (error) {
    return error;
  }
};
