import React, {useState, useEffect} from 'react';

const NoInternetConnection = (props) => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    // if user is online, return the child component else return a custom component
    if(isOnline){
    return(
        props.children
    )
    } else {
        return(
            <div className="no-data-found">
                <div>
                <h1 className='list-heading'>No Internet</h1>
                <p className='sub-heading'>Try:</p>
                <ul >
  <li className='sub-heading mb-2'>Checking the network cables, modem and router</li>
  <li className='sub-heading'>Reconnecting to Wi-Fi</li>
</ul> 
              <p className="grey-defTxt">ERR_INTERNET_DISCONNECTED</p>
              </div>
            </div>
        )
    }
}

export default NoInternetConnection;