import { Card, Radio, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllApprovedTenants,
  getAllAwaitingTenants,
  getAllTenant,
  searchApprovedTenants,
  statusTenantAction,
} from "../../store/actions/tenant.action";
import TenantsListing from "./components/TenantsListing";
import toastr from "toastr";
import { TitleSearch } from "../../components/SearchFilter";
const optionsWithDisabled = [
  {
    label: "Approved",
    value: "Approved",
  },
  {
    label: "Awaiting Approvals",
    value: "Awaiting Approvals",
  },
];

const Tenants = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useSelector((state) => state?.user?.currentUser?.organization_id);
  const reset = location?.state?.reset;
  const getTenants = useSelector((state) => state);
  const [tenantList, setTenantList] = useState("Approved");
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerpage] = useState(10);

  const tenantStore = useSelector((state) => state);
  const per_page = tenantStore?.tenant?.tenant?.pagination?.per_page;
  const page_no = tenantStore?.tenant?.tenant?.pagination?.page;

  const services = useSelector((state) => state?.user?.services);
  let currentService = "";
  if (services) {
    currentService = services?.find(
      (item) => item?.service === "User"
    )?.privilege;
  }

  const getApprovedTenants = async () => {
    if (reset === true) {
      setTenants([]);
      let verifyTenant = await dispatch(getAllApprovedTenants(0, 10, id));
      setTenants(verifyTenant?.data);
      setLoading(false);
      navigate(location.pathname, { replace: true });
    } else {
      setTenants([]);
      let verifyTenant = await dispatch(
        getAllApprovedTenants(
          page_no ? page_no : currentPage,
          per_page ? per_page : perPage,
          id
        )
      );
      setCurrentPage(page_no);
      setPerpage(per_page);
      setTenants(verifyTenant?.data);
      setLoading(false);
    }
  };
  const getAwaitingTenants = async () => {
    setTenants([]);
    let verifyTenant = await dispatch(getAllAwaitingTenants(0, 10, id));
    setCurrentPage(1);
    setPerpage(10);
    setTenants(verifyTenant?.data);
    setLoading(false);
  };
  const onChangeTenantList = async ({ target: { value } }) => {
    setLoading(true);
    setTenantList(value);
    if (value === "Awaiting Approvals") {
      getAwaitingTenants();
    } else {
      getApprovedTenants();
    }
  };
  const handleSearch = async (searchText) => {
    if (searchText) {
      const res = await dispatch(
        searchApprovedTenants(
          id,
          searchText === null ? "null" : searchText,
          tenantList === "Approved" ? "1" : "0"
        )
      );

      if (res.data?.tenants.length > 0) {
        setTenants(res?.data);
        setLoading(false);
      }
    } else {
      if (tenantList === "Awaiting Approvals") {
        getAwaitingTenants();
      } else {
        getApprovedTenants();
      }
    }
  };

  const handleChange = async (val, row) => {
    if (row.apartment_no === null && row.building_no === null) {
      toastr.error("Can't approve tenant without Building & Apartment No's");
      return;
    } else {
      await dispatch(
        statusTenantAction(
          id,
          row?.id,
          val,
          getTenants?.tenant?.tenant?.tenants
        )
      );
      const duplicateTenants = [...tenants?.tenants];
      const index = await duplicateTenants.findIndex(
        ({ id }) => id === row?.id
      );
      duplicateTenants.splice(index, 1);
      const finalTenants = {
        tenants: duplicateTenants,
      };
      setTenants(finalTenants);
    }
  };

  useEffect(() => {
    getApprovedTenants();
  }, []);

  return (
    <>
      <div className="tabled-div">
        <Card className="card">
          <div className="dp-grid-tenant">
            <div>
              <h1 className="list-heading">Tenants List</h1>
            </div>
            <div className="tenant-radio-group d-flex justify-content-between">
              <Radio.Group
                options={optionsWithDisabled}
                onChange={onChangeTenantList}
                value={tenantList}
                optionType="button"
                buttonStyle="solid"
              />
              <div className="filterButtons d-flex mt-2 ">
                <TitleSearch onSearch={handleSearch} />
              </div>
            </div>
          </div>
          {loading ? (
            <div className="no-data-found-card">
              <Spin />
            </div>
          ) : (
            <div>
              <TenantsListing
                currentService={currentService}
                tenants={tenants?.tenants}
                pagination={tenants?.pagination}
                id={id}
                reset={reset}
                setTenants={setTenants}
                tenantList={tenantList}
                handleChange={handleChange}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                perPage={perPage}
                setPerpage={setPerpage}
              />
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default Tenants;
