import React, { useEffect, useState } from "react";
import { Card, Radio, Typography } from "antd";
import { useLocation } from "react-router-dom";
import ParcelImage from "../../assets/images/core-images/parcel-dummy.png";
import Circle from "../../assets/images/icons/radio_unslect@3x.png";
import FilledCircle from "../../assets/images/icons/radio_select@3x.png";
import { viewParcelDetail } from "../../store/actions/parcel.action";
import { useDispatch } from "react-redux";
import toastr from "toastr";

const ParcelRequestDetail = () => {
  const [parcelData, setParcelData] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentReceivingOption, setPaymentReceivingOption] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const getParcelDetail = async () => {
    const parcelDetail = await dispatch(viewParcelDetail(location?.state?.id));
    if (parcelDetail.statusCode === 200) {
      setParcelData(parcelDetail?.data?.parcel);
      setPaymentMethod(parcelDetail?.data?.parcel?.payment_option);
      setPaymentReceivingOption(parcelDetail?.data?.parcel?.recieving_option);
    } else {
      toastr.error(parcelDetail?.response?.data?.message);
    }
  };

  useEffect(() => {
    getParcelDetail();
  }, []);
  return (
    <>
      <Card className="card parcel-pd">
        <div className="d-flex justify-content-between">
          <p className="list-heading">Parcel Details</p>
          <div
            className={
              parcelData?.status === "1"
                ? "status-bar d-flex align-items-center"
                : "status-bar-grey d-flex align-items-center"
            }
          >
            <span className="status-text">
              {parcelData?.status === "1"
                ? "Received by Tenant"
                : "Received at Reception"}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between mtt-20">
          <div className="d-flex" style={{ width: "100%" }}>
            <img
              alt="parcel-img"
              src={
                parcelData?.image
                  ? `${process.env.REACT_APP_S3_BUCKET_URL}/${parcelData?.image}`
                  : ParcelImage
              }
              className="parcel-img-view"
            />
            <div className="mx-3">
              <p className="parcel-heading-in-view mb-0 mtt-10">
                {parcelData?.parcel_name}
              </p>
              <p className="parcel-hash-no-in-view">#{parcelData?.id}</p>
              <p className="parcel-description-in-view">
                {parcelData?.description}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-end" style={{ width: "100%" }}>
            <div className="d-flex flex-column justify-content-between">
              <p className="received-on-in-parcel-view">
                Received on:{" "}
                <span className="received-on-date-in-parcel-view">
                  {parcelData?.date}
                </span>
              </p>
              <p className="amount-in-parcel-view m-0">
                {parcelData?.amount} AED
              </p>
            </div>
          </div>
        </div>
        <div className="payment-method">
          <p className="heading-in-parcel-view mt-20">Payment Required</p>
          <Radio.Group
            defaultValue={paymentMethod}
            buttonStyle="solid"
            disabled={true}
          >
            <Radio.Button
              value="e-butler"
              style={{
                border:
                  paymentMethod == "e-butler"
                    ? "1.5px solid #1D4599"
                    : " 1px solid #d9d9d9",
              }}
            >
              <div
                className={
                  paymentMethod === "e-butler"
                    ? "d-flex justify-content-start selected-radio-btn"
                    : "d-flex justify-content-start"
                }
              >
                <img
                  alt=""
                  src={paymentMethod === "e-butler" ? FilledCircle : Circle}
                  className="payment-circle"
                />
                <div>
                  <p className="mx-2 mb-0 E-butler-balance">E-Butler Balance</p>
                  <p
                    className={
                      paymentMethod === "e-butler"
                        ? "mx-2 mb-0 E-butler-balance-description"
                        : "mx-2 mb-0 E-butler-balance-description-grey"
                    }
                  >
                    AED{" "}
                    <span
                      style={{ color: "#1D4599" }}
                    >{`${parcelData?.balance}.00`}</span>{" "}
                    E-Butler actual Balance.
                  </p>
                </div>
              </div>
            </Radio.Button>
            <Radio.Button
              value="cash"
              style={{
                border:
                  paymentMethod == "cash"
                    ? "1.5px solid #1D4599"
                    : "1px solid #d9d9d9",
              }}
            >
              <div
                className={
                  paymentMethod === "cash"
                    ? "d-flex justify-content-start selected-radio-btn"
                    : "d-flex justify-content-start"
                }
              >
                <img
                  alt=""
                  src={paymentMethod === "cash" ? FilledCircle : Circle}
                  className="payment-circle"
                />
                <div>
                  <p className="mx-2 mb-0 E-butler-balance">
                    Pay in cash immediately
                  </p>
                  <p
                    className={
                      paymentMethod === "cash"
                        ? "mx-2 mb-0 E-butler-balance-description"
                        : "mx-2 mb-0 E-butler-balance-description-grey"
                    }
                  >
                    Cash only
                  </p>
                </div>
              </div>
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className="payment-receiving-options">
          <p className="heading-in-parcel-view mt-20">Receiving Options</p>
          <Radio.Group
            defaultValue={paymentReceivingOption}
            buttonStyle="solid"
            disabled={true}
          >
            <Radio.Button
              value="immediately"
              style={{
                border:
                  paymentReceivingOption == "immediately"
                    ? "1.5px solid #1D4599"
                    : "1px solid #d9d9d9",
              }}
            >
              <div
                className={
                  paymentReceivingOption === "immediately"
                    ? "d-flex justify-content-start selected-radio-btn"
                    : "d-flex justify-content-start"
                }
              >
                <img
                  alt=""
                  src={
                    paymentReceivingOption === "immediately"
                      ? FilledCircle
                      : Circle
                  }
                  className="payment-circle"
                />
                <div>
                  <p className="mx-2 mb-0 E-butler-balance">
                    Receive Immediately
                  </p>
                  <p
                    className={
                      paymentReceivingOption === "immediately"
                        ? "mx-2 mb-0 E-butler-balance-description"
                        : "mx-2 mb-0 E-butler-balance-description-grey"
                    }
                  >
                    You will receive the parcel by yourself
                  </p>
                </div>
              </div>
            </Radio.Button>
            <Radio.Button
              value="hold"
              style={{
                border:
                  paymentReceivingOption == "hold"
                    ? "1.5px solid #1D4599"
                    : "1px solid #d9d9d9",
              }}
            >
              <div
                className={
                  paymentReceivingOption === "hold"
                    ? "d-flex justify-content-start selected-radio-btn"
                    : "d-flex justify-content-start"
                }
              >
                <img
                  alt=""
                  src={
                    paymentReceivingOption === "hold" ? FilledCircle : Circle
                  }
                  className="payment-circle"
                />
                <div>
                  <p className="mx-2 mb-0 E-butler-balance">Hold it down</p>
                  <p
                    className={
                      paymentReceivingOption === "hold"
                        ? "mx-2 mb-0 E-butler-balance-description"
                        : "mx-2 mb-0 E-butler-balance-description-grey"
                    }
                  >
                    The Receptionist will keep it until you came
                  </p>
                </div>
              </div>
            </Radio.Button>
            <Radio.Button
              value="someone-pick"
              style={{
                border:
                  paymentReceivingOption == "someone-pick"
                    ? "1.5px solid #1D4599"
                    : "1px solid #d9d9d9",
              }}
            >
              <div
                className={
                  paymentReceivingOption === "someone-pick"
                    ? "d-flex justify-content-start selected-radio-btn"
                    : "d-flex justify-content-start"
                }
              >
                <img
                  alt=""
                  src={
                    paymentReceivingOption === "someone-pick"
                      ? FilledCircle
                      : Circle
                  }
                  className="payment-circle"
                />
                <div>
                  <p className="mx-2 mb-0 E-butler-balance">
                    Someone will pick up
                  </p>
                  <p
                    className={
                      paymentReceivingOption === "someone-pick"
                        ? "mx-2 mb-0 E-butler-balance-description"
                        : "mx-2 mb-0 E-butler-balance-description-grey"
                    }
                  >
                    You will give us some info about the person
                  </p>
                </div>
              </div>
            </Radio.Button>
          </Radio.Group>
        </div>
        {paymentReceivingOption === "someone-pick" && (
          <>
            <div className="parcel-person-detail">
              <h1>Person Details</h1>
              <h2 className="mtt-20">Name</h2>
              <p>{parcelData?.person_name}</p>
              <h2 className="mtt-20">Phone Number</h2>
              <p>{parcelData?.person_phone}</p>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default ParcelRequestDetail;
