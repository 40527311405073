import React, { useState } from "react";
import { Modal, Button, Input } from "antd";
import { useDispatch } from "react-redux";
import toastr from "toastr";
import {
  addVisitorGuideline,
  getAllVisitorGuidelines,
} from "../../../store/actions/visitors.action";
import { useEffect } from "react";
import crossIcon from "../../../assets/images/icons/cross-3x.png";
const VisitorGuidelines = ({
  showVisitorGuidelinesModal,
  setShowVisitorGuidelinesModal,
  orgId,
}) => {
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const [inputList, setInputList] = useState([
    { guidelines: "", guidelines_ar: "" },
  ]);
  const handleCancel = () => {
    setShowVisitorGuidelinesModal(false);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { guidelines: "", guidelines_ar: "" }]);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const onSubmit = async () => {
    setDisable(true);
    let guidelines = [];
    let guidelines_ar = [];
    if (inputList) {
      inputList?.map((item) => {
        guidelines.push(item?.guidelines);
        guidelines_ar.push(item?.guidelines_ar);
      });
    }

    const obj = {
      organization_id: orgId,
      guidelines,
      guidelines_ar,
    };

    const res = await dispatch(addVisitorGuideline(obj));
    if (res.statusCode === 200) {
      toastr.success("Successfully Added");
      handleCancel();
    } else if (Object.keys(res)?.length === 0) {
      toastr.success("Successfully Added");
      handleCancel();
    } else {
      toastr.error(res?.response?.data?.message);
    }
  };

  const getGuidelines = async () => {
    const res = await dispatch(getAllVisitorGuidelines(orgId));
    if (res?.data?.guidelines?.length !== 0) {
      setInputList(res?.data?.guidelines?.guidelines_array);
    }
  };

  useEffect(() => {
    getGuidelines();
  }, []);
  return (
    <>
      <Modal
        centered
        footer={null}
        header={null}
        visible={showVisitorGuidelinesModal}
        onCancel={() => handleCancel()}
        width={850}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="mt-2">
            <span className="def-sm-Title">Add Visitors Guidelines</span>
          </div>
          <img
            src={crossIcon}
            className="crossIcon"
            alt="close-modal"
            onClick={() => handleCancel()}
          />
        </div>

        <div className="row mtt-20">
          {inputList?.map((x, i) => {
            return (
              <>
                <div className="col-lg-10 mtt-20">
                  <Input
                    name="guidelines"
                    className={"inputfield mtt-20"}
                    placeholder={`Guideline Point ${i + 1}`}
                    value={x.guidelines}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  <Input
                    className={"inputfield mtt-10"}
                    lang={"ar"}
                    dir={"rtl"}
                    name="guidelines_ar"
                    placeholder={`نقطة توجيهية ${i + 1}`}
                    value={x.guidelines_ar}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                </div>
                {i === 0 && (
                  <div className="col-lg-2 mtt-20">
                    <Button
                      type="primary"
                      className="add-more-btn mtt-20"
                      onClick={handleAddClick}
                    >
                      Add More
                    </Button>
                  </div>
                )}

                {i !== 0 && (
                  <div className="col-lg-2 mtt-20">
                    <Button
                      type="primary"
                      className="add-more-btn mtt-20"
                      onClick={() => handleRemoveClick(i)}
                    >
                      Remove
                    </Button>
                  </div>
                )}
              </>
            );
          })}
        </div>

        <div className="d-flex justify-content-end mtt-10">
          <Button
            className="servicesMainBtn"
            onClick={() => onSubmit()}
            disabled={disable}
          >
            {disable ? "Loading..." : "Save"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default VisitorGuidelines;
