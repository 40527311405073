import { Modal, Upload } from "antd";
import toastr from "toastr";
const UploadPhotos = ({
  fileList,
  onChange,
  deleteImage,
  onPreview,
  previewVisible,
  setPreviewVisible,
  previewImage,
  previewTitle,
}) => {
  const handleCancel = () => setPreviewVisible(false);

  const uploadButton = (
    <>
      <div>+</div>
    </>
  );
  return (
    <>
      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileList}
        maxCount={4}
        accept=".png,.jpg"
        multiple
        onPreview={onPreview}
        onRemove={(item) => deleteImage(item)}
        onChange={onChange}
        beforeUpload={ (file) => {
          const acceptedFormats = ["PNG", "png", "jpg", "JPG", 'bmp', 'BMP', 'ico', 'ICO', 'gif', 'GIF', 'jpeg', 'JPEG'];
          const myArr = file?.name.split(".");
                const myVal = myArr[myArr?.length - 1];
                const formats = acceptedFormats.includes(myVal)
                if(formats === false){
                  toastr.error('Format not Supported')
                }
                return formats
            ? false
            : Upload.LIST_IGNORE;
        }}
      >
        {fileList?.length >= 4 ? null : uploadButton}
      </Upload>
      <span className="desc-grey-font-12 mtt-10">
        Files Supported: PNG, JPEG
      </span>
      <span className="desc-grey-font-12 mtt-10">Image Size (384 x 115)</span>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default UploadPhotos;
