import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/images/icons/HH-logo.png";
import galleryPlaceholder from "../assets/images/icons/galleryPlaceholder.jpg";
import { Layout, Menu } from "antd";
import HeaderTop from "./Header/header";
import { useSelector } from "react-redux";
import SideDrawer from "../components/Drawer";
import SideMenu from "../components/SideMenu";
import { set } from "date-fns";
const { Header, Sider, Content } = Layout;

const PortalScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState("");
  const [userId, setUserId] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [state, setState] = useState(location.pathname);
  const [openKeys, setOpenKeys] = useState([]);

  const currentUser = useSelector((state) => state?.user?.currentUser);
  const Services = useSelector((state) => state?.user?.services);
  const notificationCount = useSelector((state) => state?.notifications);
  const requestByTenants = useSelector(
    (state) => state.visitors.requestsByTenant
  );
  const getAmenityRequestCount = useSelector(
    (state) => state?.amenity?.amenities?.unread_notifications_count
  );
  const getMaintenanceRequestCount = useSelector(
    (state) => state?.maintenance?.maintenance?.unread_notifications_count
  );
  const requestByAdmin = useSelector((state) => state.visitors.requestsByAdmin);
  const getBellboyRequestList = useSelector(
    (state) => state?.bellboy?.bellboyRequests
  );

  const parcelRequestCount = notificationCount?.unReadNotificationCountOfParcel;
  const amenitiesRequestCount =
    notificationCount?.unReadNotificationCountOfAmenities;
  const maintenanceRequestCount =
    notificationCount?.unReadNotificationCountOfMaintenance;
  const requestByTenantsCount = requestByTenants?.unread_notifications_count;
  const requestByAdminCount = requestByAdmin?.unread_notifications_count;
  const bellboyRequestsCount =
    getBellboyRequestList?.unread_notifications_count;
  let arr = [];
  if (Services) {
    for (let I in Services) {
      arr.push(Services[I]?.service);
    }
  }

  const rootSubmenuKeys = [
    "111",
    "222",
    "/havenly/parcelsDashboard",
    "/havenly/amenitiesDashboard",
    "/havenly/maintenanceDashboard",
    "/havenly/visitorsDashboard",
  ];
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    setUserId(currentUser?.organization_id);
    setUserData(currentUser?.role);
  }, [currentUser]);

  const handleMenuParent = (value, path) => {
    setState(value);
    navigate(path, {
      state: { id: userId, reset: true },
    });
  };

  useEffect(() => {
    if (location?.pathname === "/havenly/organizationMembersListing") {
      setOpenKeys(["111"]);
    } else if (location?.pathname === "/havenly/bellboyRequests") {
      setOpenKeys(["222"]);
    } else {
      setOpenKeys([location?.pathname]);
    }
  }, []);

  useEffect(() => {
    if (
      location?.pathname === "/havenly/createNewOrganizationMember" ||
      location?.pathname?.split("/")[2] === "editOrganizationMember"
    ) {
      setState("/havenly/organizationMembersListing");
    } else if (
      location?.pathname === "/havenly/addNewTenant" ||
      location?.pathname?.split("/")[2] === "editTenant"
    ) {
      setState("/havenly/tenants");
    } else if (
      location?.pathname === "/havenly/addNewBellboy" ||
      location?.pathname === "/havenly/editBellboy"
    ) {
      setState("/havenly/bellboy");
    } else if (
      location?.pathname === "/havenly/addNewParcel" ||
      location?.pathname === "/havenly/parcelRequestDetail"
    ) {
      setState("/havenly/parcelsDashboard");
    } else if (
      location?.pathname === "/havenly/addAmenities" ||
      location?.pathname === "/havenly/addAmenityBanner" ||
      location?.pathname === "/havenly/editAminity" ||
      location?.state?.type === "amenity"
    ) {
      setState("/havenly/amenitiesDashboard");
    } else if (
      location?.pathname === "/havenly/addMaintenance" ||
      location?.pathname === "/havenly/addMaintenanceBanner" ||
      location?.pathname === "/havenly/editMaintenance" ||
      location?.state?.type === "maintenance"
    ) {
      setState("/havenly/maintenanceDashboard");
    } else if (location?.pathname === "/havenly/tenantDetails") {
      setState("/havenly/requestsByTenant");
    } else {
      setState(location?.pathname);
    }
  }, [location]);

  const drawerhandler = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout>
      <SideDrawer
        userData={userData}
        visible={collapsed}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        state={state}
        handleMenuParent={handleMenuParent}
        arr={arr}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        organization_id={userId}
      />
      <Sider>
        <div className="text-center mtt-10">
          {" "}
          {userData !== "eb-super-admin" ? (
            <img
              alt=""
              src={
                currentUser?.organization_image
                  ? `${process.env.REACT_APP_S3_BUCKET_URL}/${currentUser?.organization_image}`
                  : galleryPlaceholder
              }
              className={collapsed === true ? "dp-none" : "sidebar-logo"}
            />
          ) : (
            <img
              alt=""
              src={Logo}
              className={collapsed === true ? "dp-none" : "sidebar-logo"}
            />
          )}
        </div>
        <SideMenu
          userData={userData}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          state={state}
          handleMenuParent={handleMenuParent}
          arr={arr}
          bellboyRequestsCount={bellboyRequestsCount}
          parcelRequestCount={parcelRequestCount}
          getAmenityRequestCount={getAmenityRequestCount}
          getMaintenanceRequestCount={getMaintenanceRequestCount}
          amenitiesRequestCount={amenitiesRequestCount}
          maintenanceRequestCount={maintenanceRequestCount}
          requestByTenantsCount={requestByTenantsCount}
          requestByAdminCount={requestByAdminCount}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background">
          <HeaderTop drawerhandler={drawerhandler} organization_id={userId} />
        </Header>
        <Content className="site-layout-background-content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default PortalScreen;
