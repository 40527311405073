import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Steps,
  Button,
  Form,
  Select,
  Checkbox,
  Upload,
  Radio,
  Modal,
} from "antd";
import countryList from "react-select-country-list";
import toastr from "toastr";
import { userState } from "./StaticData";

import Building from "../../../assets/images/icons/building_blue@3x-3.png";
import Setting_Grey from "../../../assets/images/icons/Group 101701@3x.png";
import Setting_Blue from "../../../assets/images/icons/service_blue@3x.png";
import Preview_Grey from "../../../assets/images/icons/preview_grey@3x.png";
import Preview_Blue from "../../../assets/images/icons/preview_blue@3x-1.png";

import addImgRectangle from "../../../assets/images/icons/add_img_rectangle.png.png";
import dummyPlaceholder from "../../../assets/images/icons/super admin - 1x PNG/upload_profile_pic.png";
import Congratulations from "../../../components/congratulations";
import { useDispatch } from "react-redux";
import {
  createOrganizationUserAction,
  getOrganizationServicesAction,
} from "../../../store/actions/organization.action";
import FormInputs from "./RegistrationForm";
import AddImageModal from "../../../components/AddImageModal";
const { Step } = Steps;
const { Option } = Select;

const CreateNewUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [visible, setVisible] = useState(false);
  const [arabicFirstName, setArabicFirstName] = useState("");
  const [arabicLastName, setArabicLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [memberEmail, setMemberEmail] = useState("");
  const [memberPassword, setMemberPassword] = useState("");
  const [gender, setGender] = useState("");
  const [memberPhone, setMemberPhone] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [memberCountry, setMemberCountry] = useState("");
  const [DOB, setDOB] = useState(new Date());
  const [imgVisible, setImgVisible] = useState(false);
  const [state, setState] = useState({});
  const [data_checkBox, setData_checkBox] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [selected, setSelected] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [active, setActive] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const services = await dispatch(
      getOrganizationServicesAction(location?.state?.id)
    );
    const data_checkBoxTemp = services?.data?.services?.map((x) => {
      return {
        id: x?.service_id,
        label: x?.name,
        privilege: "read",
      };
    });
    setData_checkBox(data_checkBoxTemp);
  };

  const handleFlagCountry = (code) => {
    setSelected(code);
    const countryLabel = countryList().getLabel(code);
    setMemberCountry(countryLabel);
  };

  const radioStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    navigate(-1);
  };
  const onCheckChange = (checkedValues, e) => {
    if (e.target.checked) {
      if (!selectedServices.some((x) => x.id === checkedValues.id)) {
        setSelectedServices([...selectedServices, checkedValues]);
      }
    } else {
      if (selectedServices.some((x) => x.id === checkedValues.id)) {
        const filtered = selectedServices.filter(
          (value) => value.id !== checkedValues.id
        );
        setSelectedServices(filtered);
      }
    }

    if (!selectedServices.some((x) => x.id === checkedValues.id)) {
      setSelectedServices([...selectedServices, checkedValues]);
    }
  };
  const onStatusChange = (checkedValues, e) => {
    if (selectedServices.some((x) => x.id === checkedValues.id)) {
      const temp = data_checkBox.map((el) => {
        return {
          ...el,
          privilege:
            el.id === checkedValues.id
              ? e?.target?.value || el.privilege
              : el.privilege,
        };
      });
      setData_checkBox(temp);
      if (selectedServices.find((item) => item.id === checkedValues.id)) {
        const index = selectedServices.findIndex(
          ({ id }) => id === checkedValues.id
        );
        selectedServices[index].privilege = e?.target?.value
          ? e?.target?.value
          : selectedServices[index].privilege;
        setSelectedServices([...selectedServices]);
      } else {
        setSelectedServices([...selectedServices, checkedValues]);
      }
    }
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const nextProcess = () => {
    setCurrent(current + 1);
  };
  const onDateChange = (date, dateString) => {
    setDOB(dateString);
  };
  const handleSelect = (value) => {
    setMemberCountry(value);
  };

  const children = [];
  for (let i = 1; i <= 10; i++) {
    children.push(<Option key={i}>Pakistan</Option>);
  }

  const prev = () => {
    setCurrent(current - 1);
  };
  const onSubmit = async (values) => {
    setActive(true);
    let fd = new FormData();
    fd.append("first_name", firstName); //append the values with key, value pair
    fd.append("last_name", lastName);
    fd.append("first_name_ar", arabicFirstName); //append the values with key, value pair
    fd.append("last_name_ar", arabicLastName);
    fd.append("email", memberEmail);
    fd.append("password", memberPassword);
    fd.append("confirm_password", memberPassword);
    fd.append("phone", memberPhone);
    fd.append("country", memberCountry);
    fd.append("image", fileList?.slice(-1)[0]?.originFileObj);
    fd.append("gender", gender === "Male" ? 1 : 0);
    fd.append("date_of_birth", DOB);
    fd.append("services", JSON.stringify(selectedServices));
    fd.append("role", "org-user");
    fd.append("organization_id", location?.state?.id);

    const res = await dispatch(createOrganizationUserAction(fd));
    if (res.statusCode === 200) {
      setActive(false);
      showModal();
    } else if (Object.keys(res)?.length === 0) {
      setActive(false);
      showModal();
    } else {
      setActive(false);
      toastr.error(res?.response?.data?.message);
    }
  };

  const onGenderChange = ({ target: { value } }) => {
    setGender(value);
  };

  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.thumbUrl);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };
  const handleCancel = () => setPreviewVisible(false);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const handlePopupModal = () => {
    setImgVisible(true);
  };

  const hideImgModal = () => {
    setImgVisible(false);
  };

  const handleDeleteImage = () => {
    setFileList([]);
  };

  const onSubmitFirst = () => {
    nextProcess();
  };

  const onSubmitServices = () => {
    nextProcess();
  };

  form.setFieldsValue({
    tenantGender: gender,
    country: selected,
    memberImage: fileList,
    services: selectedServices,
  });

  const Services = (
    <Form.Item
      name="services"
      rules={[
        {
          required: true,
          message: "Please select at least one service!",
        },
      ]}
    >
      <>
        {data_checkBox?.map((item, index) => (
          <>
            <div className="slotAvailability d-flex">
              <Checkbox
                className="slotChecks"
                defaultChecked={selectedServices.find((x) => x.id === item.id)}
                key={index}
                options={data_checkBox}
                onChange={(e) => onCheckChange(item, e)}
              >
                <div>{item.label}</div>
              </Checkbox>
              <div
                style={{
                  width: "50%",
                  margin: "10px",
                }}
              >
                <Radio.Group
                  style={radioStyle}
                  options={userState}
                  onChange={(e) => onStatusChange(item, e)}
                  value={item.privilege}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </div>
          </>
        ))}
      </>
    </Form.Item>
  );

  const steps = [
    {
      title: "General Information",
      icon: <img alt="" src={Building} className="step-icon-size" />,
      content: (
        <Form
          onFinish={onSubmitFirst}
          initialValues={{
            remember: true,
          }}
          form={form}
        >
          <div>
            <h6 className="sm-title-card mtt-10"> General Information </h6>

            <div className="row">
              <div className="col-lg-6">
                <span className="sub-heading">Add New Member Details</span>
                <FormInputs
                  disable={false}
                  firstName={firstName}
                  lastName={lastName}
                  arabicFirstName={arabicFirstName}
                  arabicLastName={arabicLastName}
                  memberEmail={memberEmail}
                  memberPassword={memberPassword}
                  confirm_password={memberPassword}
                  memberPhone={memberPhone}
                  memberCountry={memberCountry}
                  fileList={fileList}
                  gender={gender}
                  DOB={DOB}
                  selectedServices={selectedServices}
                  setFirstName={setFirstName}
                  setLastName={setLastName}
                  setArabicFirstName={setArabicFirstName}
                  setArabicLastName={setArabicLastName}
                  setMemberEmail={setMemberEmail}
                  setMemberPassword={setMemberPassword}
                  setMemberPhone={setMemberPhone}
                  handleSelect={handleSelect}
                  onGenderChange={onGenderChange}
                  onDateChange={onDateChange}
                  radioStyle={radioStyle}
                  handleFlagCountry={handleFlagCountry}
                  selected={selected}
                />
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-4">
                <span className="sub-heading">Add your Photo</span>
                <Form.Item
                  name="memberImage"
                  rules={[
                    {
                      required: true,
                      message: "Please upload pictures!",
                    },
                  ]}
                >
                  {fileList.length === 0 ? (
                    <>
                      <div className="rectangle-frame-img">
                        <img
                          alt=""
                          src={addImgRectangle}
                          className="upload-img-box mtt-20"
                          onClick={handlePopupModal}
                        />
                        <p className="img-warn mtt-10">
                          Files Supported: PNG, JPEG
                        </p>
                        <p className="img-warn mtt-10">Image Size (200x200)</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <Upload
                        disabled={true}
                        listType="picture-card"
                        fileList={fileList.slice(-1)}
                        beforeUpload={() => false}
                        onPreview={handlePopupModal}
                      >
                        {fileList.length < 1 && "+"}
                      </Upload>
                      <p className="img-warn mt-1">
                        Files Supported: PNG, JPEG
                      </p>
                      <p className="img-warn mtt-10">Image Size (200x200)</p>
                    </>
                  )}
                </Form.Item>
              </div>
            </div>
            <div className="d-flex mt-4 align-items-end first-step-bottom-div">
              <div className="col-lg-8">
                <span className="sub-heading"> Step 1 of 3</span>
              </div>
              <div className="col-lg-4 px-3">
                <Button htmlType="submit" className="mainBtn servicesMainBtn">
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Form>
      ),
    },
    {
      title: "Services",
      icon: (
        <img
          alt=""
          src={current >= 1 ? Setting_Blue : Setting_Grey}
          className="step-icon-size"
        />
      ),
      content: (
        <div className="Services-Input mb-4">
          <h6 className="sm-title-card mtt-10"> Services </h6>
          <span className="sub-heading mtt-20">
            Select the services that you want to assign
          </span>
          <Form
            onFinish={onSubmitServices}
            initialValues={{
              remember: true,
              services: selectedServices,
            }}
            form={form}
          >
            {Services}
            <div className="stepNumber">
              <span className="sub-heading"> Step 2 of 3 </span>
            </div>
            <Form.Item>
              <div className="steps-action-one">
                <Button className="servicesBackBtn" onClick={() => prev()}>
                  Back
                </Button>
                <Button
                  type="primary"
                  className="servicesMainBtn"
                  htmlType="submit"
                  style={{ margin: "0 8px" }}
                >
                  Next
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
      title: "Preview",
      icon: (
        <img
          alt=""
          src={current !== 2 ? Preview_Grey : Preview_Blue}
          className="step-icon-size"
        />
      ),
      content: (
        <div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
              firstName: firstName,
              lastName: lastName,
              memberEmail: memberEmail,
              memberPassword: memberPassword,
              confirm_password: memberPassword,
              memberPhone: memberPhone,
              country: memberCountry,
              memberImage: fileList,
              gender: gender,
              DOB: DOB,
              services: selectedServices,
              created_by: 1,
            }}
            onFinish={onSubmit}
          >
            <div className="row">
              <div className="col-lg-6">
                <FormInputs
                  title={"Preview"}
                  disable={true}
                  firstName={firstName}
                  lastName={lastName}
                  arabicFirstName={arabicFirstName}
                  arabicLastName={arabicLastName}
                  memberEmail={memberEmail}
                  memberPassword={memberPassword}
                  confirm_password={memberPassword}
                  memberPhone={memberPhone}
                  memberCountry={memberCountry}
                  fileList={fileList}
                  gender={gender}
                  DOB={DOB}
                  selectedServices={selectedServices}
                  selected={selected}
                />
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-4 preview-only">
                <Form.Item
                  name="memberImage"
                  rules={[
                    {
                      required: true,
                      message: "Please upload pictures!",
                    },
                  ]}
                >
                  {fileList.length >= 1 ? (
                    <>
                      <Upload
                        disabled={true}
                        listType="picture-card"
                        fileList={fileList.slice(-1)}
                        beforeUpload={() => false}
                        onPreview={onPreview}
                      >
                        {fileList.length < 1 && "+"}
                      </Upload>
                      <p className="img-warn mt-1">
                        Files Supported: PNG, JPEG
                      </p>
                      <p className="img-warn mtt-10">Image Size (200x200)</p>
                    </>
                  ) : (
                    <div className="rectangle-frame-img">
                      <img
                        alt=""
                        src={dummyPlaceholder}
                        className="upload-img-box"
                      />
                      <p className="img-warn mtt-10">
                        Files Supported: PNG, JPEG
                      </p>
                      <p className="img-warn mtt-10">Image Size (200x200)</p>
                    </div>
                  )}
                </Form.Item>
              </div>
            </div>

            <div className="mt-5">
              <span className="sub-heading"> Step 3 of 3 </span>
            </div>
            <div className="steps-action mt-10px">
              <Button className="servicesBackBtn" onClick={() => prev()}>
                Back
              </Button>
              <Button
                type="primary"
                className="servicesMainBtn"
                htmlType="submit"
                style={{ margin: "0 8px" }}
                disabled={active}
              >
                {active ? "Loading..." : "Finish"}
              </Button>
            </div>
          </Form>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="tabled-div">
        <div className="row">
          <div className="col-lg-3">
            <Steps current={current} direction="vertical">
              {steps.map((item) => (
                <Step key={item.title} title={item.title} icon={item.icon} />
              ))}
            </Steps>
          </div>
          <div className="col-lg-9">
            <div className="steps-content"> {steps[current].content} </div>
          </div>
        </div>

        <Congratulations
          visible={visible}
          hideModal={hideModal}
          message={"Organization Member created successfully"}
        />
        <AddImageModal
          handleDeleteImage={handleDeleteImage}
          file={fileList}
          state={state}
          visible={imgVisible}
          hideModal={hideImgModal}
          onChange={onChange}
        />
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
      </div>
    </>
  );
};

export default CreateNewUser;
