module.exports = {
  timeSlots: [
    { id: 1, shift: "mid night", start_time: "12:00", end_time: "04:00" },
    { id: 3, shift: "early morning", start_time: "04:00", end_time: "08:00" },
    { id: 5, shift: "morning", start_time: "08:00", end_time: "12:00" },
    { id: 7, shift: "afternoon", start_time: "12:00", end_time: "04:00" },
    { id: 9, shift: "evening", start_time: "04:00", end_time: "08:00" },
    { id: 11, shift: "night", start_time: "08:00", end_time: "12:00" },
  ],
  monthsName: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
};
