import {
  ADD_VISITORS,
  ADD_VISITORS_ERROR,
  VISITORS_DASHBOARD,
  VISITORS_DASHBOARD_ERROR,
  REQUESTS_By_TENANT,
  REQUESTS_By_TENANT_ERROR,
  REQUESTS_By_ADMIN,
  REQUESTS_By_ADMIN_ERROR,
  UPDATE_GUEST_MEMBER,
  STATUS_GUEST_MEMBER,
  STATUS_GUEST_MEMBER_ERROR,
} from "../types/visitors.types";

const initialState = {
  visitors: [],
  loading: true,
  isData: false,
};

function visitorsReducer(state = initialState, action) {
  switch (action.type) {
    case VISITORS_DASHBOARD:
      return {
        ...state,
        visitors: action.payload,
        isData: true,
        loading: false,
      };
    case VISITORS_DASHBOARD_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    case ADD_VISITORS:
      return {
        ...state,
        isData: true,
        loading: false,
      };
    case ADD_VISITORS_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    case REQUESTS_By_TENANT:
      return {
        ...state,
        requestsByTenant: action.payload,
        isData: true,
        loading: false,
      };
    case REQUESTS_By_TENANT_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    case REQUESTS_By_ADMIN:
      return {
        ...state,
        requestsByAdmin: action.payload,
        isData: true,
        loading: false,
      };
    case REQUESTS_By_ADMIN_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    case UPDATE_GUEST_MEMBER:
      return {
        ...state,
        visitors: action.payload,
        isData: true,
        loading: false,
      };
    case STATUS_GUEST_MEMBER: {
      return {
        ...state,
        isData: true,
        loading: false,
      };
    }
    case STATUS_GUEST_MEMBER_ERROR: {
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    }
    default:
      return state;
  }
}
export default visitorsReducer;
