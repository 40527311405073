import React from "react";
import { Menu } from "antd";
import organization_blue from "../assets/images/icons/Group 101817@3x.png";
import AmenitiesIcon from "../assets/images/icons/Sidebar/amenities_grey.png";
import AmenitiesIconBlue from "../assets/images/icons/Sidebar/amenities_blue.png";
import MaintenanceIcon from "../assets/images/icons/Sidebar/maintenance_grey.png";
import MaintenanceIconBlue from "../assets/images/icons/Sidebar/maintenance_blue.png";
import BellboyIcon from "../assets/images/icons/Sidebar/bellboyname_grey.png";
import BellboyIconBlue from "../assets/images/icons/Sidebar/bellboyname_blue.png";
import VisitorsIcon from "../assets/images/icons/Sidebar/visitors_grey.png";
import VisitorsIconBlue from "../assets/images/icons/Sidebar/visitors_blue.png";
import UserIcon from "../assets/images/icons/Sidebar/user_grey.png";
import UserIconBlue from "../assets/images/icons/Sidebar/user_blue.png";
import ParcelIcon from "../assets/images/icons/Sidebar/parcels_grey.png";
import ParcelIconBlue from "../assets/images/icons/Sidebar/parcels_blue.png";
import { useNavigate } from "react-router-dom";

const SideMenu = ({
  userData,
  openKeys,
  onOpenChange,
  state,
  handleMenuParent,
  arr,
  bellboyRequestsCount,
  parcelRequestCount,
  getAmenityRequestCount,
  getMaintenanceRequestCount,
  amenitiesRequestCount,
  maintenanceRequestCount,
  requestByTenantsCount,
  requestByAdminCount,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {userData === "eb-super-admin" && (
        <>
          <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
            <Menu.Item
              key="1"
              onClick={() => navigate("organization-list")}
              icon={
                <img alt="" src={organization_blue} className="org-col-icon" />
              }
            >
              Buildings / Organizations
            </Menu.Item>
          </Menu>
        </>
      )}

      {userData !== "eb-super-admin" && (
        <Menu
          theme="dark"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={state}
          mode="inline"
        >
          {arr?.includes("User") && (
            <Menu.SubMenu
              key="111"
              onTitleClick={() =>
                handleMenuParent(
                  "/havenly/organizationMembersListing",
                  "organizationMembersListing"
                )
              }
              title="Users"
              icon={
                <img
                  className="barIcons"
                  alt=""
                  src={
                    state === "/havenly/organizationMembersListing" ||
                    state === "/havenly/tenants"
                      ? UserIconBlue
                      : UserIcon
                  }
                />
              }
            >
              <Menu.Item
                style={{ padding: "10px" }}
                key="/havenly/organizationMembersListing"
                onClick={() =>
                  handleMenuParent(
                    "/havenly/organizationMembersListing",
                    "organizationMembersListing"
                  )
                }
                icon={<img alt="" src={""} />}
              >
                Organization Members
              </Menu.Item>
              <Menu.Item
                style={{ padding: "10px" }}
                key="/havenly/tenants"
                onClick={() => handleMenuParent("/havenly/tenants", "tenants")}
                icon={<img alt="" src={""} />}
              >
                Tenants
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {arr?.includes("Bellboy") && (
            <Menu.SubMenu
              key="222"
              onTitleClick={() =>
                handleMenuParent("/havenly/bellboyRequests", "bellboyRequests")
              }
              title="Bellboy"
              icon={
                <img
                  className="barIcons"
                  alt=""
                  src={
                    state === "/havenly/bellboy" ||
                    state === "/havenly/bellboyRequests"
                      ? BellboyIconBlue
                      : BellboyIcon
                  }
                />
              }
            >
              <Menu.Item
                style={{ padding: "10px" }}
                key="/havenly/bellboy"
                onClick={() => handleMenuParent("/havenly/bellboy", "bellboy")}
                icon={<img alt="" src={""} />}
              >
                Bellboy Listing
              </Menu.Item>
              <Menu.Item
                style={{ padding: "10px" }}
                key="/havenly/bellboyRequests"
                onClick={() =>
                  handleMenuParent(
                    "/havenly/bellboyRequests",
                    "bellboyRequests"
                  )
                }
                icon={<img alt="" src={""} />}
              >
                Bellboy Requests
                {bellboyRequestsCount > 0 && (
                  <span className="side-bar-noti-count">
                    {bellboyRequestsCount > 10 ? "10+" : bellboyRequestsCount}
                  </span>
                )}
              </Menu.Item>
            </Menu.SubMenu>
          )}

          {arr?.includes("Parcels") && (
            <Menu.SubMenu
              key="/havenly/parcelsDashboard"
              onTitleClick={() =>
                handleMenuParent(
                  "/havenly/parcelsDashboard",
                  "parcelsDashboard"
                )
              }
              title="Parcels"
              icon={
                <img
                  className="barIcons"
                  alt=""
                  src={
                    state === "/havenly/parcelsDashboard" ||
                    state === "/havenly/parcelRequests"
                      ? ParcelIconBlue
                      : ParcelIcon
                  }
                />
              }
            >
              <Menu.Item
                style={{ padding: "10px" }}
                key="/havenly/parcelRequests"
                onClick={() =>
                  handleMenuParent("/havenly/parcelRequests", "parcelRequests")
                }
                icon={<img alt="" src="" />}
              >
                Parcel Requests{" "}
                {parcelRequestCount > 0 && (
                  <span className="side-bar-noti-count">
                    {parcelRequestCount > 10 ? "10+" : parcelRequestCount}
                  </span>
                )}
              </Menu.Item>
            </Menu.SubMenu>
          )}

          {arr?.includes("Amenities") && (
            <Menu.SubMenu
              key="/havenly/amenitiesDashboard"
              onTitleClick={() =>
                handleMenuParent(
                  "/havenly/amenitiesDashboard",
                  "amenitiesDashboard"
                )
              }
              title="Amenities"
              icon={
                <img
                  className="barIcons"
                  alt=""
                  src={
                    state === "/havenly/amenitiesDashboard" ||
                    state === "/havenly/amenitiesListing" ||
                    state === "/havenly/amenitiesRequests"
                      ? AmenitiesIconBlue
                      : AmenitiesIcon
                  }
                />
              }
            >
              <Menu.Item
                style={{ padding: "10px" }}
                key="/havenly/amenitiesListing"
                onClick={() =>
                  handleMenuParent(
                    "/havenly/amenitiesListing",
                    "amenitiesListing"
                  )
                }
                icon={<img alt="" src={""} />}
              >
                Amenities Listing
              </Menu.Item>
              <Menu.Item
                style={{ padding: "10px" }}
                key="/havenly/amenitiesRequests"
                onClick={() =>
                  handleMenuParent(
                    "/havenly/amenitiesRequests",
                    "amenitiesRequests"
                  )
                }
                icon={<img alt="" src={""} />}
              >
                Amenities Requests
                {getAmenityRequestCount > 0 && (
                  <span className="side-bar-noti-count">
                    {getAmenityRequestCount > 10 ? "10+" : getAmenityRequestCount}
                  </span>
                )}
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {arr?.includes("Maintenance") && (
            <Menu.SubMenu
              key="/havenly/maintenanceDashboard"
              onTitleClick={() =>
                handleMenuParent(
                  "/havenly/maintenanceDashboard",

                  "maintenanceDashboard"
                )
              }
              title="Maintenance"
              icon={
                <img
                  className="barIcons"
                  alt=""
                  src={
                    state === "/havenly/maintenanceDashboard" ||
                    state === "/havenly/maintenanceListing" ||
                    state === "/havenly/maintenanceRequests"
                      ? MaintenanceIconBlue
                      : MaintenanceIcon
                  }
                />
              }
            >
              <Menu.Item
                style={{ padding: "10px" }}
                key="/havenly/maintenanceListing"
                onClick={() =>
                  handleMenuParent(
                    "/havenly/maintenanceListing",

                    "maintenanceListing"
                  )
                }
                icon={<img alt="" src={""} />}
              >
                Maintenance Listing
              </Menu.Item>

              <Menu.Item
                style={{ padding: "10px" }}
                key="/havenly/maintenanceRequests"
                onClick={() =>
                  handleMenuParent(
                    "/havenly/maintenanceRequests",

                    "maintenanceRequests"
                  )
                }
                icon={<img alt="" src={""} />}
              >
                Maintenance Requests
                {getMaintenanceRequestCount > 0 && (
                  <span className="side-bar-noti-count">
                    {getMaintenanceRequestCount > 10
                      ? "10+"
                      : getMaintenanceRequestCount}
                  </span>
                )}
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {arr?.includes("Visitors") && (
            <Menu.SubMenu
              key="/havenly/visitorsDashboard"
              onTitleClick={() =>
                handleMenuParent(
                  "/havenly/requestsByTenant",
                  "requestsByTenant"
                )
              }
              title="Visitors"
              icon={
                <img
                  className="barIcons"
                  alt=""
                  src={
                    state === "/havenly/visitorsDashboard" ||
                    state === "/havenly/requestsByTenant" ||
                    state === "/havenly/requestsByAdmin"
                      ? VisitorsIconBlue
                      : VisitorsIcon
                  }
                />
              }
            >
              <Menu.Item
                style={{ padding: "10px" }}
                key="/havenly/requestsByTenant"
                onClick={() =>
                  handleMenuParent(
                    "/havenly/requestsByTenant",
                    "requestsByTenant"
                  )
                }
                icon={<img alt="" src={""} />}
              >
                Requests By Tenant
                {requestByTenantsCount > 0 && (
                  <span className="side-bar-noti-count">
                    {requestByTenantsCount > 10 ? "10+" : requestByTenantsCount}
                  </span>
                )}
              </Menu.Item>
              <Menu.Item
                style={{ padding: "10px" }}
                key="/havenly/requestsByAdmin"
                onClick={() =>
                  handleMenuParent(
                    "/havenly/requestsByAdmin",
                    "requestsByAdmin"
                  )
                }
                icon={<img alt="" src={""} />}
              >
                Requests By Admin
                {requestByAdminCount > 0 && (
                  <span className="side-bar-noti-count">
                    {requestByAdminCount > 10 ? "10+" : requestByAdminCount}
                  </span>
                )}
              </Menu.Item>
            </Menu.SubMenu>
          )}
        </Menu>
      )}
    </>
  );
};

export default SideMenu;
