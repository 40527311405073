import OneSignal from "react-onesignal";
import {
  notificationReceivedArray,
  todayNotificationsLabelAction,
} from "../store/actions/notifications.action";

let oneSignalId;
export async function runOneSignalOnLogin(data, dispatch, serviceObj) {
  if (data?.role !== "eb-super-admin") {
    let id = data?.organization_id?.toString();
    OneSignal.init({
      appId: "b78ee420-3086-4743-9710-151ab2d559c5",
      autoRegister: true,
      allowLocalhostAsSecureOrigin: true,
      subdomainName: "staging.admin",
      notifyButton: {
        enable: false,
      },
    }).then(() => {
      OneSignal.setExternalUserId(id);
      checkIfSubscribed();
      OneSignal.sendTags(serviceObj);
      OneSignal.on("notificationDisplay", (event) => {
        getNotiLabel();
      });
    });

    function subscribe() {
      OneSignal.on("subscriptionChange", (isSubscribed) => {
        listenForNotification();
        OneSignal.getUserId().then((userId) => {
          oneSignalId = userId;
        });
      });
    }

    function listenForNotification() {
      OneSignal.on("notificationDisplay", (event) => {
        listenForNotification();
      });
    }

    function getUserID() {
      OneSignal.getUserId().then((userId) => {
        oneSignalId = userId;
      });
    }

    function checkIfSubscribed() {
      OneSignal.isPushNotificationsEnabled((isEnabled) => {
        if (isEnabled) {
          getUserID();
        } else {
          subscribe();
        }
      });
    }
    const getNotiLabel = async () => {
      const today = await dispatch(
        todayNotificationsLabelAction(0, 2, data?.organization_id)
      );
      if (today?.statusCode === 200) {
        if (today?.data?.notifications?.length === 0) {
          return;
        } else {
          const val = today?.data?.notifications?.map((item) => {
            return {
              type: item?.type,
              created_at: item?.created_at,
            };
          });
          dispatch(notificationReceivedArray(val));
        }
      }
    };
  }
}
export function runOneSignalOnLogout(serviceObj) {
  OneSignal.removeExternalUserId();
  OneSignal.deleteTags(serviceObj);
}
