import { Button, Form } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import UploadPhotos from "../Amenities/components/UploadPhotos";
import {
  getMaintenanceBanner,
  addMaintenanceBanner,
  deleteMaintenanceBanner,
} from "../../store/actions/maintenance.action";
import toastr from "toastr";
import UploadImage from "../Amenities/components/UploadImage";
const AddMaintenanceBanner = () => {
  const [bannerFileList, setBannerFileList] = useState([]);
  const [imageFileList, setImageFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [addingBanner, setAddingBanner] = useState(false);
  const [deleteBannerArr, setDeleteBannerArr] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let previousPage = location?.state?.location;
  const onBannerChange = ({ fileList: newFileList }) => {
    setBannerFileList(newFileList);
  };
  const getBanner = async () => {
    let bannerImage = await dispatch(
      getMaintenanceBanner({
        organization_id: location?.state?.organization_id,
      })
    );
    if (bannerImage.statusCode === 200) {
      let file = bannerImage?.data?.maintenance_banner;
      file.map(
        (curr) =>
          (curr[
            "url"
          ] = `${process.env.REACT_APP_S3_BUCKET_URL}/${curr?.images}`)
      );
      setBannerFileList(file);
    }
  };
  const AddBanner = async () => {
    if (
      bannerFileList?.slice(-1)[0].originFileObj ||
      bannerFileList?.length <= 4
    ) {
      setAddingBanner(true);
      let fd = new FormData();
      fd.append("organization_id", location?.state?.organization_id);
      fd.append("delete_images", JSON.stringify(deleteBannerArr));
      for (let i = 0; i < bannerFileList.length; i++) {
        fd.append(
          "images",
          bannerFileList[i]?.originFileObj
            ? bannerFileList[i].originFileObj
            : bannerFileList[i]
        );
      }
      fd.append("status", 1);
      let postBannerImage = await dispatch(addMaintenanceBanner(fd));
      if (postBannerImage?.statusCode === 200) {
        setAddingBanner(false);
        let file = postBannerImage?.data?.maintenance_banner;
        setImagePreview(file);
        toastr.success("Banner Updated");
        getBanner();
      }
    } else {
      if (bannerFileList.length === 4) {
        toastr.error("Four Banners already added. Can't add more");
      } else {
        toastr.error("Add New Banner first");
      }
      setAddingBanner(false);
    }
  };
  const deleteBanner = async (item) => {
    setDeleteBannerArr([...deleteBannerArr, item?.id]);
  };
  const setImagePreview = async (file) => {
    file?.map((current) => {
      (current?.images !== undefined || current?.images !== null) &&
        setBannerFileList([
          ...bannerFileList,
          {
            uid: current?.id,
            id: current?.id,
            name: "image.png",
            images: current?.images,
            status: "done",
            url: `${process.env.REACT_APP_S3_BUCKET_URL}/${current?.images}`,
          },
        ]);
    });
  };
  useEffect(() => {
    getBanner();
  }, []);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url?.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  return (
    <div className="bannerImage">
      <div className="banner-main">
        <div className="bannerUpload">
          <h6 className="titleHeading"> Add Banners </h6>
          <span className="sub-heading">Add your Photo</span>
          <div className="mtt-10">
            <UploadPhotos
              defaultFileList={bannerFileList}
              listType="picture-card"
              fileList={bannerFileList}
              onChange={onBannerChange}
              beforeUpload={() => false}
              deleteImage={deleteBanner}
              onPreview={onPreview}
              previewVisible={previewVisible}
              previewImage={previewImage}
              previewTitle={previewTitle}
              setPreviewVisible={setPreviewVisible}
            />
          </div>
        </div>
        <Form.Item>
          <div style={{ float: "right", marginTop: "30px" }}>
            <Button className="servicesBackBtn " onClick={() => navigate(-1)}>
              Back
            </Button>
            <Button
              type="primary"
              className="servicesMainBtn"
              htmlType="submit"
              disabled={addingBanner}
              onClick={AddBanner}
              style={{ margin: "0 8px" }}
            >
              {addingBanner ? "Loading..." : "Save"}
            </Button>
          </div>
        </Form.Item>
      </div>
      <div className="banner-main">
        <UploadImage
          organization_id={location?.state?.organization_id}
          maintenanceImage={true}
          previousPage={previousPage}
          setImagePreview={setImagePreview}
          onPreview={onPreview}
          imageFileList={imageFileList}
          setImageFileList={setImageFileList}
        />
      </div>
    </div>
  );
};
export default AddMaintenanceBanner;
