import React from 'react'
import PhoneInput from "react-phone-number-input";

const Phone = ({value, onChange, disabled}) => {
    return(
        <>
        <PhoneInput
                limitMaxLength
                disabled={disabled}
                maxLength="15"
                initialValueFormat="international"
                placeholder="Enter phone number"
                className={disabled ? "inputfield wd-phone" : "inputfield"}
                defaultCountry="PK"
                international
                countryCallingCodeEditable={false}
                value={value}
                onChange={onChange}
              />
        </>
    )
}

export default Phone;