import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Select, Dropdown, Menu, Card, Table, Spin } from "antd";
import actionBtn from "../../assets/images/icons/action.png";
import buildingImg from "../../assets/images/icons/Group 101817@3x.png";
import Edit from "../../assets/images/icons/super admin - 1x PNG/edit.png";
import EmptyList from "../../components/EmptyList";
import {
  findOrganizationAction,
  statusOrganizationAction,
} from "../../store/actions/organization.action";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Pagination";
const { Option } = Select;

const OrganizationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [organizationData, setOrganizationData] = useState({});
  const [showData, setShowData] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [companyId, setCompanyId] = useState();
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [loading, setLoading] = useState(true);
  const companies = useSelector((state) => state);
  const per_page = companies?.organization?.organization?.pagination?.per_page;
  const page_count = companies?.organization?.organization?.pagination?.page;
  useEffect(() => {
    const getOrganization = async () => {
      const db = await dispatch(findOrganizationAction(page_count ? page_count : pageCount, per_page ? per_page : pageSize));
      setPageSize(per_page);
      setPageCount(page_count);
      setOrganizationData(db?.data);
      setLoading(false);
    };
    getOrganization();
  }, []);

  useEffect(() => {
    const TotalOrganization = () => {
      setTotalPageItems(
        companies?.organization?.organization?.pagination?.count
      );
    };
    TotalOrganization();
  }, [companies]);

  const handlerRowChange = (record, rowIndex) => {
    navigate(`/havenly/organization`, { state: { id: record?.id } });
  };

  const handlePageChange = async (page, pageSize) => {
    setLoading(true);
    setOrganizationData({});
    setPageSize(pageSize);
    setPageCount(page);
    const verifyData = await dispatch(findOrganizationAction(page, pageSize));
    setOrganizationData(verifyData?.data);
    setLoading(false);
  };

  const handleChange = (value, id) => {
    dispatch(
      statusOrganizationAction(
        value,
        id,
        companies?.organization?.organization?.organization
      )
    );
  };

  const menu = (
    <Menu
      className="editDropdown"
      items={[
        {
          label: (
            <Link
              to={`/havenly/editOrganization/${companyId}`}
              onClick={(e) => e.stopPropagation()}
              className='listing-edit-dropdown'
            >
              {" "}
              <img alt="" src={Edit} className='edit-action'/>{" "}
              <span style={{ marginLeft: "5px" }}>Edit</span>
            </Link>
          ),
          key: "0",
        },
      ]}
    />
  );

  const columns = [
    {
      title: "",
      responsive: ["xs", "sm", "md"],
      align: "right",
      render: (props) => (
        <img alt="" src={buildingImg} className="org-col-icon" />
      ),
    },
    {
      title: "Building",
      dataIndex: "name",
      responsive: ["xs", "sm", "md"],
      align: "left",
      render: (props) => (
        <span className="form-text-font-design">{props ? props?.slice(0, 20) + (props?.length > 20 ? "..." : "") : "N/A"}</span>
      ),
    },
    {
      title: "Building ID",
      dataIndex: "id",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">
          <span>{props ? props : "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Countries",
      dataIndex: "country",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">
          <span>{props ? props : "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div>
          <div
            className={
              props === "1"
                ? "activeSelector form-text-font-design"
                : "InactiveSelector form-text-font-design"
            }
          >
            {" "}
            <Select
              onClick={(e) => e.stopPropagation()}
              defaultValue={props}
              onChange={(val) => handleChange(val, row?.id)}
              style={{ width: 120 }}
            >
              <Option value="1">Active</Option>
              <Option value="0">Inactive</Option>
            </Select>{" "}
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      responsive: ["xs", "sm", "md"],
      render: (text, record, index) => (
        <div>
          <Dropdown
            onClick={() => setCompanyId(record?.id)}
            overlay={menu}
            trigger={["click"]}
          >
            <a onClick={(e) => e.stopPropagation()} className="action-menu">
              <img alt="" src={actionBtn} className="actions-col-btn" />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];
  return (
    <div>
      {showData ? (
        <div style={{ marginLeft: "10px" }}>
          {/* <p className="list-heading">Buildings List</p> */}
          <Card className="card">
            {loading ? (
              <div className="no-data-found-card">
                <Spin />
              </div>
            ) : (
              <>
                <div className="tabled-data">
                  <div className="requests-card-heading mb-3">
                    <h5 className="table-header">Buildings List</h5>
                  </div>
                  <Table
                    scroll={{
                      x: 700,
                    }}
                    pagination={false}
                    bordered={false}
                    dataSource={organizationData?.organizations}
                    columns={columns}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => handlerRowChange(record, rowIndex),
                      };
                    }}
                  />
                </div>
                <Pagination
                  total={totalPageItems}
                  defaultPageSize={pageSize}
                  defaultCurrent={pageCount}
                  onChange={handlePageChange}
                />
              </>
            )}
          </Card>
        </div>
      ) : (
        <div className="mtt-10-per">
          {" "}
          <EmptyList
            btnTxt={"Add New Building / Organization"}
            text={
              <div>
                There is no added Organization / Building <br /> Let’s add one{" "}
              </div>
            }
            clickLink={() => navigate("/havenly/newOrganization")}
          />
        </div>
      )}
    </div>
  );
};
export default OrganizationList;
