import { Card, Avatar, Pagination, Spin } from "antd";
import recentNotification from "../assets/images/icons/notification/today_notification.png";
import Bellboy from "../assets/images/core-images/img_Bellboy@3x.png";
import Parcel from "../assets/images/core-images/img_Parcels@3x.png";
import Visitor from "../assets/images/core-images/img_Visitors@3x.png";
import Amenity from "../assets/images/core-images/img_Amenities@3x.png";
import Maintenance from "../assets/images/core-images/img_Maintenance@3x.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { allNotificationsAction } from "../store/actions/notifications.action";
import moment from "moment";
import { format } from "date-fns";

const AllNotifications = () => {
  const [allNotifications, setAllNotifications] = useState([]);
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [currentPageItems, setCurrentPageItems] = useState(10);
  const [loading, setLoading] = useState(true);
  const notification = useSelector(
    (state) => state?.notifications?.notifications?.data
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const getNotifications = async () => {
    const res = await dispatch(
      allNotificationsAction(0, 10, location.state.orgId)
    );
    if (res.statusCode === 200) {
      setAllNotifications(res.data.notifications);
      setTotalPageItems(res?.data.pagination?.count);
      setCurrentPageItems(res?.data.pagination?.per_page);
      setLoading(false);
    }
  };
  const handleViewMore = async (page) => {
    let perPageItems = page + 10;
    const res = await dispatch(
      allNotificationsAction(0, perPageItems, location.state.orgId)
    );
    if (res.statusCode === 200) {
      setAllNotifications(res.data.notifications);
      setTotalPageItems(res?.data.pagination?.count);
      setCurrentPageItems(res?.data.pagination?.per_page);
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);

  const handleRedirection = (val) => {
    if (val === "bellboy_booking") {
      navigate("/havenly/bellboyRequests", {
        state: { id: location.state.orgId },
      });
    } else if (val === "parcel_pickup") {
      navigate("/havenly/parcelRequests", {
        state: { id: location.state.orgId },
      });
    } else if (val === "amenity_booking") {
      navigate("/havenly/amenitiesRequests", {
        state: { id: location.state.orgId },
      });
    } else if (val === "maintenance_booking") {
      navigate("/havenly/maintenanceRequests", {
        state: { id: location.state.orgId },
      });
    } else if (val === "visitor_booking") {
      navigate("/havenly/requestsByTenant", {
        state: { id: location.state.orgId },
      });
    } else if (
      val === "unexpected_guest_request_accept" ||
      val === "unexpected_guest_request_reject"
    ) {
      navigate("/havenly/requestsByAdmin", {
        state: { id: location.state.orgId },
      });
    } else {
      return;
    }
  };
  return (
    <div>
      <Card className="card">
        {loading ? (
          <div className="no-data-found">
            <Spin />
          </div>
        ) : allNotifications?.length > 0 ? (
          <>
            <div className="notification-header">
              <div style={{ alignSelf: "center" }}>
                <p className="table-header">
                  {allNotifications?.some(
                    (today) =>
                      format(new Date(today.created_at), "yyyy-MM-dd") ===
                      format(new Date(), "yyyy-MM-dd")
                  )
                    ? "Today"
                    : "Earlier"}
                </p>
              </div>
              <div style={{ alignSelf: "center" }}></div>
            </div>
            {allNotifications.map((item, i) => (
              <>
                {format(new Date(item.created_at), "yyyy-MM-dd") ===
                  format(new Date(), "yyyy-MM-dd") && (
                  <div key={i} onClick={() => handleRedirection(item.type)}>
                    <p className="borderTop"></p>
                    <div className="d-flex justify-content-between p-1">
                      <div className="d-flex mt-3">
                        <Avatar
                          size={50}
                          icon={
                            <img
                              src={
                                item?.type === "bellboy_booking"
                                  ? Bellboy
                                  : item?.type === "amenity_booking"
                                  ? Amenity
                                  : item?.type === "maintenance_booking"
                                  ? Maintenance
                                  : item?.type === "parcel_pickup"
                                  ? Parcel
                                  : item?.type === "visitor_booking" ||
                                    item?.type ===
                                      "unexpected_guest_request_accept" ||
                                    item?.type ===
                                      "unexpected_guest_request_reject"
                                  ? Visitor
                                  : `${process.env.REACT_APP_S3_BUCKET_URL}/${item?.file_name}`
                              }
                            />
                          }
                        />
                        <div
                          style={{ alignSelf: "center", margin: "0px 20px" }}
                        >
                          <h5 className="notification-heading mb-0">
                            {item.title}
                          </h5>
                          <p className="mb-0">{item.message}</p>
                          <p className="notification-time mt-2 mb-1">
                            {moment(item.created_at)
                              .subtract(0, "days")
                              .calendar()}
                          </p>
                        </div>
                      </div>
                      {item.is_read === "0" && (
                        <div className="pt-1">
                          <img src={recentNotification} />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            ))}
            {allNotifications?.some(
              (today) =>
                format(new Date(today.created_at), "yyyy-MM-dd") ===
                format(new Date(), "yyyy-MM-dd")
            ) && (
              <>
                <p className="borderTop"></p>
                <div className="notification-header">
                  <p className="notification-day-heading mb-0">Earlier</p>
                </div>
              </>
            )}
            {allNotifications.map(
              (item, i) =>
                format(new Date(item.created_at), "yyyy-MM-dd") !==
                  format(new Date(), "yyyy-MM-dd") && (
                  <div key={i} onClick={() => handleRedirection(item.type)}>
                    <p className="borderTop"></p>
                    <div className="d-flex justify-content-between p-1">
                      <div className="d-flex mt-3">
                        <Avatar
                          size={50}
                          icon={
                            <img
                              src={
                                item?.type === "bellboy_booking"
                                  ? Bellboy
                                  : item?.type === "amenity_booking"
                                  ? Amenity
                                  : item?.type === "maintenance_booking"
                                  ? Maintenance
                                  : item?.type === "parcel_pickup"
                                  ? Parcel
                                  : item?.type === "visitor_booking" ||
                                    item?.type ===
                                      "unexpected_guest_request_accept" ||
                                    item?.type ===
                                      "unexpected_guest_request_reject"
                                  ? Visitor
                                  : `${process.env.REACT_APP_S3_BUCKET_URL}/${item?.file_name}`
                              }
                            />
                          }
                        />
                        <div
                          style={{ alignSelf: "center", margin: "0px 20px" }}
                        >
                          <h5 className="notification-heading mb-0">
                            {item.title}
                          </h5>
                          <p className="mb-1">{item.message}</p>
                          <p className="notification-time mt-2 mb-1">
                            {moment(item.created_at)
                              .subtract(0, "days")
                              .calendar()}
                          </p>
                        </div>
                      </div>
                      {item.is_read === "0" && (
                        <div className="pt-1">
                          <img src={recentNotification} />
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}
            <div className="text-center">
              {currentPageItems < notification?.pagination?.count && (
                <a
                  className="notification-viewMore"
                  onClick={() => handleViewMore(currentPageItems)}
                >
                  View More
                </a>
              )}
            </div>
          </>
        ) : (
          <div className="no-data-found-card">
            <Spin />
            <p className="grey-defTxt">No Notifications Found!</p>
          </div>
        )}
      </Card>
    </div>
  );
};

export default AllNotifications;
