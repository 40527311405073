import { useNavigate } from "react-router-dom";
import { Avatar, Dropdown, Badge } from "antd";
import ringBell from "../assets/images/icons/super admin - 1x PNG/notification_grey@3x.png";
import ringBellBlue from "../assets/images/icons/Notification_dot_g@3x.png";
import recentNotification from "../assets/images/icons/notification/today_notification.png";
import Bellboy from "../assets/images/core-images/img_Bellboy@3x.png";
import Parcel from "../assets/images/core-images/img_Parcels@3x.png";
import Visitor from "../assets/images/core-images/img_Visitors@3x.png";
import Amenity from "../assets/images/core-images/img_Amenities@3x.png";
import Maintenance from "../assets/images/core-images/img_Maintenance@3x.png";
import moment from "moment";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllNotificationsCountAction } from "../store/actions/notifications.action";

const NotificationsDropdown = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notiCount, setNotiCount] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const Store = useSelector((state) => state);
  const notificationArr = useSelector(
    (state) => state?.notifications?.notificationsReceivedType
  );

  const handleVisibleChange = (val) => {
    if (val) {
      getCount();
    } else {
      props?.getTodayNotification();
    }
    setIsOpen(!val);
  };
  const handleRedirection = (val) => {
    if (val === "bellboy_booking") {
      navigate("/havenly/bellboyRequests", {
        state: { id: props?.organization_id },
      });
    } else if (val === "parcel_booking") {
      navigate("/havenly/parcelRequests", {
        state: { id: props?.organization_id },
      });
    } else if (val === "amenity_booking") {
      navigate("/havenly/amenitiesRequests", {
        state: { id: props?.organization_id },
      });
    } else if (val === "maintenance_booking") {
      navigate("/havenly/maintenanceRequests", {
        state: { id: props?.organization_id },
      });
    } else if (val === "visitor_booking") {
      navigate("/havenly/requestsByTenant", {
        state: { id: props?.organization_id },
      });
    } else if (
      val === "unexpected_guest_request_accept" ||
      val === "unexpected_guest_request_reject"
    ) {
      navigate("/havenly/requestsByAdmin", {
        state: { id: props?.organization_id },
      });
    } else {
     return;
    }
  };

  useEffect(() => {
    if (notificationArr?.length > 0) {
      getCount();
    }
  }, [notificationArr]);

  const getCount = async () => {
    const res = await dispatch(
      getAllNotificationsCountAction(Store?.user?.currentUser?.organization_id)
    );
    setNotiCount(res?.data?.notifications_count);
  };

  useEffect(() => {
    if (Store?.user?.currentUser?.role !== "eb-super-admin") getCount();
  }, []);
  const notificationMenu = (
    <div className="notification-dropdown-menu-item p-2">
      <div className="notification-header">
        <div>
          <p className="notification-day-heading mb-0">Notifications</p>
        </div>
        <div></div>
      </div>
      <p className="borderTop"></p>
      <h5 className="notification-day-heading">Today</h5>
      {props?.todayNotifications?.some(
        (today) =>
          format(new Date(today.created_at), "yyyy-MM-dd") ===
          format(new Date(), "yyyy-MM-dd")
      ) ? (
        props?.todayNotifications?.map(
          (item) =>
            format(new Date(item.created_at), "yyyy-MM-dd") ===
              format(new Date(), "yyyy-MM-dd") && (
              <div key={item.id}>
                <p className="borderTop"></p>
                <div
                  className="notification-main p-2"
                  onClick={() => handleRedirection(item.type)}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <Avatar
                      src={
                        item?.type === "bellboy_booking"
                          ? Bellboy
                          : item?.type === "amenity_booking"
                          ? Amenity
                          : item?.type === "maintenance_booking"
                          ? Maintenance
                          : item?.type === "parcel_pickup"
                          ? Parcel
                          : item?.type === "visitor_booking" ||
                            item?.type === "unexpected_guest_request_accept" ||
                            item?.type === "unexpected_guest_request_reject"
                          ? Visitor
                          : `${process.env.REACT_APP_S3_BUCKET_URL}/${item?.file_name}`
                      }
                    />
                    <div className="px-3">
                      <h5 className="notification-heading mb-0">
                        {item.title}
                      </h5>
                      <p className="mb-0">{item.message}</p>
                      <p className="notification-time mt-1 mb-1">
                        {moment(item.created_at).subtract(0, "days").calendar()}
                      </p>
                    </div>
                  </div>
                  <div className="pt-1">
                    <img
                      src={item?.is_read === "0" ? recentNotification : ""}
                    />
                  </div>
                </div>
              </div>
            )
        )
      ) : (
        <p className="px-2"> No Notifications for today</p>
      )}

      <p className="borderTop"></p>
      <h5 className="notification-day-heading">Earlier</h5>
      {props?.earlierNotifications?.some(
        (today) =>
          format(new Date(today.created_at), "yyyy-MM-dd") !==
          format(new Date(), "yyyy-MM-dd")
      ) ? (
        props?.earlierNotifications?.map(
          (item) =>
            format(new Date(item.created_at), "yyyy-MM-dd") !==
              format(new Date(), "yyyy-MM-dd") && (
              <div key={item.id}>
                <p className="borderTop"></p>
                <div
                  className="notification-main p-2"
                  onClick={() => handleRedirection(item.type)}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <Avatar
                      src={
                        item?.type === "bellboy_booking"
                          ? Bellboy
                          : item?.type === "amenity_booking"
                          ? Amenity
                          : item?.type === "maintenance_booking"
                          ? Maintenance
                          : item?.type === "parcel_pickup"
                          ? Parcel
                          : item?.type === "visitor_booking" ||
                            item?.type === "unexpected_guest_request_accept" ||
                            item?.type === "unexpected_guest_request_reject"
                          ? Visitor
                          : `${process.env.REACT_APP_S3_BUCKET_URL}/${item?.file_name}`
                      }
                    />
                    <div className="px-3">
                      <h5 className="notification-heading mb-0">
                        {item.title}
                      </h5>
                      <p className="mb-0">{item.message}</p>
                      <p className="notification-time mt-1 mb-1">
                        {moment(item.created_at).subtract(0, "days").calendar()}
                      </p>
                    </div>
                  </div>
                  <div className="pt-1">
                    <img
                      src={item?.is_read === "0" ? recentNotification : ""}
                    />
                  </div>
                </div>
              </div>
            )
        )
      ) : (
        <p className="px-2"> No Notifications Earlier</p>
      )}

      <p className="borderTop"></p>
      <div className="p-2">
        <a
          className="notification-viewMore"
          onClick={() =>
            navigate(`/havenly/viewNotifications`, {
              state: { orgId: props?.organization_id },
            })
          }
        >
          See All Notifications
        </a>
      </div>
    </div>
  );
  return (
    <Dropdown
      overlay={notificationMenu}
      trigger={"click"}
      style={{ borderRadius: "10px" }}
      onVisibleChange={() => handleVisibleChange(isOpen)}
    >
      <li>
        <img
          alt=""
          src={notiCount > 0 ? ringBellBlue : ringBell}
          className={
            notiCount > 0
              ? "nav-links-icons mb-1 bell-blue-dot"
              : "nav-links-icons headerIcons"
          }
        />
      </li>
    </Dropdown>
  );
};

export default NotificationsDropdown;
