import {
  GET_ORGANIZATION,
  GET_ORGANIZATION_ERROR,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_ERROR,
  STATUS_ORGANIZATION,
  STATUS_ORGANIZATION_ERROR,
  SERVICES_ORGANIZATION,
  SERVICES_ORGANIZATION_ERROR,
  UPDATE_ORGANIZATION,
  CREATE_ORG_USER,
  CREATE_ORG_USER_ERROR,
  GET_ORGANIZATION_MEMBERS,
  GET_ORGANIZATION_MEMBERS_ERROR,
  STATUS_ORGANIZATION_MEMBER,
  STATUS_ORGANIZATION_MEMBER_ERROR,
  UPDATE_ORGANIZATION_MEMBER,
  GET_ALL_ORGANIZATION_MEMBERS,
  GET_ALL_ORGANIZATION_MEMBERS_ERROR,
} from "../types/organization.types";
import axios from "axios";
import { responseApi } from "../../helpers/helper";

export const addOrganizationAction = (data) => async (dispatch) => {
  let {
    name,
    email,
    password,
    confirm_password,
    country,
    country_code,
    services,
    created_by,
    phone,
    image,
    arabic,
  } = data;
  let fd = new FormData();
  fd.append("name", name);
  fd.append("name_ar", arabic);
  fd.append("email", email);
  fd.append("password", password);
  fd.append("confirm_password", confirm_password);
  fd.append("phone", phone);
  fd.append("country", country);
  fd.append("image", image[0].originFileObj);
  fd.append("services", JSON.stringify(services));
  fd.append("created_by", created_by);
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/eb-admin/organization/create`,
      fd,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    // if (res?.data?.data?.req_id) {
    //   const data = await responseApi(res?.data?.data?.req_id);
      dispatch({
        type: ADD_ORGANIZATION_SUCCESS,
        payload: res?.data,
      });
      return res?.data;
    // }
  } catch (error) {
    dispatch({
      type: ADD_ORGANIZATION_ERROR,
      payload: error,
    });
    return error;
  }
};

export const findOrganizationAction =
  (current, peritem) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/eb-admin/organization/list?page=${current}&per_page=${peritem}&sort_by=created_at&sort_order=DESC`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const organizationData = {
        organization: res?.data?.data?.organizations,
        pagination: res?.data?.data?.pagination,
      };
      dispatch({
        type: GET_ORGANIZATION,
        payload: organizationData,
      });
      return res?.data;
    } catch (error) {
      dispatch({
        type: GET_ORGANIZATION_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const statusOrganizationAction =
  (status, organization_id, companies) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/eb-admin/organization/change-status`,
        { organization_id, status },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );

      const state = await getState();
      const duplicateOrganizationData = [...companies];
      const index = await duplicateOrganizationData.findIndex(
        ({ id }) => id === organization_id
      );
      duplicateOrganizationData[index].status = status;
      const updateOrganizationData = {
        organization: duplicateOrganizationData,
        pagination: state?.organization?.organization?.pagination,
      };

        dispatch({
          type: UPDATE_ORGANIZATION,
          payload: updateOrganizationData,
        });
        dispatch({
          type: STATUS_ORGANIZATION,
          payload: res?.data,
        });
      
    } catch (error) {
      dispatch({
        type: STATUS_ORGANIZATION_ERROR,
        payload: error,
      });
    }
  };

export const getAllOrganizationServicesAction = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/eb-admin/organization/services`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    dispatch({
      type: SERVICES_ORGANIZATION,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: SERVICES_ORGANIZATION_ERROR,
      payload: error,
    });
    return error;
  }
};

export const getOrganizationServicesAction =
  (organization_id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/services`,
        { organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );

      return res.data;
    } catch (error) {
      return error;
    }
  };

export const createOrganizationUserAction = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/create-org-user`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
      dispatch({
        type: CREATE_ORG_USER,
        payload: res?.data,
      });
      return res?.data;
  } catch (error) {
    dispatch({
      type: CREATE_ORG_USER_ERROR,
      payload: error,
    });
    return error;
  }
};

export const getMemberOfOrganizationAction =
  (current, peritem, id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/organization-members?page=${current}&per_page=${peritem}&sort_by=created_at&sort_order=DESC`,
        { organization_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const organizationMembers = {
        members: res?.data?.data?.members,
        pagination: res?.data?.data?.pagination,
      };
      dispatch({
        type: GET_ORGANIZATION_MEMBERS,
        payload: organizationMembers,
      });
      return res?.data;
    } catch (error) {
      dispatch({
        type: GET_ORGANIZATION_MEMBERS_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const getAllMemberOfOrganizationAction = (id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/organization-members2`,
      { organization_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    dispatch({
      type: GET_ALL_ORGANIZATION_MEMBERS,
      payload: res?.data?.data?.members,
    });
    return res;
  } catch (error) {
    dispatch({
      type: GET_ALL_ORGANIZATION_MEMBERS_ERROR,
      payload: error,
    });
    return error;
  }
};

export const searchOrganizationMember =
  (organization_id, searchedVal, current, perItem) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/search-members?page=${current}&per_page=${perItem}&sort_by=created_at&sort_order=ASC`,
        { organization_id, search: searchedVal },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const organizationMembers = {
        members: res?.data?.data?.members,
        pagination: res?.data?.data?.pagination,
      };
      dispatch({
        type: GET_ORGANIZATION_MEMBERS,
        payload: organizationMembers,
      });
      return res?.data;
    } catch (error) {
      dispatch({
        type: GET_ORGANIZATION_MEMBERS_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const statusOrganizationMemberAction =
  (active, member_id, organization_id, members) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/change-member-status`,
        { active, member_id, organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const state = await getState();
      const duplicateOrganizationMemberData = [...members];
      const index = await duplicateOrganizationMemberData.findIndex(
        ({ id }) => id === member_id
      );
      duplicateOrganizationMemberData[index].active = active;
      const updateOrganizationMemberData = {
        members: duplicateOrganizationMemberData,
        pagination: state?.organization?.organizationMembers?.pagination,
      };


        dispatch({
          type: UPDATE_ORGANIZATION_MEMBER,
          payload: updateOrganizationMemberData,
        });
        dispatch({
          type: STATUS_ORGANIZATION_MEMBER,
          payload: res?.data,
        });
    } catch (error) {
      dispatch({
        type: STATUS_ORGANIZATION_MEMBER_ERROR,
        payload: error,
      });
    }
  };

export const getSingleOrganization = (organization_id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/admin/view-organization`,
      { organization_id },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const editOrganization = (values) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/eb-admin/organization/update`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getOrganizationBanner = (organization_id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/fetch-org-banner`,
      { organization_id },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const deleteOrganizationBanner =
  (organization_id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/delete-banner/${organization_id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
        return res?.data;
    } catch (error) {
      return error;
    }
  };

export const uploadOrganizationBanner = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/add-banner`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const updateOrganizationUser = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/update-org-user`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
      return res?.data;
  } catch (error) {
    return error;
  }
};
