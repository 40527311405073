import { Button, Card, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { getOrganizationServicesAction } from "../../store/actions/organization.action";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addParcelImage,
  getParcelImage,
} from "../../store/actions/parcel.action";
import toastr from "toastr";

const AddParcelImage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const orgId = location?.state?.organization_id;
  const [fileList, setFileList] = useState([]);
  const [serviceId, setServiceId] = useState();

  const AddImage = async () => {
    if (fileList[0]?.originFileObj) {
      let fd = new FormData();
      fd.append("organization_id", orgId);
      fd.append("service_id", serviceId);
      fd.append("image", fileList[0]?.originFileObj);
      let addImage = await dispatch(addParcelImage(fd));
      if (addImage.statusCode === 200) {
        const file = addImage.data.organization_service;
        file?.file_name !== null &&
          setFileList([
            {
              uid: file?.id,
              id: file?.id,
              name: "image.png",
              images: file?.file_name,
              status: "done",
              url: `${process.env.REACT_APP_S3_BUCKET_URL}/${file?.file_name}`,
            },
          ]);
        toastr.success("New Image Added");
      } else {
        toastr.error(addImage?.response?.data?.message);
      }
    }
  };

  const onImageChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const deleteImage = async () => {
    let fd = new FormData();
    fd.append("organization_id", orgId);
    fd.append("service_id", serviceId && serviceId);
    fd.append("image", null);
    let deleteImage = await dispatch(addParcelImage(fd));
    if (deleteImage.statusCode === 200) {
      toastr.success("Image Deleted Successfully");
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadButton = (
    <>
      <div style={{ color: "#1D4599" }}>+</div>
    </>
  );

  const getImage = async () => {
    let parcelImage = await dispatch(
      getParcelImage({
        organization_id: orgId,
        service_id: serviceId,
      })
    );
    if (parcelImage.statusCode === 200) {
      const file = await parcelImage?.data?.organization_service;
      file?.file_name !== null &&
        setFileList([
          {
            uid: file?.id,
            id: file?.id,
            name: "image.png",
            images: file?.file_name,
            status: "done",
            url: `${process.env.REACT_APP_S3_BUCKET_URL}/${file?.file_name}`,
          },
        ]);
    } else {
      toastr.error(parcelImage?.response?.data?.message);
    }
  };

  const fetchServices = async () => {
    const res = await dispatch(getOrganizationServicesAction(orgId));
    setServiceId(
      res.data.services?.filter((item) => item?.name === "Parcels")[0]
        ?.service_id
    );
    serviceId !== undefined && getImage();
  };
  useEffect(() => {
    fetchServices();
  }, [serviceId]);

  return (
    <>
      <div className="add-service-image">
        <Card className="card" style={{ width: "70%" }}>
          <p className="list-heading mb-1">Add Image</p>
          <span className="sub-heading">Add your Photo</span>
          <div className="mtt-10">
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onChange={onImageChange}
              onRemove={() => deleteImage()}
              onPreview={onPreview}
            >
              {fileList?.length < 1 && uploadButton}
            </Upload>
          </div>
          <p className="img-warn mtt-10">Files Supported: PNG, JPEG</p>
          <p className="img-warn mtt-10">Image Size (200x200)</p>
          <div className="mtt-20 align-right">
            <Button
              className="servicesBackBtn mx-2"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="servicesMainBtn"
              htmlType="submit"
              onClick={() => AddImage()}
            >
              Save
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddParcelImage;
