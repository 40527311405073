import React, { useEffect } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import CalenderIcon from "../assets/images/icons/calendar_ic@3x.png";
import CrossIcon from "../assets/images/icons/crossIcon.png";

const FilterByDate = ({ onSearch, searchDate, setSearchDate, getAllData }) => {
  const handleDate = (date) => {
    if (date) {
      setSearchDate(date);
      onSearch();
    }
  };
  useEffect(() => {
    handleDate(searchDate);
  }, [searchDate]);

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div className="filter-datePicker ">
        <div
          className="d-flex justify-content-around my-2"
          onClick={props.onClick}
        >
          <img
            alt=""
            src={CalenderIcon}
            className="site-form-item-icon date-filter input-icon-field-twenty"
          />

          <label
            className="px-1 align-self-center"
            onClick={props.onClick}
            ref={ref}
          >
            {props.value || props.placeholder}
          </label>
          <img
            src={CrossIcon}
            className="date-cross-btn"
            onClick={() => {
              setSearchDate("");
              getAllData();
            }}
          />
        </div>
      </div>
    );
  });
  return (
    <div className="filterByDate">
      <DatePicker
        selected={searchDate}
        onChange={(date) => handleDate(date)}
        placeholderText="Filter By Date"
        customInput={<CustomInput />}
      />
    </div>
  );
};
export default FilterByDate;
