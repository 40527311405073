import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { findEmailAction } from "../../../store/actions/user.action";
import emailLogo from "../../../assets/images/icons/message.png";

import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/icons/HH-logo.png"

const formRef = React.createRef();

const key = "updatable";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailVal, setEmailVal] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const stateValues = useSelector((state) => state);

  const onSubmit = async (values) => {
    const findAccount = await dispatch(findEmailAction(values));
    if (findAccount.statusCode === 200) {
      message.loading({
        content: "Sending...",
        key,
      });
      setTimeout(() => {
        message.success({
          content: "Password Re-set link has been sent to your Email.",
          key,
          duration: 4,
        });
        navigate("/");  
        localStorage.setItem("UserEmail", emailVal);
      }, 1500);

    } else {
      setEmailError(true)
    }
  };

  return (
    <div className="main">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 centered">
            <div className="text-center mtt-10-per">
              <img alt="" src={Logo} className="logo-default" />
            </div>
            <div className="container-section ">
              <h1 className="def-sm-Title mbb-20"> Forgot Password </h1>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                ref={formRef}
                autoComplete="no"
                onFinish={onSubmit}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      message: "Invalid email address",
                      validator: (_, value) => {
                        if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                          setDisableBtn(false);
                          setEmailError(false);
                          return Promise.resolve();
                        } else {
                          setEmailError(false);
                          return Promise.reject("Please input your E-mail!");
                        }
                      },
                    },
                  ]}
                >
                  <Input
                  prefix={
                    <img
                      alt=""
                      src={emailLogo}
                      className="site-form-item-icon"
                    />
                  }
                    placeholder="Enter Your Email"
                    className={"inputfield"}
                    onChange={(e) => setEmailVal(e.target.value)}
                    autoComplete="off"
                  />
                </Form.Item>
                <p className="ant-form-item-explain-error mtt-min-20">
                  {stateValues?.user?.error?.message ===
                  "Request failed with status code 404" && emailError &&
                     "Email not found !"
                  }
                </p>
                <Form.Item>
                  <div className="align-right">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="requestLinkBtn mt-2"
                      disabled={disableBtn === true}
                    >
                      Request a Reset Link
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
