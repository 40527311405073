import React from "react";
import { Avatar, Button, Drawer, Dropdown, Space } from "antd";
import Logo from "../assets/images/icons/HH-logo.png";
import sms from "../assets/images/icons/super admin - 1x PNG/message_grey@3x.png";
import Dummy_Profile from "../assets/images/core-images/dummy-img.png";
import Setting from "../assets/images/icons/settings@3x.png";
import LogOut from "../assets/images/icons/logout@3x.png";
import { runOneSignalOnLogout } from "../components/OneSignal";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  earlierNotificationsAction,
  todayNotificationsAction,
} from "../store/actions/notifications.action";
import crossIcon from '../assets/images/icons/super admin - 1x PNG/cross-3x.png'
import NotificationsDropdown from "./NotificationsDropdown";
import { logoutAction } from "../store/actions/user.action";
import toastr from "toastr";
import SideMenu from "./SideMenu";

const SideDrawer = ({
  visible,
  userData,
  openKeys,
  onOpenChange,
  state,
  handleMenuParent,
  arr,
  collapsed,
  setCollapsed,
  organization_id,
}) => {
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const services = useSelector((state) => state?.user?.services);
  const notificationCount = useSelector((state) => state?.notifications);
  const requestByTenants = useSelector((state) => state.visitors.requestsByTenant);
  const requestByAdmin = useSelector((state) => state.visitors.requestsByAdmin);
  const getBellboyRequestList = useSelector((state) => state?.bellboy?.bellboyRequests);

  const parcelRequestCount = notificationCount?.unReadNotificationCountOfParcel;
  const amenitiesRequestCount = notificationCount?.unReadNotificationCountOfAmenities;
  const maintenanceRequestCount = notificationCount?.unReadNotificationCountOfMaintenance;
  const requestByTenantsCount = requestByTenants?.unread_notifications_count;
  const requestByAdminCount = requestByAdmin?.unread_notifications_count;
  const bellboyRequestsCount = getBellboyRequestList?.unread_notifications_count;
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState("");
  const [windowSize, setWindowSize] = useState();
  const [todayNotifications, setTodayNotifications] = useState([]);
  const [earlierNotifications, setEarlierNotifications] = useState([]);

  
  const final_service = services?.map((x) => x.service)
  const add_service = [...final_service, 'organization_id']

  const getNotification = async () => {
    if (currentUser?.organization_id) {
      const today = await dispatch(
        todayNotificationsAction(0, 2, currentUser?.organization_id)
      );
      if (today?.statusCode === 200) {
        setTodayNotifications(today?.data?.notifications);
      }
      const earlier = await dispatch(
        earlierNotificationsAction(0, 2, currentUser?.organization_id)
      );
      if (earlier?.statusCode === 200) {
        setEarlierNotifications(earlier?.data?.notifications);
      }
    }
  };
  const handleLogout = () => {
    const res = dispatch(logoutAction());
    if ((res.statusCode = 200)) {
      runOneSignalOnLogout(add_service);
      localStorage.clear();
      navigate("/");
      toastr.success("logged out successfully");
    } else {
      toastr.error("Error occurred while logging out");
    }
  };
  useEffect(() => {
    setUserRole(currentUser?.role);
    getNotification();
  }, [currentUser]);

  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);

    if (windowSize == 980) {
      setCollapsed(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);
  const orgOptions = [
    {
      icon: <Avatar src={Dummy_Profile} />,
      key: "1",
      label: (
        <>
          <p
            style={{
              fontSize: "13px",
              color: "#000000",
              fontWeight: "400",
              marginBottom: "-7px",
              fontFamily: "Poppins",
            }}
          >
            {userRole}
          </p>
          <p
            style={{
              fontSize: "9px",
              color: "#ABABAB",
              fontFamily: "Poppins",
            }}
          ></p>
        </>
      ),
    },
    {
      label: (
        <div
          style={{ height: "40px", marginLeft: "10px" }}
          className="d-flex  align-items-center"
          onClick={() => {
            navigate("/havenly/organizationSetting", {
              state: { id: organization_id },
            });
          }}
        >
          <img alt="" src={Setting} className="me-2" style={{height:'20px'}} />
          Organization Settings
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          style={{ height: "40px", marginLeft: "10px" }}
          className="d-flex  align-items-center"
          onClick={() => handleLogout()}
        >
          <img alt="" src={LogOut} className="me-2" style={{height:'20px'}}/>
          Logout
        </div>
      ),
      key: "2",
    },
  ];
  const Options = [
    {
      icon: <Avatar src={Dummy_Profile} />,
      key: "1",
      label: (
        <>
          <p
            style={{
              fontSize: "13px",
              color: "#000000",
              fontWeight: "400",
              marginBottom: "-7px",
              fontFamily: "Poppins",
            }}
          >
            {userRole}
          </p>
          <p
            style={{
              fontSize: "9px",
              color: "#ABABAB",
              fontFamily: "Poppins",
            }}
          >
            See your Profile
          </p>
        </>
      ),
    },
    {
      label: (
        <div
          style={{ height: "40px", marginLeft: "10px" }}
          className="d-flex  align-items-center"
          onClick={() => handleLogout()}
        >
          <img alt="" src={LogOut} className="me-2" style={{height:'20px'}}/>
          Logout
        </div>
      ),
      key: "2",
    },
  ];

  const menu = (
    <Menu
      className="profile-dropdown-menu-item"
      items={userRole === "org-super-admin" ? orgOptions : Options}
    />
  );
  return (
    <Drawer
      visible={visible}
      width={300}
      extra={
      <>
      {/* <div className="d-flex justify-content-center" style={{backgroundColor:'green'}}> */}
        <img src={crossIcon} style={{marginBottom:"-14px"}} className='crossIcon' onClick={() => setCollapsed(false)}/>
<div>
<ul className="nav-links drawer-header-ul ">
          <li>
            <img
              alt=""
              src={sms}
              className="nav-links-icons nav-links-icons-right mx-2 headerIcons"
            />
          </li>
          {userRole !== "eb-super-admin" && (
            <NotificationsDropdown
              organization_id={organization_id}
              todayNotifications={todayNotifications}
              earlierNotifications={earlierNotifications}
            />
          )}

          <Dropdown
            overlay={menu}
            trigger={"click"}
            placement="bottomRight"
            arrow
          >
            <li>
              <Avatar src={Dummy_Profile} />
            </li>
          </Dropdown>
        </ul>
</div>
</>
      }
    >
      <div className="text-center mb-4">
        {userData !== "eb-super-admin" ? (
          <img
            alt=""
            src={`${process.env.REACT_APP_S3_BUCKET_URL}/${currentUser?.organization_image}`}
            className={collapsed === true ? "sidebar-logo" : "dp-none"}
          />
        ) : (
          <img
            alt=""
            src={Logo}
            className={collapsed === true ? "sidebar-logo" : "dp-none"}
          />
        )}
      </div>
      <SideMenu
        userData={userData}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        state={state}
        handleMenuParent={handleMenuParent}
        arr={arr}
        bellboyRequestsCount={bellboyRequestsCount}
        parcelRequestCount={parcelRequestCount}
        amenitiesRequestCount={amenitiesRequestCount}
        maintenanceRequestCount={maintenanceRequestCount}
        requestByTenantsCount={requestByTenantsCount}
        requestByAdminCount={requestByAdminCount}
        />
    </Drawer>
  );
};

export default SideDrawer;
