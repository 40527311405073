import {
  GET_ORGANIZATION,
  GET_ORGANIZATION_ERROR,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_ERROR,
  STATUS_ORGANIZATION,
  STATUS_ORGANIZATION_ERROR,
  SERVICES_ORGANIZATION,
  SERVICES_ORGANIZATION_ERROR,
  UPDATE_ORGANIZATION,
  CREATE_ORG_USER,
  CREATE_ORG_USER_ERROR,
  GET_ORGANIZATION_MEMBERS,
  GET_ORGANIZATION_MEMBERS_ERROR,
  STATUS_ORGANIZATION_MEMBER,
  STATUS_ORGANIZATION_MEMBER_ERROR,
  UPDATE_ORGANIZATION_MEMBER,
  GET_ALL_ORGANIZATION_MEMBERS,
  GET_ALL_ORGANIZATION_MEMBERS_ERROR,
} from "../types/organization.types";

const initialState = {
  organization: [],
  loading: true,
  isData: false,
};

function organizationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
        isData: true,
        loading: false,
      };
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
        isData: true,
        loading: false,
      };
    case GET_ORGANIZATION_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_ORGANIZATION_MEMBERS:
      return {
        ...state,
        organizationMembers: action.payload,
        isData: true,
        loading: false,
      };
      case UPDATE_ORGANIZATION_MEMBER:
        return {
          ...state,
          organizationMembers: action.payload,
          isData: true,
          loading: false,
        };
    case GET_ORGANIZATION_MEMBERS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
      
    case GET_ALL_ORGANIZATION_MEMBERS:
      return {
        ...state,
        allOrganizationMembers: action.payload,
        isData: true,
        loading: false,
      };
      case GET_ALL_ORGANIZATION_MEMBERS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case ADD_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        isData: true,
      };
    }
    case ADD_ORGANIZATION_ERROR: {
      return {
        loading: false,
        error: action.payload,
      };
    }
    case STATUS_ORGANIZATION: {
      return {
        ...state,
        loading: false,
        isData: true,
      };
    }
    case STATUS_ORGANIZATION_ERROR: {
      return {
        loading: false,
        error: action.payload,
      };
    }
    case STATUS_ORGANIZATION_MEMBER: {
        return {
          ...state,
          loading: false,
          isData: true,
        };
      }
      case STATUS_ORGANIZATION_MEMBER_ERROR: {
        return {
          loading: false,
          error: action.payload,
        };
      }
    case SERVICES_ORGANIZATION:
      return {
        ...state,
        services: action.payload,
        isData: true,
        loading: false,
      };
    case SERVICES_ORGANIZATION_ERROR:
      return {
        loading: false,
        error: action.payload,
      };



    case CREATE_ORG_USER:
      return {
        ...state,
        members:action.payload,
        isData: true,
        loading: false,
      };
    case CREATE_ORG_USER_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
export default organizationReducer;
