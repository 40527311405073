import { combineReducers } from "redux";
import userReducer from "./user.reducer";
import organizationReducer from "./organization.reducer";
import tenantReducer from "./tenant.reducer";
import amenitiesReducer from "./amenities.reducer";
import bellboyReducer from "./bellboy.reducer";
import maintenanceReducer from "./maintenance.reducer";
import parcelReducer from "./parcel.reducer";
import visitorsReducer from "./visitors.reducer";
import notificationsReducer from "./notifications.reducer";

export default combineReducers({
  user: userReducer,
  organization: organizationReducer,
  tenant: tenantReducer,
  amenity: amenitiesReducer,
  bellboy: bellboyReducer,
  maintenance: maintenanceReducer,
  parcel: parcelReducer,
  visitors: visitorsReducer,
  notifications: notificationsReducer,
});
