import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch } from "react-redux";
import toastr from "toastr";
import {
  addBellboyGuideline,
  getBellboyGuideline,
} from "../../../store/actions/bellboy.action";
import crossIcon from "../../../assets/images/icons/super admin - 1x PNG/cross-3x.png";
const BellboyGuidelines = ({
  showBellboyModal,
  setShowBellboyModal,
  orgId,
}) => {
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const [description, setDescription] = useState("");
  const [arabicDescription, setArabicDescription] = useState("");
  const [form] = Form.useForm();

  const handleCancel = () => {
    setShowBellboyModal(false);
  };

  const onSubmit = async (values) => {
    setDisable(true);
    const obj = { ...values, organization_id: orgId };
    const res = await dispatch(addBellboyGuideline(obj));
    if (res.statusCode === 200) {
      setDisable(false);
      toastr.success("Successfully Added");
      handleCancel();
    } else if (Object.keys(res)?.length === 0) {
      toastr.success("Successfully Added");
      handleCancel();
    } else {
      setDisable(false);
      toastr.error(res?.response?.data?.message);
    }
  };

  const getGuidelines = async () => {
    const res = await dispatch(getBellboyGuideline(orgId));

    form.setFieldsValue({
      guidelines: res?.data?.guidelines[0]?.guidelines,
      guidelines_ar: res?.data?.guidelines[0]?.guidelines_ar,
    });
  };
  useEffect(() => {
    getGuidelines();
  }, []);
  return (
    <>
      <Modal
        centered
        footer={null}
        header={null}
        visible={showBellboyModal}
        onCancel={() => handleCancel()}
        width={850}
      >
        <Form onFinish={onSubmit} form={form} className=" guidelines-modal">
          <div className="d-flex justify-content-between">
            <p className="def-sm-Title">Add Bellboy Guidelines</p>
            <img
              src={crossIcon}
              className="crossIcon"
              alt="close-modal"
              onClick={() => handleCancel()}
            />
          </div>
          <p className="italic-sub-heading">Max Text limit is 100 Characters</p>
          <div className="mtt-20">
            <Form.Item
              name="guidelines"
              rules={[
                {
                  required: true,
                  message: "Please input guidelines!",
                },
              ]}
            >
              <TextArea
                autoSize={(false, { minRows: 4, maxRows: 6 })}
                showCount
                placeholder="Guidelines"
                maxLength={100}
                style={{
                  height: 100,
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              name="guidelines_ar"
              rules={[
                {
                  required: true,
                  message: "Please Input arabic guidelines!",
                },
              ]}
            >
              <TextArea
                autoSize={(false, { minRows: 4, maxRows: 6 })}
                showCount
                lang={"ar"}
                dir={"rtl"}
                placeholder={`القواعد الارشادية`}
                maxLength={100}
                style={{
                  height: 100,
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  setArabicDescription(e.target.value);
                }}
              />
            </Form.Item>
          </div>
          <div className="d-flex justify-content-end mtt-10">
            <Button
              className="servicesMainBtn"
              htmlType="submit"
              disabled={disable}
            >
              {disable ? "Loading..." : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default BellboyGuidelines;
