import CheckableTag from "antd/lib/tag/CheckableTag";
import React from "react";
import Timeslots from "./Timeslots";
import morningIcon from "../assets/images/icons/Slot_Icons/Morning_c@3x.png";
import morningWhiteIcon from "../assets/images/icons/Slot_Icons/Morning_w@3x.png";
import afternoonIcon from "../assets/images/icons/Slot_Icons/Afternoon_c@3x.png";
import afternoonWhiteIcon from "../assets/images/icons/Slot_Icons/Afternoon_w@3x.png";
import eveningIcon from "../assets/images/icons/Slot_Icons/Evening_c@3x.png";
import eveningWhiteIcon from "../assets/images/icons/Slot_Icons/Evening_w@3x.png";
import nightIcon from "../assets/images/icons/Slot_Icons/Night_c@3x.png";
import nightWhiteIcon from "../assets/images/icons/Slot_Icons/Night_w@3x.png";
import midNightIcon from "../assets/images/icons/Slot_Icons/Mid Night_c@3x.png";
import midNightWhiteIcon from "../assets/images/icons/Slot_Icons/Mid Night_w@3x.png";
import earlyMorningIcon from "../assets/images/icons/Slot_Icons/Early Morning_c@3x-1.png";
import earlyMorningWhiteIcon from "../assets/images/icons/Slot_Icons/Early Morning_c@3x.png";
import { AmPmFormat } from "../services/DateAndTime";

const RescheduleMain = ({
  timeSlots,
  shift,
  handleSlotChange,
  handleShiftChange,
  selectedShifts,
  selectedTags,
  serviceAvailability,
  requiredShift
}) => {
  const ShiftBtn = React.forwardRef((props, ref) => {
    return (
      <CheckableTag
        ref={ref}
        align="center"
        className="checkable-shift"
        color="blue"
        style={{
          background: `${
            selectedTags?.some((item) => item.shift === props.text) ||
            selectedShifts?.some((item) => item === props.text) ||
            requiredShift?.some((item) => item === props.text)
              ? "#0E730B"
              : "rgba(52, 199, 89, 0.25)"
          }`,
          color: `${
            selectedTags?.some((item) => item.shift === props.text) ||
            selectedShifts?.some((item) => item === props.text) ||
            requiredShift?.some((item) => item === props.text)
              ? "#ffffff"
              : "#000000"
          }`,
        }}
        key={props.text}
        defaultValue={props.text}
        checked={
          selectedTags?.some((item) => item.shift === props.text) ||
          selectedShifts?.some((item) => item === props.text)||
          requiredShift?.some((item) => item === props.text)
        }
        onChange={(checked) =>  !requiredShift && handleShiftChange(props.text, checked) }
      >
        <div
          className="d-flex"
          style={{ margin: "7px 0px", alignItems: "center" }}
        >
          <img
            src={
              selectedTags?.some((item) => item.shift === props.text) ||
              selectedShifts?.some((item) => item === props.text)||
              requiredShift?.some((item) => item === props.text)
                ? props.whiteIcon
                : props.icon
            }
            style={{
              padding: "0px 5px 0px 5px",
              width: "25px",
            }}
          />
          <p style={{ marginLeft: "5px" }} className="mb-0">
            {props.text}
          </p>
        </div>
      </CheckableTag>
    );
  });
  return (
    <div className="selectTime-main">
      <ShiftBtn
        text={"Morning"}
        icon={morningIcon}
        whiteIcon={morningWhiteIcon}
      />
      <Timeslots
        timeSlots={timeSlots}
        shift={"Morning"}
        handleSlotChange={handleSlotChange}
        AmPmFormat={AmPmFormat}
        selectedShifts={selectedShifts}
        selectedTags={selectedTags}
        serviceAvailability={serviceAvailability}
      />

      <ShiftBtn
        text={"Afternoon"}
        icon={afternoonIcon}
        whiteIcon={afternoonWhiteIcon}
      />
      <Timeslots
        timeSlots={timeSlots}
        shift={"Afternoon"}
        handleSlotChange={handleSlotChange}
        AmPmFormat={AmPmFormat}
        selectedShifts={selectedShifts}
        selectedTags={selectedTags}
        serviceAvailability={serviceAvailability}
      />
      <ShiftBtn
        text={"Evening"}
        icon={eveningIcon}
        whiteIcon={eveningWhiteIcon}
      />
      <Timeslots
        timeSlots={timeSlots}
        shift={"Evening"}
        handleSlotChange={handleSlotChange}
        AmPmFormat={AmPmFormat}
        selectedShifts={selectedShifts}
        selectedTags={selectedTags}
        serviceAvailability={serviceAvailability}
      />
      <ShiftBtn text={"Night"} icon={nightIcon} whiteIcon={nightWhiteIcon} />
      <Timeslots
        timeSlots={timeSlots}
        shift={"Night"}
        handleSlotChange={handleSlotChange}
        AmPmFormat={AmPmFormat}
        selectedShifts={selectedShifts}
        selectedTags={selectedTags}
        serviceAvailability={serviceAvailability}
      />
      <ShiftBtn
        text={"Mid Night"}
        icon={midNightIcon}
        whiteIcon={midNightWhiteIcon}
      />
      <Timeslots
        timeSlots={timeSlots}
        shift={"Mid Night"}
        handleSlotChange={handleSlotChange}
        AmPmFormat={AmPmFormat}
        selectedShifts={selectedShifts}
        selectedTags={selectedTags}
        serviceAvailability={serviceAvailability}
      />
      <ShiftBtn
        text={"Early Morning"}
        icon={earlyMorningIcon}
        whiteIcon={earlyMorningWhiteIcon}
      />
      <Timeslots
        timeSlots={timeSlots}
        shift={"Early Morning"}
        handleSlotChange={handleSlotChange}
        AmPmFormat={AmPmFormat}
        selectedShifts={selectedShifts}
        selectedTags={selectedTags}
        serviceAvailability={serviceAvailability}
      />
    </div>
  );
};

export default RescheduleMain;
