import React from 'react'

const UnAuthorized = () => {
    return(
        <div className="no-data-found">
        <p className="grey-defTxt">Unauthorized! You are not allowed to access.</p>
      </div>
    )
}

export default UnAuthorized;