import axios from "axios";
import toastr from "toastr";

export const HeaderHeading = (props) => {
  switch (props?.split("/")[2]) {
    case "createNewOrganizationMember":
      return "Add New Member";
    case "organizationMembersListing":
      return "Organization Members";
    case "organization-list":
      return "Buildings / Organizations";
    case "newOrganization":
      return "Add New Organization";
    case "organization":
      return "Buildings / Organizations";
    case "editOrganization":
      return "Edit Organization";
    case "editOrganizationMember":
      return "User Details";
    case "organizationSetting":
      return "Organization Settings";
    case "tenants":
      return "Tenants";
    case "addNewTenant":
      return "Tenants";
    case "editTenant":
      return "Tenants";
    case "amenitiesDashboard":
      return "Amenities";
    case "amenitiesListing":
      return "Amenities Listing";
    case "amenitiesRequests":
      return "Amenities Requests";
    case "rescheduleServices":
      return "Reschedule";
    case "editAminity":
      return "Update Amenity";
    case "addAmenities":
      return "Add New Amenity";
    case "addAmenityBanner":
      return "Add Amenity Images";
    case "bellboy":
      return "Bellboy Listing";
    case "addNewBellboy":
      return "Bellboy Listing";
    case "editBellboy":
      return "Update Bellboy";
    case "bellboyRequests":
      return "Bellboy Request";
    case "maintenanceListing":
      return "Maintenance Listing";
    case "maintenanceRequests":
      return "Maintenance Requests";
    case "maintenanceDashboard":
      return "Maintenance";
    case "addMaintenance":
      return "Add New Maintenance";
    case "addMaintenanceBanner":
      return "Add Maintenance Banner";
    case "editMaintenance":
      return "Update Maintenance";
    case "parcelRequests":
      return "Parcel Requests";
    case "addNewParcel":
      return "Add New Parcel";
    case "addParcelImage":
      return "Add Parcel Image";
    case "parcelRequestDetail":
      return "Parcels Requests";
    case "parcelsDashboard":
      return "Parcels";
    case "unAuthorized":
      return "Unauthorized:404";

    case "visitorsDashboard":
      return "Visitors";
    case "requestsByTenant":
      return "Visitors Requests By Tenant";
    case "tenantDetails":
      return "Visitors Requests By Tenant";
    case "requestsByAdmin":
      return "Visitors Requests By Admin";
    case "viewNotifications":
      return "Notifications";
    default:
      return props.slice(1);
  }
};

export const responseApi = async (val) => {
  let result = {};
  try {
    for (let i = 0; i < 10; i++) {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/async-response-api`,
        {
          UID: val,
        }
      );
      if (res?.data?.data?.cache_info?.response_available === true) {
        result = res?.data;
        i = 10;
        break;
      }
    }
    if (Object.keys(result)?.length === 0) {
      toastr.warning("Please wait...");
    }
    return result;
  } catch (error) {
    return error;
  }
};
