import {
  EARLIER_NOTIFICATIONS,
  EARLIER_NOTIFICATIONS_ERROR,
  NOTIFICATIONS,
  NOTIFICATIONS_ERROR,
  TODAY_NOTIFICATIONS,
  TODAY_NOTIFICATIONS_ERROR,
  UN_READ_NOTIFICATIONS_COUNT_AMENITIES,
  UN_READ_NOTIFICATIONS_COUNT_MAINTENANCE,
  UN_READ_NOTIFICATIONS_COUNT_PARCEL,
  NOTIFICATION_RECEIVED_ARRAY
} from "../types/notifications.types";

const initialState = {
  notifications: [],
  today: [],
  earlier: [],
  notificationsReceivedType: [],
  loading: true,
  isData: false,
};

function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
        isData: true,
        loading: false,
      };
    case NOTIFICATIONS_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    case TODAY_NOTIFICATIONS:
      return {
        ...state,
        today: action.payload,
        isData: true,
        loading: false,
      };
    case TODAY_NOTIFICATIONS_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    case EARLIER_NOTIFICATIONS:
      return {
        ...state,
        earlier: action.payload,
        isData: true,
        loading: false,
      };
    case EARLIER_NOTIFICATIONS_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
      case UN_READ_NOTIFICATIONS_COUNT_PARCEL:
        return{
          ...state,
          unReadNotificationCountOfParcel : action.payload,
          isData: true,
          loading: false,
        };
        case UN_READ_NOTIFICATIONS_COUNT_AMENITIES:
        return{
          ...state,
          unReadNotificationCountOfAmenities : action.payload,
          isData: true,
          loading: false,
        }
        case UN_READ_NOTIFICATIONS_COUNT_MAINTENANCE:
        return{
          ...state,
          unReadNotificationCountOfMaintenance : action.payload,
          isData: true,
          loading: false,
        }
        case NOTIFICATION_RECEIVED_ARRAY:
          return{
            ...state,
            notificationsReceivedType : action.payload,
            isData: true,
            loading: false,
          }
    default:
      return state;
  }
}
export default notificationsReducer;
