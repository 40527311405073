import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Select,
  Table,
  Dropdown,
  Menu,
  Typography,
  Popover,
  Spin,
  Badge,
} from "antd";
import butler from "../../assets/images/core-images/dummy-img.png";
import Delete from "../../assets/images/icons/delete_b@3x.png";
import actionBtn from "../../assets/images/icons/action.png";
import {
  deleteBellboyRequest,
  getAllBellboyRequests,
  searchBellboy,
  statusBellboyRequestAction,
} from "../../store/actions/bellboy.action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import toastr from "toastr";
import AlertModal from "../../components/AlertModal";
import Pagination from "../../components/Pagination";
import FilterByDate from "../../components/DateFilter";
import { TitleSearch } from "../../components/SearchFilter";
const { Paragraph } = Typography;

const { Option } = Select;
const BellboyRequest = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reset = location?.state?.reset;
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedBellboy, setSelectedBellboy] = useState();
  const [bellboy, setBellboy] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [searchDate, setSearchDate] = useState("");
  const [privilege, setPrivilege] = useState("");
  const getBellboyRequestList = useSelector(
    (state) => state?.bellboy?.bellboyRequests
  );
  const Services = useSelector((state) => state?.user?.services);
  const id = useSelector((state) => state?.user?.currentUser?.organization_id);
  const notificationArr = useSelector(
    (state) => state?.notifications?.notificationsReceivedType
  );
  const per_page = getBellboyRequestList?.pagination?.per_page;
  const page_count = getBellboyRequestList?.pagination?.page;

  useEffect(() => {
    if (Services) {
      for (let I in Services) {
        if (Services[I]?.service === "Bellboy") {
          setPrivilege(Services[I]?.privilege);
        }
      }
    }
  }, []);
  const handlePageChange = async (page, pageSize) => {
    setLoading(true);
    setPageCount(page);
    setPerPage(pageSize);
    setBellboy([]);
    const currData = await dispatch(getAllBellboyRequests(page, pageSize, id));
    setBellboy(currData?.bellboy_requests);
    setLoading(false);
  };

  const handleSearch = async (searchText) => {
    if (searchText || searchDate) {
      const dateFormat = "YYYY-MM-DD";
      let formatDate = moment(searchDate).format(dateFormat);
      const finalData = await dispatch(
        searchBellboy(
          id,
          searchText === undefined ? null : searchText,
          formatDate !== "Invalid date" ? formatDate : searchDate
        )
      );
      setBellboy(finalData?.bellboy_requests);
      setTotalPageItems(finalData?.pagination.count);
    } else {
      getBellboy();
    }
  };
  const handleChange = (status, request_id) => {
    dispatch(
      statusBellboyRequestAction(
        status,
        request_id,
        id,
        getBellboyRequestList?.bellboy_requests
      )
    );
  };

  const handleDeleteBellboy = (selected) => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setDisable(true);
    const res = await dispatch(deleteBellboyRequest(id, selectedBellboy?.id));
    if (res.statusCode === 200) {
      toastr.success("Bellboy request deleted!");
    } else {
      toastr.error(res?.response?.data?.message);
    }
    setIsModalVisible(false);
    setBellboy([]);
    getBellboy();
    setDisable(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (getBellboyRequestList?.pagination?.count !== undefined) {
      setTotalPageItems(getBellboyRequestList?.pagination?.count);
    }
  }, [getBellboyRequestList]);

  const getBellboy = async () => {
    if (reset === true) {
      const res = await dispatch(getAllBellboyRequests(1, 10, id));
      if (res) {
        setBellboy(res?.bellboy_requests);
        setLoading(false);
        navigate(location.pathname, { replace: true });
      } else {
        toastr.error(res?.response?.data?.message);
      }
    } else {
      const res = await dispatch(
        getAllBellboyRequests(
          page_count ? page_count : pageCount,
          per_page ? per_page : perPage,
          id
        )
      );
      if (res) {
        setPageCount(page_count);
        setPerPage(per_page);
        setBellboy(res?.bellboy_requests);
        setLoading(false);
      } else {
        toastr.error(res?.response?.data?.message);
      }
    }
  };
  useEffect(() => {
    getBellboy();
  }, []);

  useEffect(() => {
    if (notificationArr?.filter((item) => item?.type === "bellboy_booking")) {
      setBellboy([]);
      getBellboy();
    }
  }, [notificationArr]);

  const menu = (
    <Menu
      className="editDropdown"
      items={[
        {
          label: (
            <div
              onClick={() => handleDeleteBellboy(selectedBellboy)}
              className="listing-edit-dropdown"
            >
              <img
                alt=""
                src={Delete}
                style={{ height: "18px" }}
                className="me-2 mb-1"
              />
              Delete
            </div>
          ),
          key: "1",
        },
      ]}
    />
  );

  const columns = [
    {
      title: "",
      responsive: ["xs", "sm", "md"],
      align: "right",
      render: (props, row) => (
        <>
          {row?.is_read === "0" && <Badge status="processing" />}
          <Avatar
            alt=""
            src={
              row?.tenant_image
                ? `${process.env.REACT_APP_S3_BUCKET_URL}/${row?.tenant_image}`
                : butler
            }
            className={
              row?.is_read === "1" ? "butler-img margin-left-15" : "butler-img"
            }
          />
        </>
      ),
    },
    {
      title: "Tenants",
      dataIndex: "tenant_name",
      responsive: ["xs", "sm", "md"],
      align: "left",
      render: (props, row) => (
        <span className="form-text-font-design">
          {props
            ? props?.slice(0, 20) + (props?.length > 20 ? "..." : "")
            : "N/A"}
        </span>
      ),
    },
    {
      title: "Apart. No",
      dataIndex: "building_no",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Building No",
      dataIndex: "apartment_no",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div>
          <div
            className={
              props === "2"
                ? "activeSelector"
                : props === "1"
                ? "progressSelector"
                : "pendingSelector"
            }
          >
            <Select
              defaultValue={props}
              onChange={(val) => handleChange(val, row?.id)}
              style={{ width: 120 }}
              disabled={privilege === "read-write" ? false : true}
            >
              <Option value="0">Pending</Option>
              <Option value="1">In Progress</Option>
              <Option value="2">Completed</Option>
            </Select>{" "}
          </div>
        </div>
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "created_at",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div className="form-text-font-design">
          {props ? moment(props).format("YYYY-MM-DD LT") : "N/A"}
        </div>
      ),
    },
    {
      title: "Special Instructions",
      dataIndex: "special_instructions",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div className="specialInstructions">
          <Popover
            placement="bottom"
            content={props}
            title="Special Instructions"
            trigger="hover"
          >
            <div className="long-text">
              {props ? (
                <Paragraph
                  ellipsis={{
                    rows: 2,
                    expandable: false,
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {props}
                </Paragraph>
              ) : (
                "N/A"
              )}
            </div>
          </Popover>
        </div>
      ),
    },
    {
      title: "Action",
      responsive: ["xs", "sm", "md"],
      width: 100,
      render: (text, record, index) => (
        <div>
          <Dropdown
            onClick={() => setSelectedBellboy(record)}
            overlay={menu}
            trigger={["click"]}
            disabled={privilege === "read-write" ? false : true}
          >
            <a onClick={(e) => e.stopPropagation()} className="action-menu">
              <img alt="" src={actionBtn} className="actions-col-btn" />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="tabled-div">
        <Card className="card">
          {loading ? (
            <div className="no-data-found-card">
              <Spin />
            </div>
          ) : (
            <>
              <div className=" tabled-data">
                <div className="requests-card-heading mb-3">
                  <h5 className="table-header">Requests List</h5>
                  <div className="filterButtons">
                    <FilterByDate
                      onSearch={handleSearch}
                      setSearchDate={setSearchDate}
                      searchDate={searchDate}
                      getAllData={getBellboy}
                    />
                    <TitleSearch onSearch={handleSearch} className="mx-2" />
                  </div>
                </div>
                <Table
                  pagination={false}
                  bordered={false}
                  dataSource={bellboy}
                  scroll={{
                    x: 700,
                  }}
                  columns={columns}
                />
              </div>
              <Pagination
                total={totalPageItems}
                defaultPageSize={perPage}
                defaultCurrent={pageCount}
                onChange={handlePageChange}
              />
            </>
          )}
        </Card>
      </div>
      <AlertModal
        disable={disable}
        content="Are you sure you want to delete this Bellboy Request?"
        title={"Delete Bellboy Request"}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default BellboyRequest;
