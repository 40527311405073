import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../../assets/images/icons/HH-logo.png";
import emailLogo from "../../../../assets/images/icons/mail_generalinfo_grey@3x.png";
import passwordIcon from "../../../../assets/images/icons/password_generalinfo_grey@3x.png";
import { useDispatch, useSelector } from "react-redux";
import {
  loginAction,
  CheckEmailAction,
} from "../../../../store/actions/user.action";
import toastr from "toastr";
import { runOneSignalOnLogin } from "../../../../components/OneSignal";
import { useEffect } from "react";

const SigninUi = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateValues = useSelector((state) => state?.user || null);

  const [inputEmail, setInputEmail] = useState(true);
  const [nextState, setNextState] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [emailVal, setEmailVal] = useState("");
  const [PasswordVal, setPasswordVal] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceInfo, setDeviceInfo] = useState("");
  const [deviceToken, setDeviceToken] = useState("");

  useEffect(() => {
    let r = (Math.random() + 1).toString(36).substring(7);
    setDeviceToken(r);
    setDeviceInfo(r);
  }, []);

  const validateEmail = (value) => {
    setEmailVal(value);
  };

  const onEmailConfirm = async (value) => {
    const verifyEmail = await dispatch(CheckEmailAction(value));

    if (verifyEmail?.statusCode === 200) {
      setEmailError(false);
      setNextState(true);
      setInputEmail(false);
    } else if (verifyEmail?.response?.request.status === 404) {
      setEmailError(true);
      setNextState(false);
      toastr.error("Email not found!");
    }
  };

  const onEmailNext = async () => {
    await onEmailConfirm({ email: emailVal });
  };

  const validatePassword = (value) => {
    setPasswordVal(value);
    // setDeviceType(r);
    // setDeviceToken(r);
    if (PasswordVal !== "") {
      setNextState(true);
    }
  };

  

  const onSubmit = async (values) => {
    if(inputEmail === false){
    const obj = {
      email: values?.email,
      password: values?.password,
      device_info: deviceInfo,
      device_token: deviceToken,
      device_type: "web",
    };
    const login = await dispatch(loginAction(obj));
    if (login?.statusCode === 200) {
      setPassError(false);
      // runOneSignalOnLogin(login?.data, dispatch);
      localStorage.setItem("token", login?.data?.access_authorization);
      if (login?.data?.role?.role === "eb-super-admin") {
        navigate("/havenly/organization-list");
      } else if (login?.data?.services.some((item) => item.service === "User")) {
        navigate(`/havenly/organizationMembersListing`, {
          state: { id: login?.data?.user?.organization_id },
        });
      } else {
        if (login?.data?.services.some((item) => item.service === "Bellboy")) {
          navigate("/havenly/bellboyRequests", {
            state: { id: login?.data?.user?.organization_id },
          });
        } else if (
          login?.data?.services.some((item) => item.service === "Parcels")
        ) {
          navigate("/havenly/parcelsDashboard", {
            state: { id: login?.data?.user?.organization_id },
          });
        } else if (
          login?.data?.services.some((item) => item.service === "Amenities")
        ) {
          navigate("/havenly/amenitiesDashboard", {
            state: { id: login?.data?.user?.organization_id },
          });
        } else if (
          login?.data?.services.some((item) => item.service === "Maintenance")
        ) {
          navigate("/havenly/maintenanceDashboard", {
            state: { id: login?.data?.user?.organization_id },
          });
        } else if (
          login?.data?.services.some((item) => item.service === "Visitors")
        ) {
          navigate("/havenly/requestsByTenant", {
            state: { id: login?.data?.user?.organization_id },
          });
        } else {
          navigate("/");
        }
      }
    } else if (login?.response?.status === 401) {
      setPassError(true);
      toastr.error("Please enter your password!");
    }
  }
  };

  return (
    <div className="main">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 centered">
            <div className="text-center mtt-10-per">
              <img alt="Ebutler" src={Logo} className="logo-default" />
            </div>
            <div className="container-section ">
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  email: emailVal,
                  device_type: "web",
                  device_token: deviceToken,
                  device_info: deviceInfo,
                  remember: true,
                }}
                autoComplete="no"
                onFinish={onSubmit}
              >
                {inputEmail === true ? (
                  <div>
                    <h1 className="def-sm-Title mbb-20">
                      {" "}
                      Sign in to continue{" "}
                    </h1>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          message: "Invalid email address",
                          validator: (_, value) => {
                            if (
                              /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
                            ) {
                              setNextState(true);
                              setEmailError(false);
                              return Promise.resolve();
                            } else {
                              setEmailError(false);
                              return Promise.reject(
                                "Please input your E-mail!"
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Your Email"
                        prefix={
                          <img
                            alt=""
                            src={emailLogo}
                            className="site-form-item-icon input-icon-field"
                          />
                        }
                        className={"inputfield"}
                        onChange={(e) => validateEmail(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Item>
                    <p className="ant-form-item-explain-error mtt-min-20">
                      {stateValues?.error?.message ===
                        "Request failed with status code 404" &&
                        emailError === true &&
                        "Email not found !"}
                    </p>

                    <div className="align-right">
                      <Button
                        type="primary"
                        htmlType="text"
                        onClick={onEmailNext}
                        disabled={nextState === false}
                        className={"mainBtn mt-3"}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h1 className="def-sm-Title mbb-20">
                      {" "}
                      Sign in to continue{" "}
                    </h1>
                    <Form.Item style={{ display: "none" }} name="email">
                      <Input
                        placeholder="Email"
                        className={"inputfield"}
                        autoComplete="off"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      hasFeedback={false}
                    >
                      <Input.Password
                        className={"inputfield"}
                        prefix={
                          <img
                            alt=""
                            src={passwordIcon}
                            className="site-form-item-icon"
                            style={{ height: "17px" }}
                          />
                        }
                        placeholder="Enter Your Password"
                        onChange={(e) => validatePassword(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Item>
                    <Form.Item style={{ display: "none" }} name="device_type">
                      <Input
                        value={deviceType}
                        initialvalues={deviceType}
                        autoComplete="none"
                      />
                    </Form.Item>
                    <Form.Item style={{ display: "none" }} name="device_token">
                      <Input autoComplete="none" />
                    </Form.Item>
                    <Form.Item style={{ display: "none" }} name="device_info">
                      <Input autoComplete="none" />

                      <Input autoComplete="none" />
                    </Form.Item>
                    <p className="ant-form-item-explain-error mtt-min-20">
                      {passError && "Invalid credentials"}
                    </p>
                    <div
                      className="d-flex mt-3"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "baseline",
                      }}
                    >
                      <Link to="/forgetPassword" className="default-link">
                        {" "}
                        <b>Forgot password? </b>
                      </Link>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={nextState === false}
                          className="mainBtn mt-2"
                        >
                          Log In
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigninUi;
