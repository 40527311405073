const { GET_PARCEL_REQUESTS, GET_PARCEL_REQUESTS_ERROR, STATUS_PARCEL, STATUS_PARCEL_ERROR, UPDATE_PARCEL, CREATE_PARCEL, CREATE_PARCEL_ERROR, PARCEL_DASHBOARD, PARCEL_DASHBOARD_ERROR } = require("../types/parcel.types");


const initialState = {
    parcel: [],
    loading: true,
    isData: false,
};

function parcelReducer(state = initialState, action){
    switch(action.type){
        case GET_PARCEL_REQUESTS :
            return {
                ...state,
                parcel: action?.payload,
                isData: true,
                loading: false,
            }
        case GET_PARCEL_REQUESTS_ERROR :
            return {
          loading: false,
          error: action.payload,
            }
        case UPDATE_PARCEL :
            return {
                ...state,
                parcel : action?.payload,
                isData: true,
                loading: false,
            }    
        case STATUS_PARCEL :
           return {
                ...state,
                loading: false,
                isData: true,
            } 
        case STATUS_PARCEL_ERROR :
            return {
                loading: false,
                error: action.payload,
            }       
            case CREATE_PARCEL:
                return {
                  ...state,
                  loading: false,
                  isData: true,
                }
              case CREATE_PARCEL_ERROR:
                return {
                  loading: false,
                  error: action.payload,
                }    
                case PARCEL_DASHBOARD :
                    return{
                        ...state,
                       parcelDashboard : action?.payload,
                        isData: true,
                        loading: false,
                    }
                case PARCEL_DASHBOARD_ERROR :
                    return{
                        loading: false,
                        error: action.payload,
                    }
        default :
        return state;

    }
}

export default parcelReducer;