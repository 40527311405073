import { set } from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoInternetConnection from "./components/NoInternet";
import { runOneSignalOnLogin } from "./components/OneSignal";
import Routing from "./config/route";

function App() {
  const [state, setState] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.currentUser);
  const service = useSelector((state) => state?.user?.services);
  let obj = {};
  if (service !== undefined) {
    for (var i = 0; i < service?.length; i++) {
      obj[service[i]?.service] = 1;
    }
    obj["organization_id"] = user?.organization_id;
  }
  useEffect(() => {
    if (user && state === true) {
      runOneSignalOnLogin(user, dispatch, obj);
      setState(false);
    }
  }, [user]);
  return (
    <>
      <NoInternetConnection>
        <Routing />
      </NoInternetConnection>
    </>
  );
}

export default App;
