import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Dropdown,
  Menu,
  Table,
  Divider,
  Badge,
  Spin,
} from "antd";
import Pagination from "../../components/Pagination";
import { useNavigate, useLocation } from "react-router-dom";
import callIcon from "../../assets/images/icons/call@3x.png";
import ebutler from "../../assets/images/icons/butler.png";
import actionBtn from "../../assets/images/icons/action.png";
import { useDispatch, useSelector } from "react-redux";
import {
  RequestsByTenantAction,
  searchRequestsByTenantAction,
} from "../../store/actions/visitors.action";
import AlertModal from "../../components/AlertModal";
import FilterByDate from "../../components/DateFilter";
import { TitleSearch } from "../../components/SearchFilter";
import moment from "moment";
import { AmPmFormat } from "../../services/DateAndTime";

const RequestsByTenant = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [requestDetails, setRequestDetails] = useState();
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [content, setContent] = useState("");
  const [visitor, setVisitor] = useState([]);
  const [loading, setLoading] = useState(true);
  const reset = location?.state?.reset;
  const notificationArr = useSelector(
    (state) => state?.notifications?.notificationsReceivedType
  );
  const id = useSelector((state) => state?.user?.currentUser?.organization_id);
  const getVisitorRequestByTenants = useSelector(
    (state) => state?.visitors?.requestsByTenant
  );
  const per_page = getVisitorRequestByTenants?.pagination?.per_page;
  const page_count = getVisitorRequestByTenants?.pagination?.page;

  const getData = async () => {
    if (reset === true) {
      const res = await dispatch(RequestsByTenantAction(0, 10, id));
      setVisitor(res?.data?.visitors);
      setLoading(false);
      navigate(location.pathname, { replace: true });
    } else {
      const res = await dispatch(
        RequestsByTenantAction(
          page_count ? page_count : pageCount,
          per_page ? per_page : perPage,
          id
        )
      );
      setPageCount(page_count);
      setPerPage(per_page);
      setVisitor(res?.data?.visitors);
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (notificationArr?.filter((item) => item?.type === "visitor_booking")) {
      getData();
    }
  }, [notificationArr]);

  const getDetail = (phone, name) => {
    return (
      <>
        <p>
          Name: <span>{name}</span>
        </p>
        <p>
          Phone Number: <span>{phone}</span>
        </p>
      </>
    );
  };
  const handleSearch = async (searchText) => {
    if (searchText || searchDate) {
      const dateFormat = "YYYY-MM-DD";
      let formatDate = moment(searchDate).format(dateFormat);
      const res = await dispatch(
        searchRequestsByTenantAction(
          id,
          searchText,
          formatDate !== "Invalid date" ? formatDate : searchDate
        )
      );
      setVisitor(res?.data?.visitors);
    } else {
      getData();
    }
  };

  useEffect(() => {
    setTotalPageItems(getVisitorRequestByTenants?.pagination?.count);
  }, [getVisitorRequestByTenants]);

  const handlePageChange = async (page, pageSize) => {
    setLoading(true);
    setVisitor([]);
    setPageCount(page);
    setPerPage(pageSize);
    const res = await dispatch(RequestsByTenantAction(page, pageSize, id));
    setVisitor(res?.data?.visitors);
    setLoading(false);
  };
  const handlerRowChange = (record) => {
    navigate(`/havenly/tenantDetails`, { state: record });
  };

  const handleCallTenant = (e, requestDetails) => {
    e.stopPropagation();
    setPreviewVisible(true);
    setPreviewTitle("Tenant Detail");
    setContent(
      requestDetails.tenant_phone
        ? getDetail(requestDetails.tenant_phone, requestDetails.tenant_name)
        : "No Contact info"
    );
  };
  const handleCallVisitor = (e, requestDetails) => {
    e.stopPropagation();
    setPreviewVisible(true);
    setPreviewTitle("Visitor Detail");
    // setPreviewTitle(requestDetails.main_visitor_name.toUpperCase());
    setContent(
      requestDetails
        ? getDetail(
            requestDetails.main_visitor_phone,
            requestDetails.main_visitor_name
          )
        : "No Contact info"
    );
  };
  const handleCancel = () => setPreviewVisible(false);
  const handleOk = () => setPreviewVisible(false);
  const menu = (
    <Menu
      className="editDropdown rescheduleDropdown"
      items={[
        {
          label: (
            <div
              onClick={(e) => handleCallTenant(e, requestDetails)}
              className="listing-edit-dropdown d-flex"
            >
              <img
                alt=""
                src={callIcon}
                className="me-2 input-icon-field mt-1"
              />
              <span>Call Tenant</span>
            </div>
          ),
          key: "0",
        },
        {
          label: (
            <div
              onClick={(e) => handleCallVisitor(e, requestDetails)}
              className="listing-edit-dropdown"
            >
              <img
                alt=""
                src={callIcon}
                className="me-2 input-icon-field mb-1"
              />
              Call Visitor{" "}
            </div>
          ),
          key: "1",
        },
      ]}
    />
  );
  const columns = [
    {
      title: "",
      responsive: ["xs", "sm", "md"],
      align: "right",
      render: (props, row) => (
        <>
          {row?.is_read === "0" && <Badge status="processing" />}
          <Avatar
            alt="tenant image"
            src={
              row?.tenant_image
                ? `${process.env.REACT_APP_S3_BUCKET_URL}/${row?.tenant_image}`
                : ebutler
            }
            className={
              row?.is_read === "1" ? "butler-img margin-left-15" : "butler-img"
            }
          />
        </>
      ),
    },
    {
      title: "Tenants",
      dataIndex: "tenant_name",
      responsive: ["xs", "sm", "md"],
      align: "left",
      render: (props, row) => (
        <span className="form-text-font-design" style={{ marginLeft: "5px" }}>
          {props
            ? props?.slice(0, 20) + (props?.length > 20 ? "..." : "")
            : "N/A"}
        </span>
      ),
    },
    {
      title: "Visit Time",
      dataIndex: "visit_time",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">
          {props ? AmPmFormat(props) : "N/A"}
        </div>
      ),
    },
    {
      title: "Visit Date",
      dataIndex: "visit_date",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },

    {
      title: "Building No.",
      dataIndex: "building_no",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Apt. No.",
      dataIndex: "apartment_no",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Total Visitors",
      dataIndex: "number_of_visitors",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Visit Purpose",
      dataIndex: "purpose_of_visit",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">
          {props
            ? props?.slice(0, 15) + (props?.length > 15 ? "..." : "")
            : "N/A"}
        </div>
      ),
    },
    {
      title: "Action",
      responsive: ["xs", "sm", "md"],
      render: (record) => (
        <div>
          <Dropdown
            onClick={() => setRequestDetails(record)}
            overlay={menu}
            trigger={["click"]}
          >
            <a onClick={(e) => e.stopPropagation()} className="action-menu">
              <img alt="" src={actionBtn} className="actions-col-btn" />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="requestsSeen">
        <Card className="card">
          {loading ? (
            <div className="no-data-found">
              <Spin />
            </div>
          ) : (
            <>
              <div className="row">
                <div className="requests-card-heading mb-3">
                  <h5 className="table-header">Requests List</h5>
                  <div className="filterButtons">
                    <FilterByDate
                      onSearch={handleSearch}
                      setSearchDate={setSearchDate}
                      searchDate={searchDate}
                      getAllData={getData}
                    />
                    <TitleSearch onSearch={handleSearch} className="mx-2" />
                  </div>
                </div>
                <div className="col-lg-12 tabled-data">
                  <Table
                    pagination={false}
                    bordered={false}
                    dataSource={visitor}
                    columns={columns}
                    scroll={{
                      x: 700,
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => handlerRowChange(record, rowIndex),
                      };
                    }}
                  />
                </div>
              </div>

              <Pagination
                total={totalPageItems}
                defaultPageSize={perPage}
                defaultCurrent={pageCount}
                onChange={handlePageChange}
              />
            </>
          )}
          <AlertModal
            isModalVisible={previewVisible}
            title={previewTitle}
            footer={null}
            content={content}
            handleCancel={handleCancel}
            handleOk={handleOk}
          ></AlertModal>
        </Card>
      </div>
    </>
  );
};

export default RequestsByTenant;
