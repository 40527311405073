import { Card, Tag } from "antd";
import { useLocation } from "react-router-dom";
import {
  fullDayName,
  getMonthName,
  AmPmFormat,
} from "../../../services/DateAndTime";
const TenantDetails = () => {
  const location = useLocation();
  const tenant = location.state;
  return (
    <div className="tenantDetails">
      <Card className="card">
        <div className="mb-4">
          <h4 className="mainHeading">Visit Date & Time</h4>
          <p className="preferredText">Preferred Date</p>
        </div>
        <div className="dateAndTime my-4">
          <p
            className="mb-1 mt-1"
            style={{ fontSize: "16px", textTransform: "capitalize" }}
          >
            {fullDayName(tenant?.visit_date)}
          </p>
          <p style={{ fontSize: "26px", marginBottom: "0px" }}>
            <strong>{new Date(tenant?.visit_date)?.getDate()} </strong>
            <span>{getMonthName(tenant?.visit_date).slice(0, 3)}</span>
          </p>
        </div>
        <div className="am-and-pm-div">
          <h5 className="subHeadings mt-2">Time</h5>
          <div className="mt-2">
            <p>
              <span
                className={
                  tenant?.visit_time.slice(0, 2) < "12"
                    ? "activeShift"
                    : "inactiveShift"
                }
              >
                AM
              </span>{" "}
              <span
                className={
                  tenant?.visit_time.slice(0, 2) >= "12"
                    ? "activeShift"
                    : "inactiveShift"
                }
              >
                PM
              </span>
            </p>{" "}
          </div>
        </div>
        <div className="bookedTime mt-3 mb-3">
          <div className="py-3">{AmPmFormat(tenant?.visit_time)}</div>
        </div>
        <div className="mt-5">
          <h5 className="subHeadings mb-2">Number Of Visitors</h5>
          <p className="activeShift mt-4">{tenant?.number_of_visitors} </p>{" "}
        </div>
        <div className=" my-5">
          <h5 className="subHeadings my-4">Visitor Details</h5>
          <div>
            <tr className="detailsTableHeading">
              <td className="TableCols">Name</td>
              <td className="TableCols">Phone Number</td>
              <td className="TableCols">Car No Plate</td>
            </tr>
            <tr className="detailsTableCols">
              <td className="TableCols">{tenant?.main_visitor_name}</td>
              <td className="TableCols">{tenant?.main_visitor_phone}</td>
              <td className="TableCols">{tenant?.main_visitor_plate}</td>
            </tr>
          </div>
          <div className=" mtt-20">
            <h5 className="subHeadings">Purpose of the Visit</h5>
            <Tag className="purposeBtn mtt-20">{tenant.purpose_of_visit}</Tag>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default TenantDetails;
