import { Modal } from 'antd';
import React from 'react'

const AlertModal = ({disable, title, content, isModalVisible, handleOk, handleCancel}) => {
    return(
        <Modal okButtonProps={{disabled: disable}} className='logo' title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p className="site-form-item-icon">{content}</p>
      </Modal>
    )
}

export default AlertModal;