import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import AddBtn from "../../../assets/images/icons/responsive/add-btn.png";
import GuidelineBtn from "../../../assets/images/icons/responsive/Guidline.png";
import AddModuleImg from "../../../assets/images/icons/responsive/Add-Img.png";
import CsvImage from "../../../assets/images/icons/responsive/Csv-Img.png";
import plusIcon from "../../../assets/images/icons/plus_icon@3x.png"
import { CSVLink } from "react-csv";

const HeaderButtons = ({
  userService,
  bellboyService,
  amenitiesService,
  visitorsService,
  parcelsService,
  maintenanceService,
  location,
  tenants,
  organization_id,
  members,
  handleAddBellboyGuidelines,
  handleAddVisitor,
  handleAddVisitorGuideline,
  getUsers,
  getTenants

}) => {
  
  const member_csvLink = React.useRef(null)
  const tenants_csvLink = React.useRef(null)
  const navigate = useNavigate();

  const downloadMembersCsv = async () => {
    await getUsers()
    setTimeout(() => {
      member_csvLink.current.link.click()
    }, 0)
  }

  const downloadTenantsCsv = async () => {
    await getTenants()
    setTimeout(() => {
      tenants_csvLink.current.link.click()
    }, 0)
  }

  const headers = [
    { label: "Id", key: "id" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phone" },
    { label: "Gender", key: "gender" },
    { label: "Country", key: "country" },
    { label: "Status", key: "active" },
  ];

  const organizationHeader = [
    ...headers,
    {label: "Role", key: "role" }
  ]
  return (
    <>
      {location?.pathname?.split("/")[2] === "organization-list" ? (
        <>
          <Button
            onClick={() => navigate("/havenly/newOrganization")}
            type="primary"
            className="LgBtn build-btn large-btn"
          >
            <img src={plusIcon} className="plus-white-icon" />
            <p className="add-new-building-btn">
              Add New Building / Organization
            </p>
          </Button>
          <img
            src={AddBtn}
            onClick={() => navigate("/havenly/newOrganization")}
            className="small-btn LgBtn"
          />
        </>
      ) : location?.pathname?.split("/")[2] === "tenants" && userService === 'read-write'
       ? (
        <div className="space-between">
           <Button 
            type="primary" className="LgBtn build-btn large-btn csv-btn" onClick={() => downloadTenantsCsv()}>Export Data in CSV</Button>
          <CSVLink
          headers={headers}
            data={tenants}
            ref={tenants_csvLink}
      style={{display: 'none'}}
            filename={"tenant.csv"}
            target="_blank"
          >
            Export Data in CSV
          </CSVLink>
          <Button
          className="small-btn"
          style={{border:'none'}}
          onClick={() => downloadTenantsCsv()}
          >
            <img
              src={CsvImage}
              style={{ marginTop:'-5px' }}
              className=" LgBtn"
            />
          </Button>
          <Button
            onClick={() => navigate("/havenly/addNewTenant")}
            type="primary"
            className="LgBtn build-btn large-btn"
          >
          <img src={plusIcon} className="plus-white-icon" />
            <p className="add-new-building-btn">Add New Tenant</p>
          </Button>
          <img
            src={AddBtn}
            onClick={() => navigate("/havenly/addNewTenant")}
            className="small-btn LgBtn"
          />
        </div>
      ) : location?.pathname?.split("/")[2] === `amenitiesListing` && amenitiesService === 'read-write' ? (
        <div className="d-flex">
          <Button
            onClick={() =>
              navigate("/havenly/addAmenityBanner", {
                state: { location, organization_id: organization_id },
              })
            }
            type="primary"
            className="LgBtn build-btn large-btn csv-btn"
          >
            <p className="add-new-building-btn">Add Amenity Banner</p>
          </Button>
          <img
            src={AddModuleImg}
            onClick={() =>
              navigate("/havenly/addAmenityBanner", {
                state: { location, organization_id: organization_id },
              })
            }
            className="small-btn LgBtn mx-2"
          />
          <Button
            onClick={() => navigate("/havenly/addAmenities")}
            type="primary"
            className="LgBtn build-btn large-btn"
          >
          <img src={plusIcon} className="plus-white-icon" />
            <p className="add-new-building-btn">Add New Amenities</p>
          </Button>
          <img
            src={AddBtn}
            onClick={() => navigate("/havenly/addAmenities")}
            className="small-btn LgBtn"
          />
        </div>
      ) : location?.pathname?.split("/")[2] === "organizationMembersListing" && userService === 'read-write' ? (
        <div className="space-between">
    
          <>
          <Button 
            type="primary" className="LgBtn build-btn large-btn csv-btn" onClick={() => downloadMembersCsv()}>Export Data in CSV</Button>
          <CSVLink
          headers={organizationHeader}
            data={members}
            ref={member_csvLink}
      style={{display: 'none'}}
            filename={"members.csv"}
            target="_blank"
          >
            Export Data in CSV
          </CSVLink>
          <Button
          className="small-btn"
          style={{border:'none'}}
          onClick={() => downloadMembersCsv()}
          >
            <img
              src={CsvImage}
              style={{ marginTop:'-5px' }}
              className=" LgBtn"
            />
          </Button>
          </>
          <Button
            onClick={() =>
              navigate("/havenly/createNewOrganizationMember", {
                state: { id: organization_id },
              })
            }
            type="primary"
            className="LgBtn build-btn large-btn"
          >
          <img src={plusIcon} className="plus-white-icon" />
            <p className="add-new-building-btn">Add Organization Member</p>
          </Button>
          <img
            src={AddBtn}
            className="small-btn LgBtn"
            onClick={() =>
              navigate("/havenly/createNewOrganizationMember", {
                state: { id: organization_id },
              })
            }
          />
        </div>
      ) : location?.pathname?.split("/")[2] === "bellboy" && bellboyService === 'read-write' ? (
        <div className="d-flex">
          <Button
            onClick={() => handleAddBellboyGuidelines()}
            type="primary"
            className="LgBtn build-btn large-btn csv-btn"
          >
            <p className="add-new-building-btn">Add Bellboy Guidelines</p>
          </Button>
          <img
            src={GuidelineBtn}
            onClick={() => handleAddBellboyGuidelines()}
            className="small-btn LgBtn mx-2"
          />

          <Button
            onClick={() => navigate("/havenly/addNewBellboy")}
            type="primary"
            className="LgBtn build-btn large-btn"
          >
          <img src={plusIcon} className="plus-white-icon" />
            <p className="add-new-building-btn">Add New Bellboy</p>
          </Button>
          <img
            src={AddBtn}
            onClick={() => navigate("/havenly/addNewBellboy")}
            className="small-btn LgBtn"
          />
        </div>
      ) : location?.pathname?.split("/")[2] === `maintenanceListing` && maintenanceService === 'read-write' ? (
        <div className="d-flex">
          <Button
            onClick={() =>
              navigate("/havenly/addMaintenanceBanner", {
                state: { location, organization_id: organization_id },
              })
            }
            type="primary"
            className="LgBtn build-btn large-btn csv-btn"
          >
            <p className="add-new-building-btn">Add Maintenance Banner</p>
          </Button>
          <img
            src={AddModuleImg}
            onClick={() =>
              navigate("/havenly/addMaintenanceBanner", {
                state: { location, organization_id: organization_id },
              })
            }
            className="small-btn LgBtn mx-2"
          />

          <Button
            onClick={() => navigate("/havenly/addMaintenance")}
            type="primary"
            className="LgBtn build-btn large-btn"
          >
          <img src={plusIcon} className="plus-white-icon" />
            <p className="add-new-building-btn">Add New Maintenance</p>
          </Button>
          <img
            src={AddBtn}
            onClick={() => navigate("/havenly/addMaintenance")}
            className="small-btn LgBtn"
          />
        </div>
      ) : location?.pathname?.split("/")[2] === "parcelRequests" && parcelsService === "read-write" ? (
        <div className="d-flex">
          <Button
            onClick={() =>
              navigate("/havenly/addNewParcel", {
                state: { id: organization_id },
              })
            }
            type="primary"
            className="LgBtn build-btn large-btn"
          >
          <img src={plusIcon} className="plus-white-icon" />
            <p className="add-new-building-btn">Add New Parcel</p>
          </Button>
          <img
            src={AddBtn}
            onClick={() =>
              navigate("/havenly/addNewParcel", {
                state: { id: organization_id },
              })
            }
            className="small-btn LgBtn"
          />
        </div>
      ) : location?.pathname?.split("/")[2] === "requestsByAdmin" && visitorsService === "read-write" ? (
        <>
          <Button
            onClick={() => handleAddVisitor()}
            type="primary"
            className="LgBtn build-btn large-btn"
          >
          <img src={plusIcon} className="plus-white-icon" />
            <p className="add-new-building-btn">Add New Visitor</p>
          </Button>
          <img
            src={AddBtn}
            onClick={() => handleAddVisitor()}
            className="small-btn LgBtn"
          />
        </>
      ) : location?.pathname?.split("/")[2] === "requestsByTenant" && visitorsService === "read-write" ? (
        <>
          <Button
            onClick={() => handleAddVisitorGuideline()}
            type="primary"
            className="LgBtn build-btn large-btn csv-btn"
          >
            <p className="add-new-building-btn">Add Visitors Guidelines</p>
          </Button>
          <img
            src={GuidelineBtn}
            onClick={() => handleAddVisitorGuideline()}
            className="small-btn LgBtn mx-2"
          />
        </>
      ) : null}
    </>
  );
};

export default HeaderButtons;
