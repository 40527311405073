import React from "react";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import moment from "moment";
const ApexCharts = (props) => {
  function getAllDaysInMonth(year, month) {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push(dayjs(new Date(date)).format("YYYY-MM-DD"));
      date.setDate(date.getDate() + 1);
    }
    return [dates];
  }

  const now = new Date();

  const [dates] = getAllDaysInMonth(now.getFullYear(), now.getMonth());

  const date = moment().format("YYYY-MM");
  const currentMonthDays = moment(date).daysInMonth();
  const MonthArray = [];
  for (let i = 1; i <= currentMonthDays; i++) {
    MonthArray.push(i);
  }

  let total = [];
  let completed = [];
  for (let date in dates) {
    const curr = props?.chartData?.find((item) => item?.date == dates[date]);
    if (curr !== undefined) {
      total?.push(curr?.total - curr?.completed);
      completed?.push(parseInt(curr?.completed));
    } else {
      total?.push(0);
      completed?.push(0);
    }
  }

  const series = [
    {
      name: props?.labelTwo,
      data: total,
    },
    {
      name: props?.labelOne,
      data: completed,
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    colors: ["#34C759", "#0E730B"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        columnWidth: 50,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed();
        },
      },
    },
    xaxis: {
      categories: MonthArray,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      offsetY: 0,
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <div id="chart" className="chart">
      <h6 className="completedVsTotal">{props?.heading}</h6>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default ApexCharts;
