export const AMENITY_DASHBOARD = "AMENITY_DASHBOARD";
export const AMENITY_DASHBOARD_ERROR = "AMENITY_DASHBOARD_ERROR";
export const ADD_AMENITIES = "ADD_AMENITIES";
export const ADD_AMENITIES_ERROR = "ADD_AMENITIES_ERROR";
export const ADD_AMENITIES_SUCCESS = "ADD_AMENITIES_SUCCESS";
export const AMENITIES_REQUESTS = "AMENITIES_REQUESTS";
export const AMENITIES_REQUESTS_ERROR = "AMENITIES_REQUESTS_ERROR";
export const AMENITIES_LISTING = "AMENITIES_LISTING";
export const AMENITIES_LISTING_ERROR = "AMENITIES_LISTING_ERROR";
export const UPDATE_AMENITY_MEMBER = "UPDATE_AMENITY_MEMBER";
export const STATUS_AMENITY_MEMBER = "STATUS_AMENITY_MEMBER";
export const STATUS_AMENITY_MEMBER_ERROR = "STATUS_AMENITY_MEMBER_ERROR";
export const TIME_SLOTS = "TIME_SLOTS";
export const TIME_SLOTS_ERROR = "TIME_SLOTS_ERROR";
