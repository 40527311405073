import CheckableTag from "antd/lib/tag/CheckableTag";

const Timeslots = ({
  timeSlots,
  shift,
  handleSlotChange,
  AmPmFormat,
  selectedShifts,
  selectedTags,
  serviceAvailability,
}) => {
  return (
    <>
      {timeSlots?.map(
        (tag) =>
          tag.shift === shift && (
            <>
              <CheckableTag
                align="center"
                key={tag.id}
                className="checkable-tag"
                color="blue"
                style={{
                  background: `${
                    serviceAvailability
                      ? selectedShifts?.some((item) => item === tag.shift) &&
                        serviceAvailability?.some((item) => item.id === tag.id)
                        ? selectedTags?.some(
                            (item) =>
                              item.id === tag.id && item.shift === tag.shift
                          )
                          ? "#0E730B"
                          : "rgba(52, 199, 89, 0.25)"
                        : "#BCBCBC"
                      : selectedShifts?.some((item) => item === tag.shift)
                      ? selectedTags?.some(
                          (item) =>
                            item.id === tag.id && item.shift === tag.shift
                        )
                        ? "#0E730B"
                        : "rgba(52, 199, 89, 0.25)"
                      : "#BCBCBC"
                  }`,
                  color: `${
                    selectedShifts?.some((item) => item === tag.shift)
                      ? selectedTags?.some(
                          (item) =>
                            item.id === tag.id && item.shift === tag.shift
                        )
                        ? "#ffffff"
                        : "#000000"
                      : "#000000"
                  }`,
                }}
                defaultValue={selectedTags?.some((item) => item.id === tag.id)}
                checked={selectedTags?.some((item) => item.id === tag.id)}
                onChange={(checked) => handleSlotChange(tag, checked)}
              >
                <p style={{ marginTop: "7px" }}>
                  {AmPmFormat(
                    tag.start_time?.substr(0, tag.start_time.lastIndexOf(":"))
                  )}
                </p>
              </CheckableTag>
            </>
          )
      )}
      <br />
    </>
  );
};

export default Timeslots;
