import React from "react";
import { Form } from "antd";
import { Input } from "antd";

import amenityEngNameIcon from "../../../assets/images/icons/building_eng_grey@3x.png";
import visitorNameIcon from "../../../assets/images/icons/user_grey@3x.png";
import seacrhIcon from "../../../assets/images/icons/search_grey@3x.png";
import tenantNameIcon from "../../../assets/images/icons/fullname_generalinfo_grey@3x.png";
import Phone from "../../../components/PhoneInput";

const VisitorGeneralForm = ({
  setVisitorName,
  setArabicVisitorName,
  setVisitorNum,
  visitorNum,
  buildingNum,
  setBuildingNum,
  setApartmentNum,
  apartmentNum,
  setTenantName,
  setArabicTenantName,
  setNumOfVisitors,
  handleGetTenantByApartmentAndBuilding,
}) => {
  return (
    <div className="visitorForm">
      <div className="row">
        <div className="col-lg-6">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please input visitor name!",
              },
            ]}
          >
            <Input
              className={"inputfield"}
              prefix={
                <img
                  alt=""
                  src={visitorNameIcon}
                  className="site-form-item-icon input-icon-field-twenty"
                />
              }
              placeholder="Visitor’s Name"
              onChange={(e) => {
                setVisitorName(e.target.value);
              }}
              autoComplete="off"
            />
          </Form.Item>
        </div>

        <div className="col-lg-6">
          <Form.Item
            name="name_ar"
            rules={[
              {
                required: false,
                message: "Please input visitor arabic name!",
              },
            ]}
          >
            <Input
              className={"inputfield"}
              suffix={
                <img
                  alt=""
                  src={visitorNameIcon}
                  className="site-form-item-icon input-icon-field-twenty"
                />
              }
              lang={"ar"}
              dir={"rtl"}
              placeholder={`اسم الزائر`}
              onChange={(e) => {
                setArabicVisitorName(e.target.value);
              }}
              autoComplete="off"
            />
          </Form.Item>
        </div>
      </div>
      <div className="row mtt-20">
        <div className="col-lg-6">
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input visitor number!",
              },
            ]}
          >
            <Phone
              disabled={false}
              value={visitorNum}
              onChange={(phone) => setVisitorNum(phone)}
            />
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="number_of_visitors"
            rules={[
              {
                required: true,
                message: "Please input no of visitors!",
              },
            ]}
          >
            <Input
              className={"inputfield"}
              maxLength="3"
              prefix={
                <img
                  alt=""
                  src={visitorNameIcon}
                  className="site-form-item-icon input-icon-field-twenty"
                />
              }
              placeholder="Number of Visitors"
              onChange={(e) => {
                setNumOfVisitors(e.target.value);
              }}
              autoComplete="off"
            />
          </Form.Item>
        </div>
      </div>
      <div className="row mtt-20">
        <div className="col-lg-6">
          <Form.Item
            name="building_no"
            rules={[
              {
                required: true,
                message: "Please input building number!",
              },
            ]}
          >
            <Input
              className={"inputfield"}
              prefix={
                <img
                  alt=""
                  src={amenityEngNameIcon}
                  className="site-form-item-icon input-icon-field-twenty"
                />
              }
              placeholder={`Tenant’s Building Number`}
              onChange={(e) => {
                setBuildingNum(e.target.value);
              }}
              autoComplete="off"
            />
          </Form.Item>
        </div>

        <div className="col-lg-6">
          <Form.Item
            name="apartment_no"
            rules={[
              {
                required: true,
                message: "Please input apartment number!",
              },
            ]}
          >
            <Input
              className={"inputfield"}
              prefix={
                <img
                  alt=""
                  src={amenityEngNameIcon}
                  className="site-form-item-icon input-icon-field-twenty"
                />
              }
              suffix={
                apartmentNum?.length >= 1 && (
                  <img
                    alt=""
                    src={seacrhIcon}
                    className="site-form-item-icon input-icon-field-twenty"
                    onClick={() =>
                      handleGetTenantByApartmentAndBuilding(
                        buildingNum,
                        apartmentNum
                      )
                    }
                  />
                )
              }
              placeholder={`Tenant’s Apartment Number`}
              onChange={(e) => {
                setApartmentNum(e.target.value);
              }}
              autoComplete="off"
            />
          </Form.Item>
        </div>
      </div>
      <div className="row mtt-20">
        <div className="col-lg-6">
          <Form.Item
            name="tenant_name"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input
              className={"inputfield"}
              disabled={true}
              prefix={
                <img
                  alt=""
                  src={tenantNameIcon}
                  className="site-form-item-icon input-icon-field-twenty"
                />
              }
              placeholder="Tenant Name"
              onChange={(e) => {
                setTenantName(e.target.value);
              }}
              autoComplete="off"
            />
          </Form.Item>
        </div>

        <div className="col-lg-6">
          <Form.Item
            name="tenant_name_ar"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input
              className={"inputfield"}
              disabled={true}
              suffix={
                <img
                  alt=""
                  src={tenantNameIcon}
                  className="site-form-item-icon input-icon-field-twenty"
                />
              }
              lang={"ar"}
              dir={"rtl"}
              placeholder={`اسم المستأجر`}
              onChange={(e) => {
                setArabicTenantName(e.target.value);
              }}
              autoComplete="off"
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default VisitorGeneralForm;
