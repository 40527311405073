import {FIND_EMAIL,FIND_EMAIL_ERROR,RESET_PASSWORD,RESET_PASSWORD_ERROR, GET_USERS, LOGIN_USER_ERROR, LOGIN_USER_SUCCESS, USERS_ERROR, CHECK_EMAIL, CHECK_EMAIL_ERROR, RESET_TENANT_PASSWORD, RESET_TENANT_PASSWORD_ERROR, CURRENT_USER, CURRENT_USER_ERROR} from '../types/user.types'

const initialState = {
    user:[],
    loading:true,
    isLoggedIn:false,
}

 function userReducer(state = initialState, action){
    switch(action.type){
        case FIND_EMAIL:
        return{
            user:action.payload,
            loading: false,
        }
        case FIND_EMAIL_ERROR:
            return{
                loading: false,
                error: action.payload 
            }
        case CHECK_EMAIL:
        return{
            user:action.payload?.data?.user?.data,
            loading: false,
        }
        case CHECK_EMAIL_ERROR:
            return{
                loading: false,
                error: action.payload 
            }
        case RESET_PASSWORD:
            return{
                ...state,
                user:action.payload,
                loading: false,
            }
        case RESET_PASSWORD_ERROR:
            return{
                loading: false,
                error: action.payload 
            }
        case RESET_TENANT_PASSWORD:
            return{
                ...state,
                user:action.payload,
                loading: false,
            }
        case RESET_TENANT_PASSWORD_ERROR:
            return{
                loading: false,
                error: action.payload 
            }

        case LOGIN_USER_ERROR:{
            return {
                loading:false,
                error:action.payload
            }
        }
        case LOGIN_USER_SUCCESS:{
            return {
                ...state,
                user:action.payload,
                loading: false,
                isLoggedIn:true
            }
        }
        case CURRENT_USER:{
            return {
                ...state,
                currentUser:action.payload.data?.user,
                services: action.payload?.data?.services,
                loading: false,
                isLoggedIn:true
            }
        }
        case CURRENT_USER_ERROR:{
            return {
                loading:false,
                error:action.payload
            }
        }
      
        default: return state
    }

}
export default userReducer