import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getCurrentUserAction } from "../store/actions/user.action";

const ProtectedRoutes = ({ allowedRoles }) => {
  const currentRole = useSelector((state) => state?.user?.currentUser);
  const dispatch = useDispatch();
  const Auth = localStorage.getItem("token");
  const location = useLocation();
  const Roles = [currentRole?.role_id];

  const getUserProfile = () => {
    dispatch(getCurrentUserAction());
  };

  useEffect(() => {
    if (Auth) {
      getUserProfile();
    }
  }, []);
  return !Auth ?(
    <Navigate to="/" />
  ) :
  currentRole?.role_id === undefined ? (
    <>
      <div className="no-data-found">
        <Spin />
      </div>
    </>
  ) : Roles?.find((role) => allowedRoles.includes(role)) ? (
    <Outlet />
  ) : Auth ? (
    <Navigate to="/havenly/unAuthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;
