import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Steps, Button, Form, Select, Upload, Modal } from "antd";

import calenderIcon from "../../assets/images/icons/calender_grey@3x.png";
import calenderBlueIcon from "../../assets/images/icons/calender_blue@3x.png";
import galleryIcon from "../../assets/images/icons/gallery_grey@3x.png";
import galleryBlueIcon from "../../assets/images/icons/gallery_blue@3x.png";
import Building from "../../assets/images/icons/building_blue@3x-3.png";
import addImgRectangle from "../../assets/images/icons/add_img_rectangle.png.png";
import Congratulations from "../../components/congratulations";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";

import TimeAvailability from "../Amenities/components/TimeAvailability";
import MaintenanceGeneralInfo from "./components/MaintenanceGeneralInfo";
import { createMaintenanceService } from "../../store/actions/maintenance.action";
import { getAllTimeSlots } from "../../store/actions/amenities.action";
import AddImageModal from "../../components/AddImageModal";
const { Step } = Steps;
const { Option } = Select;

const AddMaintenance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [visible, setVisible] = useState(false);
  const [maintenanceServiceName, setMaintenanceServiceName] = useState("");
  const [arabicMaintenanceServiceName, setArabicMaintenanceServiceName] =
    useState("");
  const [amount, setAmount] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [description, setDescription] = useState("");
  const [capacity, setCapacity] = useState("");
  const [arabicDescription, setArabicDescription] = useState("");
  const [status, setStatus] = useState("0");
  const [slots, setSlots] = useState("1");
  const [fileList, setFileList] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const [addingService, setAddingService] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [imgVisible, setImgVisible] = useState(false);
  const [state, setState] = useState({});
  const [form] = Form.useForm();

  form.setFieldsValue({
    remember: true,
    name: maintenanceServiceName,
    name_ar: arabicMaintenanceServiceName,
    amount: amount,
    status: status,
    description: description,
    description_ar: arabicDescription,
    capacity: capacity,
    image: fileList,
    open_days: selectedDays,
    open_shifts: selectedTags,
    open_slots_per_user: slots,
  });

  const onPreview = async (file) => {
    if(file?.url){
      setPreviewImage(file.url);
      setPreviewVisible(true);
      setPreviewTitle(file.name);
    }else{
      setPreviewImage(file.thumbUrl);
      setPreviewVisible(true);
      setPreviewTitle(file.name);
    }
    }

  const handleCancel = () => setPreviewVisible(false);
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }


  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const handlePopupModal = () => {
    setImgVisible(true);
  };

  const hideImgModal = () => {
    setImgVisible(false);
  };

  const handleDeleteImage = () => {
    setFileList([]);
  };

  const hideModal = () => {
    setVisible(false);
    navigate(`/havenly/maintenanceListing`, { state: { id: orgId } });
  };

  const showModal = () => {
    setVisible(true);
  };


  const next = () => {
    setCurrent(current + 1);
  };
  const nextProcess = () => {
    setCurrent(current + 1);
  };

  useEffect(() => {
    setOrgId(currentUser?.organization_id);
    setCurrentUserId(currentUser?.id);
    dispatch(getAllTimeSlots());
  }, [currentUser]);

  const children = [];
  for (let i = 1; i <= 10; i++) {
    children.push(<Option key={i}>Pakistan</Option>);
  }

  const prev = () => {
    setCurrent(current - 1);
  };
  const onSubmit = async (values) => {
    setAddingService(true);
    let fd = new FormData();
    fd.append("name", maintenanceServiceName);
    fd.append("name_ar", arabicMaintenanceServiceName);
    fd.append("amount", amount);
    fd.append("status", status);
    fd.append("description", description);
    fd.append("capacity", capacity);
    fd.append("description_ar", arabicDescription);
    fd.append("images", fileList?.slice(-1)[0]?.originFileObj);
    fd.append("open_days", JSON.stringify(selectedDays));
    fd.append("open_shifts", JSON.stringify(selectedTags));
    fd.append("pop_up_description", JSON.stringify(["asf", "sd"]));
    fd.append("pop_up_description_ar", JSON.stringify(["asf", "sd"]));
    fd.append("created_by", currentUserId);
    fd.append("organization_id", orgId);
    const res = await dispatch(createMaintenanceService(fd));
    if (res?.statusCode === 200) {
      setAddingService(false);
      showModal();
    } else if(Object.keys(res)?.length === 0){
      setAddingService(false);
      showModal();
    }else {
      setAddingService(false);
      toastr.error(res?.response?.data?.message);
    }
  };

  const onSubmitNext = () => {
    nextProcess();
  };

  const steps = [
    {
      title: "General Information",
      icon: <img alt="icon" src={Building} className='step-icon-size'/>,
      content: (
        <div>
          <MaintenanceGeneralInfo
            maintenanceServiceName={maintenanceServiceName}
            arabicMaintenanceServiceName={arabicMaintenanceServiceName}
            amount={amount}
            description={description}
            arabicDescription={arabicDescription}
            setMaintenanceServiceName={setMaintenanceServiceName}
            setArabicMaintenanceServiceName={setArabicMaintenanceServiceName}
            setAmount={setAmount}
            setDescription={setDescription}
            capacity={capacity}
            setCapacity={setCapacity}
            setArabicDescription={setArabicDescription}
            setStatus={setStatus}
            status={status}
            onSubmitNext={onSubmitNext}
          />
        </div>
      ),
    },
    {
      title: "Photos & Facilities",
      icon: (
        <img alt="example" src={current >= 1 ? galleryBlueIcon : galleryIcon} className='step-icon-size'/>
      ),
      content: (
        <div className="photosAndFacilities">
          <Form
            name="add_Amenity"
            className="amenity-form"
            initialValues={{
              remember: true,
              image: fileList,
            }}
            form={form}
            onFinish={onSubmitNext}
          >
            <h6 className="sm-title-card mtt-10"> Photos & Facilities </h6>
            <span className="sub-heading mtt-10">
              Add Photo for your Maintenance
            </span>
            <Form.Item
              name="image"
              className="mtt-20"
              rules={[
                {
                  required: true,
                  message: "Please upload image!",
                },
              ]}
            >
                {fileList.length === 0 ? (
                <>
                  <div className="rectangle-frame-img">
                    <img
                      alt=""
                      src={addImgRectangle}
                      className="upload-img-box mtt-20"
                      onClick={handlePopupModal}
                    />
                    <p className="img-warn mtt-10">
                      {" "}
                      Files Supported: PNG, JPEG{" "}
                    </p>
                    <p className="img-warn mtt-10">Image Size (200x200)</p>
                  </div>
                </>
              ) : (
                <>
                  <Upload
                    disabled={true}
                    listType="picture-card"
                    fileList={fileList.slice(-1)}
                    beforeUpload={() => false}
                    onPreview={handlePopupModal}
                  >
                    {fileList.length < 1 && "+"}
                  </Upload>
                  <p className="img-warn mt-1">Files Supported: PNG, JPEG</p>
                  <p className="img-warn mtt-10">Image Size (200x200)</p>
                </>
              )}
            </Form.Item>

            <br />
            <div className="stepNumber mtt-20">
              <span className="sub-heading"> Step 2 of 3 </span>
            </div>
            <Form.Item>
              <div className="steps-action-one">
                <Button className="servicesBackBtn" onClick={() => prev()}>
                  Back
                </Button>
                <Button
                  type="primary"
                  className="servicesMainBtn"
                  htmlType="submit"
                  style={{ margin: "0 8px" }}
                >
                  Next
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      ),
    },

    {
      title: "Time Availability",
      icon: (
        <img alt="" src={current === 2 ? calenderBlueIcon : calenderIcon} className='step-icon-size' />
      ),
      content: (
        <Form
          name="add_Amenity"
          className="amenity-form"
          initialValues={{
            remember: true,
            image: fileList,
          }}
          form={form}
          onFinish={onSubmit}
        >
          <div className="Services-Input">
            <TimeAvailability
              setSelectedDays={setSelectedDays}
              selectedDays={selectedDays}
              setSlots={setSlots}
              slots={slots}
              maintenanceView={true}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              selectedShifts={selectedShifts}
              setSelectedShifts={setSelectedShifts}
              onSubmit={onSubmit}
              prev={prev}
              addingService={addingService}
            />

            <div className="stepNumber mtt-20">
              <span className="sub-heading"> Step 3 of 3 </span>
            </div>
          </div>
        </Form>
      ),
    },
  ];

  return (
    <>
      <div className="tabled-div">
        <div className="row">
          <div className="col-lg-3">
            <Steps current={current} direction="vertical">
              {steps.map((item) => (
                <Step key={item.title} title={item.title} icon={item.icon} />
              ))}
            </Steps>
          </div>
          <div className="col-lg-9">
            <div className="addServiceSteps">
              <div className="steps-content"> {steps[current].content}</div>
            </div>
          </div>
        </div>
        <Congratulations
          visible={visible}
          hideModal={hideModal}
          message={"Service created successfully"}
        />
        <AddImageModal
          handleDeleteImage={handleDeleteImage}
          file={fileList}
          state={state}
          visible={imgVisible}
          hideModal={hideImgModal}
          onChange={onChange}
        />
          <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" src={previewImage} style={{width:'472px'}} />
      </Modal>
      </div>
    </>
  );
};

export default AddMaintenance;
