import { monthsName } from "../StaticData";
export const dayName = (date) => {
  const d = new Date(date);
  const dayName = d.toString().split(" ")[0];
  return dayName;
};

export const fullDayName = (date) => {
  let days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const d = new Date(date);
  const fullDayName = days[d.getDay()];
  return fullDayName;
};

export const getMonthName = (date) => {
  const d = new Date(date);
  let name = monthsName[d.getMonth()];
  return name;
};

export const AmPmFormat = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  time.splice(3, 1);
  return time.join("");
};
