import { Divider, Pagination } from 'antd';
import React from 'react'

const RePagination  = ({total,defaultPageSize, defaultCurrent, onChange }) => {
    return(
        <div className="table-pagination">
        <Divider className="divider-border-radius" />
        <Pagination
          total={total}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          showSizeChanger={true}
          showLessItems={true}
          defaultPageSize={defaultPageSize}
          defaultCurrent={defaultCurrent}
          onChange={onChange}
        />
      </div>
    )
}

export default RePagination;