import React, { useEffect, useState } from "react";
import { Button, Card, Form, DatePicker, Upload } from "antd";
import Inputfield from "../../components/Inputfield";
import AmountIcon from "../../assets/images/icons/amount_eng_grey@3x.png";
import ParcelIcon from "../../assets/images/icons/parcels_grey@3x.png";
import ParcelIconArabic from "../../assets/images/icons/Parcel@3x.png";
import Vector from "../../assets/images/icons/building_eng_grey@3x.png";
import createUserLogo from "../../assets/images/icons/fullname_generalinfo_grey@3x.png";
import addImgRectangle from "../../assets/images/icons/add_img_rectangle.png.png";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import AddImageModal from "../../components/AddImageModal";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import toastr from "toastr";
import {
  createParcelAction,
  getTenantUsingApartmentAndBuilding,
} from "../../store/actions/parcel.action";
import Congratulations from "../../components/congratulations";
import { unreadNotificationCountOfParcel } from "../../store/actions/notifications.action";

const AddNewParcel = () => {
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const orgId = location?.state;
  const [arabicName, setArabicName] = useState("");
  const [parcelName, setParcelName] = useState("");
  const [apartmentNumber, setApartmentNumber] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [tenantId, setTenantId] = useState();
  const [amount, setAmount] = useState("");
  const [DOB, setDOB] = useState(new Date());
  const [description, setDescription] = useState("");
  const [arabicDescription, setArabicDescription] = useState("");
  const [fileList, setFileList] = useState([]);
  const [state, setState] = useState({});
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState(false);
  const [imgVisible, setImgVisible] = useState(false);
  const [form] = Form.useForm();

  form.setFieldsValue({
    parcelImage: fileList,
  });
  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    navigate("/havenly/parcelRequests", { state: { id: orgId?.id } });
  };
  const onSubmit = async (values) => {
    setActive(true);
    const file = fileList.slice(-1);
    let fd = new FormData();
    fd.append("parcel_name", parcelName);
    fd.append("ar_parcel_name", arabicName);
    fd.append("date", DOB);
    fd.append("tenant_id", tenantId);
    fd.append("amount", amount);
    fd.append("organization_id", orgId?.id);
    fd.append("image", file[0]?.originFileObj);
    fd.append("description", description);
    fd.append("ar_description", arabicDescription);
    const res = await dispatch(createParcelAction(fd));
    if (res.statusCode === 200) {
      setActive(false);
      showModal();
      dispatch(unreadNotificationCountOfParcel(1));
    } else if (Object.keys(res)?.length === 0) {
      setActive(false);
      showModal();
    } else {
      setActive(false);
      toastr.error(res?.response?.data?.message);
    }
  };

  const onDateChange = (date, dateString) => {
    setDOB(dateString);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handlePopupModal = () => {
    setImgVisible(true);
  };

  const hideImgModal = () => {
    setImgVisible(false);
  };
  const handleDeleteImage = () => {
    setFileList([]);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const handleGetTenantByApartmentAndBuilding = async (
    building_no,
    apartment_no
  ) => {
    const res = await dispatch(
      getTenantUsingApartmentAndBuilding(building_no, apartment_no, orgId?.id)
    );
    if (res.statusCode === 200) {
      setTenantName(res?.data?.tenant[0]?.full_name);
      form.setFieldsValue({
        tenant: res?.data?.tenant[0]?.full_name,
      });
      setTenantId(res?.data?.tenant[0]?.id);
    } else {
      toastr.error(res?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (buildingNumber?.length >= 1 && apartmentNumber?.length >= 1) {
      handleGetTenantByApartmentAndBuilding(buildingNumber, apartmentNumber);
    }
  }, [buildingNumber, apartmentNumber]);

  return (
    <>
      <Card className="card" style={{ width: "75%" }}>
        <p className="common-heading">General Information</p>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onSubmit}
        >
          <div className="row mtt-20">
            <div className="col-lg-6">
              <Form.Item
                name="parcelName"
                rules={[
                  {
                    required: true,
                    message: "Please enter parcel name!",
                  },
                ]}
              >
                <Inputfield
                  placeholder="Parcel Name"
                  prefix={
                    <img
                      alt=""
                      src={ParcelIcon}
                      className="input-icon-field-twenty"
                    />
                  }
                  onChange={(e) => {
                    setParcelName(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                name="arabicName"
                rules={[
                  {
                    required: true,
                    message: "Please input arabic name!",
                  },
                ]}
              >
                <Inputfield
                  placeholder="اسم الطرد"
                  suffix={
                    <img
                      alt=""
                      src={ParcelIconArabic}
                      className="input-icon-field-twenty"
                    />
                  }
                  onChange={(e) => {
                    setArabicName(e.target.value);
                  }}
                  lang={"ar"}
                  dir={"rtl"}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <DatePicker
                disabled={true}
                className="inputfield"
                onChange={onDateChange}
                defaultValue={moment(DOB, dateFormat)}
                format={dateFormat}
              />
            </div>
          </div>
          <div className="row mtt-20">
            <div className="col-lg-6">
              <Form.Item
                name="buildingNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input building number!",
                  },
                ]}
              >
                <Inputfield
                  prefix={
                    <img
                      alt=""
                      src={Vector}
                      className="site-form-item-icon input-icon-field-twenty"
                    />
                  }
                  placeholder="Building Number"
                  onChange={(e) => {
                    setBuildingNumber(e.target.value);
                  }}
                />
              </Form.Item>
            </div>

            <div className="col-lg-6">
              <Form.Item
                name="apartmentNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input apartment number!",
                  },
                ]}
              >
                <Inputfield
                  prefix={
                    <img
                      alt=""
                      src={Vector}
                      className="site-form-item-icon input-icon-field-twenty"
                    />
                  }
                  placeholder="Apartment Number"
                  onChange={(e) => {
                    setApartmentNumber(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Form.Item name="tenant">
                <Inputfield
                  disabled={true}
                  prefix={
                    <img
                      alt=""
                      src={createUserLogo}
                      className="site-form-item-icon input-icon-field-twenty"
                    />
                  }
                  placeholder="Tenant Name"
                />
              </Form.Item>
              <p className="inputfield-sub-line">
                * Above mentioned tenant will receive a notification to pick up
                the parcel
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please input amount!",
                  },
                ]}
              >
                <Inputfield
                  prefix={
                    <img
                      alt=""
                      src={AmountIcon}
                      className="site-form-item-icon input-icon-field-twenty"
                    />
                  }
                  placeholder="Amount"
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: "Please input description!",
              },
            ]}
          >
            <TextArea
              autoSize={(false, { minRows: 5, maxRows: 6 })}
              showCount
              placeholder="Description"
              maxLength={100}
              style={{
                height: 120,
                borderRadius: "8px",
              }}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="arabicDescription"
            rules={[
              {
                required: true,
                message: "Please input description!",
              },
            ]}
          >
            <TextArea
              autoSize={(false, { minRows: 5, maxRows: 6 })}
              showCount
              lang={"ar"}
              dir={"rtl"}
              placeholder={`وصف`}
              maxLength={100}
              style={{
                height: 120,
                borderRadius: "8px",
              }}
              onChange={(e) => {
                setArabicDescription(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="parcelImage"
            rules={[
              {
                required: true,
                message: "Please enter image!",
              },
            ]}
          >
            {fileList.length === 0 ? (
              <>
                <div className="rectangle-frame-img">
                  <img
                    alt=""
                    src={addImgRectangle}
                    className="upload-img-box mtt-20"
                    onClick={handlePopupModal}
                  />
                  <p className="img-warn mtt-10">Files Supported: PNG, JPEG</p>
                  <p className="img-warn mtt-10">Image Size (200x200)</p>
                </div>
              </>
            ) : (
              <>
                <Upload
                  disabled={true}
                  listType="picture-card"
                  fileList={fileList.slice(-1)}
                  beforeUpload={() => false}
                  onPreview={handlePopupModal}
                >
                  {fileList.length < 1 && "+"}
                </Upload>
                <p className="img-warn mt-1">Files Supported: PNG, JPEG</p>
                <p className="img-warn mtt-10">Image Size (200x200)</p>
              </>
            )}
          </Form.Item>
          <Form.Item>
            <div className="d-flex justify-content-end mtt-10">
              <Button
                className="servicesMainBtn"
                htmlType="submit"
                style={{ margin: "0 12px" }}
                disabled={active}
              >
                {active ? "Loading..." : "Save"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
      <Congratulations
        visible={visible}
        hideModal={hideModal}
        message={"Parcel Successfully Created"}
      />
      <AddImageModal
        handleDeleteImage={handleDeleteImage}
        file={fileList}
        state={state}
        visible={imgVisible}
        hideModal={hideImgModal}
        onChange={onChange}
      />
    </>
  );
};

export default AddNewParcel;
