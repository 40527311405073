import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import WalletLg from "../../assets/images/icons/wallet_blue@3x.png";
import createUserLogo from "../../assets/images/icons/fullname_generalinfo_grey@3x.png";
import addImgRectangle from "../../assets/images/icons/add_img_rectangle.png.png";
import Circle from "../../assets/images/icons/Circle.png";
import FilledCircle from "../../assets/images/icons/filled-circle.png";
import BuildingGrey from "../../assets/images/icons/building_eng_grey@3x.png";
import Message from "../../assets/images/icons/mail_generalinfo_grey@3x.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  editTenant,
  getSingleTenant,
  terminateAllSessionOfTenant,
} from "../../store/actions/tenant.action";
import countryList from "react-select-country-list";
import moment from "moment";
import toastr from "toastr";
import AddImageModal from "../../components/AddImageModal";
import { UploadOutlined } from "@ant-design/icons";
import Congratulations from "../../components/congratulations";
import Country from "../../components/CountryDropdown";
import Phone from "../../components/PhoneInput";
const { Option } = Select;

const EditTenant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(false);
  const [imgVisible, setImgVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [tenantGender, setTenantGender] = useState();
  const [tenantId, setTenantId] = useState("");
  const [selected, setSelected] = useState();
  const [countryLabel, setCountryLabel] = useState("");
  const [tenantCountry, setTenantCountry] = useState();
  const [tenantDocument, setTenantDocument] = useState([]);
  const [active, setActive] = useState("");
  const [DOB, setDOB] = useState();
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const phone = "";
  const [state, setState] = useState({});
  const currentUser = useSelector((state) => state?.user?.currentUser);

  form.setFieldsValue({
    organizationImage: fileList,
  });
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    navigate("/havenly/tenants", { state: { id: user?.organization_id } });
  };

  const handleChange = ({ fileList: newFileList }) => {
    setTenantDocument(newFileList);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const handleDeleteImage = () => {
    setFileList([]);
  };

  const handlePopupModal = () => {
    setImgVisible(true);
  };

  const hideImgModal = () => {
    setImgVisible(false);
  };

  const getTenants = async () => {
    const db = await dispatch(getSingleTenant(id));
    setTenantId(db?.data?.tenant?.id);
    setTenantGender(db?.data?.tenant?.gender);
    if (db?.data?.tenant?.country !== null) {
      setCountryLabel(db?.data?.tenant?.country);
      const country = await countryList().getValue(db?.data?.tenant?.country);
      setSelected(country);
    }
    if (db?.data?.tenant?.date_of_birth === null) {
      setDOB(moment("1888/08/08", dateFormat));
    } else {
      setDOB(db?.data?.tenant?.date_of_birth);
    }
    setActive(db?.data?.tenant?.active);
    if (
      db?.data?.tenant?.document_original_name !== null ||
      (undefined && db?.data?.tenant?.document !== null) ||
      undefined
    ) {
      setTenantDocument([
        {
          name: `${db?.data?.tenant?.document_original_name}`,
          url: `${process.env.REACT_APP_S3_BUCKET_URL}/${db?.data?.tenant?.document}`,
        },
      ]);
    }
    if (db?.data?.tenant?.image !== null || undefined) {
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: `${process.env.REACT_APP_S3_BUCKET_URL}/${db?.data?.tenant?.image}`,
        },
      ]);
    }
    form.setFieldsValue({
      balance: db?.data?.tenant?.balance,
      firstName: db?.data?.tenant?.first_name,
      firstName_ar: db?.data?.tenant?.first_name_ar,
      lastName_ar: db?.data?.tenant?.last_name_ar,
      lastName: db?.data?.tenant?.last_name,
      email: db?.data?.tenant?.email,
      phone: `+${db?.data?.tenant?.phone}`,
      buildingNumber: db?.data?.tenant?.building_no,
      apartmentNumber: db?.data?.tenant?.apartment_no,
      date: db?.data?.tenant?.date_of_birth,
    });
  };
  useEffect(() => {
    getTenants();
  }, []);

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  const handleStatusChange = (val) => {
    setActive(val);
  };

  const onGenderChange = ({ target: { value } }) => {
    setTenantGender(value);
  };

  const handleFlagCountry = (code) => {
    setSelected(code);
    const countryLabel = countryList().getLabel(code);
    setTenantCountry(countryLabel);
  };

  const onDateChange = (date, dateString) => {
    setDOB(dateString);
  };

  const settingValuesInForm = (key, value) => {
    form.setFieldsValue({
      [key]: value,
    });
  };

  const onSubmit = async (values) => {
    if (tenantDocument?.length === 0) {
      toastr?.error("Document is missing");
    } else {
      let fd = new FormData();
      fd.append("first_name", values?.firstName); //append the values with key, value pair
      fd.append("last_name", values?.lastName);
      fd.append("first_name_ar", values?.firstName_ar);
      fd.append("last_name_ar", values?.lastName_ar);
      fd.append("email", values?.email);
      fd.append("phone", values?.phone);
      fd.append("country", tenantCountry ? tenantCountry : countryLabel);
      fd.append("gender", tenantGender);
      fd.append("date_of_birth", DOB);
      fd.append("image", fileList?.slice(-1)[0]?.originFileObj);
      fd.append("tenant_id", id);
      fd.append("building_no", values?.buildingNumber);
      fd.append("apartment_no", values?.apartmentNumber);
      fd.append("password", values?.apartmentNumber);
      fd.append("confirm_password", values?.apartmentNumber);
      fd.append("document", tenantDocument[0]?.originFileObj);
      fd.append("balance", values?.balance);
      fd.append("active", active);
      fd.append("created_by", 1);
      setLoading(true);
      const res = await dispatch(editTenant(fd));
      if (res.statusCode === 200) {
        setLoading(false);
        showModal();
      } else if (Object.keys(res)?.length === 0) {
        setLoading(false);
        showModal();
      } else {
        setLoading(false);
        toastr.error(res?.response?.data?.message);
      }
    }
  };

  const handleTerminateSession = async () => {
    const res = await dispatch(terminateAllSessionOfTenant(tenantId));
    if (res.statusCode === 200) {
      toastr.success(
        "Congrats! You terminated the tenant session successfully."
      );
    } else {
      toastr.error(res?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="main-edit-tenant-wallet">
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onSubmit}
        >
          <Card className="card">
            <h6 className="sm-title-card mtt-10"> Wallet </h6>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <Form.Item
                  name="balance"
                  rules={[
                    {
                      required: true,
                      message: "Please input balance!",
                    },
                  ]}
                >
                  <Input className={"wallet-inputfield mtt-20"} type="number" />
                </Form.Item>
                <p className="pkr-div">PKR</p>
              </div>
              <img alt="" className="tenant-wallet-icon" src={WalletLg} />
            </div>
          </Card>
          <Card className="card">
            <h6 className="sm-title-card mtt-10"> Personal Information </h6>

            <div className="row mtt-20">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input first name!",
                        },
                      ]}
                    >
                      <Input
                        className={"inputfield"}
                        prefix={
                          <img
                            alt=""
                            src={createUserLogo}
                            className="site-form-item-icon input-icon-field-twenty"
                          />
                        }
                        placeholder="Enter First Name"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-6">
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input last name!",
                        },
                      ]}
                    >
                      <Input
                        className={"inputfield"}
                        prefix={
                          <img
                            alt=""
                            src={createUserLogo}
                            className="site-form-item-icon input-icon-field-twenty"
                          />
                        }
                        placeholder="Enter Last Name"
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="arabic-fields row">
                  <div className="col-lg-6">
                    <Form.Item
                      name="lastName_ar"
                      rules={[
                        {
                          required: false,
                          message: "Please input last name!",
                        },
                      ]}
                    >
                      <Input
                        className={"inputfield"}
                        suffix={
                          <img
                            alt=""
                            src={createUserLogo}
                            className="site-form-item-icon input-icon-field-twenty"
                          />
                        }
                        placeholder="إدخال اسم آخر"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-6">
                    <Form.Item
                      name="firstName_ar"
                      rules={[
                        {
                          required: false,
                          message: "Please input first name!",
                        },
                      ]}
                    >
                      <Input
                        className={"inputfield "}
                        suffix={
                          <img
                            alt=""
                            src={createUserLogo}
                            className="site-form-item-icon input-icon-field-twenty"
                          />
                        }
                        placeholder="أدخل الاسم الأول"
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="gender-radio-div">
                  <Form.Item
                    name="gender"
                    rules={[
                      {
                        required: false,
                        message: "Please select gender!",
                      },
                    ]}
                  >
                    <Typography className="date-of-birth">Gender</Typography>
                    <Radio.Group
                      value={tenantGender}
                      onChange={onGenderChange}
                      buttonStyle="solid"
                    >
                      <Radio.Button
                        value="1"
                        className={tenantGender === "1" ? "" : "grey-border"}
                      >
                        <div className="inner-container-radio-gender">
                          <p>Male</p>
                        </div>
                      </Radio.Button>
                      <Radio.Button
                        value="2"
                        className={tenantGender === "2" ? "" : "grey-border"}
                      >
                        <div className="inner-container-radio-gender">
                          <p>Female</p>
                        </div>
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <Form.Item
                  name="tenantCountry"
                  rules={[
                    {
                      required: false,
                      message: "Please input country!",
                    },
                  ]}
                >
                  <Country
                    selected={selected}
                    onSelect={(code) => handleFlagCountry(code)}
                    disabled={false}
                  />
                </Form.Item>
                <Form.Item name="date">
                  <Typography className="date-of-birth">
                    Date of Birth
                  </Typography>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <div className="edit-date-picker">
                      <DatePicker
                        value={moment(DOB, dateFormat)}
                        className="inputfield"
                        onChange={onDateChange}
                        format={dateFormat}
                      />
                    </div>
                  </Space>
                </Form.Item>
                <h3 className="common-heading">Profile Settings</h3>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input email!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-10"}
                    prefix={
                      <img
                        alt=""
                        src={Message}
                        className="site-form-item-icon input-icon-field"
                      />
                    }
                    placeholder="Enter your Email"
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Phone
                    disabled={false}
                    value={phone}
                    onChange={(phone) => settingValuesInForm("phone", phone)}
                  />
                </Form.Item>
                <Form.Item
                  name="buildingNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input building number!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield"}
                    maxLength={7}
                    prefix={
                      <img
                        alt=""
                        src={BuildingGrey}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    placeholder="Building Number"
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item
                  name="apartmentNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input apartment number!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield"}
                    maxLength={7}
                    prefix={
                      <img
                        alt=""
                        src={BuildingGrey}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    placeholder="Apartment Number"
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item
                  name="active"
                  rules={[
                    {
                      required: false,
                      message: "Please enter status!",
                    },
                  ]}
                >
                  <div
                    className={
                      active === "1"
                        ? "edit-tenant-select activeSelector"
                        : "edit-tenant-select InactiveSelector"
                    }
                  >
                    <Select
                      value={active}
                      onChange={(active) => handleStatusChange(active)}
                    >
                      <Option value="1">Active</Option>
                      <Option value="0">Inactive</Option>
                    </Select>
                  </div>
                </Form.Item>
                <h3 className="common-heading">Document</h3>

                <Form.Item name="document">
                  <Upload
                    fileList={tenantDocument}
                    accept=".doc,.docx,application/pdf,.png"
                    name="avatar"
                    listType="picture"
                    showUploadList={{ showRemoveIcon: true }}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    onRemove={() => setTenantDocument([])}
                    beforeUpload={() => false}
                    onChange={handleChange}
                  >
                    {tenantDocument.length < 1 && (
                      <Button className="document-btn">
                        <UploadOutlined /> <p>Upload Document</p>
                      </Button>
                    )}
                  </Upload>
                </Form.Item>
                <h3 className="common-heading mtt-20">
                  Logout from All Devices
                </h3>
                <Button
                  className="upload-document-btn mtt-10"
                  type="primary"
                  onClick={handleTerminateSession}
                >
                  Terminate All Sessions
                </Button>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-4 preview-only mobile-preview">
                <Form.Item
                  name="organizationImage"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the image!",
                    },
                  ]}
                >
                  {fileList.length === 0 ? (
                    <>
                      <div className="rectangle-frame-img">
                        <img
                          alt=""
                          src={addImgRectangle}
                          className="upload-img-box"
                          onClick={handlePopupModal}
                        />
                        <p className="img-warn mtt-10">
                          Files Supported: PNG, JPEG
                        </p>
                        <p className="img-warn mtt-10">Image Size (200x200)</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <Upload
                        disabled={true}
                        listType="picture-card"
                        fileList={fileList.slice(-1)}
                        beforeUpload={() => false}
                        onPreview={handlePopupModal}
                      >
                        {fileList.length < 1 && "+"}
                      </Upload>
                      <p className="img-warn mt-1">
                        Files Supported: PNG, JPEG
                      </p>
                      <p className="img-warn mtt-10">Image Size (200x200)</p>
                    </>
                  )}
                </Form.Item>
              </div>
            </div>
            <Form.Item>
              <div className="mtt-20 align-right">
                <Button
                  className="servicesBackBtn mx-2"
                  onClick={() => hideModal()}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="servicesMainBtn"
                  htmlType="submit"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Update"}
                </Button>
              </div>
            </Form.Item>
          </Card>
        </Form>
      </div>
      <AddImageModal
        handleDeleteImage={handleDeleteImage}
        file={fileList}
        state={state}
        onChange={onChange}
        visible={imgVisible}
        hideModal={hideImgModal}
      />
      <Congratulations
        visible={visible}
        hideModal={hideModal}
        message={"Tenant Updated Successfully"}
      />
    </>
  );
};

export default EditTenant;
