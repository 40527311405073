import React, { useEffect, useState } from "react";
import { Card } from "antd";
import totalTaskIcon from "../../assets/images/icons/total_task@3x.png";
import inProgressTaskIcon from "../../assets/images/icons/in_progress@3x.png";
import completedTaskIcon from "../../assets/images/icons/completed@3x.png";
import menuIcon from "../../assets/images/icons/menu-card.png";
import { amenityDashboardAction } from "../../store/actions/amenities.action";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApexCharts from "../../components/ApexChart";
import toastr from "toastr";
import { unreadNotificationCountOfAmenities } from "../../store/actions/notifications.action";

const AmenityDashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const amenity = useSelector((state) => state?.amenity?.amenities?.data);

  const id = location?.state?.id;
  const [res, setRes] = useState();
  const apiCall = async (id) => {
    const apiRes = await dispatch(amenityDashboardAction(id));
    if (apiRes?.statusCode === 200) {
      setRes(apiRes?.data);
    } else {
      toastr.error(apiRes?.response?.data.message);
    }
  };
  useEffect(() => {
    apiCall(id);
    dispatch(
      unreadNotificationCountOfAmenities(amenity?.unread_notifications_count)
    );
  }, []);
  return (
    <div className="amenities">
      <div className="row mb-5">
        <h2 className="amenities-heading"> Overview </h2>
        <div className="col-lg-3">
          <Card
            style={{
              marginTop: 16,
            }}
          >
            <div className="row">
              <div className="col-10">
                <div>
                  <img
                    alt=""
                    src={totalTaskIcon}
                    className="dashboard-widgets-icons"
                  />
                </div>
              </div>
              <div className="col-2" style={{ display: "none" }}>
                <img alt="" src={menuIcon} />
              </div>
            </div>

            <h6 className="sm-title-card my-3">
              {amenity?.bookings_count?.total > 0
                ? amenity?.bookings_count?.total
                : 0}
            </h6>
            <span className="desc-grey"> Total Task </span>
          </Card>
        </div>
        <div className="col-lg-3">
          <Card
            style={{
              marginTop: 16,
            }}
          >
            <div className="row">
              <div className="col-10">
                <div>
                  <img
                    alt=""
                    src={inProgressTaskIcon}
                    className="dashboard-widgets-icons"
                  />
                </div>
              </div>
              <div className="col-2" style={{ display: "none" }}>
                <img alt="" src={menuIcon} />
              </div>
            </div>

            <h6 className="sm-title-card my-3">
              {amenity?.bookings_count?.not_completed > 0
                ? amenity?.bookings_count?.not_completed
                : 0}
            </h6>
            <span className="desc-grey"> Pending Task </span>
          </Card>
        </div>
        <div className="col-lg-3">
          <Card
            style={{
              marginTop: 16,
            }}
          >
            <div className="row">
              <div className="col-10">
                <div>
                  <img
                    alt=""
                    src={completedTaskIcon}
                    className="dashboard-widgets-icons"
                  />
                </div>
              </div>
              <div className="col-2" style={{ display: "none" }}>
                <img alt="" src={menuIcon} />
              </div>
            </div>

            <h6 className="sm-title-card my-3">
              {amenity?.bookings_count?.completed > 0
                ? amenity?.bookings_count?.completed
                : 0}
            </h6>
            <span className="desc-grey"> Approved Task </span>
          </Card>
        </div>
      </div>
      <div className="row mtt-20">
        <div className="col-lg-12">
          <h2 className="amenities-heading"> Monthly Overview </h2>
          <Card className="card">
            <div>
              <ApexCharts
                chartData={amenity?.bookings_count_per_day}
                labelOne="Approved"
                labelTwo="Total"
                heading="Total vs Approved"
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AmenityDashboard;
