import React from "react";
import { Form, Upload, Button } from "antd";
import { Input } from "antd";
import PreviewMessage from "../../../assets/images/icons/message-white-3x.png";
import PreviewLock from "../../../assets/images/icons/Pass@3x.png";
import createUserLogo from "../../../assets/images/icons/User@3x.png";
import Gender from "../../../assets/images/icons/Gender@3x.png";
import Calendar from "../../../assets/images/icons/Calendar@3x.png";
import BuildingGrey from "../../../assets/images/icons/Amenity Name_w@3x.png";
import dummyPlaceholder from "../../../assets/images/icons/super admin - 1x PNG/upload_profile_pic.png";
import { UploadOutlined } from "@ant-design/icons";
import Phone from "../../../components/PhoneInput";
import Country from "../../../components/CountryDropdown";

function PreviewStep({
  firstName,
  lastName,
  arabicFirstName,
  arabicLastName,
  tenantPhone,
  tenantEmail,
  tenantPassword,
  tenantCountry,
  selected,
  DOB,
  tenantGender,
  apartmentNumber,
  buildingNumber,
  fileList,
  tenantDocument,
  onSubmit,
  onPreview,
  prev,
  active,
}) {
  return (
    <>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
          firstName: firstName,
          lastName: lastName,
          arabicFirstName: arabicFirstName,
          arabicLastName: arabicLastName,
          tenantEmail: tenantEmail,
          tenantPassword: tenantPassword,
          tenantPhone: tenantPhone,
          tenantCountry: tenantCountry,
          DOB: DOB,
          tenantGender: tenantGender,
          image: fileList,
          tenantDocument: tenantDocument,
          buildingNumber: buildingNumber,
          apartmentNumber: apartmentNumber,
        }}
        onFinish={onSubmit}
      >
        <div className="row mtt-20">
          <div className="col-lg-6 preview-only">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input first name!",
                },
              ]}
            >
              <Input
                disabled={true}
                addonBefore={
                  <img
                    alt=""
                    src={createUserLogo}
                    className="preview-icons input-icon-field-twenty"
                  />
                }
                placeholder="Enter your Name"
                autoComplete="off"
                defaultValue={`${firstName}${" "}${lastName}`}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input second name!",
                },
              ]}
            >
              <Input
                disabled={true}
                addonAfter={
                  <img
                    alt=""
                    src={createUserLogo}
                    className="preview-icons input-icon-field-twenty"
                  />
                }
                lang={"ar"}
                dir={"rtl"}
                placeholder="Enter your Name"
                autoComplete="off"
                defaultValue={`${arabicFirstName}${" "}${arabicLastName}`}
              />
            </Form.Item>
            <Form.Item
              name="tenantEmail"
              rules={[
                {
                  required: true,
                  message: "Please input first arabic name!",
                },
              ]}
            >
              <Input
                disabled={true}
                addonBefore={
                  <img
                    alt=""
                    src={PreviewMessage}
                    className="preview-icons input-icon-field"
                  />
                }
                placeholder="Enter your Email"
              />
            </Form.Item>
            <Form.Item
              name="tenantPassword"
              rules={[
                {
                  required: true,
                  message: "Please input second arabic name!",
                },
              ]}
            >
              <Input
                disabled={true}
                addonBefore={
                  <img
                    alt=""
                    src={PreviewLock}
                    className="preview-icons input-icon-field-twenty"
                  />
                }
                type="password"
                placeholder="Enter Password"
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              name="tenantPhone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
            >
              <Phone value={tenantPhone} disabled={true} />
            </Form.Item>
            <Form.Item
              name="country"
              rules={[
                {
                  required: false,
                  message: "Please input country!",
                },
              ]}
            >
              <Country selected={selected} disabled={true} />
            </Form.Item>

            <Form.Item
              name="DOB"
              rules={[
                {
                  required: false,
                  message: "Please input date of birth!",
                },
              ]}
            >
              <Input
                defaultValue={DOB}
                disabled={true}
                addonBefore={
                  <img
                    alt=""
                    src={Calendar}
                    className="preview-icons input-icon-field-twenty"
                  />
                }
                placeholder="Enter your DOB"
              />
            </Form.Item>

            <Form.Item
              name="tenantGender"
              rules={[
                {
                  required: false,
                  message: "Please input gender!",
                },
              ]}
            >
              <Input
                defaultValue={tenantGender}
                disabled={true}
                addonBefore={
                  <img
                    alt=""
                    src={Gender}
                    className="preview-icons input-icon-field-twenty"
                  />
                }
                placeholder="Enter your Gender"
              />
            </Form.Item>
            <Form.Item
              name="buildingNumber"
              rules={[
                {
                  required: true,
                  message: "Please input building number",
                },
              ]}
            >
              <Input
                disabled={true}
                addonBefore={
                  <>
                    <img
                      alt=""
                      src={BuildingGrey}
                      className="preview-icons input-icon-field-twenty"
                    />
                  </>
                }
                placeholder="Enter your Building Number"
              />
            </Form.Item>
            <Form.Item
              name="apartmentNumber"
              rules={[
                {
                  required: true,
                  message: "Please input apartment number!",
                },
              ]}
            >
              <Input
                disabled={true}
                addonBefore={
                  <>
                    <img
                      alt=""
                      src={BuildingGrey}
                      className="preview-icons input-icon-field-twenty"
                    />
                  </>
                }
                placeholder="Enter your Apartment Number"
              />
            </Form.Item>

            <Form.Item name="tenantDocument">
              {tenantDocument.length === 1 && (
                <Upload
                  fileList={tenantDocument}
                  accept=".doc,.docx,application/pdf,.png"
                  name="avatar"
                  listType="picture"
                  showUploadList={{ showRemoveIcon: false }}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={() => false}
                >
                  {tenantDocument.length < 1 && (
                    <Button className="document-btn" icon={<UploadOutlined />}>
                      Upload Document
                    </Button>
                  )}
                </Upload>
              )}
            </Form.Item>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-4 preview-only">
            <Form.Item
              name="image"
              rules={[
                {
                  required: false,
                  message: "Please upload pictures!",
                },
              ]}
            >
              {fileList.length >= 1 ? (
                <>
                  <Upload
                    disabled={true}
                    listType="picture-card"
                    fileList={fileList.slice(-1)}
                    beforeUpload={() => false}
                    onPreview={onPreview}
                  >
                    {fileList.length < 1 && "+"}
                  </Upload>

                  <p className="img-warn mtt-10">Files Supported: PNG, JPEG</p>
                  <p className="img-warn mtt-10">Image Size (200x200)</p>
                </>
              ) : (
                <div className="rectangle-frame-img">
                  <img
                    alt=""
                    src={dummyPlaceholder}
                    className="upload-img-box"
                  />
                  <p className="img-warn mtt-10">Files Supported: PNG, JPEG</p>
                  <p className="img-warn mtt-10">Image Size (200x200)</p>
                </div>
              )}
            </Form.Item>
          </div>

          <div className="stepNumber">
            <span className="sub-heading"> Step 4 of 4 </span>
          </div>
          <Form.Item>
            <div className="steps-action-one">
              <Button className="servicesBackBtn" onClick={() => prev()}>
                Back
              </Button>
              <Button
                type="primary"
                className="servicesMainBtn"
                htmlType="submit"
                disabled={active}
                style={{ margin: "0 8px" }}
              >
                {active ? "Loading..." : "Finish"}
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

export default PreviewStep;
