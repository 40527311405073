import {
  ADD_AMENITIES,
  ADD_AMENITIES_ERROR,
  AMENITIES_REQUESTS,
  AMENITIES_REQUESTS_ERROR,
  AMENITIES_LISTING,
  AMENITIES_LISTING_ERROR,
  AMENITY_DASHBOARD,
  AMENITY_DASHBOARD_ERROR,
  STATUS_AMENITY_MEMBER,
  STATUS_AMENITY_MEMBER_ERROR,
  TIME_SLOTS,
  TIME_SLOTS_ERROR,
  UPDATE_AMENITY_MEMBER,
} from "../types/amenities.types";

const initialState = {
  timeSlots: [],
  amenities: [],
  loading: true,
  isData: false,
};

function amenitiesReducer(state = initialState, action) {
  switch (action.type) {
    case AMENITY_DASHBOARD:
      return {
        ...state,
        amenities: action.payload,
        isData: true,
        loading: false,
      };
    case AMENITY_DASHBOARD_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    case ADD_AMENITIES:
      return {
        ...state,
        amenities: action.payload,
        isData: true,
        loading: false,
      };
    case ADD_AMENITIES_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    case AMENITIES_REQUESTS:
      return {
        ...state,
        amenities: action.payload,
        isData: true,
        loading: false,
      };
    case AMENITIES_REQUESTS_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    case AMENITIES_LISTING:
      return {
        ...state,
        amenitiesListing: action.payload,
        isData: true,
        loading: false,
      };
    case AMENITIES_LISTING_ERROR:
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    case UPDATE_AMENITY_MEMBER:
      return {
        ...state,
        amenities: action.payload,
        isData: true,
        loading: false,
      };
    case STATUS_AMENITY_MEMBER: {
      return {
        ...state,
        isData: true,
        loading: false,
      };
    }
    case STATUS_AMENITY_MEMBER_ERROR: {
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    }
    case TIME_SLOTS: {
      return {
        ...state,
        timeSlots: action.payload,
        isData: true,
        loading: false,
      };
    }
    case TIME_SLOTS_ERROR: {
      return {
        error: action.payload,
        loading: false,
        isData: false,
      };
    }
    default:
      return state;
  }
}
export default amenitiesReducer;
