import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgetPassword from "../views/Authentication/ForgetPassword/ForgetPassword";
import PortalScreen from "../views/PortalScreen";
import Signin from "../views/Authentication/Signin/Container/Signin";
import Signup from "../views/Authentication/Signup/Signup";
import SetPassword from "../views/Authentication/SetPassword/SetPassword";
import ProtectedRoutes from "./ProtectedRoutes";
import OrganizationList from "../views/Organizations/OrganizationList";
import OrganizationProfile from "../views/Organizations/OrganizationProfile";
import NewOrganization from "../views/Organizations/AddNewOrganization/NewOrganization";
import EditOrganization from "../views/Organizations/EditOrganization";
import CreateNewUser from "../views/Organizations/CreateUser/CreateUser";
import OrganizationMembers from "../views/Organizations/OrganizationMembers";
import UnAuthorized from "../components/UnAuthorized";
import ParcelDashboard from "../views/Parcel/ParcelDashboard";
import ParcelRequestDetail from "../views/Parcel/ParcelRequestDetail";
import AddParcelImage from "../views/Parcel/AddParcelImage";
import AddNewParcel from "../views/Parcel/AddNewParcel";
import ParcelListing from "../views/Parcel/ParcelListing";
import AddMaintenanceBanner from "../views/Maintenance/MaintenanceBanner";
import EditMaintenance from "../views/Maintenance/components/EditMaintenance";
import AddMaintenance from "../views/Maintenance/AddMaintenance";
import MaintenanceRequests from "../views/Maintenance/MaintenanceRequests";
import MaintenanceListing from "../views/Maintenance/MaintenanceListing";
import MaintenanceDashboard from "../views/Maintenance/MaintenanceDashboard";
import BellboyRequest from "../views/Bellboy/BellboyRequests";
import EditBellboy from "../views/Bellboy/EditBellboy";
import AddNewBellboy from "../views/Bellboy/AddNewBellboy";
import Bellboy from "../views/Bellboy/Bellboy";
import EditTenant from "../views/Tenants/EditTenant";
import AddNewTenant from "../views/Tenants/AddNewTenant";
import Tenants from "../views/Tenants/Tenants";
import RescheduleServices from "../components/RescheduleServices";
import AmenitiesRequests from "../views/Amenities/AmenitiesRequests";
import AddAmenityBanner from "../views/Amenities/AmenityBanner";
import EditAminity from "../views/Amenities/components/EditAmenity";
import AmenitiesListing from "../views/Amenities/AmenitiesListing";
import AmenityDashboard from "../views/Amenities/AmenityDashboard";
import AddAmenities from "../views/Amenities/AddAmenities";
import OrganizationSetting from "../views/Organizations/OrganizationSetting.js/OrganizationSetting";
import RequestsByAdmin from "../views/Visitors/RequestsByAdmin";
import VisitorsDashboard from "../views/Visitors/VisitorsDashboard";
import RequestsByTenant from "../views/Visitors/RequestsByTenants";
import TenantDetails from "../views/Visitors/components/TenantDetails";
import EditOrganizationMembers from "../views/Organizations/EditOrganizationMember";
import AllNotifications from "../components/AllNotifications";
import { useSelector } from "react-redux";

const ROLES = {
  ebutlerSuperAdmin: 1,
  orgSuperAdmin: 2,
  orgAdmin: 3,
  orgUser: 4,
};

const Routing = () => {
  const Services = useSelector((state) => state?.user?.services);

  let arr = [];
  if (Services) {
    for (let I in Services) {
      arr.push(Services[I]?.service);
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes  */}
        <Route exact path="/" element={<Signin />} />
        <Route exact path="/forgetPassword" element={<ForgetPassword />} />
        <Route exact path="/setPassword/:token" element={<SetPassword />} />
        <Route
          exact
          path="/setPassword/:token/:type"
          element={<SetPassword />}
        />
        <Route exact path="/register" element={<Signup />} />

        {/* Protected Routes  */}
        {/* <Route path="/havenly/*" element={<PortalScreen />}> */}
        <Route
          element={
            <ProtectedRoutes allowedRoles={[ROLES?.ebutlerSuperAdmin]} />
          }
        >
          <Route path="/havenly/*" element={<PortalScreen />}>
            <Route path="organization-list" element={<OrganizationList />} />
            <Route path="organization" element={<OrganizationProfile />} />
            <Route path="newOrganization" element={<NewOrganization />} />
            <Route path="editOrganization/:id" element={<EditOrganization />} />
          </Route>
        </Route>
        <Route
          element={
            <ProtectedRoutes
              allowedRoles={[
                ROLES?.ebutlerSuperAdmin,
                ROLES?.orgSuperAdmin,
                ROLES?.orgAdmin,
                ROLES?.orgUser,
              ]}
            />
          }
        >
          <Route path="/havenly/*" element={<PortalScreen />}>
            <Route
              path="createNewOrganizationMember"
              element={<CreateNewUser />}
            />

            <Route path="unAuthorized" element={<UnAuthorized />} />
          </Route>
        </Route>
        <Route
          element={
            <ProtectedRoutes
              allowedRoles={[
                ROLES?.orgSuperAdmin,
                ROLES?.orgAdmin,
                ROLES?.orgUser,
              ]}
            />
          }
        >
          <Route path="/havenly/*" element={<PortalScreen />}>
            <Route
              path="organizationSetting"
              element={<OrganizationSetting />}
            />
            <Route
              path="editOrganizationMember/:id"
              element={<EditOrganizationMembers />}
            />
            <Route
              path="organizationMembersListing"
              element={<OrganizationMembers />}
            />
            {/* Tenant */}
            <Route path="tenants" element={<Tenants />} />
            <Route path="addNewTenant" element={<AddNewTenant />} />
            <Route path="editTenant/:id" element={<EditTenant />} />
            {/* Bellboy */}
            {arr?.includes("Bellboy") && (
              <>
                <Route path="bellboy" element={<Bellboy />} />
                <Route path="addNewBellboy" element={<AddNewBellboy />} />
                <Route path="editBellboy" element={<EditBellboy />} />
                <Route path="bellboyRequests" element={<BellboyRequest />} />
              </>
            )}
            {/* Parcel */}
            {
            arr?.includes("Parcels") && 
            (
              <>
                <Route path="parcelRequests" element={<ParcelListing />} />
                <Route path="addNewParcel" element={<AddNewParcel />} />
                <Route path="addParcelImage" element={<AddParcelImage />} />
                <Route
                  path="parcelRequestDetail"
                  element={<ParcelRequestDetail />}
                />
                <Route path="parcelsDashboard" element={<ParcelDashboard />} />
              </>
            )}
            {/* Amenities */}
            {arr?.includes("Amenities") && (
              <>
                <Route path="addAmenities" element={<AddAmenities />} />
                <Route
                  path={`amenitiesDashboard`}
                  element={<AmenityDashboard />}
                />
                <Route
                  path={`amenitiesListing`}
                  element={<AmenitiesListing />}
                />
                <Route path={`editAminity`} element={<EditAminity />} />
                <Route
                  path={`addAmenityBanner`}
                  element={<AddAmenityBanner />}
                />
                <Route
                  path={`amenitiesRequests`}
                  element={<AmenitiesRequests />}
                />
                <Route
                  path={`rescheduleServices`}
                  element={<RescheduleServices />}
                />
              </>
            )}
            {/* Maintenance */}
            {arr?.includes("Maintenance") && (
              <>
                <Route
                  path={`maintenanceDashboard`}
                  element={<MaintenanceDashboard />}
                />
                <Route
                  path={`maintenanceListing`}
                  element={<MaintenanceListing />}
                />
                <Route
                  path={`maintenanceRequests`}
                  element={<MaintenanceRequests />}
                />
                <Route path="addMaintenance" element={<AddMaintenance />} />
                <Route path={`editMaintenance`} element={<EditMaintenance />} />
                <Route
                  path={`addMaintenanceBanner`}
                  element={<AddMaintenanceBanner />}
                />
              </>
            )}
            {/* Visitors */}
            {
            arr?.includes("Visitors") && 
            (
              <>
                <Route
                  path={`visitorsDashboard`}
                  element={<VisitorsDashboard />}
                />
                <Route
                  path={`requestsByTenant`}
                  element={<RequestsByTenant />}
                />
                <Route path={`tenantDetails`} element={<TenantDetails />} />
                <Route path={`requestsByAdmin`} element={<RequestsByAdmin />} />
              </>
            )}
            <Route path={`viewNotifications`} element={<AllNotifications />} />
          </Route>
        </Route>
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
};
export default Routing;
