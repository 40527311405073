import React, { useEffect } from "react";
import { Card, Select, Button } from "antd";
import membersIcon from "../../assets/images/icons/total_members@3x.png";
import butler from "../../assets/images/core-images/dummy-img.png";
import plusIcon from "../../assets/images/icons/plus_icon@3x.png";
import TableComponent from "../../components/TableComponent";
import {
  getMemberOfOrganizationAction,
  statusOrganizationMemberAction,
} from "../../store/actions/organization.action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
const { Option } = Select;

const OrganizationProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.state.id;
  const companyMembers = useSelector((state) => state);
  useEffect(() => {
    dispatch(getMemberOfOrganizationAction(0, 10, id));
  }, []);

  const handleChange = (active, member_id) => {
    dispatch(
      statusOrganizationMemberAction(
        active,
        member_id,
        id,
        companyMembers?.organization?.organizationMembers?.members
      )
    );
  };

  const handlerAddNewMember = () => {
    navigate(`/havenly/createNewOrganizationMember`, { state: { id } });
  };
  const columns = [
    {
      title: "No",
      dataIndex: "id",
      responsive: ["xs", "sm", "md"],
      render: (props) => <div className="form-text-font-design">{props}</div>,
    },
    {
      title: "Title",
      dataIndex: "role",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props?.role}</div>
      ),
    },
    {
      title: "Name",
      responsive: ["xs", "sm", "md"],
      align: "left",
      width: 200,
      render: (props) => (
        <div className="form-text-font-design d-flex">
          <img
            alt=""
            src={
              props?.image
                ? `${process.env.REACT_APP_S3_BUCKET_URL}/${props?.image}`
                : butler
            }
            className="butler-img"
          />
          <p style={{ margin: "6px 0px 0px 10px" }}>
            {props?.first_name} {props?.last_name}
          </p>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div>
          <div
            className={props === "1" ? "activeSelector" : "InactiveSelector"}
          >
            <Select
              className="form-text-font-design"
              defaultValue={props}
              onChange={(val) => handleChange(val, row?.id)}
              style={{ width: 120 }}
            >
              <Option value="1">Active</Option>
              <Option value="0">Inactive</Option>
            </Select>{" "}
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className="tabled-div">
      <div className="row">
        <div className="col-lg-8">
          <h1 className="list-heading">Overview</h1>
          <Card
            style={{
              marginTop: 16,
            }}
          >
            <div className="row">
              <div className="col-lg-10">
                <img alt="" src={membersIcon} className='dashboard-widgets-icons'/>
              </div>
              <div className="col-lg-2"></div>
            </div>

            <h6 className="sm-title-card mtt-10">
              {
                companyMembers?.organization?.organizationMembers?.members
                  ?.length
              }
            </h6>
            <span className="desc-grey"> Total Members </span>
          </Card>
        </div>
      </div>
      <div className="mtt-20 d-flex justify-content-between">
        <h1 className="list-heading">Organization Members</h1>
        <Button
          style={{ width: "200px" }}
          onClick={() => handlerAddNewMember()}
          type="primary"
          className="LgBtn build-btn d-flex justify-content-center"
        >
          <img src={plusIcon} className="plus-white-icon" />
          <p className="add-new-building-btn">Add New Member</p>
        </Button>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card className="card">
            <TableComponent
              scroll={{
                x: 700,
              }}
              columns={columns}
              data={companyMembers?.organization?.organizationMembers?.members}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default OrganizationProfile;
