import React, { useEffect, useState } from "react";
import { Avatar, Card, Divider, Dropdown, Menu, Select, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import actionBtn from "../../assets/images/icons/action.png";
import butler from "../../assets/images/core-images/dummy-img.png";
import TableComponent from "../../components/TableComponent";
import Edit from "../../assets/images/icons/super admin - 1x PNG/edit.png";
import toastr from "toastr";
import {
  getAllMemberOfOrganizationAction,
  getMemberOfOrganizationAction,
  searchOrganizationMember,
  statusOrganizationMemberAction,
} from "../../store/actions/organization.action";
import Pagination from "../../components/Pagination";
import View from "../../assets/images/icons/Combined Shape.png";
import { useDispatch, useSelector } from "react-redux";
import { TitleSearch } from "../../components/SearchFilter";
import { set } from "lodash";

const { Option } = Select;

const OrganizationMembers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [pageCount, setPageCount] = useState(1);
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [pageSize, setPageSize] = useState(10);
  const [memberData, setMemberData] = useState();
  const [orgMember, setOrgMember] = useState({});
  const [loading, setLoading] = useState(true);
  const id = useSelector((state) => state?.user?.currentUser?.organization_id);

  const companyMembers = useSelector(
    (state) => state?.organization?.organizationMembers
  );
  const per_Page = companyMembers?.pagination?.per_page;
  const page_count = companyMembers?.pagination?.page;
  const reset = location?.state?.reset;
  const services = useSelector((state) => state?.user?.services);
  let currentService = "";
  if (services) {
    currentService = services?.find(
      (item) => item?.service === "User"
    )?.privilege;
  }

  const handleViewMember = () => {
    navigate(`/havenly/editOrganizationMember/${memberData?.id}`, {
      state: { memberData: memberData },
    });
  };

  const menu = (
    <Menu
      className="editDropdown"
      items={[
        {
          label: (
            
            <div onClick={() => handleViewMember(memberData)} className='listing-edit-dropdown'>
              <img alt="" src={Edit} className="me-2 edit-action" />
              Edit
            </div>
          ),
          key: "0",
        },
      ]}
    />
  );

  const handleCheckAdminStatus = (row) => {
    if (row?.role_id === 2) {
      return true;
    } else if (currentService === "read-write") {
      return false;
    } else {
      return true;
    }
  };
  const handleSearch = async (searchText) => {
    if (searchText) {
      const res = await dispatch(
        searchOrganizationMember(
          id,
          searchText === null ? "null" : searchText,
          pageCount,
          pageSize
        )
      );
      setOrgMember(res.data);
      setLoading(false);
    } else {
      getOrganization();
    }
  };

  const getOrganization = async () => {
    if (reset === true) {
      const db = await dispatch(getMemberOfOrganizationAction(1, 10, id));
      if (db?.statusCode === 200) {
        setPageSize(10);
        setPageCount(1);
        setOrgMember(db?.data);
        setLoading(false);
        navigate(location.pathname, { replace: true });
      } else {
        toastr.error(db?.response?.data?.message);
      }
    } else {
      const db = await dispatch(
        getMemberOfOrganizationAction(
          page_count ? page_count : pageCount,
          per_Page ? per_Page : pageSize,
          id
        )
      );
      if (db?.statusCode === 200) {
        setPageSize(per_Page);
        setPageCount(page_count);
        setOrgMember(db?.data);
        setLoading(false);
      } else {
        toastr.error(db?.response?.data?.message);
      }
    }
  };
  useEffect(() => {
    getOrganization();
  }, []);

  useEffect(() => {
    const TotalOrganization = () => {
      setTotalPageItems(companyMembers?.pagination?.count);
    };
    TotalOrganization();
  }, [companyMembers]);

  const handleChange = (active, member_id) => {
    dispatch(
      statusOrganizationMemberAction(
        active,
        member_id,
        id,
        companyMembers?.members
      )
    );
  };

  const handleLimitRecords = (values) => {
    return values?.slice(0, 15) + (values?.length > 15 ? "..." : "");
  };

  const handlePageChange = async (page, pageSize) => {
    setLoading(true);
    setOrgMember({});
    setPageSize(pageSize);
    setPageCount(page);
    const verifyData = await dispatch(
      getMemberOfOrganizationAction(page, pageSize, id)
    );
    setOrgMember(verifyData?.data);
    setLoading(false);
  };

  const columns = [
    {
      title: "",
      align: "right",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <Avatar
          alt=""
          src={
            props?.image
              ? `${process.env.REACT_APP_S3_BUCKET_URL}/${props?.image}`
              : butler
          }
          className="butler-img"
        />
      ),
    },
    {
      title: "Organization Members",
      align: "left",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <span className="form-text-font-design">
          {handleLimitRecords(`${props?.first_name}${" "}${props?.last_name}`)}
        </span>
      ),
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Email",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">
          {props?.email ? props?.email : "N/A"}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div>
          <div
            className={
              props === "1"
                ? "activeSelector form-text-font-design"
                : "InactiveSelector form-text-font-design"
            }
          >
            <Select
              disabled={handleCheckAdminStatus(row)}
              defaultValue={props}
              onChange={(val) => handleChange(val, row?.id)}
              style={{ width: 120 }}
            >
              <Option value="1">Active</Option>
              <Option value="0">Inactive</Option>
            </Select>{" "}
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      responsive: ["xs", "sm", "md"],
      render: (text, record, index) => (
        <div>
          <Dropdown
            onClick={() => setMemberData(record)}
            overlay={menu}
            trigger={["click"]}
            disabled={currentService === "read-write" ? false : true}
          >
            <a onClick={(e) => e.stopPropagation()} className="action-menu">
              <img alt="" src={actionBtn} className="actions-col-btn" />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];
  return (
    <>
      <Card className="card">
        {loading ? (
          <div className="no-data-found-card">
            <Spin />
          </div>
        ) : (
          <>
            <div className=" tabled-data">
              <div className="requests-card-heading mb-3">
                <h5 className="table-header">Organization Members List</h5>
                <div className="filterButtons">
                  <TitleSearch onSearch={handleSearch} />
                </div>
              </div>
              <TableComponent
                columns={columns}
                data={orgMember?.members}
                scroll={{
                  x: 700,
                }}
                size="default"
              />
            </div>
            <Pagination
              total={totalPageItems}
              defaultPageSize={pageSize}
              defaultCurrent={pageCount}
              onChange={handlePageChange}
            />
          </>
        )}
      </Card>
    </>
  );
};

export default OrganizationMembers;
