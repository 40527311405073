import React, { useState } from "react";
import { Table } from "antd";

const TableComponent = ({ columns, data, size, scroll }) => {
  return (
    <div>
      <Table
        size={size}
        scroll={scroll}
        showHeader={true}
        pagination={false}
        columns={columns}
        dataSource={data ? data : []}
        bordered={false}
      />
    </div>
  );
};
export default TableComponent;
