export const GET_BELLBOY = 'GET_BELLBOY';
export const GET_BELLBOY_ERROR = 'GET_BELLBOY_ERROR';
export const UPDATE_BELLBOY = 'UPDATE_BELLBOY';
export const STATUS_BELLBOY = 'STATUS_BELLBOY';
export const STATUS_BELLBOY_ERROR = 'STATUS_BELLBOY_ERROR';
export const CREATE_BELLBOY = 'CREATE_BELLBOY';
export const CREATE_BELLBOY_ERROR = 'CREATE_BELLBOY_ERROR';
export const GET_BELLBOY_REQUESTS = 'GET_BELLBOY_REQUESTS';
export const GET_BELLBOY_REQUESTS_ERROR = 'GET_BELLBOY_REQUESTS_ERROR';
export const UPDATE_BELLBOY_REQUEST = 'UPDATE_BELLBOY_REQUEST';
export const STATUS_BELLBOY_REQUEST = 'STATUS_BELLBOY_REQUEST';
export const STATUS_BELLBOY_REQUEST_ERROR = 'STATUS_BELLBOY_REQUEST_ERROR';