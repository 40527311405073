import React, { useEffect, useState } from "react";
import { Card } from "antd";
import totalTaskIcon from "../../assets/images/icons/total_task@3x.png";
import inProgressTaskIcon from "../../assets/images/icons/in_progress@3x.png";
import menuIcon from "../../assets/images/icons/menu-card.png";
import ApexCharts from "../../components/ApexChart";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { parcelDashboardAction } from "../../store/actions/parcel.action";
import { unreadNotificationCountOfParcel } from "../../store/actions/notifications.action";

const ParcelDashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location?.state?.id;
  const [res, setRes] = useState();

  const parcel = useSelector((state) => state?.parcel?.parcelDashboard);
  useEffect(() => {
    dispatch(parcelDashboardAction(id));
    dispatch(unreadNotificationCountOfParcel(parcel?.unread_notifications_count))
  }, []);
  return (
    <div className="amenities">
      <div className="tabled-div">
        <div className="row mb-5">
          <h2 className="amenities-heading"> Overview </h2>
          <div className="col-lg-3">
            <Card
              style={{
                marginTop: 16,
              }}
            >
              <div className="row">
                <div className="col-10">
                  <div>
                    <img alt="" src={totalTaskIcon}  className='dashboard-widgets-icons'/>
                  </div>
                </div>
                <div className="col-2" style={{display:'none'}}>
                  <img alt="" src={menuIcon} />
                </div>
              </div>

              <h6 className="sm-title-card my-3">
                {parcel?.parcels_count?.not_completed > 0
                  ? parcel?.parcels_count?.not_completed
                  : 0}
              </h6>
              <span className="desc-grey"> Parcel Received at Reception </span>
            </Card>
          </div>
          <div className="col-lg-3">
            <Card
              style={{
                marginTop: 16,
              }}
            >
              <div className="row">
                <div className="col-10">
                  <div>
                    <img alt="" src={inProgressTaskIcon} className='dashboard-widgets-icons' />
                  </div>
                </div>
                <div className="col-2" style={{display:'none'}}>
                  <img alt="" src={menuIcon} />
                </div>
              </div>

              <h6 className="sm-title-card my-3">
                {parcel?.parcels_count?.completed > 0
                  ? parcel?.parcels_count?.completed
                  : 0}
              </h6>
              <span className="desc-grey">Parcel Received by Tenant </span>
            </Card>
          </div>
        </div>
        <div className="row mtt-20">
          <div className="col-lg-12">
            <h2 className="amenities-heading"> Monthly Overview </h2>
            <Card className="card">
              <div>
                <ApexCharts
                  chartData={parcel?.parcels_count_per_day}
                  labelOne="Parcel Received by Tenant"
                  labelTwo="Parcel Received at Reception"
                  heading="Pending vs In Progress"
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParcelDashboard;
