import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { Button, Card, Col, Divider, Row, Spin } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { organizationAmenitiesList } from "../../store/actions/amenities.action";
import galleryPlaceholder from "../../assets/images/icons/galleryPlaceholder.jpg";
import Pagination from "../../components/Pagination";

const { Meta } = Card;

const AmenitiesListing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [loading, setLoading] = useState(true);
  const reset = location?.state?.reset;
  const id = useSelector((state) => state?.user?.currentUser?.organization_id);
  const getAmenities = useSelector((state) => state?.amenity?.amenitiesListing);
  const per_page = getAmenities?.pagination?.per_page;
  const page_count = getAmenities?.pagination?.page;
  const services = useSelector((state) => state?.user?.services);
  let currentService = "";
  if (services) {
    currentService = services?.find(
      (item) => item?.service === "Amenities"
    )?.privilege;
  }

  const listOfAmenities = async () => {
    if (reset === true) {
      setAmenitiesList([]);
      const amenitiesList = await dispatch(organizationAmenitiesList(1, 10, id));
      setAmenitiesList(amenitiesList.data.amenities);
      setLoading(false);
      navigate(location.pathname, { replace: true });
    } else {
      setAmenitiesList([]);
      const amenitiesList = await dispatch(
        organizationAmenitiesList(
          page_count ? page_count : pageCount,
          per_page ? per_page : perPage,
          id
        )
      );
      setPageCount(page_count);
      setPerPage(per_page);
      setAmenitiesList(amenitiesList.data.amenities);
      setLoading(false);
    }
  };
  const handlePageChange = async (page, pageSize) => {
    setPageCount(page);
    setPerPage(pageSize);
    const res = await dispatch(
      organizationAmenitiesList(page, pageSize, id)
    );
    setAmenitiesList(res.data?.amenities);
    setTotalPageItems(res?.data?.pagination?.count);
  };

  useEffect(() => {
    setTotalPageItems(getAmenities?.pagination?.count);
  }, [getAmenities]);
  useEffect(() => {
    listOfAmenities();
  }, []);
  return loading ? (
    <div className="no-data-found">
      <Spin />
    </div>
  ) : (
    <div className="site-card-wrapper">
      <div className="maintenanceListing">
        <Row gutter={[24, 24]}>
          {amenitiesList?.length > 0 ? (
            amenitiesList?.map((amenity) => {
              return (
                <Col key={amenity.id} flex="0 0 25%">
                  <Card
                    hoverable
                    className="listing-card"
                    cover={
                      <img
                        alt=""
                        src={
                          amenity?.services_images.slice(-1)[0]?.file_name
                            ? `${process.env.REACT_APP_S3_BUCKET_URL}/${
                                amenity?.services_images.slice(-1)[0]?.file_name
                              }`
                            : galleryPlaceholder
                        }
                        className="listing-image"
                      />
                    }
                  >
                    <div className="listing-body">
                      <Meta
                        title={
                          amenity.name.slice(0, 17) +
                          (amenity?.name.length > 17 ? "..." : "")
                        }
                        description={
                          <div>
                            <EnvironmentOutlined
                              style={{ verticalAlign: "middle" }}
                            />
                            <span style={{ paddingLeft: "10px" }}>
                              {amenity.city}
                            </span>
                          </div>
                        }
                      />
                      <p href="#" className="listing-price">
                        <b className="mt-1 ps-2">
                          {amenity.amount !== null
                            ? "AED " + amenity.amount
                            : "AED 0"}
                        </b>
                      </p>
                    </div>
                    <div>
                      <Button
                        onClick={() =>
                          navigate(`/havenly/editAminity`, {
                            state: amenity,
                          })
                        }
                        type="primary"
                        className="LgBtn build-btn mtt-10"
                        style={{
                          width: "220px",
                          justifyContent: "space-evenly",
                        }}
                        disabled={
                          currentService === "read-write" ? false : true
                        }
                      >
                        <p className="add-new-building-btn">Manage</p>
                      </Button>
                    </div>
                  </Card>
                </Col>
              );
            })
          ) : (
            <div className="no-data-found">
              <p className="grey-defTxt">No Amenities Found!</p>
            </div>
          )}
        </Row>
        <div style={{ width: "100%", position: "relative" }}>
          <div className="listing-table-pagination">
            <Pagination
              total={totalPageItems}
              defaultPageSize={perPage}
              defaultCurrent={pageCount}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmenitiesListing;
