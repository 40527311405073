import {
  Avatar,
  Button,
  Card,
  Dropdown,
  Menu,
  Modal,
  Select,
  Spin,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";
import {
  deleteBellboy,
  getAllBellboy,
  statusBellboyAction,
} from "../../store/actions/bellboy.action";
import Edit from "../../assets/images/icons/super admin - 1x PNG/edit.png";
import Delete from "../../assets/images/icons/delete_b@3x.png";
import actionBtn from "../../assets/images/icons/action.png";
import butler from "../../assets/images/core-images/dummy-img.png";
import AlertModal from "../../components/AlertModal";
import toastr from "toastr";
import { set } from "lodash";
const { Option } = Select;

const Bellboy = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [bellboy, setBellboy] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedBellboy, setSelectedBellboy] = useState();
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const reset = location?.state?.reset;
  const id = useSelector((state) => state?.user?.currentUser?.organization_id);
  const getBellboyList = useSelector((state) => state?.bellboy?.bellboy);
  const per_page = getBellboyList?.pagination?.per_page;
  const page_count = getBellboyList?.pagination?.page;
  const services = useSelector((state) => state?.user?.services);
  let currentService = "";
  if (services) {
    currentService = services?.find(
      (item) => item?.service === "Bellboy"
    )?.privilege;
  }
  const handlePageChange = async (page, pageSize) => {
    setPageCount(page);
    setPerPage(pageSize);
    setBellboy([]);
    const res = await dispatch(getAllBellboy(page, pageSize, id));
    if (res.statusCode === 200) {
      setBellboy(res?.data?.bellboys);
    } else {
      toastr.error(res?.response?.data?.message);
    }
  };

  useEffect(() => {
    setTotalPageItems(getBellboyList?.pagination?.count);
  }, [getBellboyList]);

  useEffect(() => {
    getBellboy();
  }, []);

  const getBellboy = async () => {
    if (reset === true) {
      setBellboy([]);
      const res = await dispatch(getAllBellboy(0, 10, id));
      if (res.statusCode === 200) {
        setBellboy(res?.data?.bellboys);
        setLoading(false);
        navigate(location.pathname, { replace: true });
      } else {
        toastr.error(res?.response?.data?.message);
      }
    } else {
      setBellboy([]);
      const res = await dispatch(
        getAllBellboy(
          page_count ? page_count : pageCount,
          per_page ? per_page : perPage,
          id
        )
      );
      if (res.statusCode === 200) {
        setPageCount(page_count);
        setPerPage(per_page);
        setBellboy(res?.data?.bellboys);
        setLoading(false);
      } else {
        toastr.error(res?.response?.data?.message);
      }
    }
  };

  const handleChange = (value, bellboy_id) => {
    dispatch(
      statusBellboyAction(value, id, bellboy_id, getBellboyList?.bellboys)
    );
  };

  const handleEditBellboy = (selected) => {
    navigate(`/havenly/editBellboy`, {
      state: { bellboyDetail: selected },
    });
  };
  const handleDeleteBellboy = (selected) => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setDisable(true);
    const res = await dispatch(deleteBellboy(id, selectedBellboy?.id));
    if (res.statusCode === 200) {
      toastr.success("Bellboy deleted");
    } else {
      toastr.error(res?.response?.data?.message);
    }
    setIsModalVisible(false);
    getBellboy();
    setDisable(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const menu = (
    <Menu
      className="editDropdown"
      items={[
        {
          label: (
            <div onClick={() => handleEditBellboy(selectedBellboy)} className='listing-edit-dropdown'>
              <img alt="" src={Edit} className="me-2 edit-action" />
              Edit
            </div>
          ),
          key: "0",
        },
        {
          label: (
            <div onClick={() => handleDeleteBellboy(selectedBellboy)} className='listing-edit-dropdown'>
              <img
                alt=""
                src={Delete}
                style={{ height: "18px" }}
                className="me-2 mb-1"
              />
              Delete
            </div>
          ),
          key: "1",
        },
      ]}
    />
  );

  const columns = [
    {
      title: "",
      dataIndex: "",
      responsive: ["xs", "sm", "md"],
      align: "right",
      width: 50,
      render: (props, row) => (
        <Avatar
          alt=""
          src={
            row?.image
              ? `${process.env.REACT_APP_S3_BUCKET_URL}/${row?.image}`
              : butler
          }
          className="butler-img"
        />
      ),
    },
    {
      title: "Bellboy Name",
      dataIndex: "name",
      responsive: ["xs", "sm", "md"],
      width: 100,
      align: "left",
      render: (props, row) => (
        <span className="form-text-font-design">
          {props
            ? props?.slice(0, 20) + (props?.length > 20 ? "..." : "")
            : "N/A"}
        </span>
      ),
    },
    {
      title: "Bellboy ID",
      dataIndex: "id",
      responsive: ["xs", "sm", "md"],
      width: 200,
      render: (props) => (
        <div className="form-text-font-design">
          <span>{props ? props : "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["xs", "sm", "md"],
      width: 200,
      render: (props, row) => (
        <div>
          <div
            className={
              row?.active === "1" ? "activeSelector" : "InactiveSelector"
            }
          >
            <Select
              defaultValue={row?.active}
              onChange={(val) => handleChange(val, row?.id)}
              style={{ width: 120 }}
              disabled={currentService === "read-write" ? false : true}
            >
              <Option value="1">Active</Option>
              <Option value="0">Inactive</Option>
            </Select>{" "}
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      responsive: ["xs", "sm", "md"],
      width: 200,
      render: (text, record, index) => (
        <div>
          <Dropdown
            onClick={() => setSelectedBellboy(record)}
            overlay={menu}
            trigger={["click"]}
            disabled={currentService === "read-write" ? false : true}
          >
            <a onClick={(e) => e.stopPropagation()} className="action-menu">
              <img alt="" src={actionBtn} className="actions-col-btn" />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="tabled-div">
        <Card className="card">
          {loading ? (
            <div className="no-data-found-card">
              <Spin />
            </div>
          ) : (
            <>
              <div className=" tabled-data">
                <Table
                  pagination={false}
                  bordered={false}
                  dataSource={bellboy}
                  scroll={{
                    x: 700,
                  }}
                  columns={columns}
                />
              </div>
              <Pagination
                total={totalPageItems}
                defaultPageSize={perPage}
                defaultCurrent={pageCount}
                onChange={handlePageChange}
              />
            </>
          )}
        </Card>
      </div>
      <AlertModal
        disable={disable}
        content="Are you sure you want to delete the Bellboy."
        title={"Delete Bellboy"}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default Bellboy;
