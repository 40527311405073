import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Upload } from "antd";
import AddImageModal from "../../components/AddImageModal";
import addImgRectangle from "../../assets/images/icons/add_img_rectangle.png.png";
import bellboy_icon from "../../assets/images/icons/bellboyname_grey@3x.png";
import { useLocation, useNavigate } from "react-router-dom";
import { editBellboy } from "../../store/actions/bellboy.action";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import Congratulations from "../../components/congratulations";

const EditBellboy = () => {
  const [fileList, setFileList] = useState([]);
  const [imgVisible, setImgVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState({});
  const [disable, setDisable] = useState(false);
  const [orgId, setOrgId] = useState("");
  const [userId, setUserId] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bellboyDetail } = location?.state;
  const [form] = Form.useForm();
  const currentUser = useSelector((state) => state?.user?.currentUser);

  useEffect(() => {
    if (bellboyDetail?.image !== "undefined") {
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: `${process.env.REACT_APP_S3_BUCKET_URL}/${bellboyDetail?.image}`,
        },
      ]);
    }
    form.setFieldsValue({
      name: bellboyDetail?.name,
      arabicName: bellboyDetail?.ar_name,
    });
  }, [location]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handlePopupModal = () => {
    setImgVisible(true);
  };

  const handleDeleteImage = () => {
    setFileList([]);
  };

  const hideImgModal = () => {
    setImgVisible(false);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    navigate("/havenly/bellboy", { state: { id: orgId, reset: false } });
  };

  useEffect(() => {
    setOrgId(currentUser?.organization_id);
    setUserId(currentUser?.id);
  }, [currentUser]);

  const onSubmit = async (values) => {
    setDisable(true);
    const file = fileList.slice(-1);
    let fd = new FormData();
    fd.append("name", values?.name);
    fd.append("ar_name", values?.arabicName);
    if (file.length === 0) {
      fd.append("image", undefined);
    } else {
      file[0]?.uid !== "-1" && fd.append("image", file[0]?.originFileObj);
    }
    fd.append("organization_id", orgId);
    fd.append("created_by", userId);
    const res = await dispatch(editBellboy(fd, bellboyDetail?.id));
    if (res.statusCode === 200) {
      setDisable(false);
      showModal();
    } else if (Object.keys(res)?.length === 0) {
      setDisable(false);
      showModal();
    } else {
      setDisable(false);
      toastr.error(res?.response?.data?.message);
    }
  };

  return (
    <>
      <Card style={{ width: "50%" }}>
        <p className="sm-title-card mtt-10">General Information</p>
        <p className="sub-heading">Add Photo of Bellboy</p>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onSubmit}
        >
          <Form.Item
            name="image"
            rules={[
              {
                required: false,
                message: "Please enter image",
              },
            ]}
          >
            {fileList.length === 0 ? (
              <>
                <div className="rectangle-frame-img">
                  <img
                    alt=""
                    src={addImgRectangle}
                    className="upload-img-box mtt-20"
                    onClick={handlePopupModal}
                  />
                  <p className="img-warn mtt-10">Files Supported: PNG, JPEG</p>
                  <p className="img-warn mtt-10">Image Size (200x200)</p>
                </div>
              </>
            ) : (
              <>
                <Upload
                  disabled={true}
                  listType="picture-card"
                  fileList={fileList.slice(-1)}
                  beforeUpload={() => false}
                  onPreview={handlePopupModal}
                >
                  {fileList.length < 1 && "+"}
                </Upload>
                <p className="img-warn mt-1">Files Supported: PNG, JPEG</p>
                <p className="img-warn mtt-10">Image Size (200x200)</p>
              </>
            )}
          </Form.Item>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please input name!",
              },
            ]}
          >
            <Input
              style={{ width: "80%" }}
              className={"inputfield"}
              maxLength={23}
              prefix={
                <img
                  alt=""
                  src={bellboy_icon}
                  className="site-form-item-icon input-icon-field-twenty"
                />
              }
              placeholder="Bellboy Name"
            />
          </Form.Item>
          <Form.Item
            name="arabicName"
            rules={[
              {
                required: false,
                message: "Please input arabic name!",
              },
            ]}
          >
            <Input
              style={{ width: "80%" }}
              className={"inputfield"}
              maxLength={23}
              suffix={
                <img
                  alt=""
                  src={bellboy_icon}
                  className="site-form-item-icon input-icon-field-twenty"
                />
              }
              lang={"ar"}
              dir={"rtl"}
              placeholder="اسم بيلبوي"
            />
          </Form.Item>
          <Form.Item>
            <div className="d-flex justify-content-end mtt-10">
              <Button
                className="servicesMainBtn"
                htmlType="submit"
                style={{ margin: "0 12px" }}
              >
                {disable ? "Loading..." : "Update"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
      <Congratulations
        visible={visible}
        hideModal={hideModal}
        message={"Your Bellboy has been successfully Updated!"}
      />
      <AddImageModal
        handleDeleteImage={handleDeleteImage}
        file={fileList}
        state={state}
        visible={imgVisible}
        hideModal={hideImgModal}
        onChange={onChange}
      />
    </>
  );
};

export default EditBellboy;
