import { Avatar, Divider, Dropdown, Menu, Select } from "antd";
import React, { useEffect, useState } from "react";
import TableComponent from "../../../components/TableComponent";
import butler from "../../../assets/images/core-images/dummy-img.png";
import actionBtn from "../../../assets/images/icons/action.png";
import Edit from "../../../assets/images/icons/super admin - 1x PNG/edit.png";
import { Option } from "antd/lib/mentions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getAllApprovedTenants,
  getAllAwaitingTenants,
} from "../../../store/actions/tenant.action";
import Pagination from "../../../components/Pagination";

const TenantsListing = (props) => {
  let { tenants, pagination, id, setTenants, tenantList, handleChange, currentService, currentPage, setCurrentPage, perPage, setPerpage } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [tenantId, setTenantId] = useState();

  const handlePageChange = async (page, pageSize) => {
    setTenants([]);
    setCurrentPage(page);
    if (tenantList === "Approved") {
      const verifyData = await dispatch(
        getAllApprovedTenants(page, pageSize, id)
      );
      setTenants(verifyData?.data);
    } else {
      const verifyData = await dispatch(
        getAllAwaitingTenants(page, pageSize, id)
      );
      setTenants(verifyData?.data);
    }
  };

  useEffect(() => {
    setTotalPageItems(pagination?.count);
  }, [props]);

  const handleEditTenant = (id) => {
    navigate(`/havenly/editTenant/${id}`);
  };

  const handleLimitRecords = (values) => {
    return values?.slice(0, 20) + (values?.length > 20 ? "..." : "");
  };

  const menu = (
    <Menu
      className="editDropdown"
      items={[
        {
          label: (
            <div onClick={() => handleEditTenant(tenantId)} className='listing-edit-dropdown'>
              <img alt="" src={Edit} className="me-2 edit-action" />
              Edit
            </div>
          ),
          key: "0",
        },
      ]}
    />
  );

  const columns = [
    {
      dataIndex: "",
      align: "right",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <Avatar
          alt=""
          src={
            props?.image
              ? `${process.env.REACT_APP_S3_BUCKET_URL}/${props?.image}`
              : butler
          }
          className="butler-img"
        />
      ),
    },
    {
      title: "Tenants",
      align: "left",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <span className="form-text-font-design">
          {handleLimitRecords(`${props?.first_name}${" "}${props?.last_name}`)}
        </span>
      ),
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Email",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">
          {props?.email ? props?.email : "N/A"}
        </div>
      ),
    },
    {
      title: "Building No.",
      dataIndex: "building_no",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Apt No.",
      dataIndex: "apartment_no",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div>
          <div className={props === "1" ? "activeSelector" : "pendingSelector"}>
            <Select
              value={props}
              onChange={(val) => handleChange(val, row)}
              style={{ width: 120 }}
              disabled={currentService === "read-write" ? false : true}
            >
              <Option value="1">Approved</Option>
              <Option value="0">Pending</Option>
            </Select>{" "}
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      responsive: ["xs", "sm", "md"],
      render: (record) => (
        <div>
          <Dropdown
            onClick={() => setTenantId(record?.id)}
            overlay={menu}
            trigger={["click"]}
            disabled={currentService === "read-write" ? false : true}
          >
            <a onClick={(e) => e.stopPropagation()} className="action-menu">
              <img alt="" src={actionBtn} className="actions-col-btn" />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="mt-2 tenant-table">
        <TableComponent
          columns={columns}
          data={tenants}
          scroll={{
            x: 700,
          }}
          size="default"
        />
      </div>
      <Pagination
        total={totalPageItems}
        defaultPageSize={perPage}
        defaultCurrent={currentPage}
        onChange={handlePageChange}
      />
    </>
  );
};
export default TenantsListing;
