import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Card,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Select,
  Spin,
} from "antd";
import Pagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import View from "../../assets/images/icons/view@3x.png";
import Delete from "../../assets/images/icons/delete_b@3x.png";
import actionBtn from "../../assets/images/icons/action.png";
import butler from "../../assets/images/core-images/dummy-img.png";
import AlertModal from "../../components/AlertModal";
import toastr from "toastr";
import moment from "moment";
import TableComponent from "../../components/TableComponent";
import {
  deleteParcel,
  getAllParcelRequests,
  searchParcelRequests,
  statusParcelAction,
} from "../../store/actions/parcel.action";
import FilterByDate from "../../components/DateFilter";
import { TitleSearch } from "../../components/SearchFilter";
const { Option } = Select;
const ParcelListing = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedParcel, setSelectedParcel] = useState();
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [parcel, setParcel] = useState([]);
  const [searchDate, setSearchDate] = useState("");
  const navigate = useNavigate();
  const reset = location?.state?.reset;
  const id = useSelector((state) => state?.user?.currentUser?.organization_id);
  const getParcelList = useSelector((state) => state?.parcel?.parcel);
  const services = useSelector((state) => state?.user?.services);
  let currentService = "";
  if (services) {
    currentService = services?.find(
      (item) => item?.service === "Parcels"
    )?.privilege;
  }
  const per_page = getParcelList?.pagination?.per_page;
  const page_count = getParcelList?.pagination?.page;

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSearch = async (searchText) => {
    if (searchText || searchDate) {
      const dateFormat = "YYYY-MM-DD";
      let formatDate = moment(searchDate).format(dateFormat);
      const res = await dispatch(
        searchParcelRequests(
          id,
          searchText,
          formatDate !== "Invalid date" ? formatDate : searchDate
        )
      );
      setParcel(res?.data?.data.parcels);
      setTotalPageItems(res.data?.data?.pagination.count);
    } else {
      getParcel();
    }
  };
  const handlePageChange = async (page, pageSize) => {
    setPageCount(page);
    setPerPage(pageSize);
    setParcel([]);
    const res = await dispatch(getAllParcelRequests(page, pageSize, id));
    if (res.status === 200) {
      setParcel(res?.data?.data.parcels);
    } else {
      toastr.error(res?.response?.data?.message);
    }
  };

  useEffect(() => {
    setTotalPageItems(getParcelList?.pagination?.count);
  }, [getParcelList]);

  useEffect(() => {
    getParcel();
  }, []);

  const getParcel = async () => {
    if (reset === true) {
      setParcel([]);
      const res = await dispatch(getAllParcelRequests(1, 10, id));
      if (res.status === 200) {
        setParcel(res?.data?.data.parcels);
        setLoading(false);
        navigate(location.pathname, { replace: true });
      } else {
        toastr.error(res?.response?.data?.message);
      }
    } else {
      setParcel([]);
      const res = await dispatch(
        getAllParcelRequests(
          page_count ? page_count : pageCount,
          per_page ? per_page : perPage,
          id
        )
      );
      if (res.status === 200) {
        setPageCount(page_count);
        setPerPage(per_page);
        setParcel(res?.data?.data.parcels);
        setLoading(false);
      } else {
        toastr.error(res?.response?.data?.message);
      }
    }
  };

  const handleChange = (value, parcel_id) => {
    dispatch(statusParcelAction(value, parcel_id, getParcelList?.parcels));
  };

  const handleViewParcel = (selected) => {
    navigate(`/havenly/parcelRequestDetail`, {
      state: { id: selectedParcel?.id },
    });
  };
  const handleDeleteParcel = (selected) => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setDisable(true);
    const res = await dispatch(deleteParcel(selectedParcel?.id));
    if (res.statusCode === 200) {
      toastr.success(" Parcel request deleted");
    } else {
      toastr.error(res?.response?.data?.message);
    }
    setIsModalVisible(false);
    getParcel();
    setDisable(false);
  };

  const menu = (
    <Menu
      className="editDropdown"
      items={[
        {
          label: (
            <div onClick={() => handleViewParcel(selectedParcel)} className='listing-edit-dropdown'>
              <img alt="" src={View} className="me-2 input-icon-field-twenty" />
              View
            </div>
          ),
          key: "0",
        },
        {
          label: (
            <div onClick={() => handleDeleteParcel(selectedParcel)} className='listing-edit-dropdown'>
              <img
                alt=""
                src={Delete}
                style={{ height: "18px" }}
                className="me-2 mb-1 input-icon-field-twenty"
              />
              Delete
            </div>
          ),
          key: "1",
        },
      ]}
    />
  );

  const columns = [
    {
      title: "",
      responsive: ["xs", "sm", "md"],
      align: "right",
      render: (props, row) => (
        <>
          {row?.is_read === "0" && <Badge status="processing" />}
          <Avatar
            alt=""
            src={
              row?.tenant_image
                ? `${process.env.REACT_APP_S3_BUCKET_URL}/${row?.tenant_image}`
                : butler
            }
            className="butler-img"
          />
        </>
      ),
    },
    {
      title: "Tenants",
      dataIndex: "tenant_name",
      responsive: ["xs", "sm", "md"],
      align: "left",
      render: (props, row) => (
        <span className="form-text-font-design">{props}</span>
      ),
    },
    {
      title: "Parcels Name",
      dataIndex: "parcel_name",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <span className="form-text-font-design">
          {props
            ? props?.slice(0, 20) + (props?.length > 20 ? "..." : "")
            : "N/A"}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div>
          <span className="form-text-font-design">
            {props ? moment(props).format("DD MMM") : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div>
          <div className={props === "1" ? "activeSelector" : "pendingSelector"}>
            <Select
              defaultValue={props}
              onChange={(val) => handleChange(val, row?.id)}
              style={{ width: 200 }}
              disabled={currentService === "read-write" ? false : true}
            >
              <Option value="1">Received by Tenant</Option>
              <Option value="0">Received at Reception</Option>
            </Select>{" "}
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      responsive: ["xs", "sm", "md"],
      render: (text, record, index) => (
        <div>
          <Dropdown
            onClick={() => setSelectedParcel(record)}
            overlay={menu}
            trigger={["click"]}
            disabled={currentService === "read-write" ? false : true}
          >
            <a onClick={(e) => e.stopPropagation()} className="action-menu">
              <img alt="" src={actionBtn} className="actions-col-btn" />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="tabled-div">
        <Card className="card">
          {loading ? (
            <div className="no-data-found-card">
              <Spin />
            </div>
          ) : (
            <>
              <div className=" tabled-data">
                <div className="requests-card-heading mb-3">
                  <h5 className="table-header">Requests List</h5>
                  <div className="filterButtons">
                    <FilterByDate
                      onSearch={handleSearch}
                      setSearchDate={setSearchDate}
                      searchDate={searchDate}
                      getAllData={getParcel}
                    />
                    <TitleSearch onSearch={handleSearch} className="mx-2" />
                  </div>
                </div>
                <TableComponent
                  scroll={{
                    x: 700,
                  }}
                  columns={columns}
                  data={parcel}
                  size="default"
                />
              </div>
              <Pagination
                total={totalPageItems}
                defaultPageSize={perPage}
                defaultCurrent={pageCount}
                onChange={handlePageChange}
              />
            </>
          )}
        </Card>
      </div>
      <AlertModal
        disable={disable}
        content="Are you Sure! You want to delete the Parcel."
        title={"Delete Parcel"}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default ParcelListing;
