import { CREATE_BELLBOY, CREATE_BELLBOY_ERROR, GET_BELLBOY, GET_BELLBOY_ERROR, GET_BELLBOY_REQUESTS, GET_BELLBOY_REQUESTS_ERROR, STATUS_BELLBOY, STATUS_BELLBOY_ERROR, STATUS_BELLBOY_REQUEST, STATUS_BELLBOY_REQUEST_ERROR, UPDATE_BELLBOY, UPDATE_BELLBOY_REQUEST } from "../types/bellboy.types";
  
  const initialState = {
    bellboy : [],
    loading: true,
    isData: false,
  };
  
  function bellboyReducer(state = initialState, action) {
    switch (action.type) {
      case GET_BELLBOY:
        return {
          ...state,
          bellboy: action.payload,
          isData: true,
          loading: false,
        };
        case UPDATE_BELLBOY :
            return {
              ...state,
              bellboy: action.payload,
              isData: true,
              loading: false,
            };
      case GET_BELLBOY_ERROR:
        return {
          loading: false,
          error: action.payload,
        };
        case STATUS_BELLBOY : {
            return {
              ...state,
              loading: false,
              isData: true,
            };
          }
          case STATUS_BELLBOY_ERROR: {
            return {
              loading: false,
              error: action.payload,
            };
          }
          case CREATE_BELLBOY:
            return {
              ...state,
              loading: false,
              isData: true,
            }
          case CREATE_BELLBOY_ERROR:
            return {
              loading: false,
              error: action.payload,
            }
            case GET_BELLBOY_REQUESTS: 
            return {
              ...state,
              bellboyRequests: action.payload,
              isData: true,
              loading: false,
            }
            case GET_BELLBOY_REQUESTS_ERROR: 
            return {
              loading: false,
              error: action.payload,
            }
            case UPDATE_BELLBOY_REQUEST :
            return {
              ...state,
              bellboyRequests: action.payload,
              isData: true,
              loading: false,
            };
            case STATUS_BELLBOY_REQUEST : {
              return {
                ...state,
                loading: false,
                isData: true,
              };
            }
            case STATUS_BELLBOY_REQUEST_ERROR: {
              return {
                loading: false,
                error: action.payload,
              };
            }
      default:
        return state;
    }
  }
  export default bellboyReducer;
  