import { Button, Form, Modal } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GeneralInformation from "./GeneralInformation";
import TimeAvailability from "./TimeAvailability";
import { useDispatch } from "react-redux";
import toastr from "toastr";
import { useEffect } from "react";
import Upload from "antd/lib/upload/Upload";
import {
  deleteAmenityServiceImage,
  editOrganizationAmenity,
} from "../../../store/actions/amenities.action";
import addImgRectangle from "../../../assets/images/icons/add_img_rectangle.png.png";
import AddImageModal from "../../../components/AddImageModal";
import Congratulations from "../../../components/congratulations";

const EditAminity = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [amenityName, setAmenityName] = useState(location?.state?.name);
  const [arabicAmenityName, setArabicAmenityName] = useState(
    location?.state?.name_ar
  );
  const [locality, setLocality] = useState(location?.state?.city);
  const [arabicLocality, setArabicLocality] = useState(
    location?.state?.city_ar
  );
  const [address, setAddress] = useState(location?.state?.address);
  const [amount, setAmount] = useState(location?.state?.amount);
  const [arabicAmount, setArabicAmount] = useState(location?.state?.amount_ar);
  const [capacity, setCapacity] = useState(location?.state?.capacity);
  const [selectedDays, setSelectedDays] = useState([
    ...new Set(location?.state.open_days),
  ]);
  const [selectedTags, setSelectedTags] = useState(location?.state.open_slots);
  const [description, setDescription] = useState(location?.state?.description);
  const [arabicDescription, setArabicDescription] = useState(
    location?.state?.description_ar
  );
  const [status, setStatus] = useState(location?.state?.status);
  const [slots, setSlots] = useState(location?.state?.open_slots_per_user);
  const [fileList, setFileList] = useState(location?.state?.services_images);
  const [orgId, setOrgId] = useState(location?.state?.organization_id);
  const [callingApi, setCallingApi] = useState(false);
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [imgVisible, setImgVisible] = useState(false);
  const [state, setState] = useState({});

  const [form] = Form.useForm();
  form.setFieldsValue({
    name: amenityName,
    name_ar: arabicAmenityName,
    address: address,
    locality: locality,
    city_ar: arabicLocality,
    amount: amount,
    amount_ar: arabicAmount,
    status: status,
    capacity: capacity,
    description: description,
    description_ar: arabicDescription,
    image: fileList,
    open_days: selectedDays,
    open_shifts: selectedTags,
    open_slots_per_user: slots,
    organization_id: orgId,
  });
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const hideModal = () => {
    setVisible(false);
    navigate(`/havenly/amenitiesListing`, { state: { id: orgId } });
  };

  const showModal = () => {
    setVisible(true);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const handleDeleteImage = () => {
    setFileList([]);
  };

  const handlePopupModal = () => {
    setImgVisible(true);
  };

  const hideImgModal = () => {
    setImgVisible(false);
  };

  const setImageUrl = async () => {
    location?.state?.services_images.slice(-1)[0]?.file_name !== undefined &&
      setFileList([
        {
          uid: location?.state?.services_images.slice(-1)[0]?.uid,
          name: "image.png",
          status: "done",
          url: `${process.env.REACT_APP_S3_BUCKET_URL}/${
            location?.state?.services_images.slice(-1)[0]?.file_name
          }`,
        },
      ]);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setImageUrl();
    location?.state.open_slots.forEach((item) => {
      selectedShifts.push(item.shift);
    });
    setSelectedShifts([...new Set(selectedShifts)]);
  }, []);

  const handleCancel = () => setPreviewVisible(false);

  const onPreview = async (file) => {
    if (file?.url) {
      setPreviewImage(file.url);
      setPreviewVisible(true);
      setPreviewTitle(file.name);
    } else {
      setPreviewImage(file.thumbUrl);
      setPreviewVisible(true);
      setPreviewTitle(file.name);
    }
  };

  const handleImageDelete = async () => {
    const res = await dispatch(
      deleteAmenityServiceImage({
        image_id: location?.state?.services_images[0]?.id,
        amenity_service_id: location?.state?.id,
      })
    );
    if (res.statusCode === 200) {
      toastr.success("Image Deleted Successfully.");
    }
  };
  const onSubmit = async (values) => {
    setCallingApi(true);
    let fd = new FormData();
    fd.append("name", amenityName);
    fd.append("name_ar", arabicAmenityName);
    fd.append("address", address);
    fd.append("city", locality);
    fd.append("city_ar", arabicLocality);
    fd.append("amount", amount);
    fd.append("amount_ar", arabicAmount);
    fd.append("status", status);
    fd.append("latitude", 21345452);
    fd.append("longitude", 45324325);
    fd.append("capacity", capacity);
    fd.append("description", description);
    fd.append("description_ar", arabicDescription);
    fd.append("images", fileList?.slice(-1)[0]?.originFileObj);
    fd.append("open_days", JSON.stringify(selectedDays));
    fd.append("open_shifts", JSON.stringify(selectedTags));
    fd.append("open_slots_per_user", slots);
    fd.append("notes", "notes");
    fd.append("guidelines_instructions", "guidelines");
    fd.append("amenity_service_id", location?.state?.id);
    fd.append("created_by", orgId);
    fd.append("organization_id", orgId);
    const res = await dispatch(editOrganizationAmenity(fd));
    if (res.statusCode === 200) {
      setCallingApi(false);
      showModal();
    } else if (Object.keys(res)?.length === 0) {
      setCallingApi(false);
      showModal();
    } else {
      setCallingApi(false);
      toastr.error(res?.response?.data?.message);
    }
  };
  return (
    <div className="steps-content" style={{ width: "75%" }}>
      <Form
        name="edit_Amenity"
        className="amenity-form"
        form={form}
        onFinish={onSubmit}
      >
        <div className="row mtt-20">
          <GeneralInformation
            setAmenityName={setAmenityName}
            amenityName={amenityName}
            setArabicAmenityName={setArabicAmenityName}
            arabicAmenityName={arabicAmenityName}
            setLocality={setLocality}
            locality={locality}
            setArabicLocality={setArabicLocality}
            arabicLocality={arabicLocality}
            setAddress={setAddress}
            address={address}
            setAmount={setAmount}
            amount={amount}
            setArabicAmount={setArabicAmount}
            arabicAmount={arabicAmount}
            setCapacity={setCapacity}
            capacity={capacity}
            setDescription={setDescription}
            description={description}
            setArabicDescription={setArabicDescription}
            arabicDescription={arabicDescription}
            setStatus={setStatus}
            status={status}
            editForm={true}
          />
        </div>
        <div className="photosAndFacilities">
          <h6 className="sm-title-card mtt-10"> Photos & Facilities </h6>
          <span className="sub-heading mtt-10">Add an image</span>
          <Form.Item
            name="image"
            rules={[
              {
                required: true,
                message: "Please enter the image!",
              },
            ]}
            className="mtt-20"
          >
            {fileList.length === 0 ? (
              <>
                <div className="rectangle-frame-img">
                  <img
                    alt=""
                    src={addImgRectangle}
                    className="upload-img-box"
                    onClick={handlePopupModal}
                  />
                  <p className="img-warn mtt-10">Files Supported: PNG, JPEG</p>
                  <p className="img-warn mtt-10">Image Size (200x200)</p>
                </div>
              </>
            ) : (
              <>
                <Upload
                  disabled={true}
                  listType="picture-card"
                  fileList={fileList.slice(-1)}
                  beforeUpload={() => false}
                  onPreview={handlePopupModal}
                >
                  {fileList.length < 1 && "+"}
                </Upload>
                <p className="img-warn mt-1">Files Supported: PNG, JPEG</p>
                <p className="img-warn mtt-10">Image Size (200x200)</p>
              </>
            )}
          </Form.Item>
          <div className="Services-Input">
            <TimeAvailability
              setSelectedDays={setSelectedDays}
              selectedDays={selectedDays}
              setSlots={setSlots}
              slots={slots}
              selectedShifts={selectedShifts}
              setSelectedShifts={setSelectedShifts}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              edit={true}
              service_id={location?.state?.id}
            />
          </div>

          <Form.Item>
            <div className="steps-action-one">
              <Button
                className="servicesBackBtn"
                onClick={() =>
                  navigate(`/havenly/amenitiesListing`, {
                    state: { id: orgId },
                  })
                }
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="servicesMainBtn"
                htmlType="submit"
                style={{ margin: "0 8px" }}
                disabled={callingApi}
              >
                {callingApi ? "Loading..." : "Update"}
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
      <Congratulations
        visible={visible}
        hideModal={hideModal}
        message={"Service updated successfully"}
      />
      <AddImageModal
        handleDeleteImage={handleDeleteImage}
        file={fileList}
        state={state}
        onChange={onChange}
        visible={imgVisible}
        hideModal={hideImgModal}
      />
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" src={previewImage} style={{ width: "472px" }} />
      </Modal>
    </div>
  );
};

export default EditAminity;
