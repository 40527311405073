import axios from "axios";
import { responseApi } from "../../helpers/helper";
import {
  GET_BELLBOY,
  GET_BELLBOY_ERROR,
  UPDATE_BELLBOY,
  STATUS_BELLBOY,
  STATUS_BELLBOY_ERROR,
  CREATE_BELLBOY,
  CREATE_BELLBOY_ERROR,
  GET_BELLBOY_REQUESTS,
  GET_BELLBOY_REQUESTS_ERROR,
  UPDATE_BELLBOY_REQUEST,
  STATUS_BELLBOY_REQUEST,
  STATUS_BELLBOY_REQUEST_ERROR,
} from "../types/bellboy.types";

export const getAllBellboy =
  (current, peritem, organization_id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/bellboy/api/org-admin/bellboy/list?page=${current}&per_page=${peritem}&sort_by=created_at&sort_order=DESC`,
        { organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: GET_BELLBOY,
        payload: res?.data?.data,
      });
      return res?.data;
    } catch (error) {
      dispatch({
        type: GET_BELLBOY_ERROR,
        payload: error,
      });
      return error;
    }
  };
export const searchBellboy =
  (id, searchedVal, searchedDate) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/bellboy/api/org-admin/bellboy/search-requests`,
        { organization_id: id, search: searchedVal, date: searchedDate },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );

      return res.data?.data;
    } catch (error) {
      return error;
    }
  };
export const statusBellboyAction =
  (active, organization_id, bellboy_id, bellboy) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/bellboy/api/org-admin/bellboy/change-status`,
        { id: bellboy_id, organization_id, active },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const state = await getState();
      const duplicateBellboy = [...bellboy];
      const index = await duplicateBellboy.findIndex(
        ({ id }) => id === bellboy_id
      );
      duplicateBellboy[index].active = active;
      const updateBellboy = {
        bellboys: duplicateBellboy,
        pagination: state?.bellboy?.bellboy?.pagination,
      };


        dispatch({
          type: UPDATE_BELLBOY,
          payload: updateBellboy,
        });
        dispatch({
          type: STATUS_BELLBOY,
          payload: res?.data,
        });
    } catch (error) {
      dispatch({
        type: STATUS_BELLBOY_ERROR,
        payload: error,
      });
    }
  };

export const createBellboyAction = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/bellboy/api/org-admin/bellboy/create`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
      dispatch({
        type: CREATE_BELLBOY,
        payload: res?.data,
      });
      return res?.data;
  } catch (error) {
    dispatch({
      type: CREATE_BELLBOY_ERROR,
      payload: error,
    });
    return error;
  }
};

export const editBellboy = (values, id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/bellboy/api/org-admin/bellboy/updateProfile/${id}`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
      return res?.data;
  } catch (error) {
    return error;
  }
};

export const deleteBellboy = (organization_id, id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/bellboy/api/org-admin/bellboy/deleteProfile/${id}`,
      { organization_id: organization_id },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getAllBellboyRequests =
  (current, peritem, organization_id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/bellboy/api/org-admin/bellboy/show-requests?page=${current}&per_page=${peritem}&sort_by=created_at&sort_order=DESC`,
        { organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: GET_BELLBOY_REQUESTS,
        payload: res?.data?.data,
      });
      return res?.data?.data;
    } catch (error) {
      dispatch({
        type: GET_BELLBOY_REQUESTS_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const statusBellboyRequestAction =
  (status, request_id, organization_id, requests) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/bellboy/api/org-admin/bellboy/updateRequests/${request_id}`,
        { organization_id, status },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const state = await getState();
      const duplicateRequest = [...requests];
      const index = await duplicateRequest.findIndex(
        ({ id }) => id === request_id
      );
      duplicateRequest[index].status = status;
      const updateRequests = {
        bellboy_requests: duplicateRequest,
        pagination: state?.bellboy?.bellboyRequests.pagination,
      };
        dispatch({
          type: UPDATE_BELLBOY_REQUEST,
          payload: updateRequests,
        });
        dispatch({
          type: STATUS_BELLBOY_REQUEST,
          payload: res?.data,
        });
      
    } catch (error) {
      dispatch({
        type: STATUS_BELLBOY_REQUEST_ERROR,
        payload: error,
      });
    }
  };

export const deleteBellboyRequest =
  (organization_id, id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/bellboy/api/org-admin/bellboy/deleteRequests/${id}`,
        { organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      return res?.data;
    } catch (error) {
      return error;
    }
  };

export const addBellboyGuideline = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/bellboy/api/org-admin/bellboy/create-guidelines`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      } 
    );
      return res?.data;
  } catch (error) {
    return error;
  }
};

export const getBellboyGuideline = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/bellboy/api/org-admin/bellboy/get-guidelines`,
      { organization_id: payload },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};
