import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Upload, Card, Modal } from "antd";
import toastr from "toastr";
import "react-phone-number-input/style.css";
import Vector from "../../../assets/images/icons/organization_White-3x.png";
import PreviewMessage from "../../../assets/images/icons/message-white-3x.png";
import countryList from "react-select-country-list";
import Congratulations from "../../../components/congratulations";
import { useDispatch } from "react-redux";
import {
  deleteOrganizationBanner,
  editOrganization,
  getOrganizationBanner,
  getSingleOrganization,
  uploadOrganizationBanner,
} from "../../../store/actions/organization.action";
import Inputfield from "../../../components/Inputfield";
import addImgRectangle from "../../../assets/images/icons/add_img_rectangle.png.png";
import AddImageModal from "../../../components/AddImageModal";
import Phone from "../../../components/PhoneInput";
import Country from "../../../components/CountryDropdown";

const OrganizationSetting = () => {
  const location = useLocation();
  const id = location?.state?.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [organizationCountry, setOrganizationCountry] = useState("");
  const [bannerFileList, setBannerFileList] = useState([]);
  const [imgVisible, setImgVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [selected, setSelected] = useState();
  const [state, setState] = useState({});
  const [form] = Form.useForm();
  const [isDelete, setIsDelete] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [active, setActive] = useState(false);
  const phone = "";

  const setImageUrl = async () => {
    const db = await dispatch(getSingleOrganization(id));
    setOrganizationCountry(db?.data?.organization?.country);
    setFileList([
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: `${process.env.REACT_APP_S3_BUCKET_URL}/${db?.data?.organization?.image}`,
      },
    ]);
    const country = await countryList().getValue(
      db?.data?.organization?.country
    );
    setSelected(country);
    form.setFieldsValue({
      name: db?.data?.organization?.name,
      email: db?.data?.organization?.email,
      phone: db?.data?.organization?.phone,
    });
  };

  const getOrgBanner = async () => {
    const banner = await dispatch(getOrganizationBanner(id));
    if (banner.statusCode === 200) {
      const file = await banner?.data?.banner;
      file !== null &&
        setBannerFileList([
          {
            uid: file?.id,
            id: file?.id,
            name: "image.png",
            images: file?.images,
            status: "done",
            url: `${process.env.REACT_APP_S3_BUCKET_URL}/${file?.images}`,
          },
        ]);
    } else {
      toastr.error(banner?.response?.data?.message);
    }
  };

  useEffect(() => {
    setImageUrl();
    getOrgBanner();
  }, []);

  const handleFlagCountry = (code) => {
    setSelected(code);
    const countryLabel = countryList().getLabel(code);
    setOrganizationCountry(countryLabel);
  };

  const onSubmit = async (values) => {
    setActive(true);
    let bannerFd = new FormData();
    bannerFd.append("images", bannerFileList[0]?.originFileObj);
    bannerFd.append("organization_id", id);
    bannerFd.append("is_delete", isDelete ? 1 : 0);
    const bannerRes = await dispatch(uploadOrganizationBanner(bannerFd));
    if (bannerRes.statusCode === 200) {
      toastr.success("Banner Updated");
    } else {
      setActive(false);
      toastr.error(bannerRes?.response?.data?.message);
    }
    let fd = new FormData();
    fd.append("name", values?.name);
    fd.append("phone", values?.phone);
    fd.append("country", organizationCountry);
    fd.append("image", fileList?.slice(-1)[0]?.originFileObj);
    fd.append("organization_id", id);
    const res = await dispatch(editOrganization(fd));
    if (res.statusCode === 200) {
      setActive(false);
      showModal();
    } else {
      setActive(false);
      toastr.error(res?.response?.data?.message);
    }
  };

  const handleDeleteBanner = async () => {
    setIsDelete(true);
    setBannerFileList([]);
  };

  const showModal = () => {
    setVisible(true);
  };
  const hideModal = () => {
    setVisible(false);
    navigate(`/havenly/organizationMembersListing`, { state: { id } });
  };

  const handlePopupModal = () => {
    setImgVisible(true);
  };

  const hideImgModal = () => {
    setImgVisible(false);
  };

  const handleDeleteImage = () => {
    setFileList([]);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const onChangeBanner = async ({ fileList: newFileList }) => {
    setBannerFileList(newFileList);
  };

  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.url);
    }
    setPreviewImage(file.url);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };

  const handleCancel = () => setPreviewVisible(false);

  const settingValuesInForm = (key, value) => {
    form.setFieldsValue({
      [key]: value,
    });
  };

  const FormInputs = () => {
    return (
      <div className="EditForm">
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please input organization name!",
            },
          ]}
        >
          <Inputfield
            addonBefore={
              <img
                alt=""
                src={Vector}
                className="site-form-item-icon input-icon-field-twenty"
              />
            }
            placeholder="Enter your Organization Name"
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input organization email!",
            },
          ]}
        >
          <Inputfield
            disabled={true}
            addonBefore={
              <img
                alt=""
                src={PreviewMessage}
                className="preview-icons input-icon-field"
              />
            }
            placeholder="Enter your Organization Name"
          />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
          ]}
        >
          <Phone
            disabled={false}
            value={phone}
            onChange={(phone) => settingValuesInForm("phone", phone)}
          />
        </Form.Item>
        <Form.Item
          name="country"
          rules={[
            {
              required: false,
              message: "Please input country!",
            },
          ]}
        >
          <Country
            selected={selected}
            onSelect={(code) => handleFlagCountry(code)}
            disabled={false}
          />
        </Form.Item>
      </div>
    );
  };
  return (
    <div>
      <Card style={{ width: "70%", marginLeft: "20px" }}>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
            country: organizationCountry,
          }}
          form={form}
          onFinish={onSubmit}
        >
          <div className="row mtt-20">
            <div className="col-lg-7">{FormInputs()}</div>
            <div className="col-lg-1"></div>
            <div className="col-lg-3 preview-only">
              <Form.Item name="organizationImage">
                {fileList.length === 0 ? (
                  <>
                    <div className="rectangle-frame-img">
                      <img
                        alt=""
                        src={addImgRectangle}
                        className="upload-img-box"
                        onClick={handlePopupModal}
                      />
                      <p className="img-warn mtt-10">
                        Files Supported: PNG, JPEG
                      </p>
                      <p className="img-warn mtt-10">Image Size (200x200)</p>
                    </div>
                  </>
                ) : (
                  <>
                    <Upload
                      disabled={true}
                      listType="picture-card"
                      fileList={fileList.slice(-1)}
                      beforeUpload={() => false}
                      onPreview={handlePopupModal}
                    >
                      {fileList.length < 1 && "+"}
                    </Upload>
                    <p className="img-warn mt-1">Files Supported: PNG, JPEG</p>
                    <p className="img-warn mtt-10">Image Size (200x200)</p>
                  </>
                )}
              </Form.Item>
            </div>
          </div>
          <div className="organization-setting-banner">
            <h6 className="sm-title-card">Organization Banners</h6>
            <p className="sub-heading  mtt-20">Add your Photo</p>
            <Form.Item name="organizationImage">
              <Upload
                listType="picture-card"
                fileList={bannerFileList}
                onChange={onChangeBanner}
                beforeUpload={() => false}
                onRemove={() => handleDeleteBanner()}
                onPreview={onPreview}
              >
                {bannerFileList.length < 1 && (
                  <div style={{ color: "#1D4599" }}>+</div>
                )}
              </Upload>
              <div className="mb-1">
                <p className="img-warn mt-1">Files Supported: PNG, JPEG</p>
                <p className="img-warn mtt-10">Image Size (330x120)</p>
              </div>
            </Form.Item>
          </div>
          <Form.Item>
            <div className="mtt-20 align-right">
              <Button
                type="primary"
                className="servicesMainBtn organization-setting-submit"
                htmlType="submit"
                disabled={active}
              >
                {active ? "Loading..." : "Confirm"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
      <AddImageModal
        handleDeleteImage={handleDeleteImage}
        file={fileList}
        state={state}
        onChange={onChange}
        visible={imgVisible}
        hideModal={hideImgModal}
      />
      <Congratulations
        visible={visible}
        hideModal={hideModal}
        message={"Congrats! You updated the organization setting successfully."}
      />
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        className="img-preview-modal"
      >
        <img alt="example" src={previewImage} style={{ width: "472px" }} />
      </Modal>
    </div>
  );
};

export default OrganizationSetting;
