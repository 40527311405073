export const GET_APPROVED_TENANT = 'GET_APPROVED_TENANT';
export const GET_APPROVED_TENANT_ERROR = 'GET_APPROVED_TENANT_ERROR';
export const GET_AWAITING_TENANT = 'GET_AWAITING_TENANT';
export const GET_AWAITING_TENANT_ERROR = 'GET_AWAITING_TENANT_ERROR';
export const UPDATE_ORGANIZATION_TENANT = 'UPDATE_ORGANIZATION_TENANT';
export const STATUS_ORGANIZATION_TENANT = 'STATUS_ORGANIZATION_TENANT';
export const STATUS_ORGANIZATION_TENANT_ERROR = 'STATUS_ORGANIZATION_TENANT_ERROR';
export const CREATE_TENANT = 'CREATE_TENANT';
export const CREATE_TENANT_ERROR = 'CREATE_TENANT_ERROR';
export const GET_All_TENANTS = 'GET_All_TENANTS';
export const GET_ALL_TENANTS_ERROR = 'GET_ALL_TENANTS_ERROR';
