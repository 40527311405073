import {
  ADD_VISITORS,
  ADD_VISITORS_ERROR,
  VISITORS_DASHBOARD,
  VISITORS_DASHBOARD_ERROR,
  REQUESTS_By_TENANT,
  REQUESTS_By_TENANT_ERROR,
  REQUESTS_By_ADMIN,
  REQUESTS_By_ADMIN_ERROR,
  STATUS_GUEST_MEMBER,
  STATUS_GUEST_MEMBER_ERROR,
  UPDATE_GUEST_MEMBER,
} from "../types/visitors.types";
import axios from "axios";
import { responseApi } from "../../helpers/helper";
export const visitorsDashboardAction = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/visitors/api/cms/visitors/dashboard`,
      { organization_id: payload },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    dispatch({
      type: VISITORS_DASHBOARD,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: VISITORS_DASHBOARD_ERROR,
      payload: error,
    });
    return error;
  }
};

export const RequestsByTenantAction =
  (current, peritem, id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/visitor/api/cms/get-visit-requests?page=${current}&per_page=${peritem}&sort_by=created_at&sort_order=DESC`,
        { organization_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const requestsByTenant = {
        requests: res?.data?.data?.visitors,
        pagination: res?.data?.data?.pagination,
        unread_notifications_count: res?.data?.data?.unread_notifications_count,
      };
      dispatch({
        type: REQUESTS_By_TENANT,
        payload: requestsByTenant,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: REQUESTS_By_TENANT_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const searchRequestsByTenantAction =
  (id, searchedVal, searchedDate) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/visitor/api/cms/search-visit-requests`,
        { organization_id: id, search: searchedVal, date: searchedDate },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const requestsByTenant = {
        requests: res?.data?.data?.visitors,
        pagination: res?.data?.data?.pagination,
      };
      dispatch({
        type: REQUESTS_By_TENANT,
        payload: requestsByTenant,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: REQUESTS_By_TENANT_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const RequestsByAdminAction =
  (current, peritem, id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/visitor/api/cms/get-guest-requests?page=${current}&per_page=${peritem}&sort_by=created_at&sort_order=DESC`,
        { organization_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const requestsByAdmin = {
        requests: res?.data?.data?.guests,
        pagination: res?.data?.data?.pagination,
        unread_notifications_count: res?.data?.data?.unread_notifications_count,
      };
      dispatch({
        type: REQUESTS_By_ADMIN,
        payload: requestsByAdmin,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: REQUESTS_By_ADMIN_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const searchRequestsByAdminAction =
  (id, searchedVal, searchedDate) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/visitor/api/cms/search-guest-requests`,
        { organization_id: id, search: searchedVal, date: searchedDate },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const requestsByAdmin = {
        requests: res?.data?.data?.visitors,
        pagination: res?.data?.data?.pagination,
      };
      dispatch({
        type: REQUESTS_By_ADMIN,
        payload: requestsByAdmin,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: REQUESTS_By_ADMIN_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const AddNewVisitorByAdmin = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/visitor/api/cms/unexpected-guest-request`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
      dispatch({
        type: ADD_VISITORS,
        payload: res?.data,
      });
      return res?.data;
  } catch (error) {
    dispatch({
      type: ADD_VISITORS_ERROR,
      payload: error,
    });
    return error;
  }
};

export const addVisitorGuideline = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/visitor/api/cms/create-guidelines`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
      return res?.data;
  } catch (error) {
    return error;
  }
};

export const getAllVisitorGuidelines = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/visitor/api/common/get-guidelines`,
      { organization_id: payload },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};
