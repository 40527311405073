import { EnvironmentOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form } from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-day-picker/dist/style.css";
import { format, getDay } from "date-fns";
import { DayPicker, Row } from "react-day-picker";
import { useDispatch, useSelector } from "react-redux/es/exports";
import galleryPlaceholder from "../assets/images/icons/galleryPlaceholder.jpg";
import {
  amenityReschedule,
  checkOpenDaysAndSlotsInAmenity,
  getAllTimeSlots,
} from "../store/actions/amenities.action";
import {
  checkOpenDaysAndSlots,
  maintenanceReschedule,
} from "../store/actions/maintenance.action";
import RescheduleMain from "./RescheduleMain";
import toastr from "toastr";
import { fullDayName } from "../services/DateAndTime";
import { differenceInCalendarDays } from "date-fns";

function isPastDate(date) {
  return differenceInCalendarDays(date, new Date()) < 0;
}

function OnlyFutureRow(props) {
  const isPastRow = props.dates.every(isPastDate);
  if (isPastRow) return <></>;
  return <Row {...props} />;
}
const RescheduleServices = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const timeSlots = useSelector((state) => state?.amenity?.timeSlots?.slots);
  const [serviceType, setServiceType] = useState(location.state.type);
  const [serviceDetails, setServiceDetails] = useState(
    location?.state?.serviceDetails
  );
  const [selected, setSelected] = useState(
    format(new Date(serviceDetails?.date), "yyyy-MM-dd")
  );
  const [month, setMonth] = useState(new Date(selected));
  const [selectedTags, setSelectedTags] = useState();
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [serviceAvailability, setServiceAvailability] = useState([]);
  const today = new Date();
  form.setFieldsValue({
    date: selected,
    selectedTags: selectedTags,
  });
  const handleShiftChange = (tag, checked) => {
    const nextSelectedShifts = checked
      ? [...selectedShifts, tag]
      : selectedShifts.filter((t) => t !== tag);
    setSelectedShifts(nextSelectedShifts);
    const nextSelectedTags = selectedTags.filter((t) => t.shift !== tag);
    setSelectedTags(nextSelectedTags);
  };
  const handleSlotChange = (tag, checked) => {
    if (
      final_shiftArr.find((item) => item === tag.shift) &&
      serviceAvailability?.service_availability?.some(
        (item) => item?.id === tag.id
      )
    ) {
      const nextSelectedTags =
        checked && tag.id ? [tag] : selectedTags.filter((t) => t.id !== tag.id);
      setSelectedTags(nextSelectedTags);
    }
  };
  const checkAvailableSlots = async () => {
    const res = await dispatch(
      serviceType === "maintenance"
        ? checkOpenDaysAndSlots({
            tenant_id: serviceDetails?.tenant_id,
            maintenance_service_id: serviceDetails.maintenance_service_id,
            date: selected,
          })
        : checkOpenDaysAndSlotsInAmenity({
            tenant_id: serviceDetails?.tenant_id,
            amenity_service_id: serviceDetails.amenity_service_id,
            date: selected,
          })
    );
    if (res?.statusCode === 200) {
      setServiceAvailability(res?.data);
    }
  };
  useEffect(() => {
    dispatch(getAllTimeSlots());
    setSelectedTags(location?.state?.serviceDetails?.services_bookings_slots);
    location?.state?.serviceDetails?.services_bookings_slots.forEach((item) => {
      selectedShifts.push(item.shift);
    });
    setSelectedShifts([...new Set(selectedShifts)]);
  }, []);
  useEffect(() => {
    checkAvailableSlots();
  }, [selected]);
  const onSubmit = async () => {
    const payload = {
      booking_id: serviceDetails?.id,
      tenant_id: serviceDetails?.tenant_id,
      amenity_service_id: serviceDetails.amenity_service_id,
      organization_id: serviceDetails?.organization_id,
      date: format(new Date(selected), "yyyy-MM-dd"),
      booked_slots: selectedTags,
    };
    const maintenancePayload = {
      booking_id: serviceDetails?.id,
      tenant_id: serviceDetails?.tenant_id,
      maintenance_service_id: serviceDetails.maintenance_service_id,
      organization_id: serviceDetails?.organization_id,
      date: format(new Date(selected), "yyyy-MM-dd"),
      payment_method: serviceDetails?.payment_method,
      booked_slots: selectedTags,
    };
    const res = await dispatch(
      serviceType === "maintenance"
        ? maintenanceReschedule(maintenancePayload)
        : amenityReschedule(payload)
    );
    if (res.statusCode === 200 || Object.keys(res)?.length === 0) {
      if (serviceType === "amenity") {
        navigate(`/havenly/amenitiesRequests`, {
          state: { id: serviceDetails?.organization_id },
        });
      } else {
        navigate(`/havenly/maintenanceRequests`, {
          state: { id: serviceDetails?.organization_id },
        });
      }
    } else {
      toastr.error(res?.response?.data?.message);
    }
  };

  let shiftArr = [];
  if (serviceAvailability?.service_availability?.length !== 0) {
    serviceAvailability?.service_availability?.map((val) =>
      shiftArr?.push(val?.shift)
    );
  }
  const final_shiftArr = [...new Set(shiftArr)];

  let arr = [];
  serviceAvailability?.open_days?.map((val) => {
    if (val === "sunday") {
      arr.push(0);
    } else if (val === "monday") {
      arr.push(1);
    } else if (val === "tuesday") {
      arr.push(2);
    } else if (val === "wednesday") {
      arr.push(3);
    } else if (val === "thursday") {
      arr.push(4);
    } else if (val === "friday") {
      arr.push(5);
    } else {
      arr.push(6);
    }
  });

  const isWeekday = (date) => {
    const day = getDay(date);
    return !arr?.includes(day);
  };

  return (
    <>
      <Card className="card">
        <div className="reschedule">
          <div style={{ display: "flex" }}>
            <img
              alt={
                serviceType === "maintenance"
                  ? "Maintenance image"
                  : "Amenity Image"
              }
              src={
                serviceDetails?.service?.services_images[0]?.file_name
                  ? `${process.env.REACT_APP_S3_BUCKET_URL}/${serviceDetails?.service?.services_images[0]?.file_name}`
                  : galleryPlaceholder
              }
              className="reschedule-image"
            ></img>
            <div
              style={{
                padding: "10px 10px",
                alignSelf: serviceType !== "maintenance" ? "center" : "auto",
              }}
            >
              <Meta
                title={
                  serviceDetails?.service?.name
                    ? serviceDetails?.service?.name
                    : "Name"
                }
                description={
                  serviceType !== "maintenance" && (
                    <div>
                      <span className="align-text-bottom">
                        <EnvironmentOutlined />
                      </span>
                      <span style={{ paddingLeft: "5px" }}>
                        {serviceDetails?.service?.city
                          ? serviceDetails?.service?.city
                          : "Unknown"}
                      </span>
                    </div>
                  )
                }
              />
            </div>
          </div>
          <Divider className="divider-border-radius" />
          <div style={{ display: "flex", margin: "30px 5px 10px 22px" }}>
            <div>
              <h5 className="reschedule-heading"> Set Date & Time </h5>
              <span className="preferredDate">Select your preferred date</span>
            </div>
          </div>
          <Form
            name="reschedule"
            className="reschedule-form"
            initialValues={{
              date: selected,
              selectedTags: selectedTags,
            }}
            form={form}
            onFinish={onSubmit}
          >
            <Form.Item
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please input date!",
                },
              ]}
            >
              <div className="dayPicker">
                <DayPicker
                  mode="single"
                  required
                  defaultMonth={new Date(selected)}
                  fromMonth={new Date()}
                  toDate={new Date(2090, 10, 20)}
                  selected={new Date(selected)}
                  onSelect={setSelected}
                  disabled={isWeekday}
                  onMonthChange={setMonth}
                  hidden={isPastDate}
                />
              </div>
            </Form.Item>

            <div style={{ display: "flex", margin: "30px 5px 10px 22px" }}>
              <div style={{ width: "95%" }}>
                <h5>Select Time</h5>
              </div>
            </div>
            <Form.Item
              name="selectedTags"
              rules={[
                {
                  required: true,
                  message: "Please input at least one slot!",
                },
              ]}
            >
              <div className="mx-3">
                <RescheduleMain
                  timeSlots={timeSlots}
                  handleSlotChange={handleSlotChange}
                  selectedShifts={final_shiftArr}
                  selectedTags={selectedTags}
                  handleShiftChange={handleShiftChange}
                  serviceAvailability={
                    serviceAvailability?.service_availability
                  }
                  requiredShift={final_shiftArr}
                />
              </div>
            </Form.Item>
            <Form.Item>
              <div style={{ float: "right", margin: "10px" }}>
                <Button
                  className="servicesBackBtn"
                  onClick={() =>
                    navigate(
                      serviceType === "amenity"
                        ? `/havenly/amenitiesRequests`
                        : `/havenly/maintenanceRequests`,
                      {
                        state: { id: serviceDetails.organization_id },
                      }
                    )
                  }
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="servicesMainBtn"
                  htmlType="submit"
                  style={{ margin: "0 8px" }}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </>
  );
};

export default RescheduleServices;
