import axios from "axios";
import { responseApi } from "../../helpers/helper";
import {
  CREATE_TENANT,
  CREATE_TENANT_ERROR,
  GET_All_TENANTS,
  GET_ALL_TENANTS_ERROR,
  GET_APPROVED_TENANT,
  GET_APPROVED_TENANT_ERROR,
  GET_AWAITING_TENANT,
  GET_AWAITING_TENANT_ERROR,
  STATUS_ORGANIZATION_TENANT,
  STATUS_ORGANIZATION_TENANT_ERROR,
  UPDATE_ORGANIZATION_TENANT,
} from "../types/tenant.types";

export const getAllApprovedTenants =
  (current, peritem, organization_id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tenant/api/cms/tenant-list?page=${current}&per_page=${peritem}&sort_by=created_at&sort_order=DESC`,
        { organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: GET_APPROVED_TENANT,
        payload: res?.data?.data,
      });
      return res?.data;
    } catch (error) {
      dispatch({
        type: GET_APPROVED_TENANT_ERROR,
        payload: error,
      });
      return error;
    }
  };
export const searchApprovedTenants =
  (organization_id, searchedVal, status) =>
  async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tenant/api/cms/search-tenants`,
        { organization_id, search: searchedVal, status },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: GET_APPROVED_TENANT,
        payload: res?.data?.data,
      });
      return res?.data;
    } catch (error) {
      dispatch({
        type: GET_APPROVED_TENANT_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const getAllAwaitingTenants =
  (current, peritem, organization_id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tenant/api/cms/awaiting-tenant-list?page=${current}&per_page=${peritem}&sort_by=created_at&sort_order=DESC`,
        { organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: GET_AWAITING_TENANT,
        payload: res?.data?.data,
      });
      return res?.data;
    } catch (error) {
      dispatch({
        type: GET_AWAITING_TENANT_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const statusTenantAction =
  (organization_id, tenant_id, active, tenants) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tenant/api/cms/change-tenant-status`,
        { active, tenant_id, organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const state = await getState();
      const duplicateTenants = [...tenants];
      const index = await duplicateTenants.findIndex(
        ({ id }) => id === tenant_id
      );
      duplicateTenants[index].active = active;
      const updatedTenantData = {
        tenants: duplicateTenants,
        pagination: state?.tenant?.tenant?.pagination,
      };
      
      dispatch({
        type: UPDATE_ORGANIZATION_TENANT,
        payload: updatedTenantData,
      });
      dispatch({
        type: STATUS_ORGANIZATION_TENANT,
        payload: res?.data,
      });
    
    } catch (error) {
      dispatch({
        type: STATUS_ORGANIZATION_TENANT_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const createTenantAction = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/tenant/api/cms/create-tenant`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
      dispatch({
        type: CREATE_TENANT,
        payload: res?.data,
      });
      return res?.data;
    
  } catch (error) {
    dispatch({
      type: CREATE_TENANT_ERROR,
      payload: error,
    });
    return error;
  }
};

export const getSingleTenant = (tenant_id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/tenant/api/cms/tenant`,
      { tenant_id },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const editTenant = (values) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/tenant/api/cms/edit-tenant`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
      return res?.data;
  } catch (error) {
    return error;
  }
};

export const terminateAllSessionOfTenant = (tenant_id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/tenant/api/cms/terminate-sessions`,
      { tenant_id },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
      return res?.data;
  } catch (error) {
    return error;
  }
};
  export const getAllTenant = (organization_id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tenant/api/cms/all-tenants`,
        {organization_id},
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      ); 
      dispatch({
        type: GET_All_TENANTS,
        payload: res?.data?.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: GET_ALL_TENANTS_ERROR,
        payload: error,
      });
      return error;
    }
  };

 