import {
  GET_USERS,
  USERS_ERROR,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  FIND_EMAIL,
  FIND_EMAIL_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_TENANT_PASSWORD,
  RESET_TENANT_PASSWORD_ERROR,
  CURRENT_USER,
  CURRENT_USER_ERROR,
} from "../types/user.types";
import axios from "axios";
import { responseApi } from "../../helpers/helper";

export const loginAction = (credentials) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/admin/login`,
      credentials
    );
    if (res?.data) {
      // const data = await responseApi(res?.data?.data?.req_id);
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    }
  } catch (error) {
    dispatch({
      type: LOGIN_USER_ERROR,
      payload: error,
    });
    return error;
  }
};
export const logoutAction = () => async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/admin/logout`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res?.data;
    // if (res?.data?.data?.req_id) {
    //   const data = await responseApi(res?.data?.data?.req_id);
    //   return data;
    // }
  } catch (error) {
    return error;
  }
};

export const findEmailAction = (emailVal) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/admin/find-account`,
      emailVal
    );
    dispatch({
      type: FIND_EMAIL,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: FIND_EMAIL_ERROR,
      payload: error,
    });
    return error;
  }
};
export const CheckEmailAction = (emailVal) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/admin/check-email`,
      emailVal
    );
    dispatch({
      type: FIND_EMAIL,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: FIND_EMAIL_ERROR,
      payload: error,
    });
    return error;
  }
};
export const resetPasswordAction = (updates) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/admin/reset-password`,
      updates
    );
    dispatch({
      type: RESET_PASSWORD,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_ERROR,
      payload: error,
    });
    return error;
  }
};
export const resetTenantPasswordAction = (updates) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/tenant/api/app/reset-password`,
      updates
    );
    dispatch({
      type: RESET_TENANT_PASSWORD,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: RESET_TENANT_PASSWORD_ERROR,
      payload: error,
    });
    return error;
  }
};

export const getCurrentUserAction = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/admin/view-profile`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    dispatch({
      type: CURRENT_USER,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: CURRENT_USER_ERROR,
      payload: error,
    });
    return error;
  }
};
