import React, { useState } from "react";
import { Form, Button, Upload, Input } from "antd";
import Vector from "../../../../assets/images/icons/building_generalinfo_grey@3x.png";
import Message from "../../../../assets/images/icons/mail_generalinfo_grey@3x.png";
import Password from "../../../../assets/images/icons/password_generalinfo_grey@3x.png";
import addImgRectangle from "../../../../assets/images/icons/add_img_rectangle.png.png";
import Inputfield from "../../../../components/Inputfield";
import Phone from "../../../../components/PhoneInput";
import Country from "../../../../components/CountryDropdown";
const GeneralInformation = ({
  organizationName,
  setOrganizationName,
  arabicOrganizationName,
  setArabicOrganizationName,
  organizationEmail,
  setOrganizationEmail,
  organizationPassword,
  setOrganizationPassword,
  organizationPhone,
  setOrganizationPhone,
  fileList,
  handleFlagCountry,
  handlePopupModal,
  selected,
  nextProcess,
}) => {
  const [form] = Form.useForm();
  const [nextState, setNextState] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const onSubmit = () => {
    nextProcess();
  };

  form.setFieldsValue({
    image: fileList,
    country: selected,
  });

  return (
    <>
      <Form
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        initialValues={{
          remember: true,
          name: organizationName,
          arabicName: arabicOrganizationName,
          email: organizationEmail,
          password: organizationPassword,
          phone: organizationPhone,
        }}
        form={form}
      >
        <h6 className="sm-title-card mtt-10"> General Information </h6>

        <div className="row">
          <div className="col-lg-6">
            <span className="sub-heading">
              Add your new Organization Details
            </span>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please Input organization name!",
                },
              ]}
            >
              <Inputfield
                className={"mtt-20"}
                prefix={
                  <img
                    alt=""
                    src={Vector}
                    className="site-form-item-icon input-icon-field-twenty"
                  />
                }
                placeholder="Enter your Organization Name"
                onChange={(e) => {
                  setOrganizationName(e.target.value);
                }}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              name="arabicName"
              rules={[
                {
                  required: true,
                  message: "Please Input arabic name!",
                },
              ]}
            >
              <Inputfield
                dir={"rtl"}
                suffix={
                  <img
                    alt=""
                    src={Vector}
                    className="site-form-item-icon input-icon-field-twenty"
                  />
                }
                placeholder="أدخل اسم مؤسستك"
                onChange={(e) => {
                  setArabicOrganizationName(e.target.value);
                }}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input organization email!",
                  validator: (_, value) => {
                    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                      setNextState(true);
                      setEmailError(false);
                      return Promise.resolve();
                    } else {
                      setEmailError(false);
                      return Promise.reject("Please input your e-mail!");
                    }
                  },
                },
              ]}
            >
              <Inputfield
                prefix={
                  <img
                    alt=""
                    src={Message}
                    className="site-form-item-icon input-icon-field"
                  />
                }
                onChange={(e) => {
                  setOrganizationEmail(e.target.value);
                }}
                placeholder="Enter your Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                className="inputfield"
                prefix={
                  <img
                    alt=""
                    src={Password}
                    className="site-form-item-icon input-icon-field-twenty"
                  />
                }
                type="password"
                placeholder="Enter Password"
                onChange={(e) => {
                  setOrganizationPassword(e.target.value);
                }}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
            >
              <Phone
                disabled={false}
                value={organizationPhone}
                onChange={(phone) => setOrganizationPhone(phone)}
              />
            </Form.Item>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please input country!",
                },
              ]}
            >
              <Country
                selected={selected}
                onSelect={(code) => handleFlagCountry(code)}
                disabled={false}
              />
            </Form.Item>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-4">
            <span className="sub-heading">Add organization logo</span>
            <Form.Item
              name="image"
              rules={[
                {
                  required: true,
                  message: "Please upload your image!",
                },
              ]}
            >
              {fileList.length === 0 ? (
                <>
                  <div className="rectangle-frame-img">
                    <img
                      alt=""
                      src={addImgRectangle}
                      className="upload-img-box mtt-20"
                      onClick={handlePopupModal}
                    />
                    <p className="img-warn mtt-10">
                      {" "}
                      Files Supported: PNG, JPEG{" "}
                    </p>
                    <p className="img-warn mtt-10">Image Size (200x200)</p>
                  </div>
                </>
              ) : (
                <>
                  <Upload
                    disabled={true}
                    listType="picture-card"
                    fileList={fileList.slice(-1)}
                    beforeUpload={() => false}
                    onPreview={handlePopupModal}
                  >
                    {fileList.length < 1 && "+"}
                  </Upload>
                  <p className="img-warn mt-1">Files Supported: PNG, JPEG</p>
                  <p className="img-warn mtt-10">Image Size (200x200)</p>
                </>
              )}
            </Form.Item>
          </div>
        </div>
        <div className="d-flex mt-4 align-items-end first-step-bottom-div">
          <div className="col-lg-8">
            <span className="sub-heading"> Step 1 of 3</span>
          </div>
          <div className="col-lg-4 px-3">
            <Button htmlType="submit" type="primary" className="servicesMainBtn">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default GeneralInformation;
