import React from "react";
import { Input } from "antd";
import SearchIcon from "../assets/images/icons/search_grey@3x.png";

const Search = Input.Search;

export const TitleSearch = ({ onSearch, ...props }) => (
  <div {...props}>
    <Input
      className={"search-inputfield"}
      prefix={<img alt="" src={SearchIcon} className="site-form-item-icon" style={{height:'20px'}} />}
      placeholder="Search..."
      onChange={(e) => {
        onSearch(e.target.value);
      }}
      autoComplete="off"
    />
  </div>
);
