import React from 'react'
import {Modal,} from 'antd';
import congratsIcons from '../assets/images/icons/congrats.png'

const Congratulations =({visible, hideModal,message}) =>{
    return (
      <div className='congrats-modal'>
        <Modal
        visible={visible}
        onOk={hideModal}
        okText="Ok"
        className='congrats'
        cancelButtonProps={{ style: { display: 'none' } }}
      >
          <img alt='' src={congratsIcons} className={'congrats-icon'}/> 
         <h5 className="big-text mtt-20"> Congratulations </h5> 
         <p className="big-desc mtt-20">  {message}</p>
      </Modal>
      </div>
    )
}

export default Congratulations