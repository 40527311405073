import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { useDispatch } from "react-redux";
import {
  resetPasswordAction,
  resetTenantPasswordAction,
} from "../../../store/actions/user.action";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../../assets/images/icons/HH-logo.png";
import passwordIcon from "../../../assets/images/icons/password_generalinfo_grey.png";

const SetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [nextState, setNextState] = useState(false);
  const [inputEmail, setInputEmail] = useState(
    localStorage.getItem("UserEmail")
  );
  const [inputPassword, setInputPassword] = useState("");
  const [inputConfirm, setInputConfirm] = useState("");

  const nextValidate = () => {
    if (
      inputEmail !== "" &&
      inputPassword !== "" &&
      inputConfirm !== "" &&
      inputPassword === inputConfirm
    ) {
      setNextState(true);
    } else setNextState(false);
  };

  const onSubmit = async (values) => {
    let reset_password_token = params?.token;
    let type = params?.type;
    let payload = { ...values, reset_password_token: reset_password_token };
    const resetPassword = await dispatch(
      type === "tenant"
        ? resetTenantPasswordAction(payload)
        : resetPasswordAction(payload)
    );
    if (resetPassword.statusCode === 200) {
      message.loading({
        content: "Sending.",
      });
      setTimeout(() => {
        message.success({
          content: "Password Reset Successfully!",
          duration: 4,
        });
        navigate("/");
      }, 1500);
    }
  };

  return (
    <div className="main">
      <div className="container">
        <div className="row normalPlaceholder">
          <div className="col-lg-5 centered">
            <div className="text-center mtt-10-per">
              <img alt="" src={Logo} className="logo-default" />
            </div>
            <div className="container-section">
              <h1 className="def-sm-Title mbb-20"> Reset Password </h1>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                  reset_password_token: "1234",
                }}
                autoComplete="no"
                onFinish={onSubmit}
              >
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    prefix={
                      <img
                        alt=""
                        src={passwordIcon}
                        className="site-form-item-icon"
                      />
                    }
                    className={"inputfield"}
                    placeholder="Please enter Password"
                    onChange={(e) => setInputPassword(e.target.value)}
                    autoComplete="off"
                  />
                </Form.Item>

                <Form.Item
                  name="confirm_password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          if (
                            value ||
                            (getFieldValue("password") === value &&
                              value !== "")
                          ) {
                            setNextState(true);
                          }
                          return Promise.resolve();
                        }
                        setNextState(false);
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    prefix={
                      <img
                        alt=""
                        src={passwordIcon}
                        className="site-form-item-icon"
                      />
                    }
                    className={"inputfield"}
                    placeholder="Please confirm Password"
                    onChange={(e) => setInputConfirm(e.target.value)}
                    onBlur={nextValidate}
                  />
                </Form.Item>

                <Form.Item
                  style={{ display: "none" }}
                  name="reset_password_token"
                >
                  <Input placeholder="Please Enter Email" autoComplete="off" />
                </Form.Item>

                <Form.Item>
                  <div className="text-center">
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={nextState === false}
                      className="mainBtn mt-2"
                    >
                      Confirm
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
