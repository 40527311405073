import { Button, Form, Input, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import Wallet from "../../assets/images/icons/wallet_blue@3x.png";
import BuildingBlue from "../../assets/images/icons/building_blue@3x-3.png";
import BuildingGrey from "../../assets/images/icons/building_eng_grey@3x.png";
import LocationBlue from "../../assets/images/icons/location_blue@3x.png";
import LocationGrey from "../../assets/images/icons/location_grey@3x.png";
import PreviewBlue from "../../assets/images/icons/preview_blue@3x-1.png";
import PreviewGrey from "../../assets/images/icons/preview_grey@3x.png";
import Congratulations from "../../components/congratulations";
import countryList from "react-select-country-list";
import LocationStep from "./components/LocationStep";
import GeneralInformation from "./components/GeneralInformationStep";
import { createTenantAction } from "../../store/actions/tenant.action";
import AddImageModal from "../../components/AddImageModal";
import PreviewStep from "./components/PreviewSteps";
const { Step } = Steps;
const AddNewTenant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [visible, setVisible] = useState(false);
  const [imgVisible, setImgVisible] = useState(false);
  const [tenantWallet, setTenantWallet] = useState("");
  const [orgId, setOrgId] = useState("");
  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [arabicFirstName, setArabicFirstName] = useState("");
  const [arabicLastName, setArabicLastName] = useState("");
  const [tenantEmail, setTenantEmail] = useState("");
  const [tenantPassword, setTenantPassword] = useState("");
  const [tenantPhone, setTenantPhone] = useState("");
  const [tenantCountry, setTenantCountry] = useState("");
  const [DOB, setDOB] = useState(new Date());
  const [tenantGender, setTenantGender] = useState("");
  const [selected, setSelected] = useState("");
  const [tenantDocument, setTenantDocument] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [apartmentNumber, setApartmentNumber] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [state, setState] = useState({});
  const [active, setActive] = useState(false);
  const currentUser = useSelector((state) => state?.user?.currentUser);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const handleChange = ({ fileList: newFileList }) => {
    setTenantDocument(newFileList);
  };

  const handlePopupModal = () => {
    setImgVisible(true);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleFlagCountry = (code) => {
    setSelected(code);
    const countryLabel = countryList().getLabel(code);
    setTenantCountry(countryLabel);
  };

  const onDateChange = (date, dateString) => {
    setDOB(dateString);
  };

  const onGenderChange = ({ target: { value } }) => {
    setTenantGender(value);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const handleDeleteImage = () => {
    setFileList([]);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  useEffect(() => {
    setOrgId(currentUser?.organization_id);
    setUserId(currentUser?.id);
  }, [currentUser]);

  const onSubmit = async (values) => {
    setActive(true);
    const file = fileList.slice(-1);
    let fd = new FormData();
    fd.append("first_name", firstName);
    fd.append("last_name", lastName);
    fd.append("first_name_ar", arabicFirstName);
    fd.append("last_name_ar", arabicLastName);
    fd.append("email", tenantEmail);
    fd.append("password", tenantPassword);
    fd.append("confirm_password", tenantPassword);
    fd.append("phone", tenantPhone);
    fd.append("country", tenantCountry);
    fd.append("gender", tenantGender === "Male" ? 1 : 0);
    fd.append("date_of_birth", DOB);
    fd.append("organization_id", orgId);
    fd.append("image", file[0]?.originFileObj);
    fd.append("document", tenantDocument[0]?.originFileObj);
    fd.append("amount", tenantWallet);
    fd.append("created_by", userId);
    fd.append("building_no", buildingNumber);
    fd.append("apartment_no", apartmentNumber);
    const res = await dispatch(createTenantAction(fd));
    if (res.statusCode === 200) {
      setActive(false);
      showModal();
    } else if (Object.keys(res)?.length === 0) {
      setActive(false);
      showModal();
    } else {
      setActive(false);
      toastr.error(res?.response?.data?.message);
    }
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const nextProcess = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const hideModal = () => {
    setVisible(false);
    navigate("/havenly/tenants", { state: { id: orgId, reset: false } });
  };
  const hideImgModal = () => {
    setImgVisible(false);
  };

  const onSubmitNext = () => {
    nextProcess();
  };

  const steps = [
    {
      title: "Wallet",
      icon: <img alt="" src={Wallet} className="step-icon-size" />,
      content: (
        <div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
              wallet: tenantWallet,
            }}
            onFinish={onSubmitNext}
          >
            <div className="d-flex justify-content-between">
              <h6 className="sm-title-card mtt-10"> Wallet </h6>
              <img alt="" className="tenant-wallet-icon" src={Wallet} />
            </div>
            <Form.Item
              name="wallet"
              rules={[
                {
                  required: true,
                  message: "Please enter balance!",
                },
              ]}
            >
              <div className="d-flex">
                <Input
                  className={"wallet-inputfield mtt-20"}
                  type="number"
                  onChange={(e) => {
                    setTenantWallet(e.target.value);
                  }}
                  defaultValue={tenantWallet}
                />
                <p className="pkr-div">AED</p>
              </div>
            </Form.Item>
            <div className="d-flex justify-content-between align-items-end">
              <div>
                <span className="sub-heading"> Step 1 of 4 </span>
              </div>
              <Button
                htmlType="submit"
                className="servicesMainBtn"
              >
                Next
              </Button>
            </div>
          </Form>
        </div>
      ),
    },
    {
      title: "General Information",
      icon: (
        <img
          alt=""
          src={current === 0 ? BuildingGrey : BuildingBlue}
          className="step-icon-size"
        />
      ),
      content: (
        <div className="mb-5">
          <GeneralInformation
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            arabicFirstName={arabicFirstName}
            setArabicFirstName={setArabicFirstName}
            arabicLastName={arabicLastName}
            setArabicLastName={setArabicLastName}
            tenantEmail={tenantEmail}
            setTenantEmail={setTenantEmail}
            tenantPassword={tenantPassword}
            setTenantPassword={setTenantPassword}
            tenantPhone={tenantPhone}
            setTenantPhone={setTenantPhone}
            selected={selected}
            handleFlagCountry={handleFlagCountry}
            tenantCountry={tenantCountry}
            onDateChange={onDateChange}
            DOB={DOB}
            tenantGender={tenantGender}
            onGenderChange={onGenderChange}
            handleChange={handleChange}
            tenantDocument={tenantDocument}
            setTenantDocument={setTenantDocument}
            fileList={fileList}
            handlePopupModal={handlePopupModal}
            onSubmitNext={onSubmitNext}
            prev={prev}
          />
        </div>
      ),
    },

    {
      title: "Location",
      icon: (
        <img
          alt=""
          src={current > 1 ? LocationBlue : LocationGrey}
          className="step-icon-size"
        />
      ),
      content: (
        <div>
          <LocationStep
            setApartmentNumber={setApartmentNumber}
            setBuildingNumber={setBuildingNumber}
            buildingNumber={buildingNumber}
            apartmentNumber={apartmentNumber}
            onSubmitNext={onSubmitNext}
            prev={prev}
          />
        </div>
      ),
    },
    {
      title: "Preview",
      icon: (
        <img
          alt=""
          src={current === 3 ? PreviewBlue : PreviewGrey}
          className="step-icon-size"
        />
      ),
      content: (
        <div>
          <PreviewStep
            firstName={firstName}
            lastName={lastName}
            arabicFirstName={arabicFirstName}
            arabicLastName={arabicLastName}
            tenantEmail={tenantEmail}
            tenantPassword={tenantPassword}
            tenantPhone={tenantPhone}
            tenantCountry={tenantCountry}
            selected={selected}
            DOB={DOB}
            tenantGender={tenantGender}
            buildingNumber={buildingNumber}
            apartmentNumber={apartmentNumber}
            tenantDocument={tenantDocument}
            fileList={fileList}
            onSubmit={onSubmit}
            onPreview={onPreview}
            prev={prev}
            active={active}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="tabled-div">
        <div className="row">
          <div className="col-lg-3">
            <Steps current={current} direction="vertical">
              {steps.map((item) => (
                <Step key={item.title} title={item.title} icon={item.icon} />
              ))}
            </Steps>
          </div>
          <div className="col-lg-9">
            <div className="steps-content"> {steps[current].content} </div>
          </div>
        </div>
        <Congratulations
          visible={visible}
          hideModal={hideModal}
          message={"Tenant Created Successfully"}
        />
        <AddImageModal
          handleDeleteImage={handleDeleteImage}
          file={fileList}
          state={state}
          visible={imgVisible}
          hideModal={hideImgModal}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default AddNewTenant;
