import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Steps, Button, Checkbox, Modal, Form } from "antd";
import toastr from "toastr";
import countryList from "react-select-country-list";
import { SettingOutlined, UserOutlined } from "@ant-design/icons";
import Building from "../../../assets/images/icons/building_blue@3x-3.png";
import Setting_Grey from "../../../assets/images/icons/Group 101701@3x.png";
import Setting_Blue from "../../../assets/images/icons/service_blue@3x.png";
import Preview_Grey from "../../../assets/images/icons/preview_grey@3x.png";
import Preview_Blue from "../../../assets/images/icons/preview_blue@3x-1.png";
import Congratulations from "../../../components/congratulations";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrganizationAction,
  getAllOrganizationServicesAction,
} from "../../../store/actions/organization.action";
import AddImageModal from "../../../components/AddImageModal";
import GeneralInformation from "./components/GeneralInformation";
import Preview from "./components/Preview";
const { Step } = Steps;

const NewOrganization = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUserId = useSelector((state) => state?.user?.currentUser?.id);
  const [current, setCurrent] = useState(0);
  const [visible, setVisible] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [arabicOrganizationName, setArabicOrganizationName] = useState("");
  const [organizationEmail, setOrganizationEmail] = useState("");
  const [organizationPassword, setOrganizationPassword] = useState("");
  const [organizationPhone, setOrganizationPhone] = useState("");
  const [country, setCountry] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [selected, setSelected] = useState();
  const [fileList, setFileList] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [imgVisible, setImgVisible] = useState(false);
  const [state, setState] = useState({});
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [active, setActive] = useState(false);
  const getServices = async () => {
    const getAllServices = await dispatch(getAllOrganizationServicesAction());
    setAllServices(getAllServices?.data?.services);
  };
  useEffect(() => {
    getServices();
  }, []);

  const data_checkBox = allServices?.map((x) => {
    return {
      value: x?.id,
      label: x?.name,
    };
  });

  const handleFlagCountry = (code) => {
    setSelected(code);
    const countryLabel = countryList().getLabel(code);
    setCountry(countryLabel);
  };

  const hideModal = () => {
    setVisible(false);
    navigate("/havenly/organization-list");
  };

  const getCheckboxLabel = () => {
    if (data_checkBox === undefined) return [];
    const final_service = data_checkBox.filter((x) =>
      selectedServices.includes(x.value)
    );
    return final_service;
  };

  const onCheckChange = (checkedValues) => {
    setSelectedServices(checkedValues);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };
  const next = () => {
    setCurrent(current + 1);
  };
  const nextProcess = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const onSubmit = async (values) => {
    setActive(true);
    const verifyCompany = await dispatch(addOrganizationAction(values));
    if (verifyCompany?.statusCode === 200) {
      setVisible(true);
    } else if (Object.keys(verifyCompany)?.length === 0) {
      setVisible(true);
    } else {
      setActive(false);
      toastr.error(verifyCompany?.response?.data?.message);
    }
  };

  const onSubmitServices = () => {
    nextProcess();
  };
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const handlePopupModal = () => {
    setImgVisible(true);
  };

  const hideImgModal = () => {
    setImgVisible(false);
  };

  const handleDeleteImage = () => {
    setFileList([]);
  };

  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.thumbUrl);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };

  const handleCancel = () => setPreviewVisible(false);

  const steps = [
    {
      title: "General Information",
      icon: <img alt="" src={Building} className="step-icon-size" />,
      content: (
        <div>
          <GeneralInformation
            organizationName={organizationName}
            setOrganizationName={setOrganizationName}
            arabicOrganizationName={arabicOrganizationName}
            setArabicOrganizationName={setArabicOrganizationName}
            organizationEmail={organizationEmail}
            setOrganizationEmail={setOrganizationEmail}
            organizationPassword={organizationPassword}
            setOrganizationPassword={setOrganizationPassword}
            organizationPhone={organizationPhone}
            setOrganizationPhone={setOrganizationPhone}
            country={country}
            fileList={fileList}
            selectedServices={selectedServices}
            selected={selected}
            nextProcess={nextProcess}
            handleFlagCountry={handleFlagCountry}
            handlePopupModal={handlePopupModal}
            currentUserId={currentUserId}
          />
        </div>
      ),
    },
    {
      title: "Services",
      icon: (
        <img
          alt=""
          src={current >= 1 ? Setting_Blue : Setting_Grey}
          className="step-icon-size"
        />
      ),
      content: (
        <Form
          name="normal_login"
          className="login-form"
          onFinish={onSubmitServices}
          initialValues={{
            remember: true,
            services: selectedServices,
          }}
        >
          <div className="Services-Input slotAvailability">
            <h6 className="sm-title-card mtt-10"> Services </h6>
            <span className="sub-heading mtt-20">
              Select the services that you want to assign
            </span>
            <Form.Item
              name="services"
              rules={[
                {
                  required: true,
                  message: "Please select at least one service!",
                },
              ]}
            >
              <Checkbox.Group
                defaultValue={selectedServices}
                options={data_checkBox}
                style={{
                  width: "100%",
                }}
                onChange={onCheckChange}
              ></Checkbox.Group>
            </Form.Item>
            <div className="stepNumber">
              <span className="sub-heading"> Step 2 of 3 </span>
            </div>
            <Form.Item>
              <div className="steps-action-one">
                <Button className="servicesBackBtn" onClick={() => prev()}>
                  Back
                </Button>
                <Button
                  type="primary"
                  className="servicesMainBtn"
                  htmlType="submit"
                  style={{ margin: "0 8px" }}
                >
                  Next
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>
      ),
    },
    {
      title: "Preview",
      icon: (
        <img
          alt=""
          src={current !== 2 ? Preview_Grey : Preview_Blue}
          className="step-icon-size"
        />
      ),
      content: (
        <div className="preview-only">
          <Preview
            organizationName={organizationName}
            arabicOrganizationName={arabicOrganizationName}
            organizationEmail={organizationEmail}
            organizationPassword={organizationPassword}
            organizationPhone={organizationPhone}
            country={country}
            fileList={fileList}
            selectedServices={selectedServices}
            selected={selected}
            onSubmit={onSubmit}
            getCheckboxLabel={getCheckboxLabel}
            normFile={normFile}
            onPreview={onPreview}
            prev={prev}
            currentUserId={currentUserId}
            active={active}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="tabled-div">
        <div className="row">
          <div className="col-lg-3">
            <Steps current={current} direction="vertical">
              {steps.map((item) => (
                <Step key={item.title} title={item.title} icon={item.icon} />
              ))}
            </Steps>
          </div>
          <div className="col-lg-9">
            <div className="steps-content">
              {" "}
              {steps[current].content}{" "}
            </div>
          </div>
        </div>
        <Congratulations
          visible={visible}
          hideModal={hideModal}
          message={"Organization created successfully"}
        />
        <AddImageModal
          handleDeleteImage={handleDeleteImage}
          file={fileList}
          state={state}
          visible={imgVisible}
          hideModal={hideImgModal}
          onChange={onChange}
        />
      </div>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" src={previewImage} />
      </Modal>
    </>
  );
};

export default NewOrganization;
