import React from "react";
import {
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Space,
  Typography,
  Button,
  Upload,
} from "antd";
import { Input } from "antd";
import Message from "../../../assets/images/icons/mail_generalinfo_grey@3x.png";
import Password from "../../../assets/images/icons/password_generalinfo_grey@3x.png";
import createUserLogo from "../../../assets/images/icons/fullname_generalinfo_grey@3x.png";
import addImgRectangle from "../../../assets/images/icons/add_img_rectangle.png.png";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import Phone from "../../../components/PhoneInput";
import Country from "../../../components/CountryDropdown";

function GeneralInformation({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  arabicFirstName,
  setArabicFirstName,
  arabicLastName,
  setArabicLastName,
  tenantEmail,
  setTenantEmail,
  tenantPassword,
  setTenantPassword,
  tenantPhone,
  setTenantPhone,
  selected,
  handleFlagCountry,
  tenantCountry,
  onDateChange,
  DOB,
  tenantGender,
  onGenderChange,
  handleChange,
  tenantDocument,
  setTenantDocument,
  fileList,
  handlePopupModal,
  onSubmitNext,
  prev,
}) {
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";

  form.setFieldsValue({
    tenantGender: tenantGender,
    tenantCountry: selected,
  });
  return (
    <>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
          firstName: firstName,
          lastName: lastName,
          arabicFirstName: arabicFirstName,
          arabicLastName: arabicLastName,
          tenantEmail: tenantEmail,
          tenantPassword: tenantPassword,
          tenantPhone: tenantPhone,
          tenantCountry: tenantCountry,
          DOB: DOB,
          tenantGender: tenantGender,
          image: fileList,
          tenantDocument: tenantDocument,
        }}
        onFinish={onSubmitNext}
        form={form}
      >
        <h6 className="sm-title-card mtt-10"> General Information </h6>

        <div className="row mtt-10">
          <div className="col-lg-6">
            <span className="sub-heading">Add New Member Details</span>

            <Row justify="space-between" style={{ marginBottom: "-18px" }}>
              <Col span={11}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input first name!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-20"}
                    defaultValue={firstName}
                    maxLength={15}
                    prefix={
                      <img
                        alt=""
                        src={createUserLogo}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    placeholder="Enter First Name"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input last name!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-20"}
                    defaultValue={lastName}
                    maxLength={15}
                    prefix={
                      <img
                        alt=""
                        src={createUserLogo}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    placeholder="Enter Last Name"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* <div className="arabic-fields"> */}
            <Row justify="space-between">
              <Col span={11}>
                <Form.Item
                  name="arabicLastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input second arabic name!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-20"}
                    defaultValue={arabicLastName}
                    maxLength={15}
                    suffix={
                      <img
                        alt=""
                        src={createUserLogo}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    lang={"ar"}
                    dir={"rtl"}
                    placeholder="إدخال اسم آخر"
                    onChange={(e) => {
                      setArabicLastName(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="arabicFirstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input first arabic name!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-20"}
                    defaultValue={arabicFirstName}
                    maxLength={15}
                    suffix={
                      <img
                        alt=""
                        src={createUserLogo}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    lang={"ar"}
                    dir={"rtl"}
                    placeholder="أدخل الاسم الأول"
                    onChange={(e) => {
                      setArabicFirstName(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* </div> */}

            <Form.Item
              name="tenantEmail"
              rules={[
                {
                  required: true,
                  message: "Please input email!",
                },
              ]}
            >
              <Input
                className={"inputfield"}
                defaultValue={tenantEmail}
                prefix={
                  <img
                    alt=""
                    src={Message}
                    className="site-form-item-icon input-icon-field"
                  />
                }
                placeholder="Enter your Email"
                onChange={(e) => {
                  setTenantEmail(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              name="tenantPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                className="inputfield"
                prefix={
                  <img
                    alt=""
                    src={Password}
                    className="site-form-item-icon input-icon-field-twenty"
                  />
                }
                type="password"
                placeholder="Enter Your Password"
                defaultValue={tenantPassword}
                onChange={(e) => {
                  setTenantPassword(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              name="tenantPhone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
            >
              <Phone
                disabled={false}
                value={tenantPhone}
                onChange={(phone) => setTenantPhone(phone)}
              />
            </Form.Item>
            <Form.Item
              name="tenantCountry"
              rules={[
                {
                  required: true,
                  message: "Please input country!",
                },
              ]}
            >
              <Country
                disabled={false}
                selected={selected}
                onSelect={handleFlagCountry}
              />
            </Form.Item>
            <Form.Item name="DOB">
              <Typography className="date-of-birth">Date of Birth</Typography>
              <Space direction="vertical" style={{ width: "100%" }}>
                <DatePicker
                  className="inputfield"
                  onChange={onDateChange}
                  defaultValue={moment(DOB, dateFormat)}
                  format={dateFormat}
                />
              </Space>
            </Form.Item>
            <div className="gender-radio-div">
              <Form.Item
                name="tenantGender"
                rules={[
                  {
                    required: true,
                    message: "Please select gender!",
                  },
                ]}
              >
                <Typography className="date-of-birth">Gender</Typography>
                <Radio.Group
                  defaultValue={tenantGender}
                  onChange={onGenderChange}
                  buttonStyle="solid"
                >
                  <Radio.Button
                    value="Male"
                    className={tenantGender === "Male" ? "" : "grey-border"}
                  >
                    <div className="inner-container-radio-gender">
                      <p>Male</p>
                    </div>
                  </Radio.Button>
                  <Radio.Button
                    value="Female"
                    className={tenantGender === "Female" ? "" : "grey-border"}
                  >
                    <div className="inner-container-radio-gender">
                      <p>Female</p>
                    </div>
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
            <Form.Item
              name="tenantDocument"
              rules={[
                {
                  required: true,
                  message: "Please upload your document!",
                },
              ]}
            >
              <Upload
                fileList={tenantDocument}
                accept=".doc,.docx,application/pdf,.png"
                name="avatar"
                listType="picture"
                showUploadList={{ showRemoveIcon: true }}
                onRemove={() => setTenantDocument([])}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={() => false}
                onChange={handleChange}
              >
                {tenantDocument.length < 1 && (
                  <Button className="document-btn">
                    <UploadOutlined /> <p>Upload Document</p>
                  </Button>
                )}
              </Upload>
            </Form.Item>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-4">
            <span className="sub-heading">Add your Photo</span>
            <Form.Item name="organizationImage">
              {fileList.length === 0 ? (
                <>
                  <div className="rectangle-frame-img">
                    <img
                      alt=""
                      src={addImgRectangle}
                      className="upload-img-box mtt-20"
                      onClick={handlePopupModal}
                    />
                    <p className="img-warn mtt-10">
                      Files Supported: PNG, JPEG
                    </p>
                    <p className="img-warn mtt-10">Image Size (200x200)</p>
                  </div>
                </>
              ) : (
                <>
                  <Upload
                    disabled={true}
                    listType="picture-card"
                    fileList={fileList.slice(-1)}
                    beforeUpload={() => false}
                    onPreview={handlePopupModal}
                  >
                    {fileList.length < 1 && "+"}
                  </Upload>
                  <p className="img-warn mt-1">Files Supported: PNG, JPEG</p>
                  <p className="img-warn mtt-10">Image Size (200x200)</p>
                </>
              )}
            </Form.Item>
          </div>
        </div>
        <div className="stepNumber">
          <span className="sub-heading"> Step 2 of 3 </span>
        </div>
        <Form.Item>
          <div className="steps-action-one" style={{ marginTop: "22px" }}>
            <Button className="servicesBackBtn" onClick={() => prev()}>
              Back
            </Button>
            <Button
              type="primary"
              className="servicesMainBtn"
              htmlType="submit"
              style={{ margin: "0 8px" }}
            >
              Next
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default GeneralInformation;
