import { Button, Form, Upload } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addAmenityImage,
  getAmenityImage,
} from "../../../store/actions/amenities.action";
import toastr from "toastr";
import { getOrganizationServicesAction } from "../../../store/actions/organization.action";
import { set } from "lodash";
const UploadImage = ({
  organization_id,
  maintenanceImage,
  previousPage,
  onPreview,
  imageFileList,
  setImageFileList,
}) => {
  const [serviceId, setServiceId] = useState();
  const [flag, setFlag] = useState(false);
  const [addingImage, setAddingImage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onImageChange = ({ fileList: newFileList }) => {
    setImageFileList(newFileList);
    setFlag(true);
  };

  const deleteImage = () => {
    setFlag(true);
  };

  const getImage = async () => {
    let amenityImage = await dispatch(
      getAmenityImage({
        organization_id: organization_id,
        service_id: serviceId,
      })
    );
    if (amenityImage.statusCode === 200) {
      let file = amenityImage?.data?.organization_service;
      file?.file_name !== null &&
        setImageFileList([
          {
            uid: file?.id,
            id: file?.id,
            name: "image.png",
            images: file?.file_name,
            status: "done",
            url: `${process.env.REACT_APP_S3_BUCKET_URL}/${file?.file_name}`,
          },
        ]);
    }
  };
  const AddImage = async () => {
    if (flag === true) {
      setAddingImage(true);
      let fd = new FormData();
      fd.append("organization_id", organization_id);
      fd.append("service_id", serviceId && serviceId);
      fd.append(
        "image",
        imageFileList?.length > 0 ? imageFileList[0]?.originFileObj : null
      );
      let res = await dispatch(addAmenityImage(fd));
      if (res.statusCode === 200) {
        setFlag(false);
        setAddingImage(false);
        let file = res?.data?.organization_service;
        if (file?.file_name !== null) {
          setImageFileList([
            {
              uid: file?.id,
              id: file?.id,
              name: "image.png",
              images: file?.file_name,
              status: "done",
              url: `${process.env.REACT_APP_S3_BUCKET_URL}/${file?.file_name}`,
            },
          ]);
          toastr.success("New Image Added");
        } else {
          toastr.success("Image Deleted Successfully");
        }
      } else {
        setAddingImage(false);
        toastr.error(res?.response?.data?.message);
      }
    }
  };
  const fetchServices = async () => {
    const res = await dispatch(getOrganizationServicesAction(organization_id));
    setServiceId(
      res.data.services?.filter(
        (item) =>
          item?.name === (maintenanceImage ? "Maintenance" : "Amenities")
      )[0]?.service_id
    );
    serviceId !== undefined && getImage();
  };
  useEffect(() => {
    fetchServices();
  }, [serviceId]);

  const uploadButton = (
    <>
      <div>+</div>
    </>
  );
  return (
    <>
      <div className="bannerUpload">
        <h6 className="titleHeading"> Add Image </h6>
        <span className="sub-heading">Add your Photo</span>
        <div className="mtt-10">
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={imageFileList}
            onChange={onImageChange}
            accept=".png,.jpg"
            onRemove={() => deleteImage()}
            onPreview={onPreview}
            beforeUpload={(file) => {
              const acceptedFormats = [
                "PNG",
                "png",
                "jpg",
                "JPG",
                "bmp",
                "BMP",
                "ico",
                "ICO",
                "gif",
                "GIF",
                "jpeg",
                "JPEG",
              ];
              const myArr = file?.name.split(".");
              const myVal = myArr[myArr?.length - 1];
              const formats = acceptedFormats.includes(myVal);
              if (formats === false) {
                toastr.error("Format not Supported");
              }
              return formats ? false : Upload.LIST_IGNORE;
            }}
          >
            {imageFileList?.length < 1 && uploadButton}
          </Upload>
          <span className="desc-grey-font-12 mt-2">
            Files Supported: PNG, JPEG
          </span>
          <span className="desc-grey-font-12 mtt-10">
            Image Size (384 x 115)
          </span>
        </div>
      </div>
      <Form.Item>
        <div style={{ float: "right", marginTop: "30px" }}>
          <Button className="servicesBackBtn" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button
            type="primary"
            className="servicesMainBtn"
            htmlType="submit"
            onClick={() => AddImage()}
            style={{ margin: "0 8px" }}
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </>
  );
};

export default UploadImage;
