export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR';
export const ADD_ORGANIZATION_SUCCESS = 'ADD_ORGANIZATION_SUCCESS';
export const ADD_ORGANIZATION_ERROR = 'ADD_ORGANIZATION_ERROR';
export const STATUS_ORGANIZATION = 'STATUS_ORGANIZATION';
export const STATUS_ORGANIZATION_ERROR = 'STATUS_ORGANIZATION_ERROR';
export const SERVICES_ORGANIZATION = 'SERVICES_ORGANIZATION';
export const SERVICES_ORGANIZATION_ERROR = 'SERVICES_ORGANIZATION_ERROR';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const  CREATE_ORG_USER = ' CREATE_ORG_USER';
export const  CREATE_ORG_USER_ERROR = ' CREATE_ORG_USER_ERROR';
export const UPDATE_ORGANIZATION_MEMBER = 'UPDATE_ORGANIZATION_MEMBER';
export const GET_ORGANIZATION_MEMBERS = 'GET_ORGANIZATION_MEMBERS';
export const GET_ORGANIZATION_MEMBERS_ERROR = 'GET_ORGANIZATION_MEMBERS_ERROR';
export const STATUS_ORGANIZATION_MEMBER = 'STATUS_ORGANIZATION_MEMBER';
export const STATUS_ORGANIZATION_MEMBER_ERROR = 'STATUS_ORGANIZATION_MEMBER_ERROR';
export const GET_ALL_ORGANIZATION_MEMBERS = 'GET_ALL_ORGANIZATION_MEMBERS';
export const GET_ALL_ORGANIZATION_MEMBERS_ERROR = 'GET_ALL_ORGANIZATION_MEMBERS_ERROR';
