import React from "react";
import { Button, Col, Form, Row } from "antd";
import { Input } from "antd";
import Vector from "../../../assets/images/icons/building_eng_grey@3x.png";
function LocationStep({
  setBuildingNumber,
  setApartmentNumber,
  apartmentNumber,
  buildingNumber,
  onSubmitNext,
  prev
}) {
  return (
    <>
     <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
              apartmentNumber: apartmentNumber,
              buildingNumber: buildingNumber,
            }}
            onFinish={onSubmitNext}
          >
            <div className="Services-Input">
              <h6 className="sm-title-card mtt-10"> Location </h6>
              <span className="sub-heading">Add New Member Details</span>
      <Row justify="space-between" style={{ marginTop: "10px" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ padding: "5px" }}>
          <Form.Item
            name="buildingNumber"
            rules={[
              {
                required: true,
                message: "Please input building number!",
              },
            ]}
          >
            <Input
              className={"inputfield"}
              maxLength={7}
              prefix={
                <img alt="" src={Vector} className="site-form-item-icon input-icon-field-twenty" />
              }
              placeholder="Building Number"
              onChange={(e) => {
                setBuildingNumber(e.target.value);
              }}
              defaultValue={buildingNumber}
              autoComplete="off"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ padding: "5px" }}>
          <Form.Item
            name="apartmentNumber"
            rules={[
              {
                required: true,
                message: "Please input apartment number!",
              },
            ]}
          >
            <Input
              className={"inputfield"}
              maxLength={7}
              prefix={
                <img alt="" src={Vector} className="site-form-item-icon input-icon-field-twenty" />
              }
              placeholder="Apartment Number"
              onChange={(e) => {
                setApartmentNumber(e.target.value);
              }}
              defaultValue={apartmentNumber}
              autoComplete="off"
            />
          </Form.Item>
        </Col>
      </Row>
      <br />
              <div className="stepNumber mtt-20">
                <span className="sub-heading"> Step 3 of 4 </span>
              </div>
              <Form.Item>
              <div className="steps-action-one">
                <Button className="servicesBackBtn" 
                onClick={() => prev()}
                >
                  Back
                </Button>
                <Button
                  type="primary"
                  className="servicesMainBtn"
                  htmlType="submit"
                  style={{ margin: "0 8px" }}
                >
                  Next
                </Button>
              </div>
            </Form.Item>
            </div>
          </Form>
    </>
  );
}

export default LocationStep;
