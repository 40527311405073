import {
  AMENITY_DASHBOARD,
  AMENITY_DASHBOARD_ERROR,
  ADD_AMENITIES,
  ADD_AMENITIES_ERROR,
  AMENITIES_REQUESTS,
  AMENITIES_REQUESTS_ERROR,
  STATUS_AMENITY_MEMBER,
  STATUS_AMENITY_MEMBER_ERROR,
  UPDATE_AMENITY_MEMBER,
  TIME_SLOTS,
  TIME_SLOTS_ERROR,
  AMENITIES_LISTING,
  AMENITIES_LISTING_ERROR,
} from "../types/amenities.types";
import axios from "axios";
import { responseApi } from "../../helpers/helper";
export const amenityDashboardAction = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/amenity/api/cms/amenity/dashboard`,
      { organization_id: payload },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    dispatch({
      type: AMENITY_DASHBOARD,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: AMENITY_DASHBOARD_ERROR,
      payload: error,
    });
    return error;
  }
};
export const createOrganizationAmenity = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/amenity/api/cms/amenity/create`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    dispatch({
      type: ADD_AMENITIES,
      payload: res?.data,
    });
    return res?.data;
  } catch (error) {
    dispatch({
      type: ADD_AMENITIES_ERROR,
      payload: error,
    });
    return error;
  }
};
export const editOrganizationAmenity = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/amenity/api/cms/amenity/edit`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const deleteAmenityServiceAvailability =
  (payload) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/amenity/api/cms/amenity/delete-availability`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      return res?.data;
    } catch (error) {
      return error;
    }
  };
export const deleteAmenityServiceImage = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/amenity/api/cms/amenity/delete-amenity-image`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const organizationAmenitiesList =
  (current, perItem, organization_id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/amenity/api/cms/amenity/list?page=${current}&per_page=${perItem}&sort_by=created_at&sort_order=DESC`,
        { organization_id: organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const amenitiesListing = {
        requests: res?.data?.data?.amenities,
        pagination: res?.data?.data?.pagination,
      };
      dispatch({
        type: AMENITIES_LISTING,
        payload: amenitiesListing,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: AMENITIES_LISTING_ERROR,
        payload: error,
      });
      return error;
    }
  };
export const organizationAmenitiesRequestsAction =
  (current, perItem, id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/amenity/api/cms/amenity/booking-list?page=${current}&per_page=${perItem}&sort_by=created_at&sort_order=DESC`,
        { organization_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );

      const amenitiesRequests = {
        requests: res?.data?.data?.amenities,
        pagination: res?.data?.data?.pagination,
        unread_notifications_count: res?.data?.data?.unread_notifications_count,
      };
      dispatch({
        type: AMENITIES_REQUESTS,
        payload: amenitiesRequests,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: AMENITIES_REQUESTS_ERROR,
        payload: error,
      });
      return error;
    }
  };
export const searchAmenitiesRequestsAction =
  (current, perItem, id, searchedVal, searchedDate) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/amenity/api/cms/amenity/search-booked-amenities?page=${current}&per_page=${perItem}&sort_by=created_at&sort_order=ASC`,
        { organization_id: id, search: searchedVal, date: searchedDate },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const amenitiesRequests = {
        requests: res?.data?.data.amenities,
        pagination: res?.data?.data.pagination,
      };
      dispatch({
        type: AMENITIES_REQUESTS,
        payload: amenitiesRequests,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: AMENITIES_REQUESTS_ERROR,
        payload: error,
      });
      return error;
    }
  };
export const amenityReschedule = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/amenity/api/cms/amenity/reschedule`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const getAmenityBanner = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/amenity/api/common/list-banner`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};
export const addAmenityBanner = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/amenity/api/cms/amenity/add-banner`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const deleteAmenityBanner = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/amenity/api/cms/amenity/delete-banner/${payload}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getAmenityImage = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/get-service-image`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};
export const addAmenityImage = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/add-service-image`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const AmenityStatusAction =
  (status, service_booked_id, disapprove_reason, members) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/amenity/api/cms/amenity/change-status`,
        { status, disapprove_reason, service_booked_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const state = await getState();
      const duplicateOrganizationMemberData = [...members];
      const index = await duplicateOrganizationMemberData.findIndex(
        ({ id }) => id === service_booked_id
      );
      duplicateOrganizationMemberData[index].status = status;
      const updateOrganizationMemberData = {
        requests: duplicateOrganizationMemberData,
        pagination: state?.organization?.organizationMembers?.pagination,
      };
      dispatch({
        type: UPDATE_AMENITY_MEMBER,
        payload: updateOrganizationMemberData,
      });
      dispatch({
        type: STATUS_AMENITY_MEMBER,
        payload: res?.data,
      });
    } catch (error) {
      dispatch({
        type: STATUS_AMENITY_MEMBER_ERROR,
        payload: error,
      });
    }
  };
export const getAllTimeSlots = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/amenity/api/common/time-slots`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    dispatch({
      type: TIME_SLOTS,
      payload: res.data.data,
    });
    return res.data.data.slots;
  } catch (error) {
    dispatch({
      type: TIME_SLOTS_ERROR,
      payload: error,
    });
    return error;
  }
};

export const checkOpenDaysAndSlotsInAmenity =
  ({ tenant_id, amenity_service_id, date }) =>
  async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/amenity/api/common/check-availability`,
        { amenity_service_id, tenant_id, date },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      return res.data;
    } catch (error) {
      return error;
    }
  };
