import React from "react";
import {Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons'

const EmptyList = ({text,btnTxt, clickLink}) => {
  return (
    <div className="container">
      <div className="row ">
        <div className="col-lg-4 centered">
          <div className="text-center mtt-20-per">
            <div className="cont-def mtt-20-per">
                <h2 className="grey-defTxt">  {text} </h2>
                    <Button
                        onClick={clickLink}
                        type="primary"
                        className="LgBtn"
                    >
                        <PlusOutlined /> {btnTxt}
                    </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyList;
