import React from "react";
import { Button, Col, Form, Row, Select } from "antd";
import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import amenityEngNameIcon from "../../../assets/images/icons/building_eng_grey@3x.png";
import amenityArabicNameIcon from "../../../assets/images/icons/building_arabic_grey@3x.png";
import amenityEngAmountIcon from "../../../assets/images/icons/amount_eng_grey@3x.png";
import infoIcon from "../../../assets/images/icons/info@3x.png";
import locationIcon from "../../../assets/images/icons/gps_grey@3x.png";
import capacityIcon from "../../../assets/images/icons/capasity_grey@3x.png";

function GeneralInformation({
  setAmenityName,
  setArabicAmenityName,
  setLocality,
  setArabicLocality,
  setAddress,
  setAmount,
  status,
  setStatus,
  setCapacity,
  setDescription,
  setArabicDescription,
  capacity,
  amenityName,
  arabicAmenityName,
  address,
  locality,
  arabicLocality,
  amount,
  description,
  arabicDescription,
  fileList,
  onSubmitNext,
  editForm,
}) {
  return (
    <>
      <Form
        name="add_Amenity"
        className="amenity-form"
        initialValues={{
          remember: true,
          name: amenityName,
          name_ar: arabicAmenityName,
          address: address,
          locality: locality,
          city_ar: arabicLocality,
          amount: amount,
          status: status,
          capacity: capacity,
          description: description,
          description_ar: arabicDescription,
          image: fileList,
        }}
        onFinish={onSubmitNext}
      >
        <h6 className="sm-title-card"> General Information </h6>
        <div className="row mtt-20">
          <div className="addServiceForm">
            <Row justify="space-between">
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: "5px" }}
              >
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input amenity name!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield"}
                    prefix={
                      <img
                        alt=""
                        src={amenityEngNameIcon}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    placeholder="Amenity Name"
                    onChange={(e) => {
                      setAmenityName(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: "5px" }}
              >
                <Form.Item
                  name="name_ar"
                  rules={[
                    {
                      required: true,
                      message: "Please input amenity name in arabic!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield"}
                    suffix={
                      <img
                        alt=""
                        src={amenityArabicNameIcon}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    lang={"ar"}
                    dir={"rtl"}
                    placeholder={`اسم وسائل الراحة`}
                    onChange={(e) => {
                      setArabicAmenityName(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: "5px" }}
              >
                <Form.Item
                  name="locality"
                  rules={[
                    {
                      required: true,
                      message: "Please input locality!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-20"}
                    prefix={
                      <img
                        alt=""
                        src={locationIcon}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    placeholder="Locality"
                    onChange={(e) => {
                      setLocality(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: "5px" }}
              >
                <Form.Item
                  name="city_ar"
                  rules={[
                    {
                      required: true,
                      message: "Please input locality in arabic!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-20"}
                    suffix={
                      <img
                        alt=""
                        src={locationIcon}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    lang={"ar"}
                    dir={"rtl"}
                    placeholder={`المنطقة`}
                    onChange={(e) => {
                      setArabicLocality(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: "5px" }}
              >
                <Form.Item
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Please input valid amount!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-20"}
                    type="number"
                    min={0}
                    prefix={
                      <img
                        alt=""
                        src={amenityEngAmountIcon}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    placeholder="Amount"
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              style={{ padding: "5px" }}
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please input address!",
                },
              ]}
            >
              <Input
                className={"inputfield mtt-20"}
                prefix={
                  <img
                    alt=""
                    src={locationIcon}
                    className="site-form-item-icon input-icon-field-twenty"
                  />
                }
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                placeholder="Address"
              />
            </Form.Item>

            <Row justify="space-between">
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: "5px" }}
              >
                <Form.Item
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "Please input status!",
                    },
                  ]}
                >
                  <div className="mtt-20">
                    {" "}
                    <div
                      className="statusField"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* <div className="statusInput">
                        <img
                          alt=""
                          src={statusIcon}
                          className="site-form-item-icon mt-1 mx-1"
                        /> */}
                    </div>
                    <Select
                      placeholder="Select Status"
                      onClick={(e) => e.stopPropagation()}
                      defaultValue={status && status}
                      onChange={(e) => {
                        setStatus(e);
                      }}
                    >
                      <Option value="1">Active</Option>
                      <Option value="0">Inactive</Option>
                    </Select>{" "}
                  </div>
                  <p className="m-1 mx-0">
                    <img
                      alt=""
                      src={infoIcon}
                      className="input-icon-field-twenty"
                    />
                    <span className="helpingText mx-2">
                      Setting <b>Active</b> will make it appears to clients.
                    </span>
                  </p>
                  {/* </div> */}
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: "5px" }}
              >
                <Form.Item
                  name="capacity"
                  rules={[
                    {
                      required: true,
                      message: "Please input capacity!",
                    },
                  ]}
                >
                  <Input
                    className={"inputfield mtt-20 mb-1"}
                    min="0"
                    prefix={
                      <img
                        alt=""
                        src={capacityIcon}
                        className="site-form-item-icon input-icon-field-twenty"
                      />
                    }
                    type="number"
                    placeholder="Capacity"
                    onChange={(e) => {
                      setCapacity(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </Form.Item>
                <p className="m-1 mx-0">
                  <img
                    alt=""
                    src={infoIcon}
                    className="input-icon-field-twenty"
                  />
                  <span className="helpingText mx-2">
                    Number of people that can be accommodated in one slot.
                  </span>
                </p>
              </Col>
            </Row>

            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input description!",
                },
              ]}
            >
              <TextArea
                showCount
                autoSize={(false, { minRows: 4, maxRows: 6 })}
                placeholder="Description"
                maxLength={100}
                className="desc mtt-20"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              name="description_ar"
              rules={[
                {
                  required: true,
                  message: "Please input description in arabic!",
                },
              ]}
            >
              <TextArea
                showCount
                autoSize={(false, { minRows: 4, maxRows: 6 })}
                lang={"ar"}
                dir={"rtl"}
                placeholder={`وصف`}
                maxLength={100}
                className="desc mtt-20"
                onChange={(e) => {
                  setArabicDescription(e.target.value);
                }}
              />
            </Form.Item>
          </div>
        </div>
        <br />
        {!editForm && (
          <>
            <div className="stepNumber">
              <span className="sub-heading"> Step 1 of 3 </span>
            </div>
            <Form.Item>
              <div className="steps-action-one">
                <Button
                  type="primary"
                  className="servicesMainBtn"
                  htmlType="submit"
                >
                  Next
                </Button>
              </div>
            </Form.Item>
          </>
        )}
      </Form>
    </>
  );
}

export default GeneralInformation;
