import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Upload, Card, Checkbox } from "antd";
import toastr from "toastr";
import "react-phone-number-input/style.css";
import Buildings from "../../assets/images/icons/organization_White-3x.png";
import addImgRectangle from "../../assets/images/icons/add_img_rectangle.png.png";
import PreviewMessage from "../../assets/images/icons/message-white-3x.png";
import countryList from "react-select-country-list";
import Congratulations from "../../components/congratulations";
import { useDispatch } from "react-redux";
import {
  editOrganization,
  getAllOrganizationServicesAction,
  getOrganizationServicesAction,
  getSingleOrganization,
} from "../../store/actions/organization.action";
import AddImageModal from "../../components/AddImageModal";
import Country from "../../components/CountryDropdown";
import Phone from "../../components/PhoneInput";

const EditOrganization = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [imgVisible, setImgVisible] = useState(false);
  const [organizationCountry, setOrganizationCountry] = useState("");
  const [fileList, setFileList] = useState([]);
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState();
  const [data_checkBox, setData_checkBox] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [state, setState] = useState({});
  const [form] = Form.useForm();
  const phone = "";

  form.setFieldsValue({
    organizationImage: fileList
  });
  const setImageUrl = async () => {
    const db = await dispatch(getSingleOrganization(id));
    setOrganizationCountry(db?.data?.organization?.country);
    db?.data?.organization?.image !== null &&
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: `${process.env.REACT_APP_S3_BUCKET_URL}/${db?.data?.organization?.image}`,
        },
      ]);
    const country = await countryList().getValue(
      db?.data?.organization?.country
    );
    setSelected(country);
    form.setFieldsValue({
      name: db?.data?.organization?.name,
      arabicName: db?.data?.organization?.name_ar,
      email: db?.data?.organization?.email,
      phone: db?.data?.organization?.phone,
      organizationImage: fileList
    });
  };

  const getAllService = async () => {
    const getAllServices = await dispatch(getAllOrganizationServicesAction());
    const data_checkBoxTemp = getAllServices?.data?.services
      ?.map((x) => {
        return {
          value: x?.id,
          label: x?.name,
        };
      })
      .filter((x) => x.value !== 7);
    setData_checkBox(data_checkBoxTemp);
  };
  const fetchData = async () => {
    const services = await dispatch(getOrganizationServicesAction(id));
    const checkedServices = services?.data?.services
      ?.map((x) => x.service_id)
      .filter((x) => x !== 7);
    setSelectedServices(checkedServices);
  };
  useEffect(() => {
    getAllService();
    setImageUrl();
    fetchData();
  }, []);

  const handleFlagCountry = (code) => {
    setSelected(code);
    const countryLabel = countryList().getLabel(code);
    setOrganizationCountry(countryLabel);
  };

  const onSubmit = async (values) => {
    if (selectedServices.length === 0) {
      toastr.error("Please Select One Service Minimum");
    } else {
      let fd = new FormData();
      fd.append("name", values?.name);
      fd.append("name_ar", values?.arabicName);
      fd.append("phone", values?.phone);
      fd.append("country", organizationCountry);
      fd.append("services", JSON.stringify(selectedServices));
      fd.append("image", fileList?.slice(-1)[0]?.originFileObj);
      fd.append("organization_id", id);
      setActive(true);
      const res = await dispatch(editOrganization(fd));
      if (res.statusCode === 200) {
        setActive(false);
        showModal();
      } else if (Object.keys(res)?.length === 0) {
        setActive(false);
        showModal();
      } else {
        setActive(false);
        toastr.error(res?.response?.data?.message);
      }
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    navigate(`/havenly/organization-list`);
  };

  const handlePopupModal = () => {
    setImgVisible(true);
  };

  const hideImgModal = () => {
    setImgVisible(false);
  };

  const handleDeleteImage = () => {
    setFileList([]);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };
  const settingValuesInForm = (key, value) => {
    form.setFieldsValue({
      [key]: value,
    });
  };
  const onChangeServices = (value) => {
    setSelectedServices(value);
  };

  const FormInputs = () => {
    return (
      <div className="EditForm">
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please input organization name!",
            },
          ]}
        >
          <Input
            addonBefore={
              <img
                alt=""
                src={Buildings}
                className="preview-icons input-icon-field-twenty"
              />
            }
            placeholder="Enter your Organization Name"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="arabicName"
          rules={[
            {
              required: false,
              message: "Please input arabic name!",
            },
          ]}
        >
          <Input
            addonAfter={
              <img
                alt=""
                src={Buildings}
                className="preview-icons input-icon-field-twenty"
              />
            }
            lang={"ar"}
            dir={"rtl"}
            placeholder="أدخل اسم مؤسستك"
            autoComplete="off"
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input organization name!",
            },
          ]}
        >
          <Input
            disabled={true}
            addonBefore={
              <img
                alt=""
                src={PreviewMessage}
                className="preview-icons input-icon-field"
              />
            }
            placeholder="Enter your Organization Name"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
          ]}
        >
          <Phone
            disabled={false}
            value={phone}
            onChange={(phone) => settingValuesInForm("phone", phone)}
          />
        </Form.Item>
        <Form.Item
          name="country"
          rules={[
            {
              required: false,
              message: "Please input country!",
            },
          ]}
        >
          <Country
            selected={selected}
            onSelect={(code) => handleFlagCountry(code)}
            disabled={false}
          />
        </Form.Item>
      </div>
    );
  };
  return (
    <div>
      <Card className="card">
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
            country: organizationCountry,
            services: selectedServices,
          }}
          form={form}
          onFinish={onSubmit}
        >
          <div className="row mtt-20">
            <div className="col-lg-6">
              {FormInputs()}
              <h6 className="sm-title-card mtt-10"> Selected Services </h6>
              <div className="selected-services mtt-20 slotAvailability">
                {selectedServices && (
                  <Checkbox.Group
                    className="slotChecks"
                    options={data_checkBox}
                    onChange={onChangeServices}
                    style={{
                      width: "100%",
                    }}
                    value={selectedServices}
                  ></Checkbox.Group>
                )}
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-4 preview-only">
              <Form.Item
                name="organizationImage"
                rules={[
                  {
                    required: true,
                    message: "Please enter the image!",
                  },
                ]}
              >
                {fileList.length === 0 ? (
                  <>
                    <div className="rectangle-frame-img">
                      <img
                        alt=""
                        src={addImgRectangle}
                        className="upload-img-box mtt-20"
                        onClick={handlePopupModal}
                      />
                      <p className="img-warn mtt-10">
                        Files Supported: PNG, JPEG
                      </p>
                      <p className="img-warn mtt-10">Image Size (200x200)</p>
                    </div>
                  </>
                ) : (
                  <>
                    <Upload
                      disabled={true}
                      listType="picture-card"
                      fileList={fileList.slice(-1)}
                      beforeUpload={() => false}
                      onPreview={handlePopupModal}
                    >
                      {fileList.length < 1 && "+"}
                    </Upload>
                    <p className="img-warn mtt-10">
                      Files Supported: PNG, JPEG
                    </p>
                    <p className="img-warn mtt-10">Image Size (200x200)</p>
                  </>
                )}
              </Form.Item>
            </div>
          </div>
          <Form.Item>
            <div className="mtt-20 row">
              <div className="col-lg-8"></div>
              <div className="col-lg-4">
                <Button
                  type="primary"
                  className="servicesMainBtn"
                  htmlType="submit"
                  disabled={active}
                >
                  {active ? "Loading..." : "Update"}
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Card>
      <AddImageModal
        handleDeleteImage={handleDeleteImage}
        file={fileList}
        state={state}
        onChange={onChange}
        visible={imgVisible}
        hideModal={hideImgModal}
      />
      <Congratulations
        visible={visible}
        hideModal={hideModal}
        message={"Organization edited successfully"}
      />
    </div>
  );
};

export default EditOrganization;
