import axios from "axios";
import { responseApi } from "../../helpers/helper";
import {
  CREATE_PARCEL,
  CREATE_PARCEL_ERROR,
  GET_PARCEL_REQUESTS,
  GET_PARCEL_REQUESTS_ERROR,
  PARCEL_DASHBOARD,
  PARCEL_DASHBOARD_ERROR,
  STATUS_PARCEL,
  STATUS_PARCEL_ERROR,
  UPDATE_PARCEL,
} from "../types/parcel.types";

export const getAllParcelRequests =
  (current, peritem, organization_id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/parcel/api/cms/list?page=${current}&per_page=${peritem}&sort_by=id&sort_order=DESC`,
        { organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: GET_PARCEL_REQUESTS,
        payload: res?.data?.data,
      });
      return res;
    } catch (error) {
      dispatch({
        type: GET_PARCEL_REQUESTS_ERROR,
        payload: error,
      });
      return error;
    }
  };
export const searchParcelRequests =
  (id, searchedVal, searchedDate) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/parcel/api/cms/search`,
        { organization_id: id, search: searchedVal, date: searchedDate },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );

      dispatch({
        type: GET_PARCEL_REQUESTS,
        payload: res?.data?.data,
      });
      return res;
    } catch (error) {
      dispatch({
        type: GET_PARCEL_REQUESTS_ERROR,
        payload: error,
      });
      return error;
    }
  };

export const statusParcelAction =
  (status, parcel_id, parcel) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/parcel/api/cms/change-status`,
        { status, parcel_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      const state = await getState();
      const duplicateParcel = [...parcel];
      const index = await duplicateParcel.findIndex(
        ({ id }) => id === parcel_id
      );
      duplicateParcel[index].status = status;
      const updatedParcel = {
        parcels: duplicateParcel,
        pagination: state?.parcel?.parcel?.pagination,
      };
        dispatch({
          type: UPDATE_PARCEL,
          payload: updatedParcel,
        });
        dispatch({
          type: STATUS_PARCEL,
          payload: res?.data,
        });
    } catch (error) {
      dispatch({
        type: STATUS_PARCEL_ERROR,
        payload: error,
      });
    }
  };

export const getTenantUsingApartmentAndBuilding =
  (building_no, apartment_no, organization_id) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tenant/api/cms/get-tenant-using-building-aprt`,
        { building_no, apartment_no, organization_id },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      return res.data;
    } catch (error) {
      return error;
    }
  };

export const createParcelAction = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/parcel/api/cms/create`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
      dispatch({
        type: CREATE_PARCEL,
        payload: res?.data,
      });
      return res?.data;
  } catch (error) {
    dispatch({
      type: CREATE_PARCEL_ERROR,
      payload: error,
    });
    return error;
  }
};

export const getParcelImage = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/get-service-image`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};
export const addParcelImage = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/api/org-admin/organization/add-service-image`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const viewParcelDetail = (parcel_id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/parcel/api/cms/parcel`,
      { parcel_id },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const parcelDashboardAction = (organization_id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/parcel/api/cms/dashboard`,
      { organization_id },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    dispatch({
      type: PARCEL_DASHBOARD,
      payload: res.data?.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: PARCEL_DASHBOARD_ERROR,
      payload: error,
    });
    return error;
  }
};

export const deleteParcel = (parcel_id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/parcel/api/cms/delete-parcel`,
      { parcel_id },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );

    if (res?.data?.data?.req_id) {
      const data = await responseApi(res?.data?.data?.req_id);
      return data;
    }
  } catch (error) {
    return error;
  }
};
