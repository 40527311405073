import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Dropdown,
  Menu,
  Select,
  Table,
  Popover,
  Divider,
  Spin,
  Badge,
} from "antd";
import Pagination from "../../components/Pagination";
import { useNavigate, useLocation } from "react-router-dom";
import rescheduleIcon from "../../assets/images/icons/reshedule@3x.png";
import cancelIcon from "../../assets/images/icons/cancel@3x.png";
import ebutler from "../../assets/images/icons/butler.png";
import actionBtn from "../../assets/images/icons/action.png";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import {
  MaintenanceStatusAction,
  organizationMaintenanceRequests,
  searchMaintenanceRequestsAction,
} from "../../store/actions/maintenance.action";
import FilterByDate from "../../components/DateFilter";
import { TitleSearch } from "../../components/SearchFilter";
import moment from "moment";
import { AmPmFormat } from "../../services/DateAndTime";

const { Option } = Select;

const MaintenanceRequests = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState(true);
  const [totalPageItems, setTotalPageItems] = useState(100);
  const [searchDate, setSearchDate] = useState("");
  const reset = location?.state?.reset;
  const id = useSelector((state) => state?.user?.currentUser?.organization_id);
  const services = useSelector((state) => state?.user?.services);
  let currentService = "";
  if (services) {
    currentService = services?.find(
      (item) => item?.service === "Maintenance"
    )?.privilege;
  }
  const notificationArr = useSelector(
    (state) => state?.notifications?.notificationsReceivedType
  );
  const maintenanceRequests = useSelector(
    (state) => state?.maintenance?.maintenance
  );

  const per_page = maintenanceRequests?.pagination?.per_page;
  const page_count = maintenanceRequests?.pagination?.page;

  const getData = async () => {
    if (reset === true) {
      await dispatch(organizationMaintenanceRequests(1, 10, id));
      setLoading(false);
    } else {
      await dispatch(
        organizationMaintenanceRequests(
          page_count ? page_count : pageCount,
          per_page ? per_page : perPage,
          id
        )
      );
      setLoading(false);
      setPageCount(page_count);
      setPerPage(per_page);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (
      notificationArr?.filter((item) => item?.type === "maintenance_booking")
    ) {
      getData();
    }
  }, [notificationArr]);

  useEffect(() => {
    setTotalPageItems(maintenanceRequests?.pagination?.count);
  }, [maintenanceRequests]);
  const handleChange = (status, row) => {
    if (row.services_bookings_slots.length < 1 && status === "1" && row.date) {
      toastr.error("Can't approve tenant with empty timeSlots & date");
      return;
    } else {
      dispatch(
        MaintenanceStatusAction(
          status,
          row?.id,
          "service not available for you",
          maintenanceRequests?.requests
        )
      );
    }
  };
  const handleSearch = async (searchText) => {
    if (searchText || searchDate) {
      const dateFormat = "YYYY-MM-DD";
      let formatDate = moment(searchDate).format(dateFormat);
      await dispatch(
        searchMaintenanceRequestsAction(
          0,
          10,
          id,
          searchText,
          formatDate !== "Invalid date" ? formatDate : searchDate
        )
      );
    } else {
      getData();
    }
  };
  const handlePageChange = async (page, pageSize) => {
    setPageCount(page);
    dispatch(organizationMaintenanceRequests(page, pageSize, id));
  };
  const handleReschedule = (e, maintenanceDetails) => {
    e.stopPropagation();
    navigate(`/havenly/rescheduleServices`, {
      state: { serviceDetails: maintenanceDetails, type: "maintenance" },
    });
  };
  const menu = (
    <Menu
      className="editDropdown rescheduleDropdown"
      items={[
        {
          label: (
            <div
              onClick={(e) => handleReschedule(e, company)}
              className="listing-edit-dropdown d-flex"
            >
              <img
                alt=""
                src={rescheduleIcon}
                className="me-2 mt-1 input-icon-field"
              />
              <span>Reschedule</span>
            </div>
          ),
          className: "rescheduleBtn",
          key: "0",
        }
      ]}
    />
  );
  const columns = [
    {
      title: "",
      align: "right",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <>
          {row?.is_read === "0" && <Badge status="processing" />}
          <Avatar
            alt="tenant image"
            src={
              row?.tenant_image
                ? `${process.env.REACT_APP_S3_BUCKET_URL}/${row?.tenant_image}`
                : ebutler
            }
            className={
              row?.is_read === "1" ? "butler-img margin-left-15 " : "butler-img"
            }
          />
        </>
      ),
    },
    {
      title: "Tenants",
      align: "left",
      dataIndex: "tenant_name",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <span className="form-text-font-design" style={{ marginLeft: "5px" }}>
          {props
            ? props?.slice(0, 20) + (props?.length > 20 ? "..." : "")
            : "N/A"}
        </span>
      ),
    },
    {
      title: "Selected Time",
      dataIndex: "services_bookings_slots",
      responsive: ["xs", "sm", "md"],
      render: (services_bookings_slots) => (
        <Popover
          style={{
            left: "335px",
            top: "318.889px",
            transformOrigin: "50% -4px",
          }}
          placement="bottom"
          content={services_bookings_slots.map((item, i) => {
            return (
              <p>
                {`${AmPmFormat(item.start_time)} to ${AmPmFormat(
                  item.end_time
                )}`}
              </p>
            );
          })}
          title="Selected Time"
        >
          <span className="form-text-font-design">
            {
              services_bookings_slots?.map((service) =>
                service?.start_time
                  ? `${AmPmFormat(service.start_time)}`
                  : "N/A"
              )[0]
            }
          </span>
        </Popover>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Requested Service",
      dataIndex: "maintenance_name",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">
          {props
            ? props?.slice(0, 15) + (props?.length > 15 ? "..." : "")
            : "N/A"}
        </div>
      ),
    },
    {
      title: "Building No.",
      dataIndex: "building_no",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Apt. No.",
      dataIndex: "apartment_no",
      responsive: ["xs", "sm", "md"],
      render: (props) => (
        <div className="form-text-font-design">{props ? props : "N/A"}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["xs", "sm", "md"],
      render: (props, row) => (
        <div>
          <div
            className={
              props === "1"
                ? "activeSelector"
                : props === "2"
                ? "InactiveSelector"
                : "pendingSelector"
            }
          >
            <Select
              defaultValue={
                row.services_bookings_slots.length >= 1 && row.date
                  ? props
                  : "0"
              }
              value={
                row.services_bookings_slots.length < 1 &&
                row.date &&
                props === "1"
                  ? "0"
                  : props
              }
              onChange={(val) => handleChange(val, row)}
              style={{ width: "100%" }}
              disabled={currentService === "read-write" ? false : true}
            >
              <Option value="0">Pending</Option>
              <Option value="1">Approved</Option>
              <Option value="2">Disapproved</Option>
            </Select>{" "}
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      responsive: ["xs", "sm", "md"],
      render: (record) => (
        <div>
          <Dropdown
            onClick={() => setCompany(record)}
            overlay={menu}
            trigger={["click"]}
            disabled={currentService === "read-write" ? false : true}
          >
            <a onClick={(e) => e.stopPropagation()} className="action-menu">
              <img alt="" src={actionBtn} className="actions-col-btn" />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="requestsScreen">
        <Card className="card">
          {loading ? (
            <div className="no-data-found">
              <Spin />
            </div>
          ) : (
            <>
              <div className="row">
                <div className="requests-card-heading mb-3">
                  <h5 className="table-header">Requests List</h5>
                  <div className="filterButtons">
                    <FilterByDate
                      onSearch={handleSearch}
                      setSearchDate={setSearchDate}
                      searchDate={searchDate}
                      getAllData={getData}
                    />
                    <TitleSearch onSearch={handleSearch} className="mx-2" />
                  </div>
                </div>
                <div className="col-lg-12 tabled-data">
                  <Table
                    pagination={false}
                    bordered={false}
                    scroll={{
                      x: 700,
                    }}
                    dataSource={maintenanceRequests?.requests}
                    columns={columns}
                  />
                </div>
              </div>
              <Pagination
                total={totalPageItems}
                defaultPageSize={perPage}
                defaultCurrent={pageCount}
                onChange={handlePageChange}
              />
            </>
          )}
        </Card>
      </div>
    </>
  );
};

export default MaintenanceRequests;
