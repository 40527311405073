import React from "react";
import { Form, Button, Upload, Input, Checkbox } from "antd";
import dummyPlaceholder from "../../../../assets/images/icons/super admin - 1x PNG/upload_profile_pic.png";
import UserLg from "../../../../assets/images/icons/organization_White-3x.png";
import PreviewLock from "../../../../assets/images/icons/Pass@3x.png";
import PreviewMessage from "../../../../assets/images/icons/message-white-3x.png";
import { LockOutlined } from "@ant-design/icons";
import Inputfield from "../../../../components/Inputfield";
import Phone from "../../../../components/PhoneInput";
import Country from "../../../../components/CountryDropdown";
const Preview = ({
  organizationName,
  arabicOrganizationName,
  organizationEmail,
  organizationPassword,
  organizationPhone,
  country,
  fileList,
  selectedServices,
  selected,
  onSubmit,
  getCheckboxLabel,
  normFile,
  onPreview,
  prev,
  currentUserId,
  active,
}) => {
  return (
    <>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
          name: organizationName,
          arabic: arabicOrganizationName,
          email: organizationEmail,
          password: organizationPassword,
          confirm_password: organizationPassword,
          phone: organizationPhone,
          country: country,
          image: fileList,
          services: selectedServices,
          created_by: currentUserId,
        }}
        onFinish={onSubmit}
      >
        <div className="row mtt-20">
          <div className="col-lg-6">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input organization name!",
                },
              ]}
            >
              <Inputfield
                disabled={true}
                addonBefore={
                  <img
                    alt=""
                    src={UserLg}
                    className="preview-icons input-icon-field-twenty"
                  />
                }
                placeholder="Enter your Organization Name"
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              name="arabic"
              rules={[
                {
                  required: true,
                  message: "Please input arabic name!",
                },
              ]}
            >
              <Inputfield
                disabled={true}
                dir={"rtl"}
                addonAfter={
                  <img
                    alt=""
                    src={UserLg}
                    className="input-icon-field-twenty"
                  />
                }
                placeholder="أدخل اسم مؤسستك"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input organization email!",
                },
              ]}
            >
              <Inputfield
                disabled={true}
                addonBefore={
                  <img
                    alt=""
                    src={PreviewMessage}
                    className="preview-icons input-icon-field"
                  />
                }
                placeholder="Enter your Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Inputfield
                disabled={true}
                addonBefore={
                  <img
                    alt=""
                    src={PreviewLock}
                    className="preview-icons input-icon-field-twenty"
                  />
                }
                type="password"
                placeholder="Enter Password"
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item style={{ display: "none" }} name="confirm_password">
              <Input.Password
                className="inputfield"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Enter Password"
              />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
            >
              <Phone
                className="inputfield wd-phone"
                value={organizationPhone}
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please input country!",
                },
              ]}
            >
              {/* <ReactFlagsSelect selected={selected} disabled={true} /> */}

              <Country selected={selected} disabled={true} />
            </Form.Item>

            <h6 className="sm-title-card mtt-10"> Selected Services </h6>
            <div className="selected-services mtt-20">
              <Form.Item
                name="services"
                rules={[
                  {
                    required: true,
                    message: "Please select services!",
                  },
                ]}
              >
                <Checkbox.Group
                  disabled={true}
                  options={getCheckboxLabel()}
                  style={{
                    width: "100%",
                  }}
                ></Checkbox.Group>
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-4">
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Please upload pictures!",
                },
              ]}
            >
              {fileList.length >= 1 ? (
                <>
                  <Upload
                    disabled={true}
                    listType="picture-card"
                    fileList={fileList.slice(-1)}
                    beforeUpload={() => false}
                    onPreview={onPreview}
                  >
                    {fileList.length < 1 && "+"}
                  </Upload>
                  <p className="img-warn mt-1">Files Supported: PNG, JPEG</p>
                  <p className="img-warn mtt-10">Image Size (200x200)</p>
                </>
              ) : (
                <div className="rectangle-frame-img">
                  <img
                    alt=""
                    src={dummyPlaceholder}
                    className="upload-img-box"
                  />
                  <p className="img-warn mtt-10">Files Supported: PNG, JPEG</p>
                  <p className="img-warn mtt-10">Image Size (200x200)</p>
                </div>
              )}
            </Form.Item>

            <Form.Item
              name="created_by"
              style={{ display: "none" }}
              rules={[
                {
                  required: true,
                  message: "Please input organization email!",
                },
              ]}
            >
              <Input className={"inputfield"} />
            </Form.Item>
          </div>

          <div className="stepNumber">
            <span className="sub-heading"> Step 3 of 3 </span>
          </div>
          <Form.Item>
            <div className="steps-action-one">
              <Button className="servicesBackBtn" onClick={() => prev()}>
                Back
              </Button>
              <Button
                type="primary"
                className="servicesMainBtn"
                htmlType="submit"
                style={{ margin: "0 8px" }}
                disabled={active}
              >
           {active ? "Loading..." :  "Finish"}
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default Preview;
